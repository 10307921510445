import { faPipe } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';

import { Sport } from '__generated__/globalTypes';
import { FontAwesomeIcon } from 'atoms/icons';
import { MLBLogo } from 'atoms/icons/MLBLogo';
import { NBALogo } from 'atoms/icons/NBALogo';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { FootballLogo } from 'routing/MultiSportAppBar/Sport/Football';

export const sportLogos: Record<Sport, ReactNode> = {
  [Sport.FOOTBALL]: <FootballLogo />,
  [Sport.NBA]: <NBALogo />,
  [Sport.BASEBALL]: <MLBLogo />,
};

export const SportLogo = ({ sport }: { sport: Sport }) => {
  return (
    <>
      <SorareLogo />
      {[Sport.BASEBALL, Sport.NBA].includes(sport) && (
        <FontAwesomeIcon icon={faPipe} color="var(--c-white)" />
      )}
      {sportLogos[sport]}
    </>
  );
};
