import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';

export const useComposeFlowFF = () => {
  const {
    flags: {
      useComposeFlow20: useComposeFlow20Football = false,
      useComposeFlow20Nba = false,
    },
  } = useFeatureFlags();

  const { sport } = useSportContext();

  if (sport === Sport.FOOTBALL) {
    return useComposeFlow20Football;
  }

  if (sport === Sport.NBA) {
    return useComposeFlow20Nba;
  }

  return false;
};
