import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';

import {
  ItemImgContainer,
  ItemInfosContainer,
} from 'components/ItemPreview/ui';
import CardDescription from 'components/token/CardDescription';
import FlexCard from 'components/token/FlexCard';

import { SaleDetails } from './SaleDetails';
import { Sale_offer } from './__generated__/index.graphql';

const Root = styled(Horizontal).attrs({ gap: 1.5 })``;

type Props = {
  sale: Sale_offer;
};

export const Sale = ({ sale }: Props) => {
  const card = sale.senderSide.cards[0];

  return (
    <Root>
      <ItemImgContainer>
        <FlexCard card={card} withLink />
      </ItemImgContainer>
      <ItemInfosContainer>
        <CardDescription
          card={card}
          Details={LabelM}
          detailsColor="var(--c-nd-600)"
        />
        <SaleDetails sale={sale} card={card} allowColumnLayout showFees />
      </ItemInfosContainer>
    </Root>
  );
};

Sale.fragments = {
  offer: gql`
    fragment Sale_offer on TokenOffer {
      id
      senderSide {
        id
        cards: anyCards {
          slug
          ...FlexCard_anyCard
          ...SaleDetails_anyCard
          ...CardDescription_anyCard
        }
      }
      ...SaleDetails_offer
    }
    ${FlexCard.fragments.anyCard}
    ${SaleDetails.fragments.anyCard}
    ${CardDescription.fragments.anyCard}
    ${SaleDetails.fragments.offer}
  ` as TypedDocumentNode<Sale_offer>,
};
