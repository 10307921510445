import { TypedDocumentNode, gql } from '@apollo/client';
import {
  FormattedList,
  FormattedMessage,
  defineMessages,
  useIntl,
} from 'react-intl';

import { scarcityMessages } from '@sorare/core/src/lib/scarcity';

import { getCommonDraftCampaignStatus } from 'components/unlockCompetition/getCommonDraftCampaignStatus';
import { getMissingCards } from 'components/unlockCompetition/getMissingCards';

import { MissingCardsMessage_so5Leaderboard } from './__generated__/index.graphql';

type Props = {
  so5Leaderboard: MissingCardsMessage_so5Leaderboard;
  hideCommonCardsMessages?: boolean;
};

interface CardsCount {
  common?: number;
  limited: number;
  rare: number;
  super_rare: number;
  unique: number;
}

const messages = defineMessages({
  missingCards: {
    id: 'MissingCardsMessage.raritiesRequired',
    defaultMessage:
      '{rarities} {totalNb, plural, one {card} other {cards}} required',
  },
});

export const MissingCardsMessage = ({
  so5Leaderboard,
  hideCommonCardsMessages,
}: Props) => {
  const { formatMessage, formatNumber } = useIntl();
  const { canCompose } = so5Leaderboard;
  if (!canCompose.notEnoughEligibleCards) {
    return (
      <FormattedMessage
        id="MissingCardsMessage.canNotPlay"
        defaultMessage="You cannot play in this competition"
      />
    );
  }

  const combinedMissingCards = getMissingCards(canCompose);

  const getTranslatedRarities = (missingCards: CardsCount) => {
    return Object.entries(missingCards)
      .filter(([, nb]) => nb > 0)
      .map(([rarity, nb]) => {
        return `${formatNumber(nb)} ${formatMessage(
          scarcityMessages[rarity as keyof typeof missingCards]
        )}`;
      });
  };

  const buyableMissingCards: CardsCount = { ...combinedMissingCards };
  delete buyableMissingCards.common;

  const buyableMissingCardsCounts = Object.values(combinedMissingCards).filter(
    value => {
      return value > 0;
    }
  );

  if (buyableMissingCardsCounts.length && hideCommonCardsMessages) {
    const translatedRarities = getTranslatedRarities(buyableMissingCards);
    return (
      <FormattedMessage
        {...messages.missingCards}
        values={{
          rarities: (
            <FormattedList type="conjunction" value={translatedRarities} />
          ),
          totalNb: canCompose.missingCards,
        }}
      />
    );
  }

  if (combinedMissingCards.common > 0) {
    const { canDraft, canSwap } = getCommonDraftCampaignStatus(so5Leaderboard);
    if (canDraft) {
      return (
        <FormattedMessage
          id="MissingCardsMessage.commons"
          defaultMessage="You need to draft Common Cards to play this competition"
        />
      );
    }
    if (canSwap) {
      return (
        <FormattedMessage
          id="MissingCardsMessage.swap"
          defaultMessage="You need to swap Common Cards to play this competition"
        />
      );
    }
    return (
      <FormattedMessage
        id="MissingCardsMessage.noWayToGetCommon"
        defaultMessage="Come back next Game Week to get Common Cards"
      />
    );
  }

  const translatedRarities = getTranslatedRarities(combinedMissingCards);

  return (
    <FormattedMessage
      {...messages.missingCards}
      values={{
        rarities: (
          <FormattedList type="conjunction" value={translatedRarities} />
        ),
        totalNb: canCompose.missingCards,
      }}
    />
  );
};

MissingCardsMessage.fragments = {
  so5Leaderboard: gql`
    fragment MissingCardsMessage_so5Leaderboard on So5Leaderboard {
      slug
      canCompose {
        notEnoughEligibleCards
        missingCards
        ...getMissingCards_validity
      }
      ...getCommonDraftCampaignStatus_so5Leaderboard
    }
    ${getMissingCards.fragments.validity}
    ${getCommonDraftCampaignStatus.fragments.so5Leaderboard}
  ` as TypedDocumentNode<MissingCardsMessage_so5Leaderboard>,
};
