/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypedDocumentNode, gql } from '@apollo/client';
import { isFuture, isPast } from 'date-fns';
import { MessageDescriptor, defineMessages } from 'react-intl';

import {
  GameStatus,
  Position as GlobalPosition,
  Rarity,
  So5FixtureEvent,
  So5LeaderboardSeasonality,
  So5State,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import defaultShield from '@sorare/core/src/assets/club/shield_none.png';
import {
  ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG,
  ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID,
  ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG_MANAGERTEAMID,
} from '@sorare/core/src/constants/__generated__/routes';
import { FRONTEND_ASSET_HOST } from '@sorare/core/src/constants/assets';
import {
  ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD,
  ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
  ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
} from '@sorare/core/src/constants/routes';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { sortByArrayIndex } from '@sorare/core/src/lib/arrays';
import {
  CamelCaseScarcity,
  Scarcity,
  isMyCardListedOnMarket,
  isSentInDirectOffer,
} from '@sorare/core/src/lib/cards';
import { isType, withFragments } from '@sorare/core/src/lib/gql';
import { asObject } from '@sorare/core/src/lib/json';
import {
  PlayablePosition,
  anyPositionShortNames,
  isFootballPosition,
  playablePositions,
} from '@sorare/core/src/lib/players';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import {
  NegativeDecisiveStatKey,
  PositiveDecisiveStatKey,
  negativeDecisiveStatKeys,
  positiveDecisiveStatKeys,
} from '@sorare/core/src/lib/scoring';
import { FixtureState } from '@sorare/core/src/lib/so5';

import {
  getAddTeamPath_so5LeaderboardGroupInterface,
  getCompetitionPictureUrl_competition,
  getDecisiveActions_playerGameScoreInterface,
  getFixtureState_so5Fixture,
  getTeamName_team,
  hasGamesStarted_so5Lineup,
  isAppearanceLocked_so5Appearance,
  isCardUsed_anyCard,
  isFixtureClosed_so5Fixture,
  isFixtureLive_so5Fixture,
  isFixtureOpened_so5Fixture,
  isFixtureStarted_so5Fixture,
  teamPictureUrl_teamInterface,
} from './__generated__/so5.graphql';

const maxPower: {
  common: number;
  limited: number;
  rare: number;
  super_rare: number;
  unique: number;
  custom_series: number;
} = {
  common: 0.1,
  limited: 0.1,
  rare: 0.1,
  super_rare: 0.3,
  unique: 0.5,
  custom_series: 0.1,
};

export const leagues = [
  'rookie',
  'all_star',
  'under_twenty_one',
  'weekly',
  'jupiler',
  'europe',
  'america',
  'asia',
  'training_center',
  'unique_only',
] as const;
export type League = (typeof leagues)[number];
const leagueOrder: League[] = [
  'rookie',
  'all_star',
  'under_twenty_one',
  'unique_only',
  'weekly',
  'jupiler',
  'europe',
  'america',
  'asia',
  'training_center',
];

export const orderLeagues = <T extends { name: string; category: string }>(
  so5Leagues: T[]
): T[] =>
  [...so5Leagues].sort(
    (a, b) =>
      leagueOrder.indexOf(a.name as League) -
      leagueOrder.indexOf(b.name as League)
  );

export const isSpecialWeeklyEvent = (so5Fixture: {
  specialWeeklyBanner: any;
}) => so5Fixture.specialWeeklyBanner !== null;

export type EditableLineupItem<TBenchObject, TCard> = {
  benchObject: TBenchObject | null;
  anyCard: TCard | null;
  captain?: boolean;
  boost?: boolean;
};

export type EditableLineup<O, C> = {
  readonly [key in Position]: EditableLineupItem<O, C>;
};

export const emptyComposeTeamObject: EditableLineupItem<any, any> = {
  anyCard: null,
  benchObject: null,
};

export const emptyLineupByPosition: EditableLineup<any, any> = {
  [GlobalPosition.Goalkeeper]: { ...emptyComposeTeamObject },
  [GlobalPosition.Defender]: { ...emptyComposeTeamObject },
  [GlobalPosition.Midfielder]: { ...emptyComposeTeamObject },
  [GlobalPosition.Forward]: { ...emptyComposeTeamObject },
  'Extra Player': { ...emptyComposeTeamObject },
};

export const emptyLineup: {
  __typename: 'So5Lineup';
  id: string;
  name: string | null;
  so5Appearances: [];
  socialPictureUrls: {
    __typename: 'SocialPictureDerivative';
    post: string | null;
    story: string | null;
    square: string | null;
  };
  so5Fixture: {
    __typename: 'So5Fixture';
    slug: string;
    aasmState: string;
    type: So5FixtureEvent;
    endDate: Date;
    live: boolean;
  };
  so5Leaderboard: {
    __typename: 'So5Leaderboard';
    slug: string;
    displayName: string;
  } | null;
} = {
  __typename: 'So5Lineup',
  id: '',
  name: null,
  so5Appearances: [],
  socialPictureUrls: {
    __typename: 'SocialPictureDerivative',
    post: null,
    story: null,
    square: null,
  },
  so5Fixture: {
    __typename: 'So5Fixture',
    slug: '',
    aasmState: '',
    type: So5FixtureEvent.CLASSIC,
    endDate: new Date(),
    live: false,
  },
  so5Leaderboard: null,
};

export const hasAppearanceBeenUpdated = ({
  bonus,
}: {
  bonus: number | null;
}) => {
  return bonus !== null;
};

export type Position = PlayablePosition | 'Extra Player';

export const getMaxPower = (scarcity: Scarcity) => {
  return maxPower[scarcity];
};

export const isFixtureStarted = withFragments(
  (fixture: isFixtureStarted_so5Fixture): boolean =>
    ['started', 'computed', 'closed'].includes(fixture.aasmState),
  {
    so5Fixture: gql`
      fragment isFixtureStarted_so5Fixture on So5Fixture {
        slug
        aasmState
      }
    ` as TypedDocumentNode<isFixtureStarted_so5Fixture>,
  }
);
export const isFixtureOpened = withFragments(
  (fixture: isFixtureOpened_so5Fixture): boolean =>
    fixture.aasmState === 'opened',
  {
    so5Fixture: gql`
      fragment isFixtureOpened_so5Fixture on So5Fixture {
        slug
        aasmState
      }
    ` as TypedDocumentNode<isFixtureOpened_so5Fixture>,
  }
);
export const isFixtureLive = withFragments(
  (fixture: isFixtureLive_so5Fixture): boolean =>
    fixture.aasmState === 'started' &&
    (fixture.live || isFuture(fixture.endDate)),
  {
    so5Fixture: gql`
      fragment isFixtureLive_so5Fixture on So5Fixture {
        slug
        aasmState
        endDate
        live
      }
    ` as TypedDocumentNode<isFixtureLive_so5Fixture>,
  }
);
export const isFixtureClosed = withFragments(
  (fixture: { aasmState: string }): boolean => fixture.aasmState === 'closed',
  {
    so5Fixture: gql`
      fragment isFixtureClosed_so5Fixture on So5Fixture {
        slug
        aasmState
      }
    ` as TypedDocumentNode<isFixtureClosed_so5Fixture>,
  }
);

export const hasGamesStarted = withFragments(
  (so5Lineup: hasGamesStarted_so5Lineup) => {
    return so5Lineup.anyEarliestGame
      ? isPast(so5Lineup.anyEarliestGame.date)
      : true;
  },
  {
    so5Lineup: gql`
      fragment hasGamesStarted_so5Lineup on So5Lineup {
        id
        anyEarliestGame {
          id
          date
        }
      }
    ` as TypedDocumentNode<hasGamesStarted_so5Lineup>,
  }
);

export const getFixtureState = withFragments(
  (so5Fixture: Nullable<getFixtureState_so5Fixture>) => {
    if (!so5Fixture) {
      return undefined;
    }
    if (isFixtureOpened(so5Fixture)) {
      return FixtureState.opened;
    }
    if (isFixtureLive(so5Fixture)) {
      return FixtureState.started;
    }
    if (isFixtureClosed(so5Fixture)) {
      return FixtureState.closed;
    }
    return undefined;
  },
  {
    so5Fixture: gql`
      fragment getFixtureState_so5Fixture on So5Fixture {
        slug
        ...isFixtureOpened_so5Fixture
        ...isFixtureLive_so5Fixture
        ...isFixtureClosed_so5Fixture
      }
      ${isFixtureOpened.fragments.so5Fixture}
      ${isFixtureLive.fragments.so5Fixture}
      ${isFixtureClosed.fragments.so5Fixture}
    ` as TypedDocumentNode<getFixtureState_so5Fixture>,
  }
);

export const isAllStar = (league: { name: string }) =>
  league.name === 'all_star';

export const isUnderTwentyThree = (league: { name: string }) =>
  league.name === 'under_twenty_one';

export const isUnique = (league: { name: string }) =>
  league.name === 'unique_only';

export const isGlobal = (league: { category: string }) =>
  league.category === 'global';

export const isPremierLeague = (league: { name: string }) =>
  league.name === 'england';

export const hasBonuses = (leaderboard: {
  engineConfiguration: {
    scarcity: string | number | boolean | { [key: string]: any } | null;
    grade: number | null;
    season: number | null;
  };
}) =>
  Object.keys(asObject(leaderboard.engineConfiguration || {})).filter(
    bonus => bonus !== 'captain'
  ).length > 0;

export type EngineConfiguration = {
  captain: number;
  scarcity: { [key in Rarity]?: number };
};

export const getEngineConfigurationScarcities = (
  scarcity: EngineConfiguration['scarcity']
): Partial<Record<CamelCaseScarcity, number>> => {
  if (!scarcity) return {};
  const scarcities = {
    ...scarcity,
    superRare: scarcity.super_rare,
  };
  delete scarcities.super_rare;
  return scarcities;
};

export const ScoreModifier = {
  None: '',
  NoPoints: 'noPoints',
  Less: 'less',
  More: 'more',
} as const;
type ScoreModifierKeys = keyof typeof ScoreModifier;
export type ScoreModifierValues = (typeof ScoreModifier)[ScoreModifierKeys];

export const getScoreModifiers = (
  rawModifier?: number
): { score: ScoreModifierValues; scoreModifier: number } => {
  const scoreModifier = rawModifier || 0;
  let score: ScoreModifierValues = ScoreModifier.None;
  if (scoreModifier === -1) {
    score = ScoreModifier.NoPoints;
  } else if (scoreModifier < 0) {
    score = ScoreModifier.Less;
  } else if (scoreModifier > 0) {
    score = ScoreModifier.More;
  }
  return { score, scoreModifier };
};

export type FormationName = 'defensive' | 'dense' | 'attacking' | 'default';
export type Formation = {
  name: FormationName;
  formation: string;
};

export const extraPlayerPosition: {
  [key in FormationName]: PlayablePosition | null;
} = {
  defensive: GlobalPosition.Defender,
  dense: GlobalPosition.Midfielder,
  attacking: GlobalPosition.Forward,
  default: null,
};

export const formationFromExtraPlayerPosition: {
  [key in PlayablePosition]: FormationName;
} = {
  Defender: 'defensive',
  Midfielder: 'dense',
  Forward: 'attacking',
  Goalkeeper: 'defensive',
};

export const getPositionSelectionOrder = (
  formation: FormationName
): Position[] => {
  const extraPosition = extraPlayerPosition[formation];

  const result: Position[] = [...playablePositions].reverse();

  const extraPlayerIndex = extraPosition
    ? result.indexOf(extraPosition) + 1
    : 5;

  result.splice(extraPlayerIndex, 0, 'Extra Player');
  return result;
};

export const isGameCancelled = (gameStatus: GameStatus): boolean =>
  gameStatus === GameStatus.suspended ||
  gameStatus === GameStatus.cancelled ||
  gameStatus === GameStatus.postponed;

export const isGameLive = (gameStatus?: GameStatus): boolean =>
  gameStatus === GameStatus.playing;

export const isGameStarted = (gameStatus: Nullable<GameStatus>): boolean =>
  gameStatus === GameStatus.played || gameStatus === GameStatus.playing;

export const isGameFinished = (gameStatus?: GameStatus): boolean =>
  gameStatus === GameStatus.played;

export const isGameLeft = (gameStatus: GameStatus): boolean =>
  gameStatus === GameStatus.scheduled || gameStatus === GameStatus.playing;

export const isGameScheduled = (gameStatus?: GameStatus): boolean =>
  gameStatus === GameStatus.scheduled;

export const positionShortNames: Record<
  GlobalPosition | Position,
  MessageDescriptor
> = {
  ...anyPositionShortNames,
  ...defineMessages({
    'Extra Player': {
      id: 'Player.shortExtraPlayer',
      defaultMessage: 'Extra',
    },
  }),
};

type AppearanceWithPosition = {
  __typename: 'So5Appearance';
  captain: boolean;
  id: string;
  card: {
    position: GlobalPosition;
  } | null;
};

export const emptyAppearance: {
  captain: boolean;
  id: string;
  card: any;
} = {
  captain: false,
  id: '',
  card: null,
};

export const getAppearancesByPosition = <T extends AppearanceWithPosition>(
  appearances: T[]
): { [key in Position]: T | typeof emptyAppearance } =>
  appearances.reduce(
    (acc, app) => {
      const { position } = app.card || {};
      if (position === GlobalPosition.Coach) return acc;
      if (position === GlobalPosition.Unknown) return acc;
      if (!isFootballPosition(position)) return acc;

      if (acc[position]?.card) {
        acc['Extra Player'] = { ...app };
      } else {
        acc[position] = { ...app };
      }
      return acc;
    },
    {
      [GlobalPosition.Goalkeeper]: { ...emptyAppearance },
      [GlobalPosition.Defender]: { ...emptyAppearance },
      [GlobalPosition.Midfielder]: { ...emptyAppearance },
      [GlobalPosition.Forward]: { ...emptyAppearance },
      'Extra Player': { ...emptyAppearance },
    }
  );

export const startedLineupSharingMessages = defineMessages({
  set1: {
    id: 'StartedLineupSharingMessages.lineup.set1',
    defaultMessage:
      '🏆 Can you beat my team score in the {displayName} competition on #Sorare? Let’s see what you got! 💪 {link}',
  },
  set2: {
    id: 'StartedLineupSharingMessages.lineup.set2',
    defaultMessage:
      '💥 My #Sorare line-up in the {displayName} competition was on fire! 🔥 Think you can do better? Prove it! {link}',
  },
  set3: {
    id: 'StartedLineupSharingMessages.lineup.set3',
    defaultMessage:
      '🔭 Another scouting masterclass from {clubName} in the {displayName} competition. Think you know football better? See you on #Sorare! {link}',
  },
});

export const notStartedLineupSharingMessages = defineMessages({
  set1: {
    id: 'NotStartedLineupSharingMessages.lineup.set1',
    defaultMessage:
      '🚀 I’ve set my line-up for the {displayName} competition! Are you game? Join me on #Sorare and let’s compete! 🥇 {link}',
  },
  set2: {
    id: 'NotStartedLineupSharingMessages.lineup.set2',
    defaultMessage:
      '⚡️ It’s almost time for the {displayName} competition! Get your teams ready and challenge me on #Sorare! 🏆 {link}',
  },
  set3: {
    id: 'NotStartedLineupSharingMessages.lineup.set3',
    defaultMessage:
      '🌟 The {displayName} competition is coming up, and my team is ready to win! Can you beat me? Catch you on #Sorare! 🕶 {link}',
  },
});

// sorted by display order
export const HANDLED_RULES = [
  'rarityLimits',
  'age',
  'captainRarities',
  'sameActiveClub',
  'seasonBonus',
  'seasonBonusForSpecificTeams',
  'minimumPlayersAverageScore',
  'maximumPlayersAverageScore',
  'scarcity',
  'allowLegend',
  'cardEditionsCount',
  'sumOfAverageScores',
  'averageScores',
  'seasons',
  'leagues',
  'competitions',
  'internationalCompetitions',
  'notDomesticCompetitions',
  'activeClubs',
  'sameNationality',
  'serialNumber',
  'nationalities',
  'notNationalities',
  'atLeastOfCompetitions',
  'atLeastOfClubs',
  'nationalitiesCount',
] as const;

export const ELIGIBILITY_RULES = ['cardsCountOfCurrentUser'] as string[];

export const captainDialogMessages = defineMessages({
  default: {
    id: 'CaptainDialog.subtitle',
    defaultMessage:
      'The player you select as captain will get a 50% bonus to their score. Pick someone you think will perform very well this Game Week!',
  },
});

const TOURNAMENT_NAMES: { [so5LeaderboardTypePrefix: string]: string } = {
  FIRST_DIVISION_FRANCE: 'Ligue 1',
  FIRST_DIVISION_GERMANY: 'Bundesliga',
  FIRST_DIVISION_ENGLAND: 'Premier League',
  FIRST_DIVISION_ITALY: 'Serie A',
  FIRST_DIVISION_SPAIN: 'LaLiga',
};

const extractTournamentName = (so5LeaderboardType?: string) =>
  Object.entries(TOURNAMENT_NAMES).find(([prefix]) =>
    so5LeaderboardType?.startsWith(prefix)
  )?.[1];

const PrivateUserGroupInvitationMessages = defineMessages({
  inviteTitle: {
    id: 'UserGroupInvitation.Field.Share',
    defaultMessage: 'Invite',
  },
  inviteMsg: {
    id: 'UserGroupInvitation.Field.Message',
    defaultMessage:
      'Come join my Sorare Private League to compete for great prizes!\n',
  },
  inviteMsgWithTournament: {
    id: 'UserGroupInvitation.Field.MessageWithTournament',
    defaultMessage:
      'Come join my Sorare {tournamentName} Private League to compete for great prizes!\n',
  },
});

export const generatePrivateUserGroupInvitationWording = (
  so5LeaderboardType?: string
) => {
  const tournamentName = extractTournamentName(so5LeaderboardType); // only specific tournaments have their name interpolated

  return {
    title: PrivateUserGroupInvitationMessages.inviteTitle,
    message: tournamentName
      ? PrivateUserGroupInvitationMessages.inviteMsgWithTournament
      : PrivateUserGroupInvitationMessages.inviteMsg,
    values: { tournamentName },
  };
};

const TOURNAMENT_TYPE_ORDER = [
  'global_kickoff',
  'global_cap',
  'global_all_star',
  'champion_europe',
  'challenger_europe',
  'second_division_europe',
  'global_under_twenty_one',
  'champion_america',
  'champion_asia',
  'global_unique_only',
  'special_weekly',
  'special_training_center',
  'legend',
  '__DEFAULT__',
  'first_division_england',
  'first_division_us',
  'first_division_spain',
  'first_division_germany',
  'first_division_italy',
  'first_division_france',
  'second_division_england',
  'second_division_spain',
  'second_division_germany',
  'second_division_italy',
  'second_division_france',
];

function sortByArrayIndexWithDefault<T>(
  array: readonly T[],
  a: T,
  b: T,
  defaultValue = '__DEFAULT__'
) {
  return sortByArrayIndex(
    array,
    !array.some(v => v === a) ? defaultValue : a,
    !array.some(v => v === b) ? defaultValue : b
  );
}

export const sortLeaderboardsByTournamentType = (
  tournamentType1: string,
  tournamentType2: string
) => {
  return sortByArrayIndexWithDefault(
    TOURNAMENT_TYPE_ORDER,
    tournamentType1,
    tournamentType2
  );
};

export const getDecisiveActions = withFragments(
  (
    playerGameScore: getDecisiveActions_playerGameScoreInterface
  ): Record<
    PositiveDecisiveStatKey | NegativeDecisiveStatKey | 'yellow_card',
    number
  > => {
    const {
      positiveDecisiveStats = [],
      negativeDecisiveStats = [],
      anyPlayerGameStats = null,
    } = isType(playerGameScore, 'PlayerGameScore') ||
    isType(playerGameScore, 'So5Score')
      ? playerGameScore
      : {};

    const decisiveStatsScores = [
      ...positiveDecisiveStats,
      ...negativeDecisiveStats,
    ];

    const decisiveStats = [
      ...positiveDecisiveStatKeys,
      ...negativeDecisiveStatKeys,
    ].reduce<Record<PositiveDecisiveStatKey | NegativeDecisiveStatKey, number>>(
      (acc, statKey) => ({
        ...acc,
        [statKey]:
          decisiveStatsScores.find(stat => stat.stat === statKey)?.statValue ||
          0,
      }),
      {} as Record<PositiveDecisiveStatKey | NegativeDecisiveStatKey, number>
    );

    return {
      ...decisiveStats,
      yellow_card:
        (isType(anyPlayerGameStats, 'PlayerGameStats')
          ? anyPlayerGameStats.yellowCard
          : 0) || 0,
    };
  },
  {
    playerGameScoreInterface: gql`
      fragment getDecisiveActions_playerGameScoreInterface on PlayerGameScoreInterface {
        id
        ... on PlayerGameScore {
          id
          positiveDecisiveStats {
            stat
            statValue
          }
          negativeDecisiveStats {
            stat
            statValue
          }
          anyPlayerGameStats {
            id
            ... on PlayerGameStats {
              id
              yellowCard
            }
          }
        }
        ... on So5Score {
          id
          positiveDecisiveStats {
            stat
            statValue
          }
          negativeDecisiveStats {
            stat
            statValue
          }
          anyPlayerGameStats {
            id
            ... on PlayerGameStats {
              id
              yellowCard
            }
          }
        }
      }
    ` as TypedDocumentNode<getDecisiveActions_playerGameScoreInterface>,
  }
);

export const seasonalityMessages = defineMessages<So5LeaderboardSeasonality>({
  [So5LeaderboardSeasonality.IN_SEASON]: {
    id: 'lobby.headers.Lineup.inSeason',
    defaultMessage: 'In season',
  },
  [So5LeaderboardSeasonality.ALL_SEASONS]: {
    id: 'lib.so5.seasonalityMessages.classicSeason',
    defaultMessage: 'Classic Season',
  },
});

export const FIXTURES: Record<string, So5State | undefined> = {
  past: So5State.PAST,
  live: So5State.LIVE,
  upcoming: So5State.UPCOMING,
  ongoing: So5State.MY_ONGOING,
  onboarding: So5State.ONBOARDING,
  tutorial: So5State.NEXT_WITH_TUTORIAL,
};

export const getFixtureQueryVariables = (fixture: string | undefined) => {
  const fixtureType = FIXTURES[fixture!];

  return {
    type: fixtureType,
    slug: fixtureType ? undefined : fixture,
  };
};

export function getComposeTeamRoute({
  leaderboardSlug,
  lineupId,
  managerTeamId,
  sport = Sport.FOOTBALL,
  eventType,
  useComposeFlow20,
}: {
  leaderboardSlug: string;
  lineupId?: Nullable<string>;
  managerTeamId?: string;
  sport?: Sport;
  eventType?: string;
  useComposeFlow20: boolean;
}) {
  let url;

  if (useComposeFlow20) {
    if (lineupId) {
      url = generateSportPath(
        ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_LINEUP_LINEUP,
        {
          params: {
            leaderboard: leaderboardSlug,
            lineup: idFromObject(lineupId),
            eventType,
          },
          sport,
        }
      );
    } else if (managerTeamId) {
      url = generateSportPath(
        ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD_MANAGERTEAM_MANAGERTEAM,
        {
          params: {
            leaderboard: leaderboardSlug,
            managerTeam: idFromObject(managerTeamId),
            eventType,
          },
          sport,
        }
      );
    } else {
      url = generateSportPath(ANY_SPORT_PLAY_EVENTTYPE_COMPOSE_LEADERBOARD, {
        params: { leaderboard: leaderboardSlug, eventType },
        sport,
      });
    }
  } else if (lineupId) {
    url = generateSportPath(
      ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID,
      {
        params: { leaderboardSlug, lineupId: idFromObject(lineupId) },
        sport,
      }
    );
  } else if (managerTeamId) {
    url = generateSportPath(
      ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG_MANAGERTEAMID,
      {
        params: { leaderboardSlug, managerTeamId: idFromObject(managerTeamId) },
        sport,
      }
    );
  } else {
    url = generateSportPath(ANY_SPORT_COMPOSE_TEAM_LEADERBOARDSLUG, {
      params: { leaderboardSlug },
      sport,
    });
  }
  return url;
}

export const teamPictureUrl = withFragments(
  (team: teamPictureUrl_teamInterface) => {
    if (isType(team, 'Club')) {
      return team.pictureUrl;
    }

    return `${FRONTEND_ASSET_HOST}/flags/${team.country.code}.svg`;
  },
  {
    teamInterface: gql`
      fragment teamPictureUrl_teamInterface on TeamInterface {
        slug
        pictureUrl
        ... on NationalTeam {
          slug
          country {
            slug
            code
          }
        }
      }
    ` as TypedDocumentNode<teamPictureUrl_teamInterface>,
  }
);

export const getTeamName = withFragments(
  (team: getTeamName_team | null) => {
    if (!team) {
      return '';
    }
    if (isType(team, 'NationalTeam')) {
      return team.country.name;
    }
    return team.shortName;
  },
  {
    team: gql`
      fragment getTeamName_team on Team {
        ... on Club {
          slug
          shortName
        }
        ... on NationalTeam {
          slug
          country {
            slug
            name
          }
        }
      }
    ` as TypedDocumentNode<getTeamName_team>,
  }
);

export const getCompetitionPictureUrl = withFragments(
  (competition: getCompetitionPictureUrl_competition) => {
    const countryPictureUrl =
      !competition.country || competition.country.slug.includes(' ')
        ? null
        : `${FRONTEND_ASSET_HOST}/flags/${competition.country.slug.toLowerCase()}.svg`;

    const competitionPictureUrl = competition.pictureUrl
      ? competition.pictureUrl
      : countryPictureUrl;

    return competitionPictureUrl || defaultShield;
  },
  {
    competition: gql`
      fragment getCompetitionPictureUrl_competition on Competition {
        slug
        pictureUrl
        country {
          slug
        }
      }
    ` as TypedDocumentNode<getCompetitionPictureUrl_competition>,
  }
);

export const isCardUsed = withFragments(
  ({
    anyCard,
    so5LineupId,
  }: {
    anyCard: isCardUsed_anyCard;
    so5LineupId: string;
  }) => {
    const usedInAnotherLineup = anyCard.concurrentSo5Lineups.some(
      l => l.id !== so5LineupId
    );

    return (
      isMyCardListedOnMarket(anyCard) ||
      isSentInDirectOffer(anyCard) ||
      usedInAnotherLineup
    );
  },
  {
    anyCard: gql`
      fragment isCardUsed_anyCard on AnyCardInterface {
        slug
        concurrentSo5Lineups(so5LeaderboardSlug: $so5LeaderboardSlug) {
          id
        }
        ...isMyCardListedOnMarket_anyCard
        ...isSentInDirectOffer_anyCard
      }
      ${isMyCardListedOnMarket.fragments.anyCard}
      ${isSentInDirectOffer.fragments.anyCard}
    ` as TypedDocumentNode<isCardUsed_anyCard>,
  }
);

export const getAddTeamPath = withFragments(
  ({
    so5LeaderboardGroupInterface,
    seasonality,
    useComposeFlow20,
  }: {
    so5LeaderboardGroupInterface?: getAddTeamPath_so5LeaderboardGroupInterface;
    seasonality?: So5LeaderboardSeasonality;
    useComposeFlow20: boolean;
  }) => {
    if (!so5LeaderboardGroupInterface) {
      return null;
    }
    const { so5LeagueTracks, so5Fixture, seasonalities } =
      so5LeaderboardGroupInterface;

    const composableTracks = so5LeagueTracks.filter(lt => {
      const seasonalityToMatch =
        so5Fixture.sport === Sport.FOOTBALL
          ? lt.entrySo5Leaderboard.seasonality ||
            So5LeaderboardSeasonality.ALL_SEASONS
          : lt.entrySo5Leaderboard.seasonality;

      return (
        lt.entrySo5Leaderboard.canCompose.value &&
        (seasonality === undefined || seasonalityToMatch === seasonality)
      );
    });

    const nextTrackToAdd = composableTracks.find(lt => {
      const { maxManagerTeamsCount, entrySo5Leaderboard, so5Leaderboards } = lt;
      if (!entrySo5Leaderboard.canCompose.value) return false;

      const managerTeamBased = maxManagerTeamsCount > 0;
      const maxCount = managerTeamBased
        ? maxManagerTeamsCount
        : entrySo5Leaderboard.teamsCap || 0;
      const contenders = so5Leaderboards.flatMap(
        so5Leaderboard => so5Leaderboard.mySo5LeaderboardContenders
      );
      const composedLineupCount = contenders.filter(
        contender => contender.so5Lineup
      ).length;
      return maxCount > composedLineupCount;
    });

    if (!nextTrackToAdd) {
      return null;
    }

    if (composableTracks.length === 1) {
      return getComposeTeamRoute({
        leaderboardSlug: composableTracks[0].entrySo5Leaderboard.slug,
        lineupId: undefined,
        managerTeamId: undefined,
        sport: so5Fixture.sport,
        eventType: so5Fixture.type.toLowerCase(),
        useComposeFlow20,
      });
    }

    if (composableTracks.length > 1) {
      return generateSportPath(
        ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
        {
          sport: so5Fixture.sport,
          params: {
            eventType: so5Fixture.type.toLowerCase(),
            fixture: so5Fixture.slug,
            competition: so5LeaderboardGroupInterface.slug,
            seasonality:
              seasonality ||
              (seasonalities.includes(So5LeaderboardSeasonality.IN_SEASON)
                ? So5LeaderboardSeasonality.IN_SEASON
                : So5LeaderboardSeasonality.ALL_SEASONS
              ).toLowerCase(),
            trck: nextTrackToAdd.slug,
          },
        }
      );
    }

    return null;
  },
  {
    so5LeaderboardGroupInterface: gql`
      fragment getAddTeamPath_so5LeaderboardGroupInterface on So5LeaderboardGroupInterface {
        slug
        displayName
        seasonalities
        so5Fixture {
          slug
          type
          sport
        }
        so5LeagueTracks {
          slug
          maxManagerTeamsCount
          entrySo5Leaderboard {
            slug
            seasonality
            teamsCap
            canCompose {
              value
            }
          }
          so5Leaderboards {
            slug
            mySo5LeaderboardContenders(includeHiddenManagerTeam: true) {
              slug
              so5Lineup {
                id
              }
            }
          }
        }
      }
    ` as TypedDocumentNode<getAddTeamPath_so5LeaderboardGroupInterface>,
  }
);

export const isAppearanceLocked = withFragments(
  (appearance: isAppearanceLocked_so5Appearance) => {
    const firstGame = appearance?.firstGame;
    return firstGame && !isGameScheduled(firstGame.statusTyped);
  },
  {
    so5Appearance: gql`
      fragment isAppearanceLocked_so5Appearance on So5AppearanceInterface {
        id
        firstGame {
          id
          statusTyped
        }
      }
    ` as TypedDocumentNode<isAppearanceLocked_so5Appearance>,
  }
);
