import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentType, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { Small } from '@sorare/core/src/atoms/typography/Small';
import { useTitleAndDescription } from '@sorare/core/src/hooks/useTitleAndDescription';
import { scarcityNames } from '@sorare/core/src/lib/cards';
import { metadatas } from '@sorare/core/src/lib/seo/common';

import GenericCardPage from '@sorare/marketplace/src/components/GenericCardPage';

import { ProgressBar } from '@sorare/us-sports/src/components/stats/ProgressBar';

import { CardWithTokenPageContent_anyCardInterface } from './__generated__/index.graphql';

type Props<T> = {
  card: T;
  CardProperties: ComponentType<{
    card: T;
    withTransferMalus: boolean;
  }>;
  PlayerScoresHistory: ComponentType<{
    card: T;
    InfiniteScrollLoader: ReactNode;
  }>;
  News?: ComponentType<{ card: T }>;
  loadMoreBids: (
    reload: boolean,
    variable: { bidCursor: string }
  ) => Promise<any>;
};

const CardLevel = ({
  card,
}: {
  card: CardWithTokenPageContent_anyCardInterface;
}) => {
  const { grade, xp, xpNeededForNextGrade, xpNeededForCurrentGrade } = card;
  return (
    <div>
      <FormattedMessage
        id="cardPage.level.2"
        defaultMessage="<b>Level {level}</b><small>/20</small>"
        values={{
          level: grade,
          small: Small,
          b: Bold,
        }}
      />
      {xp > 0 && xpNeededForNextGrade !== null && (
        <ProgressBar
          value={xp - xpNeededForCurrentGrade}
          max={xpNeededForNextGrade - xpNeededForCurrentGrade}
          unit="xp"
        />
      )}
    </div>
  );
};

export const CardWithTokenPageContent = <
  T extends CardWithTokenPageContent_anyCardInterface,
>({
  card,
  CardProperties,
  PlayerScoresHistory,
  News,
  loadMoreBids,
}: Props<T>) => {
  useTitleAndDescription(
    metadatas.card.title,
    metadatas.card.description,
    card && {
      display_name: card.anyPlayer.displayName,
      scarcity: scarcityNames[card.rarityTyped],
      season: card.seasonYear,
    }
  );

  return (
    <GenericCardPage
      loadMoreBids={loadMoreBids}
      card={card}
      CardProperties={CardProperties}
      CardLevel={CardLevel}
      LastScores={PlayerScoresHistory}
      News={News}
    />
  );
};

CardWithTokenPageContent.fragments = {
  anyCardInterface: gql`
    fragment CardWithTokenPageContent_anyCardInterface on AnyCardInterface {
      slug
      rarityTyped
      seasonYear
      xp
      grade
      xpNeededForNextGrade
      xpNeededForCurrentGrade
      anyPlayer {
        slug
        displayName
        country {
          slug
          name
          code
        }
      }
      ...GenericCardPage_anyCard
    }
    ${GenericCardPage.fragments.anyCard}
  ` as TypedDocumentNode<CardWithTokenPageContent_anyCardInterface>,
};

export default CardWithTokenPageContent;
