import { TypedDocumentNode, gql } from '@apollo/client';

import { CommonDraftCampaignStatus } from '@sorare/core/src/__generated__/globalTypes';
import { withFragments } from '@sorare/core/src/lib/gql';

import { getCommonDraftCampaignStatus_so5Leaderboard } from './__generated__/getCommonDraftCampaignStatus.graphql';

export const getCommonDraftCampaignStatus = withFragments(
  (so5Leaderboard: getCommonDraftCampaignStatus_so5Leaderboard) => {
    const {
      so5League: { so5Leaderboards },
    } = so5Leaderboard;

    const leaderboardWithCampaign = so5Leaderboards.find(
      l => l.commonDraftCampaign
    );

    const draftCampaignStatus =
      leaderboardWithCampaign?.commonDraftCampaign?.status;

    const canDraft =
      draftCampaignStatus === CommonDraftCampaignStatus.REDRAFTABLE ||
      draftCampaignStatus === CommonDraftCampaignStatus.OPEN;

    const canSwap = draftCampaignStatus === CommonDraftCampaignStatus.SWAPPABLE;

    return { canDraft, canSwap };
  },
  {
    so5Leaderboard: gql`
      fragment getCommonDraftCampaignStatus_so5Leaderboard on So5Leaderboard {
        slug
        so5League {
          slug
          so5Leaderboards {
            slug
            commonDraftCampaign {
              slug
              status
            }
          }
        }
      }
    ` as TypedDocumentNode<getCommonDraftCampaignStatus_so5Leaderboard>,
  }
);
