import { FormattedMessage } from 'react-intl';

import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { playerUnavailability } from '@sorare/core/src/lib/glossary';

type Props = {
  player: {
    activeClub: { slug: string } | null;
  };
};

export const Status = ({ player }: Props) => {
  if (player.activeClub?.slug) {
    return (
      <Chip size="medium" color="green">
        <FormattedMessage {...playerUnavailability.active} />
      </Chip>
    );
  }
  return (
    <Chip size="medium" color="red">
      <FormattedMessage {...playerUnavailability.inactive} />
    </Chip>
  );
};
