import { FC, ReactNode, createElement } from 'react';

type Props<T extends object> = {
  wrap: boolean;
  Wrapper: FC<T>;
  children: ReactNode;
  props?: NoInfer<T>;
};

export const ConditionalWrapper = <T extends object>({
  wrap,
  Wrapper,
  children,
  props,
}: Props<T>) => {
  return wrap
    ? // use createElement instead of JSX as spreaded props will mess up typings
      createElement(Wrapper, props, children)
    : children;
};
