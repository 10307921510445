import { faEthereum } from '@fortawesome/free-brands-svg-icons';

import PaymentMethodIcon, { Props as PaymentMethodIconProps } from '..';

type Props = Pick<PaymentMethodIconProps, 'size'>;

export const EthWalletIcon = ({ size }: Props) => {
  return (
    <PaymentMethodIcon
      size={size}
      backgroundColor="var(--c-brand-600)"
      color="var(--c-white)"
      icon={faEthereum}
    />
  );
};
