import { TypedDocumentNode, gql } from '@apollo/client';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Text14, Text16 } from '@sorare/core/src/atoms/typography';
import {
  NotAndroidAppFeature,
  NotIOsAppFeature,
} from '@sorare/core/src/components/BuyableFeature';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { useTokenBelongsToUser } from '@sorare/core/src/hooks/useTokenBelongsToUser';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import SetupMinimumPriceDialog from 'components/offer/SetupMinimumPriceDialog';

import { MinimumPrice_anyCard } from './__generated__/index.graphql';

interface Props {
  card: MinimumPrice_anyCard;
}

export const MinimumPrice = ({ card }: Props) => {
  const [open, setOpen] = useState(false);
  const belongsToUser = useTokenBelongsToUser();

  const { myMintedSingleSaleOffer, privateMinPrices, publicMinPrices } = card;

  if (!belongsToUser(card) || myMintedSingleSaleOffer) {
    return null;
  }

  const onClick = () => setOpen(true);

  const minPrice = privateMinPrices || publicMinPrices;

  return (
    <NotIOsAppFeature>
      <NotAndroidAppFeature>
        <SBHorizontal>
          <Vertical gap={0}>
            <Text14 color="var(--c-nd-600)">
              <FormattedMessage
                id="MinimumPrice.title"
                defaultMessage="Minimum offer:"
              />
            </Text14>
            {minPrice ? (
              <AmountWithConversion monetaryAmount={minPrice} />
            ) : (
              <Text16>
                <FormattedMessage
                  id="MinimumPrice.notSet"
                  defaultMessage="No minimum set"
                />
              </Text16>
            )}
          </Vertical>
          <ButtonBase onClick={onClick}>
            <Horizontal gap={1}>
              <FontAwesomeIcon icon={faEdit} />
              <Text16 bold>
                <FormattedMessage
                  id="MinimumPrice.edit"
                  defaultMessage="Edit"
                />
              </Text16>
            </Horizontal>
          </ButtonBase>
          {open && (
            <SetupMinimumPriceDialog
              open={open}
              onClose={() => setOpen(false)}
              card={card}
            />
          )}
        </SBHorizontal>
      </NotAndroidAppFeature>
    </NotIOsAppFeature>
  );
};

MinimumPrice.fragments = {
  anyCard: gql`
    fragment MinimumPrice_anyCard on AnyCardInterface {
      slug
      ...SetupMinimumPriceDialog_anyCard
      user {
        slug
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
      }
      privateMinPrices {
        ...MonetaryAmountFragment_monetaryAmount
      }
      publicMinPrices {
        ...MonetaryAmountFragment_monetaryAmount
      }
      ...useTokenBelongsToUser_anyCard
    }
    ${monetaryAmountFragment}
    ${useTokenBelongsToUser.fragments.anyCard}
    ${SetupMinimumPriceDialog.fragments.anyCard}
  ` as TypedDocumentNode<MinimumPrice_anyCard>,
};

export default MinimumPrice;
