import { TypedDocumentNode, gql } from '@apollo/client';

import { PostalAddressForm } from 'components/settings/PostalAddressForm';
import { ActiveUserAvatar } from 'components/user/ActiveUserAvatar';
import { MyClubLink } from 'components/user/MyClubLink';
import { UserName } from 'components/user/UserName';
import { currentUserConversionCredits } from 'hooks/useConversionCredits';
import { monetaryAmountFragment } from 'lib/monetaryAmount';

import {
  CurrentUseProvider_ethereumAccounts,
  CurrentUseProvider_fiatAccounts,
  CurrentUseProvider_sportProfile,
  CurrentUserProvider_coinBalances,
  CurrentUserProvider_currentSportUnreadNotificationsCount,
  CurrentUserProvider_currentUser,
  CurrentUserProvider_depositPromotionalEventCompletion,
  CurrentUserProvider_inventoryBalances,
  CurrentUserProvider_managerProgression,
  CurrentUserProvider_userSportProfile,
  CurrentUserProvider_walletRecovery,
  CurrentUserQuery,
  CurrentUserQueryVariables,
  SignInMutation,
  SignInMutationVariables,
  commonCardMarketplace_currentUser,
  onCurrentUserWasUpdated,
  onCurrentUserWasUpdatedVariables,
  onDeviceWasUpdated,
  onDeviceWasUpdatedVariables,
} from './__generated__/queries.graphql';

const userSportProfileFragment = gql`
  fragment CurrentUserProvider_userSportProfile on UserSportProfileInterface {
    id
    onboarded
    clubName
    clubBadge {
      id
      pictureUrl
      name
    }
    achievementBadges {
      id
    }
  }
` as TypedDocumentNode<CurrentUserProvider_userSportProfile>;
const sportProfile = gql`
  fragment CurrentUseProvider_sportProfile on CurrentUser {
    slug
    cardCounts(sport: $sport) {
      total
      limited
      rare
      super_rare: superRare
      superRare
      unique
      common
      custom_series: customSeries
    }
    footballUserProfile {
      id
      ...CurrentUserProvider_userSportProfile
    }
    baseballUserProfile {
      id
      ...CurrentUserProvider_userSportProfile
    }
    nbaUserProfile {
      id
      ...CurrentUserProvider_userSportProfile
    }
  }
  ${userSportProfileFragment}
` as TypedDocumentNode<CurrentUseProvider_sportProfile>;

export const walletRecovery = gql`
  fragment CurrentUserProvider_walletRecovery on CurrentUser {
    slug
    wallet {
      id
      ethereumAddress
      status
      holdsValue
      recoveryOptions {
        id
        destination
        method
        status
      }
    }
  }
` as TypedDocumentNode<CurrentUserProvider_walletRecovery>;

export const ethereumAccounts = gql`
  fragment CurrentUseProvider_ethereumAccounts on CurrentUser {
    slug
    myAccounts {
      id
      account {
        id
        sorareManaged
        accountable {
          ... on Node {
            id
          }
          ... on EthereumAccount {
            id
            address
          }
          ... on StarkwareAccount {
            id
          }
        }
      }
    }
  }
` as TypedDocumentNode<CurrentUseProvider_ethereumAccounts>;
export const fiatAccounts = gql`
  fragment CurrentUseProvider_fiatAccounts on CurrentUser {
    slug
    myAccounts {
      id
      accountable {
        ... on Node {
          id
        }
        ... on PrivateFiatWalletAccount {
          id
          state
          firstName
          lastName
          nationalityCode
          dob
          countryOfResidenceCode
          availableBalance
          totalBalance
          kycStatus
          kycRefusedReason
          depositBankAccount {
            ... on BankAccountInterface {
              id
            }
            ... on IbanBankAccount {
              id
              ownerName
              bic
              iban
            }
          }
          publicInfo {
            id
            currency
          }
        }
      }
    }
  }
` as TypedDocumentNode<CurrentUseProvider_fiatAccounts>;

const managerProgression = gql`
  fragment CurrentUserProvider_managerProgression on CurrentUser {
    slug
    footballChallengesManagerProgression: managerProgression(sport: FOOTBALL) {
      id
      levelReached
      points
    }
  }
` as TypedDocumentNode<CurrentUserProvider_managerProgression>;

export const coinBalancesFragment = gql`
  fragment CurrentUserProvider_coinBalances on CurrentUser {
    slug
    coinBalanceOfBaseball: coinBalance(sport: BASEBALL)
    coinBalanceOfFootball: coinBalance(sport: FOOTBALL)
  }
` as TypedDocumentNode<CurrentUserProvider_coinBalances>;

const depositPromotionalEventCompletion = gql`
  fragment CurrentUserProvider_depositPromotionalEventCompletion on CurrentUser {
    slug
    activePromotionalEventCompletion {
      id
      aasmState
      actualAmounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<CurrentUserProvider_depositPromotionalEventCompletion>;

export const inventoryBalancesFragment = gql`
  fragment CurrentUserProvider_inventoryBalances on CurrentUser {
    slug
    anyUserSportProfile(sport: $sport) {
      id
      blueprintCardsCount
    }
    unopenedBoxes: unopenedProbabilisticBundles(sport: $sport) {
      totalCount
    }
    raffleTicketsCount: availableRaffleTicketsCount(sport: $sport)
    shardsChests: cardShardsChests(sport: $sport) {
      id
      rarity
      cardShardsCount
      newThresholdUnlocked
    }
  }
` as TypedDocumentNode<CurrentUserProvider_inventoryBalances>;

export const currentSportUnreadNotificationsCountFragment = gql`
  fragment CurrentUserProvider_currentSportUnreadNotificationsCount on CurrentUser {
    slug
    # this is required for theInGameNotificationProvider refetch to work
    allUnreadNotificationsCount: unreadNotificationsCount(sports: [$sport])
    currentSportUnreadNotificationsCount: unreadNotificationsCount(
      sports: [$sport]
      categories: $notificationCategories
    )
  }
` as TypedDocumentNode<CurrentUserProvider_currentSportUnreadNotificationsCount>;

export const commonCardMarketplaceFragment = gql`
  fragment commonCardMarketplace_currentUser on CurrentUser {
    slug
    commonCardPoints: commonCardPoints(sport: $sport)
    cardCounts(sport: $sport) {
      common
    }
  }
` as TypedDocumentNode<commonCardMarketplace_currentUser>;

export const currentUser = gql`
  fragment CurrentUserProvider_currentUser on CurrentUser {
    id
    slug
    suspended
    forcedIntoOnboarding
    footballLast30DaysLineupsCount
    currentFootballRivalsManager {
      id
      sawKickoffWelcomeToKickoff
      favoriteTeam {
        slug
      }
    }
    createdAt
    email
    nickname
    active
    fromPath
    blockedUntil
    confirmedDevice
    # this is required for theInGameNotificationProvider refetch to work
    allUnreadNotificationsCount: unreadNotificationsCount(sports: [$sport])
    referrer {
      id
      slug
      suspended
      nickname
      createdAt
      profile {
        id
        verified
      }
      ...ActiveUserAvatar_user
      ...UserName_publicUserInfoInterface
    }
    unclaimedReferralRewardsCount
    referralRewardsCount
    referralUrl
    sorarePrivateKey {
      iv
      salt
      encryptedPrivateKey
    }
    sorarePrivateKeyRecovery
    sorareAddress
    sorareAddressBalance
    starkKey
    bankMappedEthereumAddress
    depositedEth
    currentDevice {
      id
    }
    availableBalance
    availableBalanceForWithdrawal
    totalBalance
    bankBalance
    ethMigration {
      id
      aasmState
    }
    otpRequiredForLogin
    featureFlagCustomAttributes
    phoneNumber
    phoneNumberVerificationRequested
    phoneNumberVerified
    confirmed
    profile {
      id
      verified
      status
      discordUsername
      twitterUsername
      enabledWallets
    }
    baseballUserProfile {
      id
      shieldUrl
      clubName
    }
    nbaUserProfile {
      id
      shieldUrl
      clubName
    }
    unverifiedPhoneNumber
    userSettings {
      id
      disableAllEmails
      locale
      currency
      fiatCurrency
      lifecycle
      hideBalance
      hidePermanentTutorials
      tcuStatus
      rewardCurrency
      discoverable
      ...PostalAddressForm_userSettings
    }
    rampSupported
    moonpaySupported
    apiKey
    mustAcceptTcus
    followingCount
    followersCount
    connectedOauths {
      id
      email
      provider
    }
    mangopayUser {
      id
      blocked
      requiredDocuments
      additionalKycRequests {
        id
        requestType
        aasmState
      }
    }
    noCardRouteEnabled
    so5NoCardRouteOpened
    hideTutorials
    warningFlags {
      id
      reason
      relatedUsers {
        slug
        nickname
        ...MyClubLink_publicUserInfoInterface
      }
      until
    }
    activePromotionalEventCompletion {
      id
      aasmState
      expiresAt
      promotionalEvent {
        slug
      }
      actualAmounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
      targetAmount {
        ...MonetaryAmountFragment_monetaryAmount
      }
    }
    depositPromotionalEvent {
      slug
      endDate
      blogUrl
      completedParticipants
      eligibilityRules {
        id
        minimumDepositAmount
      }
      rewardsProbabilisticBundleConfig {
        id
        slots {
          probableConfigs {
            conversionCredit {
              id
              maxDiscount {
                usd
              }
              percentageDiscount
            }
          }
        }
      }
      completionRules
    }
    betaTesterFootballCommonCardsGame
    ...CurrentUseProvider_sportProfile
    ...CurrentUserProvider_walletRecovery
    ...CurrentUseProvider_ethereumAccounts
    ...CurrentUseProvider_fiatAccounts
    ...ActiveUserAvatar_user
    ...CurrentUserProvider_managerProgression
    ...CurrentUserProvider_coinBalances
    ...CurrentUserProvider_inventoryBalances
    ...commonCardMarketplace_currentUser
    ...CurrentUserProvider_depositPromotionalEventCompletion
  }
  ${walletRecovery}
  ${sportProfile}
  ${ethereumAccounts}
  ${fiatAccounts}
  ${ActiveUserAvatar.fragments.user}
  ${PostalAddressForm.fragments.userSettings}
  ${UserName.fragments.user}
  ${managerProgression}
  ${coinBalancesFragment}
  ${inventoryBalancesFragment}
  ${commonCardMarketplaceFragment}
  ${MyClubLink.fragments.user}
  ${monetaryAmountFragment}
  ${depositPromotionalEventCompletion}
` as TypedDocumentNode<CurrentUserProvider_currentUser>;

export const CURRENT_USER_QUERY = gql`
  query CurrentUserQuery($sport: Sport!) {
    currentUser {
      slug
      ...CurrentUserProvider_currentUser
    }
  }
  ${currentUser}
` as TypedDocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;

export const onDeviceSubscription = gql`
  subscription onDeviceWasUpdated {
    deviceWasUpdated {
      id
      eventType
    }
  }
` as TypedDocumentNode<onDeviceWasUpdated, onDeviceWasUpdatedVariables>;

export const subscription = gql`
  subscription onCurrentUserWasUpdated(
    $sport: Sport!
    $notificationCategories: [NotificationCategoryInput!]
  ) {
    currentUserWasUpdated {
      slug
      bankMappedEthereumAddress
      availableBalance
      availableBalanceForWithdrawal
      bankBalance
      sorareAddressBalance
      confirmed
      ethMigration {
        id
        aasmState
      }
      mangopayUser {
        id
        blocked
      }
      pendingDeposits {
        id
        date
        providerType
        transactionHash
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      myRecentActiveBids {
        id
        maximumAmounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
        auction {
          id
          privateCurrentPrice
          privateMinNextBid
          currency
        }
      }
      ...CurrentUser_conversionCredits
      ...CurrentUseProvider_fiatAccounts
      ...CurrentUserProvider_managerProgression
      ...CurrentUserProvider_coinBalances
      ...CurrentUserProvider_inventoryBalances
      ...CurrentUserProvider_currentSportUnreadNotificationsCount
      ...CurrentUserProvider_depositPromotionalEventCompletion
    }
  }
  ${monetaryAmountFragment}
  ${currentUserConversionCredits}
  ${fiatAccounts}
  ${managerProgression}
  ${coinBalancesFragment}
  ${inventoryBalancesFragment}
  ${currentSportUnreadNotificationsCountFragment}
  ${depositPromotionalEventCompletion}
` as TypedDocumentNode<
  onCurrentUserWasUpdated,
  onCurrentUserWasUpdatedVariables
>;

export const SIGN_IN_MUTATION = gql`
  mutation SignInMutation($input: signInInput!, $sport: Sport!) {
    signIn(input: $input) {
      currentUser {
        slug
        timeLeftForConfirmation
        ...CurrentUserProvider_currentUser
      }
      otpSessionChallenge
      errors {
        path
        message
        code
      }
      tcuToken
    }
  }
  ${currentUser}
` as TypedDocumentNode<SignInMutation, SignInMutationVariables>;
