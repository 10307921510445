import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM } from '@sorare/core/src/atoms/typography';

import PlayerListItem from 'components/player/PlayerListItem';

import {
  PlayerWithSupply_playerWithSupply,
  PlayerWithSupply_so5League,
} from './__generated__/index.graphql';

const messages = defineMessages({
  noCards: {
    id: 'Rewards.noCards',
    defaultMessage: 'No Cards available',
  },
  remainingCards: {
    id: 'Rewards.remainingCards',
    defaultMessage: '{supply} left',
  },
});

type Props = {
  rarity: Rarity;
  players: PlayerWithSupply_playerWithSupply[];
  so5League?: PlayerWithSupply_so5League;
};

const Supply = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: var(--c-green-600);
  &.empty {
    color: var(--c-nd-600);
  }
  &.last {
    color: var(--c-red-600);
  }
`;

export const PlayerWithSupply = ({ rarity, players, so5League }: Props) => {
  const { formatDate, formatTime } = useIntl();
  const remainingSupply = (player: PlayerWithSupply_playerWithSupply) => {
    if (rarity === 'common') return null;

    return player.availableSupply;
  };

  if (!players.length)
    return (
      <BodyM as="p">
        <FormattedMessage {...messages.noCards} />
      </BodyM>
    );

  return (
    <Vertical>
      {so5League?.rewardPoolComputedAt && (
        <BodyM as="p" bold>
          <FormattedMessage
            id="rewards.warning.lastUpdated"
            defaultMessage="Players are eligible to change tiers until Game Week closing. Last Updated: {date} at {time}"
            values={{
              date: formatDate(so5League.rewardPoolComputedAt, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              }),
              time: formatTime(so5League.rewardPoolComputedAt),
            }}
          />
        </BodyM>
      )}
      <div>
        {players.map(s => {
          const supply = remainingSupply(s);
          const { anyPlayer: player } = s;
          return (
            <PlayerListItem
              key={player.slug}
              player={player}
              subtitle={player.activeClub?.name}
            >
              {supply !== null ? (
                <Supply
                  className={classnames({
                    last: supply && supply <= 3,
                    empty: supply === 0,
                  })}
                >
                  <FormattedMessage
                    {...messages.remainingCards}
                    values={{ supply }}
                  />
                </Supply>
              ) : undefined}
            </PlayerListItem>
          );
        })}
      </div>
    </Vertical>
  );
};

PlayerWithSupply.fragments = {
  playerWithSupply: gql`
    fragment PlayerWithSupply_playerWithSupply on PlayerWithSupply {
      slug
      availableSupply
      anyPlayer {
        slug
        activeClub {
          slug
          name
        }
        ...PlayerListItem_player
      }
    }
    ${PlayerListItem.fragments.player}
  ` as TypedDocumentNode<PlayerWithSupply_playerWithSupply>,
  so5League: gql`
    fragment PlayerWithSupply_so5League on So5League {
      slug
      rewardPoolComputedAt
    }
  ` as TypedDocumentNode<PlayerWithSupply_so5League>,
};
