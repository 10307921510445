import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { useGetInteractionContext } from '@sorare/core/src/hooks/useGetInteractionContext';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { fromWei } from '@sorare/core/src/lib/wei';

import { tokenInfoProperties, tokensInfoProperties } from 'lib/tokenEvents';

import { useClickBidEvent_tokenAuction } from './__generated__/useClickBidEvent.graphql';

export const useClickBidEvent = () => {
  const getInteractionContext = useGetInteractionContext();
  const track = useEvents();
  const trackClickBid = useCallback(
    (
      auction: useClickBidEvent_tokenAuction,
      monetaryAmount: MonetaryAmountOutput
    ) => {
      const { anyCards } = auction;
      const sharedProperties = {
        auctionId: auction.id,
        count: auction.bidsCount,
        ethAmount: fromWei(monetaryAmount.wei),
        eurAmount: monetaryAmount.eur,
        secondary: false,
        interactionContext: getInteractionContext(),
        sport: anyCards[0].sport,
      };

      if (anyCards.length === 1) {
        const params = {
          ...sharedProperties,
          ...tokenInfoProperties(anyCards[0]),
        };

        track('Click Bid', params);
      } else {
        const params = {
          ...sharedProperties,
          ...tokensInfoProperties(anyCards),
        };

        track('Click Bundled Bid', params);
      }
    },
    [track, getInteractionContext]
  );

  return trackClickBid;
};

useClickBidEvent.fragments = {
  tokenAuction: gql`
    fragment useClickBidEvent_tokenAuction on TokenAuction {
      id
      bidsCount
      anyCards {
        slug
        sport
        rarityTyped
      }
    }
  ` as TypedDocumentNode<useClickBidEvent_tokenAuction>,
};
