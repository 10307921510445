import { Outlet } from 'react-router-dom';

import { SearchLayoutRoot } from '@sorare/core/src/components/search/SearchLayout/ui';

export const MyCardsCollectionsLayout = () => {
  return (
    <SearchLayoutRoot>
      <Outlet />
    </SearchLayoutRoot>
  );
};
