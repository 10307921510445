import { faClock } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { LabelS } from 'atoms/typography';
import { Chip } from 'atoms/ui/Chip';
import { useCurrentUserContext } from 'contexts/currentUser';
import TimeLeft from 'contexts/ticker/TimeLeft';

const Layout = styled(LabelS).attrs({ bold: true })``;

const ActivePromotionalEventTimeLeft = () => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser?.activePromotionalEventCompletion?.expiresAt) return null;
  return (
    <Chip size="medium" color="yellow">
      <FontAwesomeIcon size="xs" icon={faClock} />
      <TimeLeft
        time={currentUser?.activePromotionalEventCompletion?.expiresAt}
        Layout={Layout}
        withExplicitTime
        hideTooltip
      />
    </Chip>
  );
};

export default ActivePromotionalEventTimeLeft;
