import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { AveragePlayerScore, ScoreStatus } from '__generated__/globalTypes';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { TotalAveragePlayerScore } from 'lib/averagePlayerScore';
import { averagePlayerScoresFragment, statusMessages } from 'lib/players';
import { modeTooltipMessages } from 'lib/so5';

import { Score } from '../Score';
import { PlayerScore_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: PlayerScore_anyPlayer;
  mode: TotalAveragePlayerScore;
  isCappedMode?: boolean;
  remainingPoints?: number;
  animated?: boolean;
  hideTooltip?: boolean;
  shape?: 'HEXAGON' | 'TEXT';
  small?: boolean;
};

export const PlayerScore = ({
  player,
  mode,
  isCappedMode,
  remainingPoints,
  animated,
  hideTooltip,
  shape = 'HEXAGON',
  small,
}: Props) => {
  const score = {
    LAST_TEN_PLAYED_SO5_AVERAGE_SCORE: player.lastTenPlayedSo5AverageScore,
    LAST_FIVE_SO5_AVERAGE_SCORE: player.lastFiveSo5AverageScore,
    LAST_FIFTEEN_SO5_AVERAGE_SCORE: player.lastFifteenSo5AverageScore,
    SEASON_AVERAGE_SCORE: player.seasonAverage,
  }[mode];

  const isAffordable =
    remainingPoints !== undefined ? remainingPoints >= (score || 0) : true;

  return (
    <Tooltip
      title={
        <FormattedMessage
          {...(score ? modeTooltipMessages[mode] : statusMessages.DID_NOT_PLAY)}
        />
      }
      skip={hideTooltip}
    >
      <Score
        hideReviewing
        shape={shape}
        score={score || 0}
        scoreStatus={ScoreStatus.FINAL}
        scoreType="PLAYER_SCORE"
        isCappedMode={isCappedMode}
        isAffordable={isAffordable}
        animated={animated}
        inverted={mode === AveragePlayerScore.LAST_FIVE_SO5_AVERAGE_SCORE}
        size={small ? 3 : undefined}
      />
    </Tooltip>
  );
};

PlayerScore.fragments = {
  anyPlayer: gql`
    fragment PlayerScore_anyPlayer on AnyPlayerInterface {
      slug
      ...AverageScores_anyPlayer
    }
    ${averagePlayerScoresFragment}
  ` as TypedDocumentNode<PlayerScore_anyPlayer>,
};
