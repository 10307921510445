import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { Caption } from '@sorare/core/src/atoms/typography';
import { isType } from '@sorare/core/src/gql';
import { glossary } from '@sorare/core/src/lib/glossary';

import { GameTime as DumbGameTime } from '@sorare/gameplay/src/components/game/GameView/GameTime';

import { GameTime_anyGame } from './__generated__/index.graphql';

type Props = {
  game: GameTime_anyGame;
};

export const GameTime = ({ game }: Props) => {
  const { statusTyped } = game;

  if (isType(game, 'GameOfBasketball') && statusTyped === GameStatus.playing) {
    return (
      <DumbGameTime
        game={game}
        secondLine={
          game.isHalftime ? (
            <FormattedMessage id="Game.status.halftime" defaultMessage="Half" />
          ) : (
            <FormattedMessage
              {...glossary.ordinal}
              values={{ ordinal: game.quarter }}
            />
          )
        }
      />
    );
  }

  if (statusTyped === GameStatus.played) {
    <DumbGameTime
      game={game}
      secondLine={
        <Caption color="var(--c-nd-600)">
          <FormattedMessage id="Game.status.final" defaultMessage="Final" />
        </Caption>
      }
    />;
  }
  return <DumbGameTime game={game} />;
};

GameTime.fragments = {
  anyGame: gql`
    fragment GameTime_anyGame on AnyGameInterface {
      id
      statusTyped
      ... on GameOfBasketball {
        id
        quarter
        isHalftime
      }
      ...GameTime_AnyGameInterface
    }
    ${DumbGameTime.fragments.AnyGameInterface}
  ` as TypedDocumentNode<GameTime_anyGame>,
};
