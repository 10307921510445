import classNames from 'classnames';
import {
  CSSProperties,
  Children,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { ChevronDownBold } from 'atoms/icons/ChevronDownBold';
import { LabelS } from 'atoms/typography';
import { glossary } from 'lib/glossary';

import { Horizontal, Vertical } from '../flex';

const Root = styled(Vertical)`
  gap: var(--double-unit);
  cursor: pointer;
  position: relative;
  isolation: isolate;

  &.expanded {
    cursor: initial;
  }
`;
const ShowLessWrapper = styled(Horizontal)`
  justify-content: flex-end;
  transition: all 0.5s;
  opacity: 0;
  margin-top: calc(-1 * var(--quadruple-unit));
  z-index: -1;

  .expanded & {
    opacity: 1;
    margin-top: 0;
  }
`;
const ItemWrapper = styled.div`
  transition: all 0.5s;
  &:nth-child(1) {
    z-index: 3;
  }
  &:nth-child(2) {
    z-index: 2;
    margin-top: calc(-1 * var(--firstItemHeight));
    transform: scale(0.95);
  }
  &:nth-child(3) {
    z-index: 1;
    margin-top: calc(-1 * var(--firstItemHeight));
    transform: scale(0.9);
  }
  &:nth-child(n + 4) {
    z-index: 0;
    margin-top: calc(-1 * var(--firstItemHeight) - var(--double-unit));
    transform: scale(0.9);
  }
  .expanded & {
    margin-top: 0;
    transform: none;
  }
`;

const StyledHorizontal = styled(Horizontal)`
  align-self: flex-end;
  justify-content: flex-end;
`;
const ChevronUp = styled(ChevronDownBold)`
  transform: rotate(180deg);
`;

type ShowLessProps = {
  onClick: () => void;
};
const ShowLess = ({ onClick }: ShowLessProps) => {
  return (
    <ButtonBase onClick={onClick}>
      <StyledHorizontal gap={0.5}>
        <ChevronUp />
        <LabelS color="var(--c-nd-800)">
          <FormattedMessage {...glossary.showLess} />
        </LabelS>
      </StyledHorizontal>
    </ButtonBase>
  );
};

type Props = {
  children: ReactNode;
  startsCollapsed?: boolean;
};
export const CollapsibleTileContainer = ({
  children,
  startsCollapsed = true,
}: Props) => {
  const [rendered, setRendered] = useState(false);
  const [expanded, setExpanded] = useState(!startsCollapsed);
  const ref = useRef<HTMLDivElement>(null);
  const childrenArray = Children.toArray(children);
  const firstItem = childrenArray.slice(0, 1);
  const otherItems = childrenArray.slice(1, childrenArray.length);

  useLayoutEffect(() => {
    /**
     * mandatory because the ref is only set once rendered once,
     * otherwise its height would be undefined.
     */
    setRendered(true);
  }, []);

  if (childrenArray.length === 1) {
    return children;
  }

  return (
    <Root
      className={classNames({ expanded })}
      onClickCapture={e => {
        if (!expanded) {
          e.stopPropagation();
          e.preventDefault();
          setExpanded(true);
        }
      }}
      style={
        {
          '--firstItemHeight': `${ref.current?.clientHeight ?? 0}px`,
        } as CSSProperties
      }
    >
      <ShowLessWrapper>
        <ShowLess
          onClick={() => {
            setExpanded(false);
          }}
        />
      </ShowLessWrapper>
      <Vertical gap={2}>
        <ItemWrapper ref={ref}>{firstItem}</ItemWrapper>
        {rendered &&
          otherItems.map((child, index) => (
            <ItemWrapper
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {child}
            </ItemWrapper>
          ))}
      </Vertical>
      {expanded && (
        <ShowLess
          onClick={() => {
            setExpanded(false);
          }}
        />
      )}
    </Root>
  );
};
