import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { CSSProperties, Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelM, LabelS } from 'atoms/typography';
import { fantasy } from 'lib/glossary';
import { Color } from 'style/types';
import { skeletonStyle } from 'style/utils';

const BAR_HEIGHT = 8;
const STEP_MARKER_SIZE = 16;
const FIRST_STEP_POSITION = 50; // 50% of the bar

const Root = styled(Vertical).attrs({ gap: 0.5 })`
  align-items: flex-start;
`;
const Heading = styled(Vertical).attrs({ gap: 0.5 })`
  padding-bottom: var(--half-unit);
`;
const Logo = styled.img`
  width: var(--double-unit);
  aspect-ratio: 1;
`;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${STEP_MARKER_SIZE}px;
`;
const Bar = styled.div`
  position: absolute;
  height: ${BAR_HEIGHT}px;
  border-radius: calc(${BAR_HEIGHT}px / 2);
  top: calc(${STEP_MARKER_SIZE}px / 2 - ${BAR_HEIGHT}px / 2);
  left: 0;
`;
const BackgroundBar = styled(Bar)`
  background-color: var(--bg-color);
  right: 0;

  &.loading {
    ${skeletonStyle}
    --skeleton-highlight: rgba(var(--c-rgb-neutral-800), 0.2);
    position: absolute;
  }
`;
const ProgressBar = styled(Bar)`
  background-color: var(--color);

  .animated & {
    transition: 0.5s ease-in-out width;
  }
`;
const StepMarker = styled.div`
  width: ${STEP_MARKER_SIZE}px;
  height: ${STEP_MARKER_SIZE}px;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%);
  background-color: var(--c-nl-900-opaque);
  border: 1px solid var(--c-nd-200);
  color: var(--c-nd-400);
  display: flex;
  justify-content: center;
  align-items: center;

  &.completed {
    background-color: var(--color);
    color: var(--c-black);
    border: none;
  }
`;
const StyledFontawesomeIcon = styled(FontAwesomeIcon)`
  width: 8px;
`;
const ValueWrapper = styled.div`
  position: absolute;
  height: ${STEP_MARKER_SIZE}px;
  min-width: ${STEP_MARKER_SIZE}px;
  top: 0;
  transform: translate(-50%);
  border-radius: ${STEP_MARKER_SIZE / 2}px;
  background-color: var(--c-white);
  color: var(--c-black);
  padding: var(--half-unit);
  display: flex;
  align-items: center;
  justify-content: center;

  .animated & {
    transition: 0.5s ease-in-out left;
  }
`;
const Value = styled.span`
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
`;
const LabelsWrapper = styled(Vertical).attrs({ gap: 0 })`
  align-items: flex-start;
  gap: 2px;
`;
const ThresholdValue = styled(LabelS)`
  position: absolute;
  transform: translate(-50%);
`;
const StatValue = styled(ThresholdValue)`
  top: 20px;

  &.completed {
    color: var(--color);
  }
`;
const BonusValue = styled(ThresholdValue)`
  top: 34px;

  &.completed {
    color: var(--c-score-high);
  }
`;
const StatNameLabel = styled(LabelS)`
  color: var(--color);
`;

export type Step = { threshold: number; bonus: string | number };

type GetProgressParams = {
  steps: Step[];
  value: number;
  completedSteps: Step[];
  nbSteps: number;
};
const getProgress = ({
  steps,
  value,
  completedSteps,
  nbSteps,
}: GetProgressParams) => {
  if (completedSteps.length === 0) {
    return FIRST_STEP_POSITION * (value / steps[0].threshold);
  }
  if (completedSteps.length < steps.length) {
    const lastCompletedStep = completedSteps[completedSteps.length - 1];
    const nextStep = steps[completedSteps.length];
    return (
      FIRST_STEP_POSITION +
      ((100 - FIRST_STEP_POSITION) / nbSteps) * (completedSteps.length - 1) +
      (((100 - FIRST_STEP_POSITION) / nbSteps) *
        (value - lastCompletedStep.threshold)) /
        (nextStep.threshold - lastCompletedStep.threshold)
    );
  }

  const lastCompletedStep = completedSteps[completedSteps.length - 1];
  const previousToLastCompletedStep = completedSteps[completedSteps.length - 2];
  return Math.min(
    FIRST_STEP_POSITION +
      ((100 - FIRST_STEP_POSITION) / nbSteps) * (completedSteps.length - 1) +
      (((100 - FIRST_STEP_POSITION) / nbSteps) *
        (value - lastCompletedStep.threshold)) /
        (lastCompletedStep.threshold - previousToLastCompletedStep.threshold),
    100
  );
};

type Props = {
  steps: Step[];
  value: number;
  logoUrl?: string;
  tacticName: string;
  statName: string;
  valueLoading?: boolean;
  color: string;
  backgroundColor?: Color;
  animated?: boolean;
};

export const TacticProgress = ({
  steps,
  value,
  logoUrl,
  tacticName,
  statName,
  valueLoading,
  color,
  backgroundColor,
  animated = false,
}: Props) => {
  const nbSteps = steps.length;
  const completedSteps = steps.filter(step => step.threshold <= value);
  const progress = getProgress({ steps, value, completedSteps, nbSteps });

  return (
    <Root
      style={{ '--color': color } as CSSProperties}
      className={classNames({ animated })}
    >
      <Heading>
        <Horizontal gap={0.5}>
          {logoUrl && <Logo src={logoUrl} alt="" />}
          <LabelM as="p" bold>
            {tacticName}
          </LabelM>
        </Horizontal>
      </Heading>
      <Wrapper>
        <BackgroundBar
          style={
            {
              '--bg-color': backgroundColor || 'var(--c-nd-200)',
            } as CSSProperties
          }
          className={classNames({ loading: valueLoading })}
        />
        <ProgressBar
          style={{
            width: `${progress}%`,
          }}
        />
        {steps.map(({ threshold, bonus }, index) => {
          const stepPosition = `${
            FIRST_STEP_POSITION +
            index * ((100 - FIRST_STEP_POSITION) / nbSteps)
          }%`;
          const completed = value >= threshold;

          return (
            <Fragment key={threshold}>
              <StepMarker
                className={classNames({ completed })}
                style={{
                  left: stepPosition,
                }}
              >
                <StyledFontawesomeIcon icon={faCheck} />
              </StepMarker>
              <BonusValue
                className={classNames({ completed })}
                style={{ left: stepPosition }}
              >
                +{bonus}
              </BonusValue>
              <StatValue
                className={classNames({ completed })}
                style={{ left: stepPosition }}
              >
                {threshold}
              </StatValue>
            </Fragment>
          );
        })}
        {!valueLoading && (
          <ValueWrapper style={{ left: `min(${progress}%, 100% - 8px)` }}>
            <Value>
              <FormattedNumber
                value={value}
                maximumFractionDigits={value < 10 ? 1 : 0}
              />
            </Value>
          </ValueWrapper>
        )}
      </Wrapper>
      <LabelsWrapper>
        <StatNameLabel>{statName}</StatNameLabel>
        <LabelS color="var(--c-score-high)">
          <FormattedMessage {...fantasy.bonus} />
        </LabelS>
      </LabelsWrapper>
    </Root>
  );
};
