import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import playerPlaceholder from '@sorare/core/src/assets/players/placeholder.png';
import { Horizontal, SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { getShortPlayerName } from '@sorare/core/src/lib/players';

import { FeaturedPlayerListItem_anyPlayerInterface } from './__generated__/index.graphql';

const SpaceBetween = styled(SBHorizontal).attrs({ gap: 5 })`
  &.highlighted {
    background: linear-gradient(
        to bottom,
        rgba(var(--c-rgb-brand-600), 0.1),
        rgba(var(--c-rgb-brand-600), 0.1)
      ),
      linear-gradient(to bottom, var(--c-nd-50), var(--c-nd-50));
  }
`;

const PlayerImage = styled.div`
  position: relative;
  width: var(--player-image-size);
  height: var(--player-image-size);
  border-radius: 100%;
  border: 3px solid var(--c-nd-800-opaque);
  background-position: center center;
  background-size: contain;
  background-color: var(--c-white);
  background-repeat: no-repeat;
`;

const PlayerName = styled(LabelM).attrs({ as: 'div' })`
  white-space: nowrap;
`;

type Props = {
  player: FeaturedPlayerListItem_anyPlayerInterface;
  score: ReactNode;
  highlighted?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const FeaturedPlayersListItem = ({
  player,
  score,
  highlighted,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <SpaceBetween
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames({ highlighted })}
    >
      <Horizontal>
        <PlayerImage
          style={{
            backgroundImage: `url(${
              player.avatarPictureUrl || playerPlaceholder
            })`,
          }}
        />
        <PlayerName>{getShortPlayerName(player.displayName)}</PlayerName>
      </Horizontal>

      {score}
    </SpaceBetween>
  );
};

FeaturedPlayersListItem.fragments = {
  anyPlayerInterface: gql`
    fragment FeaturedPlayerListItem_anyPlayerInterface on AnyPlayerInterface {
      slug
      displayName
      avatarPictureUrl
    }
  ` as TypedDocumentNode<FeaturedPlayerListItem_anyPlayerInterface>,
};
