import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { Bold } from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import {
  ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG,
  FOOTBALL_CLUB_SHOP_TAB,
  FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { getName } from 'lib/cards';
import { getShieldScoreRequirement } from 'lib/collections';
import { getSportFromSportOrShared } from 'lib/sports';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { CardCollectionNotification_cardCollectionNotification } from './__generated__/index.graphql';

const FlexWrapper = styled(Vertical).attrs({ gap: 0 })``;

type Props = CommonNotificationProps & {
  notification: CardCollectionNotification_cardCollectionNotification;
};

export const CardCollectionNotification = ({
  notification,
  ...rest
}: Props) => {
  const { currentUser, sportProfileBySport } = useCurrentUserContext();
  const { generateSportContextPath } = useSportContext();
  if (!currentUser) return null;
  const {
    name,
    createdAt,
    anyCard: card,
    cardCollection,
    sport,
    read,
    shieldShopItem,
  } = notification;

  if (name === 'card_attached' && card) {
    return (
      <DumbNotification
        name={name}
        title={
          <FormattedMessage
            id="Activity.Notifications.cardAttached"
            defaultMessage="<b>{card}</b> has been added to your collection <b>{collection}</b>"
            values={{
              b: Bold,
              card: getName(card),
              collection: cardCollection.name,
            }}
          />
        }
        userAvatar={currentUser}
        link={generateSportContextPath(
          ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG,
          {
            params: {
              slug: currentUser.slug,
              collection: cardCollection.slug,
              collectionSlug: cardCollection.slug,
            },
            sport: card.sport,
          }
        )}
        cardPicture={card}
        createdAt={createdAt}
        sport={sport}
        read={read}
        {...rest}
      />
    );
  }

  if (name === 'shield_rewarded') {
    return (
      <DumbNotification
        name={name}
        title={
          <FormattedMessage
            id="Activity.Notifications.shieldRewarded"
            defaultMessage="Congrats! You have unlocked the <b>{clubName}</b> Club Badge in the club shop by reaching {score} points in the <b>{collection}</b> collection"
            values={{
              b: Bold,
              clubName: cardCollection.team?.name,
              score: getShieldScoreRequirement(
                cardCollection.slug,
                shieldShopItem
              ),
              collection: cardCollection.name,
            }}
          />
        }
        userAvatar={currentUser}
        link={generatePath(FOOTBALL_CLUB_SHOP_TAB, {
          tab: 'inventory',
        })}
        createdAt={createdAt}
        sport={sport}
        read={read}
        {...rest}
      />
    );
  }

  if (name === 'shield_deprived') {
    const actualSport = getSportFromSportOrShared(sport);
    const equippedClubShieldId = actualSport
      ? sportProfileBySport[actualSport]?.clubBadge?.id
      : null;
    const collectionShieldId = shieldShopItem?.id;

    return (
      <DumbNotification
        name={name}
        title={
          <FlexWrapper>
            <span>
              <FormattedMessage
                id="Activity.Notifications.shieldDeprived"
                defaultMessage="You lost access to the <b>{clubName}</b> Club Badge in the club shop by scoring under {score} points in the <b>{collection}</b> collection"
                values={{
                  b: Bold,
                  clubName: cardCollection.team?.name,
                  score: getShieldScoreRequirement(
                    cardCollection.slug,
                    shieldShopItem
                  ),
                  collection: cardCollection.name,
                }}
              />
            </span>
            {equippedClubShieldId === collectionShieldId && (
              <span>
                <FormattedMessage
                  id="Activity.Notification.equippedShieldDeprived"
                  defaultMessage="You will lose access to your badge once it is unequipped"
                />
              </span>
            )}
          </FlexWrapper>
        }
        userAvatar={currentUser}
        link={generatePath(FOOTBALL_MY_CLUB_SLUG_COLLECTIONS_COLLECTION, {
          slug: currentUser.slug,
          collection: cardCollection.slug,
        })}
        createdAt={createdAt}
        sport={sport}
        read={read}
        {...rest}
      />
    );
  }

  return null;
};

CardCollectionNotification.fragments = {
  cardCollectionNotification: gql`
    fragment CardCollectionNotification_cardCollectionNotification on CardCollectionNotification {
      id
      ...Notification_notificationInterface
      cardCollection {
        slug
        name
        team: anyTeam {
          slug
          name
        }
      }
      anyCard {
        slug
        pictureUrl
        sport
        player {
          slug
          displayName
        }
        ...DumbNotification_cardPicture
        ...getName_anyCard
      }
      shieldShopItem {
        id
        ...getShieldScoreRequirement_skinShopItem
      }
    }
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
    ${getShieldScoreRequirement.fragments.skinShopItem}
    ${getName.fragments.anyCard}
  ` as TypedDocumentNode<CardCollectionNotification_cardCollectionNotification>,
};
