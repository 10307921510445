import classNames from 'classnames';
import styled from 'styled-components';

import { useIntlContext } from 'contexts/intl';
import { Color } from 'style/types';

type StatsType = {
  percent: number;
  color: Color;
}[];

const Root = styled.svg`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--quadruple-unit);
  height: var(--triple-unit);
  border-radius: var(--half-unit);
  background-color: var(--c-nd-100);
  &[role='button']:hover {
    cursor: pointer;
    background-color: var(--c-nd-200);
  }

  &.outlined {
    outline: 1px solid var(--c-nd-200);
  }
  &.transparent {
    background-color: transparent;
  }
`;

type Props = {
  stats: StatsType;
  outlined?: boolean;
  transparent?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
};

export const StatsIcon = ({ stats, onClick, outlined, transparent }: Props) => {
  const { formatMessage } = useIntlContext();
  return (
    <Root
      viewBox="0 0 300 200"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={formatMessage({
        id: 'StatsIcon.label',
        defaultMessage: 'Last 5 stats',
      })}
      onClick={onClick}
      role={onClick ? 'button' : 'img'}
      className={classNames({ outlined, transparent })}
    >
      {stats.map(({ percent, color }, i) => {
        const maxHeight = 140;
        const height = Math.max(30, maxHeight * percent);

        return (
          <rect
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            x={(i + 1) * 50 - 15}
            y={maxHeight + 30 - height}
            width={30}
            height={height}
            rx={15}
            fill={color}
            aria-label="stat-icon-rect"
          />
        );
      })}
    </Root>
  );
};
