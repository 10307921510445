import styled, { css } from 'styled-components';

import { ShopItemType } from '@sorare/core/src/__generated__/globalTypes';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';

enum Areas {
  picture = 'picture',
  pictureUrl = 'picture_url',
  firstLine = 'first_line',
  secondLine = 'second_line',
  content = 'content',
}
const Root = styled.div`
  --areas: '${Areas.picture} ${Areas.firstLine} ${Areas.firstLine} .'
    '${Areas.picture} ${Areas.pictureUrl} ${Areas.secondLine} .'
    '${Areas.content} ${Areas.content} ${Areas.content} ${Areas.content}';
  display: grid;
  grid-template-areas: 'header header header' 'achievementBadges main .';
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr var(--double-unit);
  gap: var(--double-unit);
  padding: var(--double-unit);
  background-color: var(--c-nd-100);
  background-size: cover;
  background-position: center;
  border-radius: var(--intermediate-unit);
  width: 260px;
  height: 170px;
`;
const PlaceholderBlock = styled.div`
  background-color: var(--c-nd-50);
  border-radius: var(--unit);
`;
const AvatarPlaceholder = styled(PlaceholderBlock)`
  width: var(--triple-unit);
  height: var(--unit);
`;
const ButtonPlaceholder = styled(PlaceholderBlock)`
  width: var(--quadruple-unit);
  height: var(--unit);
`;
const achievementBadgeCss = css`
  width: var(--triple-unit);
  height: var(--triple-unit);
  border-radius: 50%;
`;
const AchievementBadgePlaceholder = styled(PlaceholderBlock)`
  ${achievementBadgeCss}
`;
const AchievementBadge = styled.img`
  ${achievementBadgeCss}
`;
const clubBadgeCss = css`
  width: calc(5 * var(--unit));
  height: calc(5 * var(--unit));
`;
const ClubBadgePlaceholder = styled(PlaceholderBlock)`
  ${clubBadgeCss}
`;
const ClubBadge = styled.img`
  ${clubBadgeCss}
`;
const ClubNamePlaceholder = styled(PlaceholderBlock)`
  width: calc(15 * var(--unit));
  height: var(--unit);
`;
const StatsPlaceholder = styled(PlaceholderBlock)`
  width: calc(15 * var(--unit));
  height: var(--double-unit);
`;
const Header = styled(SBHorizontal)`
  grid-area: header;
`;
const AchievementBadges = styled(Vertical)`
  grid-area: achievementBadges;
`;
const Main = styled(Vertical)`
  grid-area: main;
`;

type Props = { type: ShopItemType; name: string; pictureUrl: string };
const ItemImagePlaceholder = ({ type, name, pictureUrl }: Props) => {
  return (
    <Root
      style={
        type === ShopItemType.BACKGROUND
          ? {
              backgroundImage: `url(${pictureUrl})`,
            }
          : {}
      }
    >
      <Header>
        <AvatarPlaceholder />
        <Horizontal>
          <ButtonPlaceholder />
          <ButtonPlaceholder />
          <ButtonPlaceholder />
        </Horizontal>
      </Header>
      <AchievementBadges center>
        {type === ShopItemType.ACHIEVEMENT_BADGE ? (
          <AchievementBadge src={pictureUrl} alt={name} />
        ) : (
          <AchievementBadgePlaceholder />
        )}
        <AchievementBadgePlaceholder />
        <AchievementBadgePlaceholder />
      </AchievementBadges>
      <Main center>
        {type === ShopItemType.CLUB_BADGE ? (
          <ClubBadge src={pictureUrl} alt={name} />
        ) : (
          <ClubBadgePlaceholder />
        )}
        <ClubNamePlaceholder />
        <StatsPlaceholder />
      </Main>
    </Root>
  );
};

export default ItemImagePlaceholder;
