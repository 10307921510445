import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Rarity } from '__generated__/globalTypes';
import { ScarcityIcon } from 'atoms/icons/ScarcityIcon';
import { Horizontal, SBHorizontal } from 'atoms/layout/flex';
import {
  NullableTypographyVariant,
  Text16,
  TypographyVariant,
} from 'atoms/typography';
import { Color } from 'style/types';

interface Props {
  displayName: string;
  path: string | null;
  description: ReactNode;
  Title?: TypographyVariant;
  titleColor?: Color;
  Details?: NullableTypographyVariant;
  detailsColor?: Color;
  withoutLink?: boolean;
  row?: boolean;
  scarcity?: Rarity;
  bonus?: ReactNode;
}

const Root = styled.div`
  &.row {
    flex-direction: row;
  }
`;
const LinkWrapper = styled(Link)`
  &:hover {
    text-decoration: underline;
    color: var(--c-link);
  }
`;

const FullWidth = styled.div`
  width: 100%;
`;

const DisplayNameWitBonus = styled(SBHorizontal)`
  width: 100%;
`;

export const CardDescriptionFromProps = ({
  displayName,
  path,
  description,
  Title = Text16,
  titleColor = 'var(--c-white)',
  Details = Title,
  detailsColor = titleColor,
  withoutLink = false,
  row = false,
  scarcity,
  bonus,
}: Props) => {
  const renderDisplayName = () => {
    if (bonus)
      return (
        <DisplayNameWitBonus as="span" gap={2}>
          <Title color={titleColor}>{displayName} </Title>
          {bonus}
        </DisplayNameWitBonus>
      );
    return <Title color={titleColor}>{displayName}</Title>;
  };

  return (
    <Root className={classNames({ row })}>
      <Horizontal>
        {scarcity && <ScarcityIcon scarcity={scarcity} size="lg" />}
        <FullWidth>
          {withoutLink || !path ? (
            renderDisplayName()
          ) : (
            <LinkWrapper to={path}>{renderDisplayName()}</LinkWrapper>
          )}
        </FullWidth>
      </Horizontal>
      <Details color={detailsColor}>{description}</Details>
    </Root>
  );
};
