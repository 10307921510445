import { useMemo } from 'react';
import { useHits } from 'react-instantsearch';
import { FormattedMessage } from 'react-intl';

import { Rarity, Sport } from '@sorare/core/src/__generated__/globalTypes';
import { BlockHeader } from '@sorare/core/src/atoms/layout/BlockHeader';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { InstantCardSearch } from '@sorare/core/src/components/search/InstantSearch';
import { CardHit } from '@sorare/core/src/lib/algolia';

import { tokenPageMessages } from 'components/token/TokenPage/tokenPageMessages';
import useDefaultFilters from 'hooks/useDefaultFilters';

import TokensRow from '../TokensRow';

type TokenProps = {
  hitsPerRow: number;
};
export const TokensAvailableOnPrimary = ({ hitsPerRow }: TokenProps) => {
  const { items } = useHits<CardHit>();

  const assetIds = useMemo(() => items.map(h => h.asset_id), [items]);

  if (!items.length) return null;

  return (
    <Vertical>
      <BlockHeader
        title={
          <FormattedMessage {...tokenPageMessages.alsoAvailableOnPrimary} />
        }
      />
      <TokensRow hitsPerRow={hitsPerRow} assetIds={assetIds} />
    </Vertical>
  );
};

type Props = {
  rarity: Rarity;
  playerSlug: string;
  sport: Sport;
  hitsPerRow: number;
};

export const AvailableOnPrimary = ({
  hitsPerRow = 3,
  rarity,
  playerSlug,
  sport,
}: Props) => {
  const filters = useDefaultFilters({
    primary: true,
    playerSlug,
    rarity,
    bundled: false,
  });

  return (
    <InstantCardSearch
      indexes={['Ending Soon']}
      analyticsTags={['AvailableOnPrimary']}
      defaultHitsPerPage={hitsPerRow}
      sport={sport}
      defaultFilters={filters}
    >
      <TokensAvailableOnPrimary hitsPerRow={hitsPerRow} />
    </InstantCardSearch>
  );
};

export default AvailableOnPrimary;
