import {
  faEnvelope,
  faEnvelopeCircleCheck,
} from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeDot } from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingButton } from 'atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { Text16, Title3 } from 'atoms/typography';
import Dialog from 'components/dialog';
import { GoogleReCAPTCHA, ReCAPTCHA } from 'components/recaptcha';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useEvents } from 'lib/events/useEvents';
import { avoidOrphan } from 'lib/text';
import { laptopAndAbove } from 'style/mediaQuery';

import useResendConfirmationInstructions from './useResendConfirmationInstructions';

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--double-unit);
  padding: calc(3 * var(--unit));
  text-align: center;
  @media ${laptopAndAbove} {
    width: 480px;
  }
`;
const GreyText = styled(Text16)`
  color: var(--c-nd-600);
`;
const ButtonContainer = styled.div`
  padding: var(--unit);
`;

const EnveloppeIcon = styled(FontAwesomeIcon)`
  margin-left: var(--unit);
`;

const buttonTextMessages = defineMessages({
  emailNotSent: {
    id: 'user.verifyEmail.cta.emailNotSent',
    defaultMessage: 'Resend an email',
  },
  emailSent: {
    id: 'user.verifyEmail.cta.emailSent',
    defaultMessage: 'Email sent',
  },
});

type Props = { closable?: boolean; onClose: () => void };

const EmailVerificationDialog = ({ closable = true, onClose }: Props) => {
  const track = useEvents();
  const { formatMessage } = useIntl();
  const resendConfirmationInstructions = useResendConfirmationInstructions();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { currentUser } = useCurrentUserContext();

  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);

  useEffect(() => {
    track('View Email Confirmation Popin');
  }, [track]);

  useEffect(() => {
    if (currentUser?.confirmed) {
      onClose();
    }
  }, [currentUser?.confirmed, onClose]);

  const onClick = async () => {
    setLoading(true);

    recaptchaRef.current?.reset();
    const recaptchaTokenV2 = await recaptchaRef.current?.executeAsync();

    if (recaptchaTokenV2) {
      const { errors } = await resendConfirmationInstructions({
        variables: {
          input: {
            recaptchaTokenV2,
            fromPath: location.pathname,
          },
        },
      });
      if (!errors.length) {
        setEmailSent(true);
      }
    }
    setLoading(false);
  };
  return (
    <Dialog
      open
      maxWidth={false}
      data-dialog="EmailVerificationDialog"
      onClose={closable ? onClose : undefined}
    >
      <DialogContainer>
        <FontAwesomeIcon
          icon={faEnvelopeDot}
          fontSize={64}
          color="var(--c-brand-600)"
        />

        <Title3>
          {avoidOrphan(
            formatMessage({
              id: 'user.verifyEmail.title',
              defaultMessage: 'We still need you to confirm your account.',
            })
          )}
        </Title3>

        <GreyText>
          <FormattedMessage
            id="user.verifyEmail.subtitle"
            defaultMessage="Check your emails to confirm your account or try resending a new confirmation email."
          />
        </GreyText>

        <ReCAPTCHA ref={recaptchaRef} />

        <ButtonContainer>
          <LoadingButton
            size="medium"
            color="primary"
            onClick={() => {
              onClick();
            }}
            disabled={emailSent}
            loading={loading}
            startIcon={
              !loading &&
              (emailSent ? (
                <EnveloppeIcon icon={faEnvelopeCircleCheck} />
              ) : (
                <EnveloppeIcon icon={faEnvelope} />
              ))
            }
          >
            {formatMessage(
              emailSent
                ? buttonTextMessages.emailSent
                : buttonTextMessages.emailNotSent
            )}
          </LoadingButton>
        </ButtonContainer>
      </DialogContainer>
    </Dialog>
  );
};

export default EmailVerificationDialog;
