import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Rarity, So5LeaderboardRarity } from '__generated__/globalTypes';
import { unitMapping } from 'lib/style';

import { User } from '../User';

type ScarcityType =
  | Lowercase<keyof typeof So5LeaderboardRarity>
  | keyof typeof Rarity;

export type Props = {
  scarcity: ScarcityType;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

const Icon = styled.span`
  display: inline-flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  width: 1.5em;
  height: 2em;
  &.common {
    background: var(--c-gradient-common);
    color: var(--c-black);
  }
  &.custom_series {
    background: var(--c-gradient-customSeries);
  }
  &.limited {
    background: var(--c-gradient-limited);
  }
  &.mix {
    background: var(--c-gradient-mix);
  }
  &.rare {
    background: var(--c-gradient-rare);
  }
  &.rare_pro {
    background: var(--c-gradient-rare);
  }
  &.super_rare {
    background: var(--c-gradient-superRare);
  }
  &.unique {
    background: var(--c-gradient-unique);
  }
  &.sm {
    font-size: 7px;
  }
  &.md {
    font-size: 10px;
  }
  &.lg {
    font-size: 14px;
  }
  &.xl {
    font-size: 18px;
  }
`;

export const ScarcityIcon = ({
  scarcity,
  size = 'sm',
  children = <User />,
}: Props) => {
  return (
    <Icon aria-label={scarcity} className={classNames(scarcity, size)}>
      {children}
    </Icon>
  );
};

const colorsPerRarity = {
  [Rarity.common]: ['#D1DCDF', '#A9B1B5'],
  [Rarity.limited]: ['#D98C00', '#905D00'],
  [Rarity.rare]: ['#BF030E', '#900000'],
  [Rarity.super_rare]: ['#032AB9', '#17156D'],
  [Rarity.unique]: ['#3C3461', '#0E184A'],
};

type NewScarcityIconProps = {
  rarity: Rarity | 'mix';
  size?: keyof typeof unitMapping;
  className?: string;
};

export const NewScarcityIcon = ({
  rarity,
  size = 3,
  className,
}: NewScarcityIconProps) => {
  const [fill, gradient] =
    colorsPerRarity[
      rarity in colorsPerRarity
        ? (rarity as keyof typeof colorsPerRarity)
        : Rarity.unique
    ];

  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.5 3C4.5 1.34315 5.84315 0 7.5 0H16.5C18.1569 0 19.5 1.34315 19.5 3V21C19.5 22.6569 18.1569 24 16.5 24H7.5C5.84315 24 4.5 22.6569 4.5 21V3Z"
        fill={fill}
      />
      <path
        d="M4.6875 3C4.6875 1.4467 5.9467 0.1875 7.5 0.1875H16.5C18.0533 0.1875 19.3125 1.4467 19.3125 3V21C19.3125 22.5533 18.0533 23.8125 16.5 23.8125H7.5C5.9467 23.8125 4.6875 22.5533 4.6875 21V3Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="0.375"
      />
      <g>
        <path
          d="M5.09344 14.3819V6.56536L12.2434 3.12817L19.1418 6.56536V14.3819L12.2434 17.6684L5.09344 14.3819Z"
          fill="white"
          fillOpacity="0.3"
        />
      </g>
      <path
        d="M19.1418 12.0528C18.7838 12.0528 19.0405 11.5507 18.6335 11.4236C18.3634 11.297 16.0448 10.3863 14.9193 9.94675C14.8127 9.88015 14.5939 9.60328 14.4188 9.38165C14.3166 9.25225 14.2292 9.14168 14.1877 9.10276C14.0977 9.01831 14.1127 8.1534 14.1314 7.7315C14.1502 7.46779 14.244 7.0985 14.244 7.0985C14.863 6.8876 14.9193 5.88547 14.9193 5.88547C15.0544 5.37913 14.6379 5.35803 14.4691 5.56892L14.4691 4.83059C14.4691 4.7884 14.3941 4.0746 14.3565 3.72297C14.3565 3.12521 13.625 2.14065 12.1055 1.98242C10.3047 2.02462 9.77946 3.16037 9.74194 3.72297L9.74194 4.77785L9.79822 5.56892C9.64815 5.27004 9.34801 5.41077 9.34801 5.83264C9.34801 6.76094 9.83574 7.06333 10.0796 7.0985C10.1546 7.29189 10.316 7.73142 10.361 7.9424C10.406 8.15338 10.3422 8.80388 10.3047 9.10276L9.85449 9.89392C8.86029 10.2455 6.71432 11.0015 6.08403 11.2125C5.45374 11.4235 5.24351 12.1459 5.09344 12.4447L5.09344 13.4397L5.09344 13.3744C5.09344 14.1318 5.09345 14.3518 5.99985 14.7458L12.1055 17.6985L18.3504 14.7458C19.1788 14.7458 19.1418 14.1318 19.1418 13.3744V12.0528Z"
        fill={`url(#card-gradient-${rarity})`}
      />
      <g>
        <path
          d="M12.4144 18.392L5.06049 14.985C5.06049 15.9257 5.43423 16.1408 5.68706 16.2513L12.4144 19.5377C14.2282 18.6533 18.2778 16.4925 18.6472 16.2513C19.0165 16.0101 19.0869 15.387 19.1089 14.985L12.4144 18.392Z"
          fill="white"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <linearGradient
          id={`card-gradient-${rarity}`}
          x1="12.1178"
          y1="0.482422"
          x2="12.1178"
          y2="17.6985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={gradient} />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
