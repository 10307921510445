import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { TextInput } from 'atoms/inputs/TextInput';
import { Vertical } from 'atoms/layout/flex';
import { Caption } from 'atoms/typography';
import { FormLabel } from 'components/form/FormLabel';

import Field from '../Field';

const Root = styled(Vertical).attrs({ gap: 0.5 })`
  width: 100%;
`;

export type Props = {
  id?: string;
  name: string;
  placeholder?: string;
  className?: string;
  rows?: number;
  label?: ReactNode;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  helperText?: string;
  endAdornment?: ReactNode;
  withoutAsterisk?: boolean;
  validate?: (value: string) => string | null;
  defaultValue?: string;
} & Partial<InputHTMLAttributes<HTMLInputElement>>;

const LabelText = ({
  label,
  error,
  required,
  withoutAsterisk,
}: {
  label: ReactNode;
  error: string | null;
  required?: boolean;
  withoutAsterisk?: boolean;
}) => (
  <>
    {label} {required && !withoutAsterisk && '* '}
    {error && <span className="error">{error || null}</span>}
  </>
);

const TextField = ({
  id,
  defaultValue = '',
  onChange = () => {},
  name,
  label,
  placeholder,
  required,
  className,
  helperText,
  withoutAsterisk,
  validate,
  ...rest
}: Props) => (
  <Field
    defaultValue={defaultValue}
    name={name}
    validate={validate}
    render={({ error, handleChange, value, disabled }) => {
      return (
        <Root>
          {label && (
            <FormLabel id={id || name}>
              <LabelText label={label} error={error} required={required} />
            </FormLabel>
          )}
          <TextInput
            className={className}
            id={id || name}
            placeholder={
              placeholder
                ? `${placeholder}${
                    required && !label && !withoutAsterisk ? ' *' : ''
                  }`
                : undefined
            }
            value={value}
            required={required}
            onChange={e => {
              onChange?.(e);
              handleChange(e.target.value, e.target);
            }}
            {...rest}
            disabled={disabled || rest.disabled}
          />
          {helperText && (
            <Caption color="var(--c-nd-600)">{helperText}</Caption>
          )}
        </Root>
      );
    }}
  />
);

export default TextField;
