import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { FormEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

import { Dropdown, DropdownOptionLabel } from 'atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from 'atoms/icons';
import { Vertical } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';
import { FormLabel } from 'components/form/FormLabel';

const Container = styled(Vertical).attrs({ gap: 0 })``;

const SelectedLabel = styled(Text16).attrs({
  as: 'span',
})`
  flex: 1;
  text-align: left;
`;

const Button = styled(Text16).attrs({
  as: 'button',
  type: 'button',
})<{ isDisabled?: boolean }>`
  display: flex;
  flex: 1;
  gap: var(--unit);
  align-items: center;
  justify-content: flex-start;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  border: 1px solid var(--c-nd-100);
  padding: 0 var(--double-unit);
  height: 48px;
  border-radius: var(--double-unit);
  color: var(--c-white);
  background: var(--c-nd-100);
  &.disabled {
    cursor: initial;
    pointer-events: none;
    background: var(--c-nd-50);
    & > * {
      opacity: 0.5;
    }
  }
`;

const Select = styled.select`
  opacity: 0;
  height: 0;
`;

export type SimpleSelectOption = {
  label: string | ReactNode;
  value: string;
};
export interface Props {
  options: SimpleSelectOption[];
  value?: string;
  onChange?: FormEventHandler<HTMLFormElement>;
  label?: ReactNode | string;
  isDisabled?: boolean;
  name: string;
  required?: boolean;
}
const SimpleSelect = ({
  name,
  options,
  value,
  onChange,
  label,
  isDisabled,
  required = false,
}: Props) => {
  const getLabel = () => {
    if (!label) {
      return null;
    }
    if (typeof label === 'string') {
      return (
        <FormLabel id={name} required={required}>
          {label}
        </FormLabel>
      );
    }
    return label;
  };
  return (
    <Container>
      {getLabel()}
      <Dropdown
        closeOnChange
        label={props => (
          <Button
            className={classNames({
              disabled: isDisabled,
            })}
            {...props}
          >
            <SelectedLabel>
              {
                (options.find(option => option.value === value) || options[0])
                  ?.label
              }
            </SelectedLabel>
            <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
          </Button>
        )}
        onChange={onChange}
      >
        {options.map(option => {
          return (
            <DropdownOptionLabel
              key={option.value}
              className={classNames({
                selected: value === option.value,
              })}
            >
              {option.label}
              <input
                name={name}
                type="radio"
                value={option.value}
                defaultChecked={value === option.value}
                className="sr-only"
              />
            </DropdownOptionLabel>
          );
        })}
      </Dropdown>
      <Select
        className="sr-only"
        required={required}
        name={name}
        value={value}
        onChange={() => {}}
      >
        {options.map(option => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <option key={option.value} value={option.value} />
        ))}
      </Select>
    </Container>
  );
};

export default SimpleSelect;
