import { To, unstable_useViewTransitionState } from 'react-router-dom';

export const useViewTransitionState = (path: To) => {
  let isTransitioning = false;
  try {
    isTransitioning = unstable_useViewTransitionState(path);
  } catch {
    // It's still expereimental, so the try/catch is here to avoid breaking the app if anything happen.
    // It's fine if it breaks and we don't need to monitor this.'
  }
  return isTransitioning;
};
