import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import styled from 'styled-components';

import { SportOrShared } from '__generated__/globalTypes';
import { Ball } from 'atoms/icons/Ball';
import { MLBBall } from 'atoms/icons/MLBBall';
import { NBABall } from 'atoms/icons/NBABall';
import { Vertical } from 'atoms/layout/flex';
import { Tab, TabBar } from 'atoms/navigation/TabBar';
import { Title4 } from 'atoms/typography';
import { HorizontalDivider } from 'atoms/ui/Divider';
import { SettingsSection as FormSection } from 'components/settings/SettingsSection';
import { DisabledEmailWarning } from 'components/user/DisabledEmailWarning';
import { useCurrentUserContext } from 'contexts/currentUser';
import useLifecycle from 'hooks/useLifecycle';
import {
  NotificationPreferences as Preferences,
  useNotificationPreferences,
} from 'hooks/useNotificationPreferences';
import { getSportOrSharedFromSport, useFormattedSport } from 'lib/sports';

import { Header } from '../SettingsSection/Header';
import { NotificationPreference } from './NotificationPreference';
import {
  DisplayableMarketingPreference,
  DisplayablePreference,
  SupportedNotificationPreference,
  displayableMarketingPreferences,
  displayablePreferences,
  sharedNotificationPreferenceNames,
} from './types';

const title = defineMessage({
  id: 'Settings.Notifications.title',
  defaultMessage: 'Notifications you will receive:',
});

const Wrapper = styled(Vertical).attrs({ gap: 2 })`
  align-items: start;
`;

const SportIcon = ({ sport }: { sport: SportOrShared }) => {
  if (sport === SportOrShared.FOOTBALL) return <Ball />;
  if (sport === SportOrShared.NBA) return <NBABall />;
  if (sport === SportOrShared.BASEBALL) return <MLBBall />;
  return null;
};

const isDisplayablePreference = (
  preference: Preferences
): preference is { name: DisplayablePreference } & Preferences =>
  displayablePreferences.includes(preference.name as DisplayablePreference);

const isDisplayableMarketingPreference = (
  preference: Preferences
): preference is {
  name: DisplayableMarketingPreference;
} & Preferences =>
  displayableMarketingPreferences.includes(
    preference.name as DisplayableMarketingPreference
  );

const isSharedNotificationSetting = (
  preference: Preferences
): preference is { name: SupportedNotificationPreference } & Preferences =>
  sharedNotificationPreferenceNames.includes(
    preference.name as SupportedNotificationPreference
  );

export const NotificationPreferences = () => {
  const { currentUser } = useCurrentUserContext();
  const { lifecycle } = useLifecycle();
  const lastVisitedSport = lifecycle?.lastVisitedSport;
  const [sport, setSport] = useState<SportOrShared>(
    getSportOrSharedFromSport(lastVisitedSport) || SportOrShared.FOOTBALL
  );
  const sportNotificationPreferences = useNotificationPreferences(sport);

  const filteredPreferences = useMemo(() => {
    const preferenceNames = [
      ...displayablePreferences,
      ...displayableMarketingPreferences,
    ] as string[];

    return (
      sportNotificationPreferences.filter(p =>
        preferenceNames.includes(p.name)
      ) || []
    );
  }, [sportNotificationPreferences]);

  if (!currentUser) {
    return null;
  }

  const marketingFilteredPreferences = filteredPreferences.filter(
    isDisplayableMarketingPreference
  );

  const displayableNotificationPreferences = filteredPreferences.filter(
    isDisplayablePreference
  );

  const sharedNotificationPreferences =
    displayableNotificationPreferences.filter(isSharedNotificationSetting);

  const nonSharedNotificationPreferences =
    displayableNotificationPreferences.filter(
      preference => !isSharedNotificationSetting(preference)
    );

  const formTitle =
    sharedNotificationPreferences.length > 0 ? undefined : title;

  const formatSport = useFormattedSport();

  return (
    <Wrapper>
      <TabBar value={sport} compact>
        {[
          SportOrShared.GLOBAL,
          SportOrShared.FOOTBALL,
          SportOrShared.NBA,
          SportOrShared.BASEBALL,
        ].map(s => (
          <Tab key={s} value={s} onClick={() => setSport(s)}>
            <SportIcon sport={s} />
            {formatSport(s)}
          </Tab>
        ))}
      </TabBar>
      <FormSection title={formTitle}>
        <DisabledEmailWarning />
        {sharedNotificationPreferences.length > 0 && (
          <>
            {sharedNotificationPreferences.map(p => (
              <NotificationPreference
                key={p.name}
                preference={p}
                sport={sport}
              />
            ))}
          </>
        )}
        {sharedNotificationPreferences.length > 0 &&
          (nonSharedNotificationPreferences.length > 0 ||
            marketingFilteredPreferences.length > 0) && (
            <Header title={title} />
          )}
        {nonSharedNotificationPreferences.map(p => (
          <NotificationPreference key={p.name} preference={p} sport={sport} />
        ))}
        {!!marketingFilteredPreferences.length && (
          <>
            <HorizontalDivider />
            <Title4>
              <FormattedMessage
                id="Settings.Notifications.marketingTitle"
                defaultMessage="Marketing"
              />
            </Title4>
            {marketingFilteredPreferences.map(p => (
              <NotificationPreference
                key={p.name}
                preference={p}
                sport={sport}
              />
            ))}
          </>
        )}
      </FormSection>
    </Wrapper>
  );
};
