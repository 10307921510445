import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';

import {
  TransactionStatusQuery,
  TransactionStatusQueryVariables,
} from './__generated__/useFetchTransactionStatus.graphql';

const TRANSACTION_STATUS_QUERY = gql`
  query TransactionStatusQuery($transactionId: String!) {
    mangopay {
      id
      remoteTransactionStatus(transactionId: $transactionId) {
        status
        message
      }
    }
  }
` as TypedDocumentNode<TransactionStatusQuery, TransactionStatusQueryVariables>;

export default () => {
  return useLazyQuery(TRANSACTION_STATUS_QUERY);
};
