import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Button, Color } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { CreateFiatWalletWrapper } from 'components/fiatWallet/CreateFiatWalletWrapper';
import { useCurrentUserContext } from 'contexts/currentUser';
import { WalletTab, useWalletDrawerContext } from 'contexts/walletDrawer';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';

export const Label = ({ minimumAmount }: { minimumAmount: number }) => {
  const { formatNumber } = useIntl();
  const { fiatCurrency } = useCurrentUserContext();

  return (
    <FormattedMessage
      id="AddFundsToFiatWallet.Promo.Banner.cta"
      defaultMessage="Deposit {amount}* or more"
      values={{
        amount: formatNumber(minimumAmount, {
          style: 'currency',
          currency: fiatCurrency.code,
          minimumFractionDigits: 0,
        }),
      }}
    />
  );
};

const Root = styled(Vertical).attrs({ gap: 1, center: true })`
  width: 100%;
`;

const Cta = ({
  color = 'primary',
  size = 'medium',
}: {
  color?: Color;
  size?: 'medium' | 'large';
}) => {
  const {
    currentUser,
    walletPreferences: { onlyShowFiatCurrency },
  } = useCurrentUserContext();
  const { setCurrentTab } = useWalletDrawerContext();
  const { canDepositAndWithdraw } = useFiatBalance();

  const getDepositTab = () => {
    if (onlyShowFiatCurrency) {
      return WalletTab.ADD_FUNDS_TO_FIAT_WALLET;
    }
    return WalletTab.ADD_FUNDS;
  };

  return (
    <CreateFiatWalletWrapper>
      {({ setShowCreateFiatWallet }) => (
        <Root>
          <Button
            fullWidth
            size={size}
            color={color}
            onClick={() => {
              if (onlyShowFiatCurrency && !canDepositAndWithdraw) {
                setShowCreateFiatWallet(true);
                return;
              }
              setCurrentTab(getDepositTab());
            }}
          >
            <Label
              minimumAmount={
                (currentUser?.depositPromotionalEvent?.eligibilityRules
                  .minimumDepositAmount || 0) / 100
              }
            />
          </Button>
          <LabelS color="var(--c-nd-600-opaque)">
            <FormattedMessage
              id="AddFundsToFiatWallet.Promo.Banner.ctaHint"
              defaultMessage="*ETH allowed"
            />
          </LabelS>
        </Root>
      )}
    </CreateFiatWalletWrapper>
  );
};

export default Cta;
