// eslint-disable-next-line no-restricted-imports
import { QRCodeCanvas } from 'qrcode.react';
import styled from 'styled-components';

const QRBorder = styled.div`
  display: inline-flex;
  border: var(--half-unit) solid var(--c-white);
`;

type Props = { value: string; size?: number };

export const QRCode = ({ value, size }: Props) => {
  return (
    <QRBorder>
      <QRCodeCanvas value={value} size={size} />
    </QRBorder>
  );
};
