import { useTypedParams } from '@sorare/routing';

import {
  CardQuality,
  Rarity,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';

import { RewardsEssenceQualityRarityPage } from '@sorare/gameplay/src/pages/rewards/RewardsEssenceQualityRarityPage';

import { PageParams } from './__generated__/routeParams';

const FootballRewardsEssenceQualityRarityPage = () => {
  const { quality, rarity } = useTypedParams<PageParams>();
  return (
    <RewardsEssenceQualityRarityPage
      quality={quality as CardQuality}
      rarity={rarity as Rarity}
      sport={Sport.FOOTBALL}
    />
  );
};
export default FootballRewardsEssenceQualityRarityPage;
