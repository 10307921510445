import { TypedDocumentNode, gql } from '@apollo/client';
import { faChevronDown, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { addDays, isBefore } from 'date-fns';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import styled from 'styled-components';

import {
  FiatWalletAccountState,
  Sport,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Select } from '@sorare/core/src/atoms/inputs/Select';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelM, Text16, Title6 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { CreateFiatWallet } from '@sorare/core/src/components/fiatWallet/CreateFiatWallet';
import { FEES_HELP_LINKS } from '@sorare/core/src/components/fiatWallet/CreateFiatWallet/externalLinks';
import {
  GraphqlForm,
  SubmitButtonProps,
} from '@sorare/core/src/components/form/Form';
import MonetaryInputField from '@sorare/core/src/components/form/Form/MonetaryInputField';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { AcceptedCurrenciesValue } from '@sorare/core/src/hooks/useAcceptedCurrencies';
import { useCurrentUserAcceptedCurrencies } from '@sorare/core/src/hooks/useCurrentUserAcceptedCurrencies';
import useLifecycle, { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';
import {
  MonetaryAmountOutput,
  calculateFees,
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useReferenceCurrencyForSale } from '@sorare/core/src/hooks/useReferenceCurrencyForSale';
import { useCashWalletKycEvents } from '@sorare/core/src/hooks/wallets/useCashWalletKycEvents';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { fiatWallet, tradeLabels } from '@sorare/core/src/lib/glossary';
import {
  MonetaryAmountParams,
  getMonetaryAmountIndex,
} from '@sorare/core/src/lib/monetaryAmount';
import {
  ETH_DECIMAL_PLACES,
  RoundingMode,
  fromWei,
} from '@sorare/core/src/lib/wei';
import {
  EnterCashWalletKYCFlow_Source,
  EnterCashWalletKYCFlow_Target,
} from '@sorare/core/src/protos/events/platform/web/events';

import ConfirmationDialogContent from 'components/ConfirmationDialogContent';
import { PaymentBoxAmountWithConversion } from 'components/buyActions/PaymentBox/AmountWithConversion';
import TokenSummary from 'components/buyActions/TokenSummary';
import Row from 'components/offer/Row';
import { TokenTransferValidator } from 'components/token/TokenTransferValidator';
import { TokenTransferChildrenProps } from 'components/token/TokenTransferValidator/types';
import { CreateSingleSaleOfferArgs } from 'hooks/offers/useCreateSingleSaleOffer';
import { useNextTimeUserShouldSeeAcceptedCurrenciesOnListing } from 'hooks/offers/useNextTimeUserShouldSeeAcceptedCurrenciesOnListing';
import { useNextTimeUserShouldSeeActivateFiatWalletOnListing } from 'hooks/offers/useNextTimeUserShouldSeeActivateFiatWalletOnListing';
import { useMarketplaceEvents } from 'lib/events';

import { AcceptedCurrenciesOnListing } from '../AcceptedCurrenciesOnListing';
import { ActivateYourFiatWalletInterstitial } from '../ActivateYourFiatWalletInterstitial';
import { ActivateYourFiatWalletOnListing } from '../ActivateYourFiatWalletOnListing';
import {
  OfferDialogQuery,
  OfferDialogQueryVariables,
  OfferDialog_anyCard,
} from './__generated__/index.graphql';

interface Props {
  open: boolean;
  onClose: () => void;
  submit: (args: Omit<CreateSingleSaleOfferArgs, 'card'>) => Promise<void>;
  title: MessageDescriptor;
  onSuccess?: () => void;
  showSaleDuration?: boolean;
  cta: MessageDescriptor;
  initialAmount?: MonetaryAmountParams;
  assetId: string;
  confirmationMessage: MessageDescriptor;
}

export const messages = defineMessages({
  marketFees: {
    id: 'OfferDialog.marketFees',
    defaultMessage: 'Market fee',
  },
  marketFeesLabel: {
    id: 'OfferDialog.marketFeesLabel',
    defaultMessage: 'Market fee (incl. tax, if applicable)',
  },
  marketFeesDetails: {
    id: 'OfferDialog.marketFeesDetails',
    defaultMessage: 'Sorare is currently covering this fee',
  },
  tooltip: {
    id: 'OfferDialog.tooltip',
    defaultMessage: 'Market fee',
  },
  feesTooltip: {
    id: 'OfferDialog.feesTooltip',
    defaultMessage:
      'Listing is free. Once sold, the following {value}% fees are deducted.',
  },
  feesTooltipLearnMore: {
    id: 'OfferDialog.feesTooltipLearnMore',
    defaultMessage: 'Learn more',
  },
  warningMessage: {
    id: 'OfferDialog.amountTooLow',
    defaultMessage:
      'This amount is much lower than the recorded past transactions for this player.',
  },
  listingDuration: {
    id: 'OfferDialog.listingDuration',
    defaultMessage: 'Listing duration',
  },
  nbDays: {
    id: 'OfferDialog.DurationInput.nbDays',
    defaultMessage: '{nbDays, plural, one {# day} other {# days}}',
  },
});

const MarketFeesLabel = styled(Horizontal).attrs({ gap: 0 })`
  gap: 4px;
`;
const StyledTooltip = styled.div`
  max-width: 220px;
`;

const FeesLabel = ({
  percentagePoints,
  sport,
}: {
  percentagePoints: bigint;
  sport: Sport;
}) => {
  if (percentagePoints > 0) {
    return (
      <MarketFeesLabel>
        <FormattedMessage {...messages.marketFeesLabel} />
        <Tooltip
          placement="top"
          title={
            <StyledTooltip>
              <FormattedMessage
                {...messages.feesTooltip}
                values={{ value: percentagePoints.toString() }}
              />{' '}
              <a href={FEES_HELP_LINKS[sport]} target="_blank" rel="noreferrer">
                <FormattedMessage {...messages.feesTooltipLearnMore} />
              </a>
            </StyledTooltip>
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </MarketFeesLabel>
    );
  }
  return (
    <div>
      <FormattedMessage {...messages.marketFees} />
      <LabelM color="var(--c-nd-600)">
        <FormattedMessage {...messages.marketFeesDetails} />
      </LabelM>
    </div>
  );
};

const allowedDurationsInDays = [1, 2, 3, 4, 5, 6, 7] as const;

type DurationInDays = (typeof allowedDurationsInDays)[number];

const defaultDurationInDays: DurationInDays = 2;

type DurationOptionType = { label: string; value: DurationInDays };

const Title = styled(Text16).attrs({ bold: true })`
  text-align: center;
  color: var(--c-nd-600);
`;
const DetailsWrapper = styled(Vertical).attrs({ gap: 2 })`
  border-radius: var(--double-unit);
  background: var(--c-nd-100);
  margin: var(--double-unit) 0;
  padding: var(--double-unit);
`;
const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
`;
const Body = styled.div`
  padding: var(--triple-unit);
`;
const SubmitButtonWrapper = styled.div`
  padding-top: var(--double-unit);
`;

const now = new Date();

const fragment = gql`
  fragment OfferDialog_anyCard on AnyCardInterface {
    slug
    sport
    secondaryMarketFeeEnabled
    priceRange {
      min
      max
    }
    ...TokenTransferValidator_anyCard
    ...ConfirmationDialogContent_anyCard
    ...TokenSummary_anyCard
  }
  ${TokenSummary.fragments.anyCard}
  ${TokenTransferValidator.fragments.anyCard}
  ${ConfirmationDialogContent.fragments.anyCard}
` as TypedDocumentNode<OfferDialog_anyCard>;

const OFFER_DIALOG_QUERY = gql`
  query OfferDialogQuery($assetId: String!) {
    anyCard(assetId: $assetId) {
      slug
      ...OfferDialog_anyCard
    }
  }
  ${fragment}
` as TypedDocumentNode<OfferDialogQuery, OfferDialogQueryVariables>;

const OfferDialog = ({
  onClose,
  open,
  submit,
  title,
  onSuccess,
  cta,
  initialAmount,
  assetId,
  confirmationMessage,
}: Props) => {
  const { trackEnter } = useCashWalletKycEvents();
  const { data, loading } = useQuery(OFFER_DIALOG_QUERY, {
    variables: { assetId },
    skip: !open,
  });

  const {
    acceptedCurrencies,
    updateAcceptedCurrencies,
    acceptedCurrenciesHaveBeenSet,
  } = useCurrentUserAcceptedCurrencies();
  const { canListAndTrade } = useFiatBalance();
  const [promptCreateFiatWallet, setPromptCreateFiatWallet] = useState(false);
  const [acceptCurrenciesRadioInput, setAcceptCurrenciesRadioInput] =
    useState<AcceptedCurrenciesValue | null>(null);
  const [pegToEth, setPegToEth] = useState<boolean>(false);

  const [
    promptActivateYourFiatWalletInterstitial,
    setPromptActivateYourFiatWalletInterstitial,
  ] = useState(false);

  const [promptConfirm, setPromptConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { up: isTablet } = useScreenSize('tablet');
  const { formatMessage } = useIntlContext();
  const { update: updateDuration, lifecycle } = useLifecycle();

  const {
    walletPreferences: { onlyShowFiatCurrency, hasMigratedAndSetupWallets },
  } = useCurrentUserContext();
  const lastSaleDuration = lifecycle?.lastSaleDuration;

  const { nextTimeUserShouldSeeActivateFiatWalletOnListing } =
    useNextTimeUserShouldSeeActivateFiatWalletOnListing();
  const {
    nextTimeUserShouldSeeAcceptedCurrenciesOnListing,
    update: updateNextTimeUserShouldSeeAcceptedCurrenciesOnListing,
  } = useNextTimeUserShouldSeeAcceptedCurrenciesOnListing();

  const { toMonetaryAmount } = useMonetaryAmount();

  const track = useMarketplaceEvents();

  const {
    referenceCurrencyForSale: referenceCurrency,
    update: updateReferenceCurrencyForSale,
  } = useReferenceCurrencyForSale();

  const [monetaryAmount, setMonetaryAmount] = useState<MonetaryAmountOutput>(
    initialAmount ? toMonetaryAmount(initialAmount) : zeroMonetaryAmount
  );

  const [durationInDays, setDurationInDays] = useState<number>(
    lastSaleDuration || defaultDurationInDays
  );

  const { marketFeePercentagePoints, minimumReceiveWeiAmount } =
    useConfigContext();

  const { fiatCurrency, currency } = useCurrentUserContext();

  const onDurationChange = useCallback(
    (newDuration?: DurationOptionType | null) => {
      if (!newDuration) return;
      setDurationInDays(newDuration.value);
      updateDuration(LIFECYCLE.lastSaleDuration, newDuration.value);
    },
    [setDurationInDays, updateDuration]
  );

  const durationOptions = useMemo(
    () =>
      allowedDurationsInDays.map(element => ({
        label: formatMessage(messages.nbDays, { nbDays: element }),
        value: element,
      })),
    [formatMessage]
  );
  const selectedDuration = durationOptions.find(
    ({ value }) => value === durationInDays
  );

  const ethAmount = useMemo(
    () =>
      fromWei(monetaryAmount.wei, ETH_DECIMAL_PLACES, RoundingMode.ROUND_DOWN),
    [monetaryAmount.wei]
  );

  const showWarningMessage = (card: OfferDialog_anyCard) =>
    (card.priceRange?.min || 0n) > monetaryAmount.wei;

  const askForConfirmation = (card: OfferDialog_anyCard) => {
    setPromptConfirm(true);
    if (showWarningMessage(card)) {
      track('[Client] Warning Listing too low', {
        ethAmount,
      });
    }
  };

  const trackEnterCreatFiatWalletFlow = () => {
    trackEnter({
      source: EnterCashWalletKYCFlow_Source.LIST_A_CARD,
      target: EnterCashWalletKYCFlow_Target.OWNER,
    });
  };

  const gateAskForConfirmationWithCreateFiatWallet = async (
    card: OfferDialog_anyCard,
    onCancel?: () => void
  ) => {
    if (onlyShowFiatCurrency && !canListAndTrade) {
      onCancel?.();
      setPromptCreateFiatWallet(true);
      trackEnterCreatFiatWalletFlow();
    } else if (
      acceptCurrenciesRadioInput === AcceptedCurrenciesValue.BOTH &&
      !canListAndTrade
    ) {
      onCancel?.();
      setPromptCreateFiatWallet(true);
      trackEnterCreatFiatWalletFlow();
    } else if (
      (!canListAndTrade || !hasMigratedAndSetupWallets) &&
      (!nextTimeUserShouldSeeActivateFiatWalletOnListing ||
        isBefore(
          new Date(nextTimeUserShouldSeeActivateFiatWalletOnListing),
          now
        ))
    ) {
      setPromptActivateYourFiatWalletInterstitial(true);
      onCancel?.();
    } else {
      if (
        acceptCurrenciesRadioInput === AcceptedCurrenciesValue.BOTH &&
        canListAndTrade
      ) {
        updateAcceptedCurrencies(AcceptedCurrenciesValue.BOTH);
        updateReferenceCurrencyForSale(
          pegToEth
            ? SupportedCurrency.WEI
            : (fiatCurrency.code as SupportedCurrency)
        );
      }
      askForConfirmation(card);
    }
  };

  const submitListing = async (card: OfferDialog_anyCard) => {
    setSubmitting(true);
    const amountInput = canListAndTrade
      ? {
          amount:
            monetaryAmount[
              getMonetaryAmountIndex(referenceCurrency)
            ].toString(),
          currency: referenceCurrency,
        }
      : {
          amount: monetaryAmount.wei.toString(),
          currency: SupportedCurrency.WEI,
        };
    await submit({
      amountInput,
      duration: durationInDays * 24 * 60 * 60,
    });
    if (showWarningMessage(card)) {
      track('[Client] Listed after too low warning', {
        ethAmount,
      });
    }
    if (
      !acceptedCurrenciesHaveBeenSet &&
      acceptCurrenciesRadioInput === AcceptedCurrenciesValue.ETH
    ) {
      updateAcceptedCurrencies(AcceptedCurrenciesValue.ETH);
    }
    setSubmitting(false);
  };
  const onCloseCreateFiatWallet = () => {
    setAcceptCurrenciesRadioInput(null);
    setPromptCreateFiatWallet(false);
  };

  const showAcceptedCurrenciesRadioInput = useMemo(() => {
    if (onlyShowFiatCurrency) return false;
    if (
      acceptedCurrencies === AcceptedCurrenciesValue.ETH &&
      (!nextTimeUserShouldSeeAcceptedCurrenciesOnListing ||
        isBefore(
          new Date(nextTimeUserShouldSeeAcceptedCurrenciesOnListing),
          now
        ))
    ) {
      return true;
    }
    return !canListAndTrade && !acceptedCurrenciesHaveBeenSet;
  }, [
    acceptedCurrencies,
    acceptedCurrenciesHaveBeenSet,
    canListAndTrade,
    nextTimeUserShouldSeeAcceptedCurrenciesOnListing,
    onlyShowFiatCurrency,
  ]);

  const disabledWhenAcceptedCurrenciesNotSet =
    showAcceptedCurrenciesRadioInput && !acceptCurrenciesRadioInput;

  // This is not idiomatic but not problematic (see here https://unsplash.com/blog/calling-react-hooks-conditionally-dynamically-using-render-props/)
  // Though we might want to refactor this code because it's really hard to reason about.
  // TODO [MAR-269] https://linear.app/sorare/issue/MAR-269/find-a-cleaner-way-to-render-dialogcontent
  const dialogContent = ({ card }: { card: OfferDialog_anyCard }) =>
    // eslint-disable-next-line react/no-unstable-nested-components
    function DialogContent({
      validationMessages,
      loading: validationLoading,
      ConsentMessage,
    }: TokenTransferChildrenProps) {
      const secondaryMarketFeesPercentagePoints = card.secondaryMarketFeeEnabled
        ? marketFeePercentagePoints
        : 0n;
      const { feesMonetaryAmount, youReceiveMonetaryAmount } = calculateFees({
        monetaryAmount,
        feesPercentagePoints: secondaryMarketFeesPercentagePoints,
      });

      const validationMessagesList = Object.values(validationMessages);

      const { sport } = card;

      const updateNextTimeUserShouldSeeAcceptedCurrencies = () => {
        if (
          !nextTimeUserShouldSeeAcceptedCurrenciesOnListing ||
          isBefore(
            new Date(nextTimeUserShouldSeeAcceptedCurrenciesOnListing),
            now
          )
        ) {
          updateNextTimeUserShouldSeeAcceptedCurrenciesOnListing(
            addDays(new Date(), 7).toISOString()
          );
        }
      };
      if (promptConfirm) {
        return (
          <ConfirmationDialogContent
            card={card}
            showWarningMessage={showWarningMessage(card)}
            monetaryAmount={monetaryAmount}
            referenceCurrency={referenceCurrency}
            onClose={() => {
              setPromptConfirm(false);
              updateNextTimeUserShouldSeeAcceptedCurrencies();
            }}
            submitting={submitting}
            submit={() => {
              submitListing(card);
              updateNextTimeUserShouldSeeAcceptedCurrencies();
              track('Click Submit List Card', {
                cardSlug: card.slug,
                hasWarnings: Object.keys(validationMessages).length > 0,
              });
            }}
            warningMessage={messages.warningMessage}
            secondaryMarketFeesPercentagePoints={
              secondaryMarketFeesPercentagePoints
            }
            validationMessages={
              validationMessagesList.length > 0 && validationMessagesList
            }
            ConsentMessage={ConsentMessage}
          />
        );
      }

      if (promptCreateFiatWallet) {
        return (
          <CreateFiatWallet
            statusTarget={FiatWalletAccountState.OWNER}
            canDismissAfterActivation
            activationSuccessCta={
              <FormattedMessage
                id="OfferDialog.createFiatWallet.activationSuccess.cta"
                defaultMessage="Finish listing my card"
              />
            }
            unsupportedCountryCta={
              <FormattedMessage
                id="OfferDialog.createFiatWallet.unsupportedCountryCta"
                defaultMessage="Agree and return to listing"
              />
            }
            onDeclarativeFormSuccess={() => {
              updateAcceptedCurrencies(AcceptedCurrenciesValue.BOTH);
            }}
            onDismissActivationSuccess={() => {
              onCloseCreateFiatWallet();
            }}
            onClose={onCloseCreateFiatWallet}
          />
        );
      }

      return (
        <Vertical>
          <StyledGraphqlForm
            onSubmit={(_, __, onCancel) => {
              gateAskForConfirmationWithCreateFiatWallet(card, onCancel);
            }}
            onSuccess={onSuccess || onClose}
            render={(
              Error: React.ComponentType<React.PropsWithChildren<unknown>>,
              SubmitButton: FunctionComponent<
                React.PropsWithChildren<SubmitButtonProps>
              >
            ) => (
              <>
                <Title>
                  <FormattedMessage
                    id="OfferDialog.title"
                    defaultMessage="Your price"
                  />
                </Title>
                <Error />

                <MonetaryInputField
                  name="price"
                  defaultValue={monetaryAmount}
                  defaultCurrency={currency}
                  minWeiAmount={Number(minimumReceiveWeiAmount)}
                  onChange={amount => {
                    setMonetaryAmount(amount);
                  }}
                />

                <DetailsWrapper>
                  <TokenSummary card={card} context="Offer Dialog" />
                  {validationMessagesList.length > 0 && validationMessagesList}
                </DetailsWrapper>
                <div>
                  <Row
                    title={
                      <FeesLabel
                        percentagePoints={secondaryMarketFeesPercentagePoints}
                        sport={sport}
                      />
                    }
                  >
                    <PaymentBoxAmountWithConversion
                      monetaryAmount={{
                        referenceCurrency,
                        ...feesMonetaryAmount,
                      }}
                    />
                  </Row>
                  <Row
                    title={
                      <Title6>{formatMessage(tradeLabels.youReceive)}</Title6>
                    }
                  >
                    <PaymentBoxAmountWithConversion
                      monetaryAmount={{
                        referenceCurrency,
                        ...youReceiveMonetaryAmount,
                      }}
                    />
                  </Row>
                  <Row
                    title={
                      <Title6 bold as="strong">
                        {formatMessage(messages.listingDuration)}
                      </Title6>
                    }
                  >
                    <Select
                      options={durationOptions}
                      value={selectedDuration}
                      onChange={onDurationChange}
                      menuPlacement="top"
                      menuPosition="fixed"
                      menuLateralAlignment="right"
                      icon={faChevronDown}
                    />
                  </Row>
                </div>
                {showAcceptedCurrenciesRadioInput && (
                  <AcceptedCurrenciesOnListing
                    onChange={(
                      value: AcceptedCurrenciesValue,
                      valuePegToEth?: boolean
                    ) => {
                      setAcceptCurrenciesRadioInput(value);
                      setPegToEth(valuePegToEth || false);
                    }}
                  />
                )}
                {!showAcceptedCurrenciesRadioInput && !canListAndTrade && (
                  <ActivateYourFiatWalletOnListing
                    onClick={() => {
                      setPromptCreateFiatWallet(true);
                      trackEnterCreatFiatWalletFlow();
                    }}
                  />
                )}
                <SubmitButtonWrapper>
                  <SubmitButton
                    size="medium"
                    disabled={
                      validationLoading ||
                      minimumReceiveWeiAmount > monetaryAmount.wei ||
                      disabledWhenAcceptedCurrenciesNotSet
                    }
                    fullWidth
                  >
                    <FormattedMessage
                      {...(acceptCurrenciesRadioInput ===
                        AcceptedCurrenciesValue.BOTH && !canListAndTrade
                        ? fiatWallet.activateCashWallet
                        : cta)}
                    />
                  </SubmitButton>
                </SubmitButtonWrapper>
              </>
            )}
          />
        </Vertical>
      );
    };

  const onBack = () => {
    if (promptCreateFiatWallet) setPromptCreateFiatWallet(false);
    if (promptConfirm) setPromptConfirm(false);
  };

  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        onBack={promptConfirm || promptCreateFiatWallet ? onBack : undefined}
        open={open}
        onClose={promptConfirm || promptCreateFiatWallet ? undefined : onClose}
        fullScreen={!isTablet}
        title={
          promptConfirm ? (
            <Title6 className="text-center">
              <FormattedMessage {...confirmationMessage} />
            </Title6>
          ) : (
            <Title6 className="text-center">
              <FormattedMessage {...title} />
            </Title6>
          )
        }
      >
        <Body>
          {data?.anyCard && !loading ? (
            <TokenTransferValidator
              cards={[data.anyCard]}
              transferContext="list"
            >
              {dialogContent({ card: data.anyCard })}
            </TokenTransferValidator>
          ) : (
            <LoadingIndicator />
          )}
        </Body>
      </Dialog>
      {promptActivateYourFiatWalletInterstitial && (
        <ActivateYourFiatWalletInterstitial
          onActivate={() => {
            setPromptActivateYourFiatWalletInterstitial(false);
            setPromptCreateFiatWallet(true);
            trackEnterCreatFiatWalletFlow();
          }}
          onDismiss={() => {
            setPromptActivateYourFiatWalletInterstitial(false);
            if (data?.anyCard)
              gateAskForConfirmationWithCreateFiatWallet(data?.anyCard);
          }}
        />
      )}
    </>
  );
};

export default OfferDialog;
