import { TypedDocumentNode, gql } from '@apollo/client';

import ItemSold from 'components/ItemPreview/ItemSold';
import { TokenDetailsRoot } from 'components/ItemPreview/ui';
import { AuctionDetails } from 'components/auction/Auction/AuctionDetails';
import {
  PrimaryBuyDetails,
  Props as PrimaryBuyDetailsProps,
} from 'components/primaryBuy/PrimaryBuyDetails';
import useGetAuctionDetails from 'hooks/offers/useGetAuctionDetails';
import useGetTokenSingleSaleDetails from 'hooks/offers/useGetTokenSingleSaleDetails';
import { useIsPrimaryBuyLive } from 'hooks/primaryBuy/useIsPrimaryBuyLive';

import { TokenOfferDetails } from './TokenOfferDetails';
import { TokenDetails_anyCard } from './__generated__/index.graphql';

type Props = {
  card: TokenDetails_anyCard;
  stackedTokensCount?: number;
  isDesktopLayout: boolean;
  hideSorareUser?: boolean;
  hideOwner?: boolean;
  galleryOwnerSlug?: string;
  disableSportSpecific?: boolean;
  displayMarketplaceOnboardingTooltip?: boolean;
  primaryBuyConfirmationOptions?: PrimaryBuyDetailsProps['primaryBuyConfirmationOptions'];
  onPrimaryBuyConfirmationClose?: () => void;
};

export const TokenDetails = ({
  card,
  stackedTokensCount,
  isDesktopLayout,
  hideSorareUser,
  hideOwner,
  galleryOwnerSlug,
  disableSportSpecific,
  displayMarketplaceOnboardingTooltip,
  primaryBuyConfirmationOptions,
  onPrimaryBuyConfirmationClose,
}: Props) => {
  const auction = useGetAuctionDetails(card.latestEnglishAuction);
  const offer = useGetTokenSingleSaleDetails(card);
  const primaryBuy = useIsPrimaryBuyLive(card);

  if (primaryBuy && !auction && !offer) {
    return (
      <TokenDetailsRoot>
        <PrimaryBuyDetails
          card={card}
          isDesktopLayout={isDesktopLayout}
          hideSorareUser={hideSorareUser}
          primaryBuyConfirmationOptions={primaryBuyConfirmationOptions}
          onPrimaryBuyConfirmationClose={onPrimaryBuyConfirmationClose}
        />
      </TokenDetailsRoot>
    );
  }

  if (offer) {
    return (
      <TokenOfferDetails
        card={card}
        stackedTokensCount={stackedTokensCount}
        isDesktopLayout={isDesktopLayout}
        displayMarketplaceOnboardingTooltip={
          displayMarketplaceOnboardingTooltip
        }
      />
    );
  }

  if (auction && card.latestEnglishAuction) {
    return (
      <AuctionDetails
        auction={card.latestEnglishAuction}
        hideSorareUser={hideSorareUser}
        isDesktopLayout={isDesktopLayout}
        useConversionRate
      />
    );
  }

  return (
    <ItemSold
      card={card}
      hideOwner={hideOwner}
      galleryOwnerSlug={galleryOwnerSlug}
      disableSportSpecific={disableSportSpecific}
    />
  );
};

TokenDetails.fragments = {
  anyCard: gql`
    fragment TokenDetails_anyCard on AnyCardInterface {
      slug
      latestEnglishAuction {
        id
        ...useGetAuctionDetails_auction
        ...AuctionDetails_auction
      }
      latestPrimaryOffer {
        id
      }
      ...useGetTokenSingleSaleDetails_anyCard
      ...useIsPrimaryBuyLive_anyCard
      ...ItemSold_anyCard
      ...PrimaryBuyDetails_anyCard
      ...TokenOfferDetails_anyCard
    }
    ${useGetAuctionDetails.fragments.auction}
    ${useGetTokenSingleSaleDetails.fragments.anyCard}
    ${useIsPrimaryBuyLive.fragments.anyCard}
    ${AuctionDetails.fragments.auction}
    ${ItemSold.fragments.anyCard}
    ${PrimaryBuyDetails.fragments.anyCard}
    ${TokenOfferDetails.fragments.anyCard}
  ` as TypedDocumentNode<TokenDetails_anyCard>,
};
