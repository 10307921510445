import { useIntl } from 'react-intl';
import styled from 'styled-components';

const BaseScoreStyle = styled.div`
  display: inline-flex;
  align-items: center;

  font: var(--t-label-xs);
  line-height: 2em;
  padding: 1px;
`;
const PositiveScore = styled(BaseScoreStyle)`
  color: var(--c-green-800);
  position: relative;
  top: -10px;
`;
const NegativeScore = styled(BaseScoreStyle)`
  color: var(--c-red-800);
  position: relative;
  bottom: -2px;
`;

const NeutralScore = styled(BaseScoreStyle)`
  color: var(--c-nd-600);
`;

export const ColoredPointsDelta = ({ score }: { score: number | string }) => {
  const { formatNumber } = useIntl();

  const formattedScore =
    typeof score === 'number'
      ? formatNumber(score, { maximumFractionDigits: 0 })
      : score;

  if (Math.floor(+score) > 0) {
    return <PositiveScore>+{formattedScore}</PositiveScore>;
  }
  if (Math.floor(+score) < 0) {
    return <NegativeScore>{formattedScore}</NegativeScore>;
  }
  return <NeutralScore>+{formattedScore}</NeutralScore>;
};
