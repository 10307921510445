import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Caption, LabelM } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { useTokenOfferBelongsToUser } from '@sorare/core/src/hooks/useTokenOfferBelongsToUser';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { TokenDetailsRoot } from 'components/ItemPreview/ui';
import NewSaleDialog from 'components/offer/NewSaleDialog';

import { SalePrice } from '../SalePrice';
import { SaleWinner } from '../SaleWinner';
import {
  EndedSaleDetails_anyCard,
  EndedSaleDetails_offer,
} from './__generated__/index.graphql';

type Props = {
  sale: EndedSaleDetails_offer;
  card: EndedSaleDetails_anyCard;
  allowColumnLayout?: boolean;
};

export const EndedSaleDetails = ({ sale, card, allowColumnLayout }: Props) => {
  const [open, setOpen] = useState(false);
  const { currentUser } = useCurrentUserContext();
  const belongsToUser = useTokenOfferBelongsToUser();
  const track = useEvents();
  const { isIosApp } = useIsMobileApp();

  const ownsToken = currentUser?.slug === card.user?.slug;
  const offerExpired = sale.status === 'ended';
  const relistedToken =
    card.myMintedSingleSaleOffer && belongsToUser(card.myMintedSingleSaleOffer);

  const onClickListAgain = () => {
    setOpen(true);
    track('Click List Again', { cardSlug: card.slug });
  };

  return (
    <TokenDetailsRoot className={classNames({ allowColumnLayout })}>
      <SalePrice sale={sale} showFees={!!sale.acceptedAt} />
      {offerExpired ? (
        <Caption color="var(--c-nd-600)">
          <FormattedMessage
            id="MySorare.PastSaleOfferItem.Expired"
            defaultMessage="Expired"
          />
        </Caption>
      ) : (
        <SaleWinner sale={sale} />
      )}
      {ownsToken && offerExpired && (
        <div>
          {relistedToken ? (
            <LabelM color="var(--c-nd-600)">
              <FormattedMessage
                id="MySorare.PastSaleOfferItem.Relisted"
                defaultMessage="Currently re-listed"
              />
            </LabelM>
          ) : (
            <>
              <Button
                disabled={!!isIosApp}
                size="small"
                color="primary"
                onClick={onClickListAgain}
              >
                <FormattedMessage
                  id="MySorare.PastSaleOfferItem.ListAgain"
                  defaultMessage="List Again"
                />
              </Button>
              {open && (
                <NewSaleDialog
                  open
                  onClose={() => setOpen(false)}
                  card={card}
                  initialAmount={sale.receiverSide.amounts}
                />
              )}
            </>
          )}
        </div>
      )}
    </TokenDetailsRoot>
  );
};

EndedSaleDetails.fragments = {
  anyCard: gql`
    fragment EndedSaleDetails_anyCard on AnyCardInterface {
      slug
      user {
        slug
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        ...useTokenOfferBelongsToUser_offer
      }
      ...NewSaleDialog_anyCard
    }
    ${useTokenOfferBelongsToUser.fragments.offer}
    ${NewSaleDialog.fragments.anyCard}
  ` as TypedDocumentNode<EndedSaleDetails_anyCard>,
  offer: gql`
    fragment EndedSaleDetails_offer on TokenOffer {
      id
      status
      acceptedAt
      receiverSide {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      ...SaleWinner_offer
      ...SalePrice_offer
      ...useTokenOfferBelongsToUser_offer
    }
    ${monetaryAmountFragment}
    ${useTokenOfferBelongsToUser.fragments.offer}
    ${SaleWinner.fragments.offer}
    ${SalePrice.fragments.offer}
  ` as TypedDocumentNode<EndedSaleDetails_offer>,
};
