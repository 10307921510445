import { TypedDocumentNode, gql } from '@apollo/client';

import { SportOrShared } from '__generated__/globalTypes';

import {
  NotificationPreferencesQuery,
  NotificationPreferencesQueryVariables,
  useNotificationPreferences_notificationPreference,
  useNotificationPreferences_userSettings,
} from './__generated__/useNotificationPreferences.graphql';
import { useQuery } from './graphql/useQuery';

const notificationPreferencesFragment = gql`
  fragment useNotificationPreferences_notificationPreference on NotificationPreference {
    name
    value
    defaultValue
    values
    labels
  }
` as TypedDocumentNode<useNotificationPreferences_notificationPreference>;

export const USE_NOTIFICATION_PREFERENCES_USER_SETTINGS = gql`
  fragment useNotificationPreferences_userSettings on UserSettings {
    id
    footballNotificationPreferences: notificationPreferences(sport: FOOTBALL) {
      ...useNotificationPreferences_notificationPreference
    }
    nbaNotificationPreferences: notificationPreferences(sport: NBA) {
      ...useNotificationPreferences_notificationPreference
    }
    baseballNotificationPreferences: notificationPreferences(sport: BASEBALL) {
      ...useNotificationPreferences_notificationPreference
    }
    globalNotificationPreferences: notificationPreferences(sport: GLOBAL) {
      ...useNotificationPreferences_notificationPreference
    }
  }
  ${notificationPreferencesFragment}
` as TypedDocumentNode<useNotificationPreferences_userSettings>;

const NOTIFICATION_PREFERENCES_QUERY = gql`
  query NotificationPreferencesQuery {
    currentUser {
      slug
      userSettings {
        id
        ...useNotificationPreferences_userSettings
      }
    }
  }
  ${USE_NOTIFICATION_PREFERENCES_USER_SETTINGS}
` as TypedDocumentNode<
  NotificationPreferencesQuery,
  NotificationPreferencesQueryVariables
>;

export type NotificationPreferences =
  useNotificationPreferences_notificationPreference;

export const useNotificationPreferences = (
  sport: SportOrShared
): NotificationPreferences[] => {
  const { data, loading } = useQuery(NOTIFICATION_PREFERENCES_QUERY);

  if (loading || !data?.currentUser) return [];

  const { userSettings } = data.currentUser;

  switch (sport) {
    case SportOrShared.FOOTBALL:
      return userSettings.footballNotificationPreferences;
    case SportOrShared.NBA:
      return userSettings.nbaNotificationPreferences;
    case SportOrShared.BASEBALL:
      return userSettings.baseballNotificationPreferences;

    case SportOrShared.GLOBAL:
      return userSettings.globalNotificationPreferences;
    default:
      return [];
  }
};
