import { Color } from 'style/types';

type Props = {
  fill?: Color;
  title?: string;
} & React.JSX.IntrinsicElements['svg'];
export const Neutral = ({ fill = 'var(--c-white)', title, ...rest }: Props) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...rest}
    >
      <title>{title}</title>
      <path d="m4 8v4h14v-4z" fill={fill} />
    </svg>
  );
};
