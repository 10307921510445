import {
  DocumentNode,
  DocumentType,
  FetchResult,
  Observable,
  OperationVariables,
  SubscriptionHookOptions,
  SubscriptionResult,
  TypedDocumentNode,
  useApolloClient,
} from '@apollo/client';
import { verifyDocumentType } from '@apollo/client/react/parser';
// eslint-disable-next-line import/no-extraneous-dependencies
import { equal } from '@wry/equality';
import { useEffect, useRef, useState } from 'react';

// Inlined from https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useSubscription.ts
// With the following change
//
// Subscribe in an effect instead of in useState initializer, as the initializer
// can run multiple times when react is in concurrent mode

export type NoInfer<T> = [T][T extends any ? 0 : never];

export function useSubscription<
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  subscription: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: SubscriptionHookOptions<NoInfer<TData>, NoInfer<TVariables>>
) {
  const hasIssuedDeprecationWarningRef = useRef(false);
  const client = useApolloClient(options?.client);
  verifyDocumentType(subscription, DocumentType.Subscription);
  const [result, setResult] = useState<SubscriptionResult<TData, TVariables>>({
    loading: !options?.skip,
    error: undefined,
    data: undefined,
    variables: options?.variables,
  });

  if (!hasIssuedDeprecationWarningRef.current) {
    hasIssuedDeprecationWarningRef.current = true;
  }

  const [observable, setObservable] = useState<null | Observable<
    FetchResult<TData>
  >>(null);

  const canResetObservableRef = useRef(true);
  useEffect(() => {
    return () => {
      canResetObservableRef.current = true;
    };
  }, []);

  const ref = useRef({ client, subscription, options });
  useEffect(() => {
    let shouldResubscribe = options?.shouldResubscribe;
    if (typeof shouldResubscribe === 'function') {
      shouldResubscribe = !!shouldResubscribe(options!);
    }

    if (options?.skip) {
      if (
        !options?.skip !== !ref.current.options?.skip ||
        canResetObservableRef.current
      ) {
        setResult({
          loading: false,
          data: undefined,
          error: undefined,
          variables: options?.variables,
        });
        setObservable(null);
        canResetObservableRef.current = false;
      }
    } else if (
      (shouldResubscribe !== false &&
        (client !== ref.current.client ||
          subscription !== ref.current.subscription ||
          options?.fetchPolicy !== ref.current.options?.fetchPolicy ||
          !options?.skip !== !ref.current.options?.skip ||
          !equal(options?.variables, ref.current.options?.variables))) ||
      canResetObservableRef.current
    ) {
      setResult({
        loading: true,
        data: undefined,
        error: undefined,
        variables: options?.variables,
      });
      setObservable(
        client.subscribe({
          query: subscription,
          variables: options?.variables,
          fetchPolicy: options?.fetchPolicy,
          context: options?.context,
        })
      );
      canResetObservableRef.current = false;
    }

    Object.assign(ref.current, { client, subscription, options });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, subscription, options, canResetObservableRef.current]);

  useEffect(() => {
    if (!observable) {
      return;
    }

    let subscriptionStopped = false;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const subscription = observable.subscribe({
      next(fetchResult) {
        if (subscriptionStopped) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const result = {
          loading: false,
          // TODO: fetchResult.data can be null but SubscriptionResult.data
          // expects TData | undefined only
          data: fetchResult.data!,
          error: undefined,
          variables: options?.variables,
        };
        setResult(result);

        if (ref.current.options?.onData) {
          ref.current.options.onData({
            client,
            data: result,
          });
        }
      },
      error(error) {
        if (!subscriptionStopped) {
          setResult({
            loading: false,
            data: undefined,
            error,
            variables: options?.variables,
          });
          ref.current.options?.onError?.(error);
        }
      },
      complete() {
        if (!subscriptionStopped) {
          if (ref.current.options?.onComplete) {
            ref.current.options.onComplete();
          }
        }
      },
    });

    // eslint-disable-next-line consistent-return
    return () => {
      // immediately stop receiving subscription values, but do not unsubscribe
      // until after a short delay in case another useSubscription hook is
      // reusing the same underlying observable and is about to subscribe
      subscriptionStopped = true;
      setTimeout(() => {
        subscription.unsubscribe();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable]);

  return result;
}
