import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Currency, SupportedCurrency } from '__generated__/globalTypes';
import { Box } from 'atoms/icons/Box';
import { Horizontal } from 'atoms/layout/flex';
import { Progress } from 'atoms/loader/Progress';
import { BodyS } from 'atoms/typography';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { useMonetaryAmount, zeroMonetaryAmount } from 'hooks/useMonetaryAmount';

const Root = styled.div`
  width: 100%;
  position: relative;
  --progress-height: 20px;
  --progress-color: linear-gradient(323deg, #366bf2 14.3%, #6b49db 85.7%);
`;

const Amount = styled(BodyS).attrs({ bold: true })`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
`;

const Target = styled(Horizontal).attrs({ gap: 0 })`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  justify-content: center;
  background-color: var(--c-white);
  width: var(--triple-unit);
  height: var(--triple-unit);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.17);
  color: var(--c-brand-600);
`;

const SpentProgress = () => {
  const { currentUser } = useCurrentUserContext();
  const { toMonetaryAmount } = useMonetaryAmount();
  const actualAmounts =
    currentUser?.activePromotionalEventCompletion?.actualAmounts;
  const totalAmounts =
    currentUser?.activePromotionalEventCompletion?.targetAmount;

  const primaryCurrency =
    actualAmounts?.referenceCurrency === SupportedCurrency.WEI
      ? Currency.ETH
      : Currency.FIAT;

  const { main: spent } = useAmountWithConversion({
    monetaryAmount: actualAmounts || zeroMonetaryAmount,
    primaryCurrency,
  });

  const { main: total } = useAmountWithConversion({
    monetaryAmount: totalAmounts || zeroMonetaryAmount,
    primaryCurrency,
  });

  return (
    <Root>
      <Target>
        <Box />
      </Target>
      <Amount>
        <FormattedMessage
          id="AddFundsToFiatWallet.Promo.SpentProgress"
          defaultMessage="{spent}/{total} spent"
          values={{
            spent,
            total,
          }}
        />
      </Amount>
      <Progress
        style={
          {
            '--height': '20px',
          } as React.CSSProperties
        }
        value={
          actualAmounts
            ? Number(
                toMonetaryAmount(actualAmounts)[
                  actualAmounts.referenceCurrency.toLowerCase() as
                    | 'eur'
                    | 'usd'
                    | 'gbp'
                ]
              )
            : 0
        }
        max={
          totalAmounts
            ? Number(
                toMonetaryAmount(totalAmounts)[
                  totalAmounts.referenceCurrency.toLowerCase() as
                    | 'eur'
                    | 'usd'
                    | 'gbp'
                ]
              )
            : 0
        }
      />
    </Root>
  );
};

export default SpentProgress;
