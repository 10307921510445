import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  RankingRatioRange,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import defaultShield from '@sorare/core/src/assets/club/shield_none.png';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { BodyM, LabelL, LabelM } from '@sorare/core/src/atoms/typography';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { isType } from '@sorare/core/src/gql';
import { glossary } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { Color } from '@sorare/core/src/style/types';

import { isGameStarted } from 'lib/so5';

import { GameStatusLabel } from '../GameStatusLabel';
import {
  BriefGame_anyGameInterface,
  BriefGame_anyPlayerInterface,
  BriefGame_team,
} from './__generated__/index.graphql';

const Root = styled(ButtonBase)`
  width: 100%;
  text-align: left;
  cursor: default;
  background: var(--c-nd-50);
  border-radius: var(--double-unit);
  padding: var(--double-unit);

  @media ${tabletAndAbove} {
    &.withHoverStyles {
      padding: var(--double-unit);
      cursor: pointer;
      border-radius: var(--double-unit);

      &:hover {
        background-color: var(--c-nd-50);
      }
    }
  }
`;
const StyledImg = styled.img`
  object-fit: contain;
`;
const StyledHorizontal = styled(Horizontal)`
  align-items: baseline;
`;

const getTeamTextColor = (
  game: BriefGame_anyGameInterface,
  teamSlug?: string
): Color => {
  return !game.winner || game.winner?.slug === teamSlug
    ? 'var(--c-white)'
    : 'var(--c-nd-400)';
};

const getTeamRankingColor = (
  team: BriefGame_team,
  player?: BriefGame_anyPlayerInterface
): Color => {
  let color: Color = 'var(--c-nd-600)';

  if (
    !isType(team, 'Club') ||
    !player ||
    player.activeClub?.slug === team?.slug
  ) {
    return color;
  }

  const contestantRangeRatio = team.domesticLeagueRankingRatioRange;
  if (contestantRangeRatio === RankingRatioRange.TOP) {
    color = 'var(--c-red-600)';
  } else if (contestantRangeRatio === RankingRatioRange.MEDIUM) {
    color = 'var(--c-score-mediumLow)';
  } else if (contestantRangeRatio === RankingRatioRange.LOW) {
    color = 'var(--c-score-high)';
  }

  return color;
};

const isGameFromDomesticCompetition = (
  game: BriefGame_anyGameInterface
): boolean => game.competition.format === 'DOMESTIC_LEAGUE';

type DomesticLeagueRankingProps = {
  game: BriefGame_anyGameInterface;
  team: BriefGame_team;
  player?: BriefGame_anyPlayerInterface;
};
export const DomesticLeagueRanking = ({
  game,
  team,
  player,
}: DomesticLeagueRankingProps) => {
  if (!isType(team, 'Club') || !isGameFromDomesticCompetition(game)) {
    return null;
  }

  return (
    <LabelM color={getTeamRankingColor(team, player)}>
      <FormattedMessage
        {...glossary.ordinal}
        values={{ ordinal: team.domesticLeagueRanking }}
      />
    </LabelM>
  );
};

type Props = {
  game: BriefGame_anyGameInterface;
  player?: BriefGame_anyPlayerInterface;
  onClick?: () => void;
};
export const BriefGame = ({ game, player, onClick }: Props) => {
  const { sport } = useSportContext();
  const hasGameStarted = isGameStarted(game.status);
  const isUsSports = sport !== Sport.FOOTBALL;

  const homeTeam = isUsSports ? game.awayTeam : game.homeTeam;
  const homeScore = isUsSports ? game.awayScore : game.homeScore;
  const awayTeam = isUsSports ? game.homeTeam : game.awayTeam;
  const awayScore = isUsSports ? game.homeScore : game.awayScore;

  return (
    <Root
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      className={classNames({ withHoverStyles: !!onClick })}
    >
      <Vertical gap={1.5}>
        <LabelM bold color="var(--c-nd-600)">
          <GameStatusLabel game={game} />
        </LabelM>
        <Vertical gap={1}>
          <SBHorizontal>
            <Horizontal gap={1}>
              <StyledImg
                src={homeTeam?.pictureUrl || defaultShield}
                alt=""
                width="16px"
                height="16px"
              />
              <StyledHorizontal>
                <LabelL bold color={getTeamTextColor(game, homeTeam?.slug)}>
                  {homeTeam?.name}
                </LabelL>
                {homeTeam && (
                  <DomesticLeagueRanking
                    game={game}
                    team={homeTeam}
                    player={player}
                  />
                )}
              </StyledHorizontal>
            </Horizontal>
            <BodyM
              as="p"
              bold
              color={
                hasGameStarted
                  ? getTeamTextColor(game, homeTeam?.slug)
                  : 'var(--c-nd-600)'
              }
            >
              {hasGameStarted ? homeScore : '-'}
            </BodyM>
          </SBHorizontal>
          <SBHorizontal>
            <Horizontal gap={1}>
              <StyledImg
                src={awayTeam?.pictureUrl || defaultShield}
                alt=""
                width="16px"
                height="16px"
              />
              <StyledHorizontal>
                <LabelL bold color={getTeamTextColor(game, awayTeam?.slug)}>
                  {awayTeam?.name}
                </LabelL>
                {awayTeam && (
                  <DomesticLeagueRanking
                    game={game}
                    team={awayTeam}
                    player={player}
                  />
                )}
              </StyledHorizontal>
            </Horizontal>
            <BodyM
              as="p"
              bold
              color={
                hasGameStarted
                  ? getTeamTextColor(game, awayTeam?.slug)
                  : 'var(--c-nd-600)'
              }
            >
              {hasGameStarted ? awayScore : '-'}
            </BodyM>
          </SBHorizontal>
        </Vertical>
      </Vertical>
    </Root>
  );
};

const teamFragment = gql`
  fragment BriefGame_team on TeamInterface {
    slug
    name
    pictureUrl
    ... on NationalTeam {
      slug
      country {
        slug
        code
      }
    }
    ... on Club {
      slug
      domesticLeagueRanking
      domesticLeagueRankingRatioRange
    }
  }
` as TypedDocumentNode<BriefGame_team>;

BriefGame.fragments = {
  anyGameInterface: gql`
    fragment BriefGame_anyGameInterface on AnyGameInterface {
      id
      status: statusTyped
      homeScore
      homeTeam {
        slug
        ...BriefGame_team
      }
      awayScore
      awayTeam {
        slug
        ...BriefGame_team
      }
      winner {
        slug
      }
      competition {
        slug
        format
      }
      ...GameStatusLabel_anyGameInterface
    }
    ${GameStatusLabel.fragments.anyGameInterface}
    ${teamFragment}
  ` as TypedDocumentNode<BriefGame_anyGameInterface>,

  anyPlayerInterface: gql`
    fragment BriefGame_anyPlayerInterface on AnyPlayerInterface {
      slug
      activeClub {
        slug
      }
    }
  ` as TypedDocumentNode<BriefGame_anyPlayerInterface>,
};
