import { PropsWithChildren } from 'react';
import styled, { CSSProperties, keyframes } from 'styled-components';

import { absCenter } from 'lib/style';
import { RGBColor } from 'style/types';

const arrowTranslation = keyframes`
  to { d: var(--animated-path) }
`;
const scaling = keyframes`
  from { transform: scale(0) }
  to { transform: scale(1) }
`;

const Root = styled.div`
  --scaling-duration: 0.2s;
  --arrows-duration: 0.3s;
  --white-arrows-offset: 0.03s;
  position: relative;
  transform: scale(0);
  animation: ${scaling} var(--scaling-duration)
    cubic-bezier(0.61, 1.01, 0.11, 1.1) var(--delay) forwards;
`;
const Content = styled.div`
  ${absCenter}
`;
const AnimatedArrow = styled.path`
  fill: rgba(var(--color), 1);
  animation: ${arrowTranslation} cubic-bezier(0.34, 0.08, 0.22, 0.99) forwards;
  animation-duration: var(--arrows-duration);
  animation-delay: calc(var(--scaling-duration) + var(--delay));

  &.white {
    fill: var(--c-white);
    animation-duration: calc(
      var(--arrows-duration) - var(--white-arrows-offset)
    );
    animation-delay: calc(
      var(--scaling-duration) + var(--delay) + var(--white-arrows-offset)
    );
  }
`;

type Props = React.JSX.IntrinsicElements['svg'] & {
  fill: RGBColor;
  delay?: number;
  opacity?: number;
  onAnimationEnd?: () => void;
};
export const AnimatedDiamond = ({
  children,
  fill,
  delay = 0,
  opacity = 0.35,
  onAnimationEnd,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Root style={{ '--color': fill, '--delay': `${delay}s` } as CSSProperties}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 312 313"
        enableBackground="new 0 0 312 313"
        xmlSpace="preserve"
        {...rest}
      >
        <defs>
          <clipPath id="arrow-mask">
            <rect
              x="46.3"
              y="47.2"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -65.176 156.0534)"
              width="219"
              height="219"
            />
          </clipPath>
        </defs>

        <g clipPath="url(#arrow-mask)">
          <rect
            x="46.3"
            y="47.2"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -65.176 156.0534)"
            stroke={`rgba(${fill}, 1)`}
            strokeWidth={0.9082}
            strokeMiterlimit={4}
            width="219"
            height="219"
            fill={`rgba(${fill}, ${opacity})`}
          />
          <rect
            x="46.3"
            y="47.2"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -65.176 156.0534)"
            width="219"
            height="219"
            fill="none"
          />
        </g>

        <g clipPath="url(#arrow-mask)">
          <AnimatedArrow
            className="white"
            d="m 53.2 191.5 l -55.1 -37.5 l 77.6 77.6 l -32.5 -40.2 h 10 v 0.1 z"
            style={
              {
                '--animated-path':
                  "path('m 133.2 271.5 l -55.1 -37.5 l 77.6 77.6 l -32.5 -40.2 h 10 v 0.1 z')",
              } as CSSProperties
            }
          />
          <AnimatedArrow
            d="m 53.2 191.5 l -55.1 -37.5 l 77.6 77.6 l -32.5 -40.2 h 10 v 0.1 z"
            style={
              {
                '--animated-path':
                  "path('m 133.2 271.5 l -55.1 -37.5 l 77.6 77.6 l -32.5 -40.2 h 10 v 0.1 z')",
              } as CSSProperties
            }
          />
          <AnimatedArrow
            className="white"
            d="m 258.2 191.8 l 55.1 -37.5 l -77.6 77.6 l 32.5 -40.2 h -10 v 0.1 z"
            style={
              {
                '--animated-path':
                  "path('m 178.2 271.8 l 55.1 -37.5 l -77.6 77.6 l 32.5 -40.2 h -10 v 0.1 z')",
              } as CSSProperties
            }
          />

          <AnimatedArrow
            d="m 258.2 191.8 l 55.1 -37.5 l -77.6 77.6 l 32.5 -40.2 h -10 v 0.1 z"
            style={
              {
                '--animated-path':
                  "path('m 178.2 271.8 l 55.1 -37.5 l -77.6 77.6 l 32.5 -40.2 h -10 v 0.1 z')",
              } as CSSProperties
            }
          />
          <AnimatedArrow
            className="white"
            onAnimationEnd={onAnimationEnd}
            d="m 53.5 121.4 l -55 37.4 l 77.6 -77.6 l -32.5 40.2 c 0 -0 9.9 -0 9.9 -0 z"
            style={
              {
                '--animated-path':
                  "path('m 133.5 41.4 l -55 37.4 l 77.6 -77.6 l -32.5 40.2 c 0 -0 9.9 -0 9.9 -0 z')",
              } as CSSProperties
            }
          />

          <AnimatedArrow
            d="m 53.5 121.4 l -55 37.4 l 77.6 -77.6 l -32.5 40.2 c 0 -0 9.9 -0 9.9 -0 z"
            style={
              {
                '--animated-path':
                  "path('m 133.5 41.4 l -55 37.4 l 77.6 -77.6 l -32.5 40.2 c 0 -0 9.9 -0 9.9 -0 z')",
              } as CSSProperties
            }
          />
          <AnimatedArrow
            className="white"
            d="m 257.3 120.1 l 55.1 37.5 l -77.6 -77.6 l 32.5 40.2 h -10 v -0.1 z"
            style={
              {
                '--animated-path':
                  "path('m 177.3 40.1 l 55.1 37.5 l -77.6 -77.6 l 32.5 40.2 h -10 v -0.1 z')",
              } as CSSProperties
            }
          />
          <AnimatedArrow
            d="m 257.3 120.1 l 55.1 37.5 l -77.6 -77.6 l 32.5 40.2 h -10 v -0.1 z"
            style={
              {
                '--animated-path':
                  "path('m 177.3 40.1 l 55.1 37.5 l -77.6 -77.6 l 32.5 40.2 h -10 v -0.1 z')",
              } as CSSProperties
            }
          />
        </g>
      </svg>
      <Content>{children}</Content>
    </Root>
  );
};
