import { TypedDocumentNode, gql } from '@apollo/client';
import { isPast } from 'date-fns';

import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { useGetTokenSingleSaleDetails_anyCard } from './__generated__/useGetTokenSingleSaleDetails.graphql';

const useGetTokenSingleSaleDetails = (
  card: Nullable<useGetTokenSingleSaleDetails_anyCard>
) => {
  const singleSaleOffer =
    card?.liveSingleSaleOffer || card?.myMintedSingleSaleOffer;
  const endDate = singleSaleOffer?.endDate;
  const price = singleSaleOffer?.receiverSide?.amounts;
  const isOnSale = !!singleSaleOffer;

  if (isOnSale && price && endDate) {
    const ended = isPast(endDate);
    return {
      endDate,
      ended,
      price,
    };
  }
  return undefined;
};

useGetTokenSingleSaleDetails.fragments = {
  anyCard: gql`
    fragment useGetTokenSingleSaleDetails_anyCard on AnyCardInterface {
      slug
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        endDate
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        endDate
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<useGetTokenSingleSaleDetails_anyCard>,
};

export default useGetTokenSingleSaleDetails;
