import { css } from 'styled-components';

export const cellBackground = css`
  --cell-size: 44px;
  --y-offset: 22px;
  --x-offset: 22px;
  background: repeating-linear-gradient(
      to right,
      transparent var(--x-offset),
      rgb(66, 66, 66, 0.44) calc(var(--x-offset) + 1px),
      transparent calc(var(--x-offset) + 2px),
      transparent calc(var(--x-offset) + var(--cell-size))
    ),
    repeating-linear-gradient(
      to bottom,
      transparent var(--y-offset),
      rgb(66, 66, 66, 0.44) calc(var(--y-offset) + 1px),
      transparent calc(var(--y-offset) + 2px),
      transparent calc(var(--y-offset) + var(--cell-size))
    ),
    var(--c-black);
`;
