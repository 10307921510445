import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { ExternalLink } from '@sorare/core/src/atoms/navigation/ExternalLink';
import { BodyS, Title3 } from '@sorare/core/src/atoms/typography';
import { CoinPrice } from '@sorare/core/src/components/clubShop/ClubShopItem/Labels/CoinPrice';
import { ClubShopItemStatus } from '@sorare/core/src/components/clubShop/ClubShopItemStatus';

import { OrderConfirmed } from 'components/shopItems/ShopItemPicker/Item/Label';
import { canEquip } from 'components/shopItems/ShopItemPicker/utils';

import { Details_shopItem } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 0, center: true })`
  justify-content: stretch;
`;
const StyledExternalLink = styled.a`
  color: var(--c-nd-400);
`;

type Props = {
  item: Details_shopItem;
  itemEquipped?: boolean;
  orderConfirmed?: boolean;
  displayTerms?: boolean;
  inventory?: boolean;
};
const Details = ({
  item,
  itemEquipped,
  orderConfirmed,
  displayTerms,
  inventory,
}: Props) => {
  const equippable = canEquip(item) || inventory;
  const displayPrice = !equippable && !orderConfirmed;

  return (
    <Root>
      {orderConfirmed && <OrderConfirmed />}
      {equippable && (
        <ClubShopItemStatus status={itemEquipped ? 'equipped' : 'owned'} />
      )}
      {displayPrice && !itemEquipped && (
        <CoinPrice price={item.price} salePrice={item.salePrice} />
      )}
      <Title3>{item.name}</Title3>
      <Vertical gap={2}>
        <BodyS color="var(--c-nd-400)" as="p" className="text-center">
          {item.description}
        </BodyS>
        {item.requirementsRulesLink && !orderConfirmed && (
          <BodyS color="var(--c-nd-400)" as="p" className="text-center">
            <FormattedMessage
              id="ItemPreviewDialog.Details.requirementUrl"
              defaultMessage="For details on how to unlock this item, click <link>here</link>"
              values={{
                link: string => (
                  <ExternalLink href={item.requirementsRulesLink!} inline>
                    {string}
                  </ExternalLink>
                ),
              }}
            />
          </BodyS>
        )}
        {displayTerms && (
          <BodyS color="var(--c-nd-400)" as="p" className="text-center">
            <FormattedMessage
              id="ItemPreviewDialog.Details.terms"
              defaultMessage="Raffle rewards may be subject to shipping eligibility criteria. <link>Terms apply</link>"
              values={{
                link: content => (
                  <StyledExternalLink
                    target="_blank"
                    href="https://sorare.com/help/a/15499279878429/club-shop-drops-terms-and-conditions"
                  >
                    {content}
                  </StyledExternalLink>
                ),
              }}
            />
          </BodyS>
        )}
      </Vertical>
    </Root>
  );
};

Details.fragments = {
  shopItem: gql`
    fragment Details_shopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        name
        description
        position
        price
        salePrice
        myPurchasesCount
        requirementsRulesLink
      }
    }
  ` as TypedDocumentNode<Details_shopItem>,
};

export default Details;
