import { compareAsc, compareDesc } from 'date-fns';

export const dateProgress = (start: Date, end: Date) => {
  const now = new Date();
  const total = end.getTime() - start.getTime();
  const spent = Math.min(total, Math.max(0, now.getTime() - start.getTime()));
  return (spent / total) * 100;
};

export function sortByDate<T>(
  array: T[],
  getDate: (entry: T) => Date,
  direction?: 'asc' | 'desc'
) {
  const sortFunc = direction === 'desc' ? compareDesc : compareAsc;

  array.sort((a, b) => sortFunc(getDate(a), getDate(b)));

  return array;
}
