import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { NBA_TEAMS_SLUG_PLAYERS } from '@sorare/core/src/constants/__generated__/routes';

import { TeamBox } from 'components/game/TeamBox';

import { FeaturedPlayersList_team } from './__generated__/index.graphql';

const PlayersList = styled(Vertical).attrs({ gap: 0 })`
  margin: var(--half-unit) 0;
  & > * + * {
    border-top: 1px solid var(--c-nd-100);
  }
  & > * {
    padding: var(--half-unit) var(--triple-unit);
    cursor: pointer;

    &:hover {
      background: rgba(var(--c-rgb-brand-600), 0.1);
    }
  }
`;

export type Props = {
  team: FeaturedPlayersList_team;
  title: ReactNode;
  children: ReactNode;
};

export const FeaturedPlayersList = ({ team, title, children }: Props) => {
  return (
    <TeamBox
      team={team}
      title={title}
      to={generatePath(NBA_TEAMS_SLUG_PLAYERS, {
        slug: team.slug,
      })}
    >
      <PlayersList>{children}</PlayersList>
    </TeamBox>
  );
};

FeaturedPlayersList.fragments = {
  team: gql`
    fragment FeaturedPlayersList_team on TeamInterface {
      slug
      ...TeamBox_team
    }
    ${TeamBox.fragments.team}
  ` as TypedDocumentNode<FeaturedPlayersList_team>,
};
