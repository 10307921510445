import { TypedDocumentNode, gql } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { CardHit } from '@sorare/core/src/lib/algolia';

import PriceHistoryTooltip from 'components/price/PriceHistoryTooltip';
import { TokenTransferValidator } from 'components/token/TokenTransferValidator';

import CardRow from '../CardRow';
import { OfferSide_anyCard } from './__generated__/index.graphql';
import messages from './i18n';

type CardDataProvider = {
  cards: CardHit[];
  cardsData: Record<string, OfferSide_anyCard>;
};

type Props = CardDataProvider & {
  title: ReactNode;
  toggleAddCardOpened: () => void;
  setCards: (cards: CardHit[]) => Promise<boolean>;
  children?: ReactNode;
  displayMinPrices?: boolean;
  isOwnSide?: boolean;
  addCardDisabledWarning?: ReactNode;
};

type SelectedCardProps = {
  card: CardHit;
  cardData: OfferSide_anyCard;
  displayMinPrices: boolean;
  removeCard: (card: CardHit) => void;
  children?: ReactNode;
};

const Section = styled(Vertical)`
  padding: var(--double-unit);
`;

const SectionHeader = styled(SBHorizontal)`
  margin-bottom: var(--double--unit);
`;

const CardWrapper = styled.div`
  background: var(--c-nd-100);
  border-radius: var(--unit);
  border: 1px solid var(--c-nd-200);
`;

const ClearButton = styled(ButtonBase)`
  padding: var(--half-unit);
  height: var(--quadruple-unit);
  width: var(--quadruple-unit);
  display: flex;
  aspect-ratio: 1;
  > svg {
    width: 100%;
  }
`;

const WarningWrapper = styled.div`
  padding: 0 var(--unit) var(--unit);
`;

const SelectedCard = ({
  card,
  cardData,
  displayMinPrices,
  removeCard,
  children,
}: SelectedCardProps) => {
  const onRemove = useCallback(() => removeCard(card), [removeCard, card]);

  return (
    <CardWrapper>
      <CardRow
        card={card}
        cardData={cardData}
        displayMinPrice={displayMinPrices}
        hideBonus
      >
        <PriceHistoryTooltip card={cardData} context="Offer builder" />
        <ClearButton onClick={onRemove}>
          <FontAwesomeIcon icon={faTimes} size="xl" />
        </ClearButton>
      </CardRow>
      {children}
    </CardWrapper>
  );
};

export const OfferSide = ({
  cards,
  cardsData,
  toggleAddCardOpened,
  setCards,
  title,
  children,
  displayMinPrices = false,
  isOwnSide,
  addCardDisabledWarning,
}: Props) => {
  const removeCard = useCallback(
    (card: CardHit) => {
      setCards(cards.filter(c => c.objectID !== card.objectID));
    },
    [setCards, cards]
  );

  return (
    <Section>
      <SectionHeader>
        {title}
        <Tooltip
          title={
            addCardDisabledWarning ? (
              <FormattedMessage
                id="NewOfferBuilder.OfferSide.userDoesNotAcceptCardTrades"
                defaultMessage="This user does not accept card trades."
              />
            ) : (
              ''
            )
          }
        >
          <Button
            color="tertiary"
            onClick={toggleAddCardOpened}
            size="small"
            disabled={!!addCardDisabledWarning}
          >
            <FormattedMessage {...messages.addCard} />
          </Button>
        </Tooltip>
      </SectionHeader>
      {addCardDisabledWarning}
      {cards.length > 0 && (
        <TokenTransferValidator
          cards={Object.values(cardsData).filter(token =>
            cards.some(card => card.objectID === token?.slug)
          )}
          transferContext="send_trade"
          shouldValidate={!!isOwnSide}
        >
          {({ validationMessages }) => (
            <Vertical>
              {cards.map(card => (
                <SelectedCard
                  key={card.objectID}
                  card={card}
                  cardData={cardsData[card.objectID]}
                  displayMinPrices={displayMinPrices}
                  removeCard={removeCard}
                >
                  {validationMessages[card.objectID] && (
                    <WarningWrapper>
                      {validationMessages[card.objectID]}
                    </WarningWrapper>
                  )}
                </SelectedCard>
              ))}
            </Vertical>
          )}
        </TokenTransferValidator>
      )}
      {children}
    </Section>
  );
};

OfferSide.fragments = {
  anyCard: gql`
    fragment OfferSide_anyCard on AnyCardInterface {
      slug
      ...PriceHistoryTooltip_anyCard
      ...TokenTransferValidator_anyCard
      ...DirectOffer_CardRow_anyCard
    }
    ${CardRow.fragments.anyCard}
    ${PriceHistoryTooltip.fragments.anyCard}
    ${TokenTransferValidator.fragments.anyCard}
  ` as TypedDocumentNode<OfferSide_anyCard>,
};

export default OfferSide;
