import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { DocumentType } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { HeadlineL, HeadlineXS, LabelM, Text16 } from 'atoms/typography';
import { MANGOPAY_PRIVACY_POLICY } from 'components/fiatWallet/CreateFiatWallet/externalLinks';
import { glossary } from 'lib/glossary';

import { documentNameByType } from '../type';

const ItemRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--unit);
  padding: var(--intermediate-unit);
  background-color: var(--c-nd-100);
  border-radius: var(--unit);
`;

const ItemContent = styled.div`
  display: flex;
  gap: var(--unit);
`;

type ItemProps = {
  title: ReactNode;
  desc?: ReactNode;
  step: number;
};
const Item = ({ title, desc, step }: ItemProps) => (
  <ItemRoot>
    <ItemContent>
      <Text16 bold color="var(--c-white)">
        {step}
      </Text16>
      <div>
        {title && (
          <Text16 bold color="var(--c-white)">
            {title}
          </Text16>
        )}
        {desc && <LabelM color="var(--c-nd-600)">{desc}</LabelM>}
      </div>
    </ItemContent>
  </ItemRoot>
);

const Content = styled(Vertical).attrs({ gap: 2 })`
  height: 100%;
`;
const LinkInFormattedMessage = styled.a`
  text-decoration: underline;
  color: var(--c-link);
`;

type Props = {
  documentsRequired: DocumentType[];
  onNext: () => void;
};

export const Intro = ({ documentsRequired, onNext }: Props) => {
  return (
    <Content>
      <HeadlineL>
        <FormattedMessage
          id="UnblockWallet.intro.title"
          defaultMessage="We need more information to unlock your Cash Wallet!"
        />
      </HeadlineL>
      <HeadlineXS>
        <FormattedMessage
          id="UnblockWallet.intro.subtitle"
          defaultMessage="To unlock your Cash Wallet, you need to upload the following document(s):"
        />
      </HeadlineXS>
      {documentsRequired.map((document, index) => {
        return (
          <Item
            key={document}
            step={index + 1}
            title={<FormattedMessage {...documentNameByType[document]} />}
          />
        );
      })}
      <LabelM color="var(--c-nd-600)">
        <FormattedMessage
          id="UnblockWallet.uploadId.privacy"
          defaultMessage="Your documents remains private and will be handled according to Mangopay’s <link>Privacy policy</link>."
          values={{
            link: (...text) => (
              <LinkInFormattedMessage
                target="_blank"
                href={MANGOPAY_PRIVACY_POLICY}
              >
                {text}
              </LinkInFormattedMessage>
            ),
          }}
        />
      </LabelM>
      <Button fullWidth color="primary" size="medium" onClick={onNext}>
        <FormattedMessage {...glossary.getStarted} />
      </Button>
    </Content>
  );
};
