import useFontFaceObserver, { FontFace } from '@sorare/use-font-face-observer';

const PATTERN = /^(?<content>(.+))\s+(?<lastWord>[^\s]+)$/;
export const UNBREAKABLE_SPACE = '\u00A0';
export const avoidOrphan = (text: string) => {
  return text.replace(PATTERN, `$<content>${UNBREAKABLE_SPACE}$<lastWord>`);
};

type Boldness = 'light' | 'normal' | 'bold' | 'bolder' | number;
export type Font = `${Boldness} ${number}px ${string}`;
export const getTextSize = (
  text: string,
  font: Font,
  letterSpacing: string = '0'
) => {
  if (import.meta.env.NODE_ENV === 'test') {
    return { width: 0, height: 0 };
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  context.font = font;

  context.letterSpacing = letterSpacing;

  const metrics = context.measureText(text);

  return {
    width: metrics.width,
    height: metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent,
  };
};

const fontFaceExtract = /(?<boldness>\w+) (?<size>\w+) (?<family>.*)/;
export const useTextSize = (
  text: string,
  font: Font,
  letterSpacing: string = '0'
) => {
  const {
    family,
    boldness,
  }: { family?: string; boldness?: FontFace['weight'] } =
    fontFaceExtract.exec(font)?.groups || {};
  const firstFont = family.replace(/['"]/g, '').split(/, ?/)[0];
  const fontStatus = useFontFaceObserver(
    [{ family: firstFont, weight: boldness }],
    {
      timeout: 1000,
    }
  );
  if (fontStatus === 'initial') {
    return { width: 0, height: 0 };
  }
  return getTextSize(text, font, letterSpacing);
};
