import styled from 'styled-components';

import { Verified } from 'atoms/icons/Verified';
import { Horizontal } from 'atoms/layout/flex';

const Root = styled(Horizontal)`
  color: var(--c-white);
  font: var(--t-12);
  font-weight: var(--t-bold);
`;

export const SorareUser = () => {
  return (
    <Root>
      <Verified /> Sorare
    </Root>
  );
};
