import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { CSSProperties, css } from 'styled-components';

import { Currency, SupportedCurrency } from '__generated__/globalTypes';
import { IconButton } from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { DecimalInput } from 'atoms/inputs/DecimalInput';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
// eslint-disable-next-line import/no-restricted-paths
import { useIntlContext } from 'contexts/intl';
import {
  MonetaryAmountOutput,
  useMonetaryAmount,
} from 'hooks/useMonetaryAmount';
import { CurrencyCode, currencySymbol } from 'lib/fiat';
import { getFiatMonetaryAmountIndex } from 'lib/monetaryAmount';
import { fromWei, toWei } from 'lib/wei';

const Label = styled.label<{
  $error?: boolean;
}>`
  position: relative;
  max-width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  cursor: text;
  font: var(--input-font, var(--t-bold) var(--t-48));
  color: var(--color);
  z-index: 0;
  ${({ $error }) =>
    $error
      ? css`
          --color: var(--c-red-600);
        `
      : css`
          --color: var(--input-color, var(--c-brand-600));
        `};
  &:before {
    content: attr(data-before);
    margin-right: var(--unit);
    display: block;
    visibility: hidden;
  }
  &:after {
    content: attr(data-after);
    margin-left: var(--unit);
    display: block;
    visibility: hidden;
  }
`;

const Placeholder = styled.span`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: transparent;
  &:before {
    content: attr(data-before);
    margin-right: var(--unit);
    display: block;
    color: var(--color);
  }
  &:after {
    content: attr(data-after);
    display: block;
    margin-left: var(--unit);
    color: var(--color);
  }
`;

const PlaceholderAmount = styled.span<{ background?: boolean }>`
  ${({ background }) =>
    background
      ? css`
          padding: var(--unit) var(--unit);
          border-radius: var(--double-unit);
          background-color: var(--c-nd-200);
          z-index: -1;
        `
      : css`
          visibility: hidden;
        `}
`;

const StyledInput = styled(DecimalInput)`
  width: 100%;
  display: inline-flex;
  text-align: center;
  font: inherit;
  border: none;
  background-color: transparent;
  color: var(--color);
  line-height: 100%;
`;

const CenteredInputWithSymbol = forwardRef<
  HTMLInputElement,
  {
    value: string;
    symbol: string;
    symbolBefore?: string;
    placeholder?: string;
    disabled?: boolean;
    ariaLabel: string;
    error?: boolean;
    onChange: (newValue: string, newFormattedValue: number) => void;
    autoFocus?: boolean;
    background?: boolean;
    max: number;
    min: number;
    step: string;
  }
>(
  (
    {
      value,
      symbol,
      symbolBefore,
      placeholder,
      disabled,
      ariaLabel,
      onChange,
      error,
      autoFocus,
      background,
      max,
      min,
      step,
    },
    ref
  ) => (
    <Label data-before={symbolBefore} data-after={symbol} $error={error}>
      <StyledInput
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ariaLabel={ariaLabel}
        autoFocus={autoFocus}
        max={max}
        min={min}
        step={step}
      />
      <Placeholder data-before={symbolBefore} data-after={symbol}>
        <PlaceholderAmount background={background}>
          {value || placeholder}
        </PlaceholderAmount>
      </Placeholder>
    </Label>
  )
);
CenteredInputWithSymbol.displayName = 'CenteredInputWithSymbol';

export type Props = {
  minWeiAmount?: number;
  defaultValue: MonetaryAmountOutput;
  fiatCurrency: CurrencyCode;
  defaultCurrency?: Currency;
  onlyShowFiatCurrency: boolean;
  onChange: (
    monetaryAmount: MonetaryAmountOutput,
    target: HTMLInputElement,
    referenceCurrency: SupportedCurrency
  ) => void;
  onToggleEthDisplay?: (ethDisplay: boolean) => void;
  placeholder?: string;
  error?: ReactNode;
  disabled?: boolean;
  setOutBidCallback?: (callback: (amount: string) => void) => void;
  autoFocus?: boolean;
  controlledEthDisplay?: boolean;
  useSecondaryInput?: boolean;
};

export const MonetaryInput = ({
  defaultValue,
  controlledEthDisplay,
  fiatCurrency,
  onChange,
  defaultCurrency,
  placeholder,
  onToggleEthDisplay,
  setOutBidCallback,
  onlyShowFiatCurrency,
  error,
  disabled,
  autoFocus = true,
  useSecondaryInput = false,
  minWeiAmount = 0,
}: Props) => {
  const { formatWei, formatNumber } = useIntlContext();
  const [monetaryAmount, setMonetaryAmount] =
    useState<MonetaryAmountOutput>(defaultValue);
  const { toMonetaryAmount } = useMonetaryAmount();
  const ref = useRef<HTMLInputElement>(null);
  const monetaryAmountIndex = getFiatMonetaryAmountIndex(fiatCurrency);
  const minAmount = toMonetaryAmount({
    wei: minWeiAmount.toString(),
    referenceCurrency: SupportedCurrency.WEI,
  });

  const inputProps = (ethDisplay: boolean) => {
    if (ethDisplay) {
      return {
        step: '0.0001',
        max: 5000,
        symbol: 'ETH',
        symbolBefore: '',
        min: fromWei(minAmount.wei, 4),
      };
    }
    return {
      step: '0.01',
      max: 2147483647,
      symbol: '',
      symbolBefore: currencySymbol(fiatCurrency),
      min: minAmount[monetaryAmountIndex] / 100,
    };
  };

  const [ethDisplay, setEthDisplay] = useState(
    !onlyShowFiatCurrency && defaultCurrency === Currency.ETH
  );

  useEffect(() => {
    setEthDisplay(!onlyShowFiatCurrency && defaultCurrency === Currency.ETH);
  }, [defaultCurrency, onlyShowFiatCurrency, setEthDisplay]);

  const ethAmountStr = formatWei(
    monetaryAmount.wei,
    undefined,
    {
      maximumFractionDigits: 4,
      useGrouping: false,
    },
    {
      hideSymbol: true,
    }
  );
  const ethAmountWithCurrency = formatWei(monetaryAmount.wei, undefined, {
    maximumFractionDigits: 4,
  });
  const fiatAmountStr = formatNumber(
    monetaryAmount[monetaryAmountIndex] / 100,
    {
      maximumFractionDigits: 2,
      useGrouping: false,
    }
  );
  const fiatAmountWithCurrency = formatNumber(
    monetaryAmount[monetaryAmountIndex] / 100,
    {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: fiatCurrency,
    }
  );
  const [input, setInput] = useState<string>(
    ethDisplay ? ethAmountStr : fiatAmountStr
  );

  const [secondaryInput, setSecondaryInput] = useState<string>(
    !ethDisplay ? ethAmountStr : fiatAmountStr
  );

  const toggleDisplayedCurrency = useCallback(() => {
    if (onToggleEthDisplay) onToggleEthDisplay(!ethDisplay);
    setEthDisplay(prevEthDisplay => !prevEthDisplay);
    setInput(ethDisplay ? fiatAmountStr : ethAmountStr);
    setSecondaryInput(!ethDisplay ? fiatAmountStr : ethAmountStr);
  }, [ethAmountStr, ethDisplay, fiatAmountStr, onToggleEthDisplay]);

  useEffect(() => {
    if (
      controlledEthDisplay !== undefined &&
      ethDisplay !== controlledEthDisplay
    ) {
      toggleDisplayedCurrency();
    }
  }, [controlledEthDisplay, ethDisplay, toggleDisplayedCurrency]);

  const handle = useCallback(
    (value: string, newFormattedValue: number, isSecondaryInput?: boolean) => {
      const inputIsEth = isSecondaryInput ? !ethDisplay : ethDisplay;
      if (isSecondaryInput) {
        setSecondaryInput(value);
      } else {
        setInput(value);
      }

      const referenceCurrency = inputIsEth
        ? SupportedCurrency.WEI
        : SupportedCurrency[fiatCurrency];
      const amount = inputIsEth
        ? toWei(newFormattedValue)
        : Math.round(newFormattedValue * 100);
      const inputMonetaryParams = {
        referenceCurrency,
        [referenceCurrency.toLowerCase()]: amount,
      };
      const newMonetaryAmount = toMonetaryAmount(inputMonetaryParams);

      setMonetaryAmount(newMonetaryAmount);
      onChange(
        newMonetaryAmount,
        ref.current!,
        inputIsEth ? SupportedCurrency.WEI : SupportedCurrency[fiatCurrency]
      );
      const convertedAmount = inputIsEth
        ? (newMonetaryAmount[monetaryAmountIndex] / 100).toString()
        : fromWei(newMonetaryAmount.wei, 4).toString();
      if (isSecondaryInput) {
        setInput(convertedAmount);
      } else {
        setSecondaryInput(convertedAmount);
      }
    },
    [ethDisplay, fiatCurrency, toMonetaryAmount, onChange, monetaryAmountIndex]
  );

  const handler =
    (isSecondaryInput: boolean) =>
    (newValue: string, newFormattedValue: number) => {
      handle(newValue, newFormattedValue, isSecondaryInput);
    };

  useEffect(() => {
    if (setOutBidCallback) {
      setOutBidCallback(() => {
        return (amount: string) => {
          const newMonetaryAmount = toMonetaryAmount({
            wei: amount,
            referenceCurrency: SupportedCurrency.WEI,
          });
          const newAmount = ethDisplay
            ? fromWei(newMonetaryAmount.wei, 4)
            : newMonetaryAmount[monetaryAmountIndex] / 100;
          handle(newAmount.toString(), newAmount);
        };
      });
    }
  }, [
    ethDisplay,
    handle,
    setOutBidCallback,
    monetaryAmountIndex,
    toMonetaryAmount,
  ]);

  return (
    <Vertical gap={1} center>
      <Vertical gap={0} center>
        <CenteredInputWithSymbol
          ref={ref}
          value={input}
          onChange={handler(false)}
          placeholder={placeholder}
          ariaLabel={ethDisplay ? 'ether' : 'fiat'}
          disabled={disabled}
          error={!!error}
          autoFocus={autoFocus}
          {...inputProps(ethDisplay)}
        />
        {error}
      </Vertical>
      {!onlyShowFiatCurrency && (
        <Horizontal>
          {useSecondaryInput ? (
            <div
              style={
                {
                  '--input-font': '16px',
                  '--input-color': 'var(--c-nd-700)',
                } as CSSProperties
              }
            >
              <CenteredInputWithSymbol
                onChange={handler(true)}
                value={secondaryInput}
                placeholder="0"
                ariaLabel="secondaryInput"
                background
                {...inputProps(!ethDisplay)}
                symbolBefore="≈"
                symbol={ethDisplay ? currencySymbol(fiatCurrency) : 'ETH'}
              />
            </div>
          ) : (
            <>
              <LabelM color="var(--c-nd-700)">
                ≈&nbsp;
                {ethDisplay ? fiatAmountWithCurrency : ethAmountWithCurrency}
              </LabelM>
              <IconButton
                smaller
                color="tertiary"
                aria-label="switch"
                onClick={toggleDisplayedCurrency}
                disableDebounce
              >
                <FontAwesomeIcon icon={faRepeat} size="xs" />
              </IconButton>
            </>
          )}
        </Horizontal>
      )}
    </Vertical>
  );
};
