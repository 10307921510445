import { TypedDocumentNode, gql } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Block } from '@sorare/core/src/atoms/layout/Block';
import { BlockHeader } from '@sorare/core/src/atoms/layout/BlockHeader';
import {
  FullWidth,
  FullWidthScroll,
} from '@sorare/core/src/atoms/layout/Container';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { NewsTitleContent } from '@sorare/core/src/components/player/News/NewsTitleContent';
import { NewsUpdates } from '@sorare/core/src/components/player/News/NewsUpdates';
import { isType } from '@sorare/core/src/gql';
import usePaginatedQuery from '@sorare/core/src/hooks/graphql/usePaginatedQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { HandledError } from '@sorare/core/src/routing/HandledError';

import { LazyFixtureChart } from '@sorare/gameplay/src/components/stats/LastScores/LazyFixtureChart';

import CardWithTokenPageContent from '@sorare/us-sports/src/components/cards/CardWithTokenPageContent';

import { CardPropertiesWithInjury } from 'components/cards/CardPropertiesWithInjury';

import {
  BaseballCardQuery,
  BaseballCardQueryVariables,
} from './__generated__/index.graphql';

type BaseballCard = Extract<
  NonNullable<BaseballCardQuery['anyCard']>,
  { __typename: 'BaseballCard' }
>;

const PlayerScoreHistory = ({ card }: { card: BaseballCard }) => {
  const { anyPlayer } = card;
  if (!isType(anyPlayer, 'BaseballPlayer')) return null;
  const { slug: playerSlug } = anyPlayer;

  if (!playerSlug) return null;

  return (
    <FullWidthScroll>
      <LazyFixtureChart playerSlug={playerSlug} />
    </FullWidthScroll>
  );
};

const News = ({ card }: { card: BaseballCard }) => {
  const { anyPlayer } = card;
  if (!isType(anyPlayer, 'BaseballPlayer')) return null;
  const { displayName, news } = anyPlayer;
  if (!news) return null;
  return (
    <Vertical>
      <BlockHeader
        title={
          <FullWidth>
            <NewsTitleContent />
          </FullWidth>
        }
      />
      <Block>
        <NewsUpdates updates={news} playerName={displayName} />
      </Block>
    </Vertical>
  );
};

const CARD_QUERY = gql`
  query BaseballCardQuery(
    $slug: String!
    $bidCursor: String
    $onlyPrimary: Boolean = false
    $googlePlayStoreCountryCode: String
    $isAndroidApp: Boolean = false
    $isIosApp: Boolean = false
  ) {
    anyCard(slug: $slug) {
      slug
      rarityTyped
      seasonYear
      anyPlayer {
        slug
        displayName
        news(last: 10) {
          ...NewsUpdates_PlayerNewsUpdate
        }
      }
      ...CardWithTokenPageContent_anyCardInterface
      ...CardPropertiesWithInjury_anyCard
    }
  }
  ${CardWithTokenPageContent.fragments.anyCardInterface}
  ${CardPropertiesWithInjury.fragments.anyCard}
  ${NewsUpdates.fragments.PlayerNewsUpdate}
` as TypedDocumentNode<BaseballCardQuery, BaseballCardQueryVariables>;

export const CardPageContent = () => {
  const { slug: routeSlug } = useParams();
  const dialogSlug = useDialogParam(DialogKey.card);
  const slug = dialogSlug || routeSlug;

  const { formatMessage } = useIntl();
  const { isAndroidApp, isIosApp, googlePlayStoreCountryCode } =
    useIsMobileApp();

  const { data, loading, loadMore } = usePaginatedQuery(CARD_QUERY, {
    variables: {
      slug: slug as string,
      isAndroidApp,
      googlePlayStoreCountryCode,
      isIosApp,
    },
    connections: ['TokenBidConnection'],
  });
  const card = data?.anyCard;

  if (loading && !data) {
    return <LoadingIndicator fullHeight />;
  }

  if (!card || !isType(card, 'BaseballCard')) {
    return (
      <FullWidth>
        <HandledError
          code={404}
          message={formatMessage({
            id: 'card.notFound',
            defaultMessage: 'This Card does not exist',
          })}
        />
      </FullWidth>
    );
  }

  return (
    <CardWithTokenPageContent<BaseballCard>
      card={card}
      loadMoreBids={loadMore}
      CardProperties={CardPropertiesWithInjury}
      PlayerScoresHistory={PlayerScoreHistory}
      News={News}
    />
  );
};

export default CardPageContent;
