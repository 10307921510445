import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import ethIcon from '@sorare/core/src/assets/eth_icon.png';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Caption } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import {
  MonetaryAmountOutput,
  useMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { cardRatio } from '@sorare/core/src/lib/cardPicture';
import { getFaCurrencySymbol } from '@sorare/core/src/lib/fiat';
import { MonetaryAmountParams } from '@sorare/core/src/lib/monetaryAmount';

import { MarketFeeStatus } from 'hooks/useMarketFeesHelperStatus';

import FeesDetailsTooltip from '../FeesDetailsTooltip';

const Card = styled(Horizontal).attrs({ gap: 0 })`
  justify-content: start;
`;

const MonetaryImageContainer = styled.div<{ isFiat: boolean }>`
  width: 30px;
  aspect-ratio: ${cardRatio};
  background-color: ${({ isFiat }) =>
    isFiat ? 'var(--c-green-600)' : 'rgba(var(--c-rgb-brand-600), 0.25)'};

  margin-right: var(--unit);
  border-radius: var(--half-unit);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--unit) 0;
`;

const EthImage = styled.img`
  width: 30px;
`;

const Amount = styled.div`
  display: flex;
  align-items: baseline;
  gap: var(--unit);
`;

const Fees = styled(Horizontal)`
  color: var(--c-nd-600);
`;

export interface Props {
  amount: MonetaryAmountParams;
  displayFees?: boolean;
  marketFeeAmount?: MonetaryAmountOutput;
  marketFeeStatus?: MarketFeeStatus;
}

const MonetaryCard = ({
  amount,
  marketFeeAmount,
  displayFees,
  marketFeeStatus,
}: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  const { fiatCurrency } = useFiatBalance();
  const {
    walletPreferences: { onlyShowFiatCurrency },
  } = useCurrentUserContext();

  if (!amount) {
    return null;
  }

  const { referenceCurrency } = amount;
  const isFiat = referenceCurrency !== SupportedCurrency.WEI;
  const feesDisplayable =
    displayFees && marketFeeAmount && marketFeeAmount?.eur > 0;

  return (
    <Card>
      <MonetaryImageContainer isFiat={isFiat}>
        {isFiat ? (
          <FontAwesomeIcon
            size="lg"
            color="var(--c-white)"
            icon={getFaCurrencySymbol(fiatCurrency)}
          />
        ) : (
          <EthImage src={ethIcon} alt="Ethereum" />
        )}
      </MonetaryImageContainer>
      <Amount>
        <AmountWithConversion
          monetaryAmount={amount}
          primaryCurrency={
            referenceCurrency === SupportedCurrency.WEI
              ? Currency.ETH
              : Currency.FIAT
          }
          hideExponent={onlyShowFiatCurrency}
          column
        />
        {feesDisplayable && (
          <Fees>
            <FeesDetailsTooltip
              monetaryAmount={toMonetaryAmount(amount)}
              marketFeeMonetaryAmount={marketFeeAmount}
              referenceCurrency={amount.referenceCurrency}
              marketFeeStatus={
                marketFeeStatus !== MarketFeeStatus.DISABLED
                  ? marketFeeStatus
                  : undefined
              }
            />
          </Fees>
        )}
        {feesDisplayable && (
          <Caption>
            <FormattedMessage
              id="EthereumCard.marketFeesApply"
              defaultMessage="market fees apply"
            />
          </Caption>
        )}
      </Amount>
    </Card>
  );
};

export default MonetaryCard;
