import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { ChevronRightBold } from '@sorare/core/src/atoms/icons/ChevronRightBold';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text16 } from '@sorare/core/src/atoms/typography';

const Border = styled.div`
  border-radius: var(--unit);
  padding: 1px;
  background: var(--c-gradient-conversionCreditBorder);
`;

const Background = styled.div`
  background-color: var(--c-nd-50);
  border-radius: var(--unit);
`;
const StyledButtonBase = styled(ButtonBase)`
  background: var(--c-gradient-conversionCredit);
  border-radius: var(--unit);
  padding: var(--intermediate-unit) var(--double-unit);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--double-unit);
`;

const Text = styled(Vertical).attrs({ gap: 0.5 })`
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
`;

type Props = {
  onClick: () => void;
};

export const ActivateYourFiatWalletOnListing = ({ onClick }: Props) => {
  return (
    <Border>
      <Background>
        <StyledButtonBase onClick={onClick}>
          <Text>
            <Text16 bold>
              <FormattedMessage
                id="ActivateYourFiatWalletOnListing.title"
                defaultMessage="Reach more managers"
              />
            </Text16>
            <LabelM>
              <FormattedMessage
                id="ActivateYourFiatWalletOnListing.description"
                defaultMessage="Activate your Cash Wallet and start accepting cash as payment."
              />
            </LabelM>
          </Text>
          <ChevronRightBold />
        </StyledButtonBase>
      </Background>
    </Border>
  );
};
