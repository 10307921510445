import { TypedDocumentNode, gql } from '@apollo/client';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { DumbClubAvatar } from '@sorare/core/src/components/club/DumbClubAvatar';
import { FlagAvatar } from '@sorare/core/src/components/country/FlagAvatar';
import {
  FOOTBALL_CLUB_SHOW,
  FOOTBALL_COUNTRY_SHOW,
} from '@sorare/core/src/constants/routes';
import { isType } from '@sorare/core/src/lib/gql';

import { AnyPlayerDescription } from '../../AnyPlayerDescription';
import {
  FollowDescription_anyPlayer,
  FollowDescription_club,
  FollowDescription_country,
} from './__generated__/index.graphql';

interface Props {
  followedItem:
    | FollowDescription_anyPlayer
    | FollowDescription_club
    | FollowDescription_country;
}

const Root = styled.div`
  display: flex;
`;
const StyledFlagAvatar = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
`;
const Description = styled(Horizontal).attrs({ gap: 0 })`
  padding-left: 10px;
`;
const Avatar = styled(DumbClubAvatar)`
  width: 48px;
  height: 48px;
`;

const FollowDescription = (props: Props) => {
  const { followedItem } = props;
  if (isType(followedItem, 'Country')) {
    return (
      <Root>
        <StyledFlagAvatar>
          <FlagAvatar country={followedItem} imageRes={64} type="flat" />
        </StyledFlagAvatar>
        <Description>
          <Link
            to={generatePath(FOOTBALL_COUNTRY_SHOW, {
              countryCode: followedItem.code,
            })}
          >
            <Text16 bold>{followedItem.name}</Text16>
          </Link>
        </Description>
      </Root>
    );
  }

  if (isType(followedItem, 'Club')) {
    return (
      <Root>
        <Avatar club={followedItem} />
        <Description>
          <Link
            to={generatePath(FOOTBALL_CLUB_SHOW, { slug: followedItem.slug })}
          >
            <Text16 bold>{followedItem.name}</Text16>
          </Link>
        </Description>
      </Root>
    );
  }

  if (
    isType(followedItem, 'Player') ||
    isType(followedItem, 'NBAPlayer') ||
    isType(followedItem, 'BaseballPlayer')
  ) {
    return <AnyPlayerDescription player={followedItem} />;
  }

  return null;
};

FollowDescription.fragments = {
  club: gql`
    fragment FollowDescription_club on Club {
      slug
      name
      avatarUrl: pictureUrl(derivative: "avatar")
    }
  ` as TypedDocumentNode<FollowDescription_club>,
  country: gql`
    fragment FollowDescription_country on Country {
      slug
      code
      name
    }
  ` as TypedDocumentNode<FollowDescription_country>,
  anyPlayer: gql`
    fragment FollowDescription_anyPlayer on AnyPlayerInterface {
      slug
      ...AnyPlayerDescription_anyPlayer
    }
    ${AnyPlayerDescription.fragments.anyPlayer}
  ` as TypedDocumentNode<FollowDescription_anyPlayer>,
};

export default FollowDescription;
