import { FormattedMessage } from 'react-intl';

import { Horizontal } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { glossary } from 'lib/glossary';

import rotowire from './assets/rotowire.svg';

export const PoweredByRotowire = () => {
  return (
    <Horizontal gap={0.5} center>
      <LabelS as="p" color="var(--c-nd-600)">
        <FormattedMessage {...glossary.poweredBy} />
      </LabelS>
      <img src={rotowire} alt="RotoWire" />
    </Horizontal>
  );
};
