import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import { getFaCurrencySymbol } from 'lib/fiat';

import PaymentMethodIcon, { Props as PaymentMethodIconProps } from '..';

type Props = Pick<PaymentMethodIconProps, 'size'>;

export const FiatWalletIcon = ({ size }: Props) => {
  const { fiatCurrency } = useFiatBalance();
  return (
    <PaymentMethodIcon
      size={size}
      backgroundColor="var(--c-green-600)"
      color="var(--c-white)"
      icon={getFaCurrencySymbol(fiatCurrency)}
    />
  );
};
