import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-template-columns: var(--double-unit) 1fr repeat(3, calc(5 * var(--unit)));
  /* set the grid-template-rows to work-around a Safari behavior where the grid row doesn't adjust to content */
  grid-template-rows: max-content;
  gap: var(--unit);
  align-items: center;

  & > *:nth-child(n + 3) {
    text-align: right;
  }
`;
