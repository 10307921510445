import { ReactNode } from 'react';
import styled from 'styled-components';

const WhiteBold = styled.b`
  color: var(--c-white);
`;

export const BoldWhite = (chunks: ReactNode[]) => {
  return <WhiteBold>{chunks}</WhiteBold>;
};
