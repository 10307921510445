import { TypedDocumentNode, gql } from '@apollo/client';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LiveIndicator } from '@sorare/core/src/atoms/ui/LiveIndicator';
import { TeamAvatar } from '@sorare/core/src/components/club/TeamAvatar';

import { ColoredScore } from '@sorare/us-sports/src/components/scores/ColoredScore';
import { ColoredScoreWithDelta } from '@sorare/us-sports/src/components/scores/ColoredScoreWithDelta';

import {
  LivePlayerCardScore_anyGame,
  LivePlayerCardScore_anyPlayer,
} from './__generated__/index.graphql';

const Wrapper = styled(Vertical).attrs({ gap: 0.5, center: true })`
  justify-content: stretch;
`;

const SmallTeamLogo = styled(TeamAvatar)`
  display: inline-flex;
  max-width: var(--double-unit);
  max-height: var(--double-unit);
`;

const Line = styled(Horizontal).attrs({ gap: 0.5 })`
  font: var(--t-12);
  font-weight: var(--t-bold);
`;

const LiveDot = styled(LiveIndicator)`
  width: 6px;
  height: 6px;
  align-self: center;
`;
const InlineHorizontal = styled(Horizontal)`
  display: inline-flex;
`;

const ClockWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--c-nd-200);
  border-radius: 0.35em;
  padding: 0.33em 0.5em;
  min-width: 2.5em;
`;

type Props = {
  player: LivePlayerCardScore_anyPlayer;
  nbPastGames: number;
  upcomingGames: LivePlayerCardScore_anyGame[];
  hasTBDGames: boolean;
  isLive: boolean;
  liveCardScoreWithBonus: { score: number; playedInGame: boolean };
  cardInLineupScoreWithBonus: { score: number; playedInGame: boolean };
};

export const LivePlayerCardScore = ({
  player,
  nbPastGames,
  upcomingGames,
  hasTBDGames,
  isLive,
  liveCardScoreWithBonus,
  cardInLineupScoreWithBonus,
}: Props) => {
  const nbUpcomingGames = upcomingGames.length;

  const hasBetterPastScore =
    liveCardScoreWithBonus.score < cardInLineupScoreWithBonus.score;

  const upcoming =
    nbUpcomingGames > 0 ? (
      <>
        {upcomingGames.map(g => {
          return (
            <SmallTeamLogo
              key={g.id}
              team={
                player?.activeClub?.slug === g.awayTeam?.slug
                  ? g.homeTeam
                  : g.awayTeam
              }
            />
          );
        })}
      </>
    ) : (
      <>
        {hasTBDGames && (
          <FormattedMessage id="gameCount.tbd" defaultMessage="TBD" />
        )}
      </>
    );

  if (!nbPastGames && !isLive) {
    return (
      <Wrapper>
        <Line>
          <ClockWrapper>
            <FontAwesomeIcon icon={faClock} />
          </ClockWrapper>
        </Line>
        <Line>{upcoming}</Line>
      </Wrapper>
    );
  }

  const averageGameScore = +(player?.lastTenPlayedSo5AverageScore || 0);
  // live game
  if (isLive) {
    // if there is a past game with a better score, we display it
    if (nbPastGames > 0 && hasBetterPastScore) {
      return (
        <Wrapper>
          <Line>
            <ColoredScoreWithDelta
              score={cardInLineupScoreWithBonus.score}
              averageGameScore={averageGameScore}
              playedInGame={cardInLineupScoreWithBonus.playedInGame}
            />
          </Line>
          <Line>
            <InlineHorizontal gap={0.5}>
              <LiveDot />
              <FormattedNumber
                value={liveCardScoreWithBonus.score}
                maximumFractionDigits={0}
              />
            </InlineHorizontal>
          </Line>
        </Wrapper>
      );
    }

    if (!nbPastGames) {
      return (
        <Wrapper>
          <Line>
            <ColoredScore
              score={liveCardScoreWithBonus.score}
              playedInGame
              isLive
            />
          </Line>
          <Line>{upcoming}</Line>
        </Wrapper>
      );
    }

    // display live score
    return (
      <Wrapper>
        <Line>
          <ColoredScoreWithDelta
            score={liveCardScoreWithBonus.score}
            averageGameScore={averageGameScore}
            playedInGame={liveCardScoreWithBonus.playedInGame}
            isLive
          />
        </Line>
        <Line>{upcoming}</Line>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Line>
        <ColoredScoreWithDelta
          score={cardInLineupScoreWithBonus.score}
          averageGameScore={averageGameScore}
          playedInGame={cardInLineupScoreWithBonus.playedInGame}
          isLive={isLive}
        />
      </Line>
      <Line>{upcoming}</Line>
    </Wrapper>
  );
};

LivePlayerCardScore.fragments = {
  anyPlayer: gql`
    fragment LivePlayerCardScore_anyPlayer on AnyPlayerInterface {
      slug
      lastTenPlayedSo5AverageScore: averageScore(
        type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
      )
      activeClub {
        slug
      }
    }
  ` as TypedDocumentNode<LivePlayerCardScore_anyPlayer>,
  anyGame: gql`
    fragment LivePlayerCardScore_anyGame on AnyGameInterface {
      id
      awayTeam {
        slug
        name
        ...TeamAvatar_team
      }
      homeTeam {
        slug
        name
        ...TeamAvatar_team
      }
    }
    ${TeamAvatar.fragments.team}
  ` as TypedDocumentNode<LivePlayerCardScore_anyGame>,
};
