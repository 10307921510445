import styled from 'styled-components';

import { Text16 } from 'atoms/typography';
import { Ordinal } from 'atoms/typography/Ordinal';

const StyledSup = styled.sup`
  font-size: var(--fs-12);
`;

const Root = styled(Text16)`
  display: flex;
  max-height: 24px;
`;

type Props = { liveRanking: number };

export const Ranking = ({ liveRanking }: Props) => {
  return (
    <Root bold>
      {liveRanking > 0 ? (
        <Ordinal ordinal={liveRanking} Sup={StyledSup} />
      ) : (
        '-'
      )}
    </Root>
  );
};
