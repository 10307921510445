import { Navigate, useParams } from 'react-router-dom';

import { LANDING } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { usePromoSignup } from '@sorare/core/src/hooks/usePromoSignup';

const PromoSignupPage = () => {
  const { code } = useParams();
  const { currentUser } = useCurrentUserContext();

  usePromoSignup(code);

  if (currentUser || !code) {
    return <Navigate to={LANDING} replace />;
  }

  return null;
};

export default PromoSignupPage;
