export const InlineLineupIcon = () => {
  return (
    <svg width="21" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="16" y="4" width="1.25" height="6" rx=".5" fill="currentColor" />
      <rect x="13" y="4" width="1.25" height="6" rx=".5" fill="currentColor" />
      <rect x="10" y="4" width="1.25" height="6" rx=".5" fill="currentColor" />
      <rect x="7" y="4" width="1.25" height="6" rx=".5" fill="currentColor" />
      <rect x="4" y="4" width="1.25" height="6" rx=".5" fill="currentColor" />
      <rect
        x=".5"
        y=".5"
        width="20"
        height="13"
        rx="3.5"
        stroke="currentColor"
      />
    </svg>
  );
};
