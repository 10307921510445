import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Color } from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import {
  MobileAppFeature,
  NotMobileAppFeature,
} from '@sorare/core/src/components/BuyableFeature';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import useLoggedCallback from '@sorare/core/src/hooks/useLoggedCallback';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useHandleWalletStateBeforePayment } from '@sorare/core/src/hooks/wallets/useHandleWalletStateBeforePayment';
import { mobileApp } from '@sorare/core/src/lib/glossary';

import usePollAuction from 'components/auction/usePollAuction';
import useBestBidBelongsToUser from 'hooks/auctions/useBestBidBelongsToUser';
import { useClickBidEvent } from 'hooks/events/useClickBidEvent';
import useCannotTrade from 'hooks/offers/useCannotTrade';
import { auctionMinNextBid } from 'lib/auctions';

import LazyBidPaymentFlow from './BidPaymentFlow';
import { BidField_auction } from './__generated__/index.graphql';

type BidFieldProps = {
  auction: BidField_auction;
  variant: 'small' | 'medium';
  color?: Color;
};

export const messages = defineMessages({
  increaseBid: { id: 'BidField.increase', defaultMessage: 'Increase bid' },
  placeBid: { id: 'BidField.placeBid', defaultMessage: 'Place bid' },
});

const Root = styled(Vertical).attrs({ gap: 0, center: true })`
  justify-content: space-between;
  overflow: visible;
  flex-grow: 1;
`;
const ActivateButton = styled(LoadingButton)`
  flex-shrink: 0;
  width: 100%;
`;
const ActivateButtonTooltip = styled(Tooltip)`
  width: 100%;
`;

const BidField = ({ auction, variant, color = 'primary' }: BidFieldProps) => {
  const { toMonetaryAmount } = useMonetaryAmount();
  usePollAuction(auction);
  const { open, cancelled } = auction;
  const trackClickBid = useClickBidEvent();
  const cannotTrade = useCannotTrade();
  const cannotTradeToken = cannotTrade();
  const handleWalletStateBeforePayment = useHandleWalletStateBeforePayment();

  const { formatMessage } = useIntlContext();
  const doesBestBidBelongsToUser = useBestBidBelongsToUser();
  const [paymentStarted, setPaymentStarted] = useState(false);

  const bestBidBelongsToUser =
    auction?.bestBid && doesBestBidBelongsToUser(auction.bestBid);

  const loggedTogglePaymentStarted = useLoggedCallback<boolean>(b =>
    handleWalletStateBeforePayment(() => {
      setPaymentStarted(b);
    })
  );

  if (!open || cancelled) return null;

  const minNextBid = auctionMinNextBid(auction);

  const minNextBidMonetary = toMonetaryAmount({
    [auction.currency.toLowerCase()]: minNextBid,
    referenceCurrency: auction.currency,
  });

  const onClick = () => {
    trackClickBid(auction, minNextBidMonetary);
    return loggedTogglePaymentStarted(true);
  };

  return (
    <Root>
      <ActivateButtonTooltip
        title={cannotTradeToken ? formatMessage(cannotTradeToken) : ''}
      >
        <NotMobileAppFeature>
          <ActivateButton
            color={color}
            size={variant}
            disabled={Boolean(cannotTradeToken)}
            onClick={onClick}
            loading={paymentStarted}
          >
            {formatMessage(
              bestBidBelongsToUser || auction.myLastBid
                ? messages.increaseBid
                : messages.placeBid
            )}
          </ActivateButton>
        </NotMobileAppFeature>
        <MobileAppFeature>
          <ActivateButton
            color={color}
            size={variant}
            disabled
            onClick={() => {}}
            loading={false}
          >
            <FormattedMessage {...mobileApp.unavailable} />
          </ActivateButton>
        </MobileAppFeature>
      </ActivateButtonTooltip>
      {paymentStarted && (
        <LazyBidPaymentFlow
          auctionId={auction.id}
          onClose={() => setPaymentStarted(false)}
        />
      )}
    </Root>
  );
};

BidField.fragments = {
  auction: gql`
    fragment BidField_auction on TokenAuction {
      id
      open
      cancelled
      minNextBid
      privateMinNextBid
      currency
      myLastBid {
        id
      }
      bestBid {
        id
        bidder {
          ... on User {
            slug
          }
        }
        ...UseBestBidBelongsToUser_bestBid
      }
      ...UsePollAuction_auction
      ...useClickBidEvent_tokenAuction
    }
    ${useBestBidBelongsToUser.fragments.bestBid}
    ${usePollAuction.fragments.auction}
    ${useClickBidEvent.fragments.tokenAuction}
  ` as TypedDocumentNode<BidField_auction>,
};

export default BidField;
