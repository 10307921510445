import { isPast } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { PromotionalEventCompletionState } from '__generated__/globalTypes';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyS, LabelS } from 'atoms/typography';
import { useCurrentUserContext } from 'contexts/currentUser';
import useFeatureFlags from 'hooks/useFeatureFlags';

import ActivePromotionalEventTimeLeft from '../ActivePromotionalEventTimeLeft';
import Cta from '../Cta';
import cover from '../Details/assets/cover.jpg';
import Remaining from '../Remaining';
import SpentProgress from '../SpentProgress';

const Root = styled(Vertical).attrs({ center: true })`
  text-align: center;
  padding: var(--double-unit);
  border-radius: var(--double-unit);
  color: var(--c-white);
  border: 1px solid;
  background:
    linear-gradient(
      0deg,
      var(--surface-primary-nd-50, rgba(255, 255, 255, 0.07)),
      var(--surface-primary-nd-50, rgba(255, 255, 255, 0.07))
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${cover}) center center no-repeat;
  background-size: cover;
`;

const StyledLink = styled(Link)`
  color: var(--c-nd-600);
  text-decoration: underline;
`;

const CompactBanner = ({
  showDepositCta,
  showInprogress,
}: {
  showInprogress?: boolean;
  showDepositCta?: boolean;
}) => {
  const { formatNumber } = useIntl();
  const { currentUser, fiatCurrency } = useCurrentUserContext();

  const {
    flags: { useDepositPromoEvent, useShowActiveDepositPromo },
  } = useFeatureFlags();

  if (!currentUser?.depositPromotionalEvent || !useShowActiveDepositPromo)
    return null;

  const event = currentUser.depositPromotionalEvent;

  if (currentUser?.activePromotionalEventCompletion) {
    if (
      showInprogress &&
      currentUser?.activePromotionalEventCompletion?.aasmState ===
        PromotionalEventCompletionState.PENDING &&
      !isPast(currentUser?.activePromotionalEventCompletion.expiresAt)
    ) {
      return (
        <Root>
          <Horizontal>
            <ActivePromotionalEventTimeLeft />
          </Horizontal>
          <BodyS>
            <FormattedMessage
              id="AddFundsToFiatWallet.Promo.compactBanner.inProgress.description"
              defaultMessage="Spend at least {percentage, number, ::percent} in Instant Buy or Auctions to get your reward"
              values={{
                percentage:
                  currentUser.depositPromotionalEvent.completionRules
                    .completion_percentage / 100,
              }}
            />
          </BodyS>
          <SpentProgress />
        </Root>
      );
    }
    return null;
  }

  if (!useDepositPromoEvent) return null;

  return (
    <Root>
      <Remaining completedParticipants={event.completedParticipants} />
      <LabelS bold>
        <FormattedMessage
          id="AddFundsToFiatWallet.Promo.remaining"
          defaultMessage="Deposit {amount}+ for a guaranteed {min}-{max} in market credits!"
          values={{
            amount: formatNumber(
              (event.eligibilityRules.minimumDepositAmount || 0) / 100,
              {
                style: 'currency',
                currency: fiatCurrency.code,
                minimumFractionDigits: 0,
              }
            ),
            min: formatNumber(15, {
              style: 'currency',
              currency: fiatCurrency.code,
              minimumFractionDigits: 0,
            }),
            max: formatNumber(3000, {
              style: 'currency',
              currency: fiatCurrency.code,
              minimumFractionDigits: 0,
            }),
          }}
        />{' '}
        <StyledLink to={currentUser.depositPromotionalEvent.blogUrl || ''}>
          <FormattedMessage
            id="AddFundsToFiatWallet.Promo.moreDetails"
            defaultMessage="More details"
          />
        </StyledLink>
      </LabelS>
      {showDepositCta && <Cta color="quaternary" />}
    </Root>
  );
};

export default CompactBanner;
