import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Caption, Text14, Text16, Title6 } from 'atoms/typography';
import {
  SecurityCheckTab,
  useAccountSecurityCheckContext,
} from 'contexts/accountSecurityCheck';
import { useCurrentUserContext } from 'contexts/currentUser';
import { glossary } from 'lib/glossary';

const Wrapper = styled(Vertical).attrs({ gap: 2 })``;

const Badge = styled.div<{ enabled: boolean }>`
  color: ${({ enabled }) =>
    enabled ? 'var(--c-green-600)' : 'var(--c-red-800)'};
  display: inline-block;
  padding: 0 var(--half-unit);
  gap: var(--double-unit);
  border: solid 1px currentColor;
  border-radius: var(--half-unit);
`;
const RecoveryCodes = styled.div`
  border-top: solid 1px var(--c-nd-200);
  padding-top: var(--double-unit);
`;

export const TwoFA = () => {
  const { setSecurityCheckTab } = useAccountSecurityCheckContext();

  const { currentUser } = useCurrentUserContext();
  if (!currentUser) return null;
  const { otpRequiredForLogin } = currentUser;

  return (
    <Wrapper>
      <Vertical>
        <Horizontal>
          {otpRequiredForLogin ? (
            <FontAwesomeIcon icon={faCircleCheck} color="var(--c-green-600)" />
          ) : (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              color="var(--c-red-300)"
            />
          )}
          <Title6 color="var(--c-white)">
            <FormattedMessage
              id="accountSecurityCheck.2fa.title"
              defaultMessage="Two-Factor Authentication"
            />
          </Title6>
        </Horizontal>
        {!otpRequiredForLogin && (
          <Text14 color="var(--c-nd-600)">
            <FormattedMessage
              id="accountSecurityCheck.2fa.desc"
              defaultMessage="Two-factor authentication (known as “2FA”) adds an extra layer of security to your account to ensure that only you have the ability to log in."
            />
          </Text14>
        )}
      </Vertical>
      <div>
        <Text16 color="var(--c-white)">
          <FormattedMessage
            id="accountSecurityCheck.2fa.authenticatorApp"
            defaultMessage="Authenticator app"
          />
        </Text16>
        <Badge enabled={otpRequiredForLogin}>
          <Caption bold>
            {otpRequiredForLogin ? (
              <FormattedMessage {...glossary.enabled} />
            ) : (
              <FormattedMessage {...glossary.disabled} />
            )}
          </Caption>
        </Badge>
      </div>
      {otpRequiredForLogin && (
        <RecoveryCodes>
          <Text16 color="var(--c-white)">
            <FormattedMessage
              id="accountSecurityCheck.2fa.codes"
              defaultMessage="Recovery codes"
            />
          </Text16>
          <Badge enabled>
            <Caption bold>
              <FormattedMessage {...glossary.viewed} />
            </Caption>
          </Badge>
        </RecoveryCodes>
      )}
      {!otpRequiredForLogin && (
        <Text16 color="var(--c-link)">
          <button
            type="button"
            onClick={() => setSecurityCheckTab(SecurityCheckTab.ENABLE_2FA)}
          >
            <FormattedMessage
              id="accountSecurityCheck.2fa.enable"
              defaultMessage="Enable 2FA"
            />
          </button>
        </Text16>
      )}
    </Wrapper>
  );
};
