import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { SorareLogo } from '@sorare/core/src/atoms/icons/SorareLogo';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Title2 } from '@sorare/core/src/atoms/typography';
import { LANDING } from '@sorare/core/src/constants/__generated__/routes';

const Root = styled(Vertical).attrs({ gap: 2 })`
  background-color: var(--c-brand-800);
  color: var(--c-white);
  padding: var(--double-unit);
`;
const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WhiteLink = styled(Link)`
  &,
  &:focus,
  &:hover {
    color: var(--c-white);
  }
`;

type Props = { displayTitle?: boolean };
const Header = ({ displayTitle }: Props) => {
  return (
    <Root>
      <Navbar>
        <Horizontal>
          <SorareLogo />
        </Horizontal>

        <Horizontal gap={0.5}>
          <WhiteLink to={LANDING}>sorare.com</WhiteLink>
        </Horizontal>
      </Navbar>

      <LabelM color="var(--c-nd-600)">sorare.com / submit a request</LabelM>

      {displayTitle && (
        <Title2>
          <FormattedMessage
            id="NoCardEntryForm.title"
            defaultMessage="Submit a request"
          />
        </Title2>
      )}
    </Root>
  );
};

export default Header;
