import classnames from 'classnames';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { range } from 'lib/arrays';

const Root = styled.div`
  --size: 6px;
  --padding: calc(var(--unit) / 2);
  --paddingBox: calc(var(--size) + 2 * var(--padding));
  display: flex;
`;
const Dot = styled(ButtonBase)`
  opacity: 0.3;
  padding: var(--padding);
  cursor: pointer;
  &::before {
    content: '';
    display: block;
    background: currentColor;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
  }
  &:hover {
    opacity: 0.8;
  }
  &.selected {
    opacity: 1;
  }
`;

type Props = {
  titles: string[];
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
};

export const Dots = ({ selectedIndex, titles, setSelectedIndex }: Props) => {
  if (titles.length < 1) {
    return null;
  }
  return (
    <Root>
      {range(titles.length).map((_, index) => {
        const title = titles[index];
        return (
          <Dot
            // We need to use index and not slotname here as we reuse slots
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={title}
            className={classnames({ selected: index === selectedIndex })}
            onClick={() => setSelectedIndex(index)}
          />
        );
      })}
    </Root>
  );
};
