import { Navigate } from '@sorare/routing';

import {
  ANY_SPORT_MY_CARDS_CARDS,
  ANY_SPORT_MY_CARDS_COLLECTIONS,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import useLifecycle, { LIFECYCLE } from '@sorare/core/src/hooks/useLifecycle';

export const MyCardsPage = () => {
  const { lifecycle } = useLifecycle();
  const { generateSportContextPath, sport } = useSportContext();
  const { blockchainCardsCount } = useCurrentUserContext();

  const lastVisitedTab = lifecycle?.[LIFECYCLE.lastVisitedMyCardsTab]?.[sport];
  const tabToVisit =
    !blockchainCardsCount || lastVisitedTab === 'myCards'
      ? ANY_SPORT_MY_CARDS_CARDS
      : ANY_SPORT_MY_CARDS_COLLECTIONS;

  return (
    <Navigate
      replace
      to={generateSportContextPath(tabToVisit)}
      keepSearchParams
    />
  );
};
