import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';

import ItemOwnerSince from 'components/ItemPreview/ItemOwnerSince';
import { ItemForSaleSecondRow } from 'components/ItemPreview/ui';
import { OwnerMyClubLink } from 'components/TokenPreview/TokenOwner';
import TokenOwnerTransfer from 'components/token/TokenOwnerTransfer';

import { ItemSold_anyCard } from './__generated__/index.graphql';

export interface Props {
  card: ItemSold_anyCard;
  hideOwner?: boolean;
  galleryOwnerSlug?: string;
  disableSportSpecific?: boolean;
}

const Container = styled(Vertical).attrs({ gap: 0 })`
  align-items: flex-start;
  & > *:first-child {
    margin-bottom: 5;
  }
  & > *:nth-child(2) {
    margin-bottom: var(--unit);
  }
`;

const TransferInProgress = styled.p`
  height: 40;
  width: 100%;
  font: var(--t-12);
  color: var(--c-nd-600);
`;

export const ItemSold = ({
  card,
  hideOwner,
  galleryOwnerSlug,
  disableSportSpecific,
}: Props) => {
  const { tokenOwner } = card;

  return (
    <Container>
      {!disableSportSpecific && <TokenOwnerTransfer tokenOwner={tokenOwner} />}
      <ItemForSaleSecondRow>
        <ItemOwnerSince card={card} />
      </ItemForSaleSecondRow>
      {!hideOwner && tokenOwner?.user && (
        <ItemForSaleSecondRow>
          <OwnerMyClubLink
            user={tokenOwner.user}
            sport={card.sport}
            withAvatar
          />
        </ItemForSaleSecondRow>
      )}
      {tokenOwner?.user &&
        hideOwner &&
        galleryOwnerSlug !== tokenOwner.user.slug && (
          <TransferInProgress>
            <FormattedMessage
              id="ItemSold.transferInProgress"
              defaultMessage="Transfer in progress"
            />
          </TransferInProgress>
        )}
    </Container>
  );
};

ItemSold.fragments = {
  anyCard: gql`
    fragment ItemSold_anyCard on AnyCardInterface {
      slug
      sport
      tokenOwner {
        id
        user {
          slug
          ...OwnerMyClubLink_User
        }
        ...TokenOwnerTransfer_owner
      }
      ...ItemOwnerSince_anyCard
    }
    ${ItemOwnerSince.fragments.anyCard}
    ${OwnerMyClubLink.fragments.user}
    ${TokenOwnerTransfer.fragments.tokenOwner}
  ` as TypedDocumentNode<ItemSold_anyCard>,
};

export default ItemSold;
