import { Suspense } from 'react';
import styled from 'styled-components';

import { lazy } from '@sorare/core/src/lib/retry';

import snowUrl from './assets/snow.png';

const SnowBackground = lazy(async () =>
  import('@sorare/trois/src/components/SnowBackground').then(module => ({
    default: module.SnowBackground,
  }))
);

const SnowPicture = styled.div`
  position: absolute;
  inset: 0;
  background-image: url(${snowUrl});

  background-size: cover;
  background-color: var(--c-nd-50-opaque);
`;
const SnowWrapper = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
`;

export const Snow = () => (
  <SnowWrapper>
    <Suspense fallback={<SnowPicture />}>
      <SnowBackground layers={10} fallback={<SnowPicture />} />
    </Suspense>
  </SnowWrapper>
);
