import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { BodyM, BodyS, LabelS } from '@sorare/core/src/atoms/typography';
import { CardItem } from '@sorare/core/src/components/card/CardItem';
import { PlayerGameScoreScore } from '@sorare/core/src/components/scoring/PlayerGameScoreScore';
import {
  ANY_SPORT_CARDS_SLUG,
  ANY_SPORT_PLAYERS_SLUG,
} from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { uniqueBy } from '@sorare/core/src/lib/arrays';
import { fantasy } from '@sorare/core/src/lib/glossary';

import {
  BriefPlayerScore_anyCardInterface,
  BriefPlayerScore_anyPlayerInterface,
  BriefPlayerScore_playerGameScoreInterface,
} from './__generated__/index.graphql';

const StyledHorizontal = styled(Horizontal)`
  &.showScoreDetails {
    align-items: start;
  }
`;
const ImgWrapper = styled.div`
  position: relative;
`;

export type Props = {
  pictureUrl: string;
  anyCard?: BriefPlayerScore_anyCardInterface | null;
  cardBadge?: ReactNode;
  anyPlayer: BriefPlayerScore_anyPlayerInterface;
  playerGameScores: BriefPlayerScore_playerGameScoreInterface[];
  bonus?: number | null;
  children: ReactNode;
  disableLinks?: boolean;
  scoreWithoutBonus?: ReactNode;
};

export const BriefPlayerScore: FC<PropsWithChildren<Props>> = ({
  pictureUrl,
  anyCard,
  cardBadge,
  anyPlayer,
  playerGameScores,
  bonus,
  children,
  disableLinks,
  scoreWithoutBonus,
}: Props) => {
  const { generateSportContextPath } = useSportContext();

  const uniqueTeams = uniqueBy(
    team => team.slug,
    playerGameScores.map(pgs => pgs.anyPlayerGameStats.anyTeam)
  );

  const bonusPercent = bonus ? bonus - 1 : 0;
  const showScoreDetails =
    (playerGameScores.length === 1 || !!scoreWithoutBonus) && !!bonusPercent;

  const img = (
    <ImgWrapper>
      <img src={pictureUrl || ''} alt="" width="60px" />
      {cardBadge}
    </ImgWrapper>
  );
  const playerName = (
    <BodyM as="p" bold>
      {anyPlayer.displayName}
    </BodyM>
  );

  return (
    <SBHorizontal>
      <StyledHorizontal gap={2} className={classNames({ showScoreDetails })}>
        {anyCard && !disableLinks ? (
          <Link
            to={generateSportContextPath(ANY_SPORT_CARDS_SLUG, {
              params: { slug: anyCard.slug },
            })}
          >
            {img}
          </Link>
        ) : (
          img
        )}
        <Vertical gap={1}>
          {showScoreDetails && (
            <Horizontal>
              <Tooltip
                placement="top"
                title={
                  <LabelS as="p">
                    <FormattedMessage {...fantasy.playerScore} />
                  </LabelS>
                }
              >
                {scoreWithoutBonus || (
                  <PlayerGameScoreScore playerGameScore={playerGameScores[0]} />
                )}
              </Tooltip>
              <Tooltip
                placement="top"
                title={
                  <LabelS as="p">
                    <FormattedMessage {...fantasy.cardBonus} />
                  </LabelS>
                }
              >
                <CardItem
                  value={
                    <FormattedNumber
                      value={bonusPercent}
                      style="percent"
                      signDisplay="always"
                      maximumFractionDigits={1}
                    />
                  }
                />
              </Tooltip>
            </Horizontal>
          )}
          <Vertical gap={0}>
            {disableLinks ? (
              playerName
            ) : (
              <Link
                to={generateSportContextPath(ANY_SPORT_PLAYERS_SLUG, {
                  params: { slug: anyPlayer.slug },
                })}
              >
                {playerName}
              </Link>
            )}
            <BodyS as="p">
              {uniqueTeams.map(team => team.name).join(', ')}
            </BodyS>
          </Vertical>
        </Vertical>
      </StyledHorizontal>
      {children}
    </SBHorizontal>
  );
};

BriefPlayerScore.fragments = {
  anyCardInterface: gql`
    fragment BriefPlayerScore_anyCardInterface on AnyCardInterface {
      slug
    }
  ` as TypedDocumentNode<BriefPlayerScore_anyCardInterface>,
  anyPlayerInterface: gql`
    fragment BriefPlayerScore_anyPlayerInterface on AnyPlayerInterface {
      slug
      displayName
    }
  ` as TypedDocumentNode<BriefPlayerScore_anyPlayerInterface>,
  playerGameScoreInterface: gql`
    fragment BriefPlayerScore_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      anyPlayerGameStats {
        id
        anyTeam {
          slug
          name
        }
      }
      ...PlayerGameScoreScore_playerGameScoreInterface
    }
    ${PlayerGameScoreScore.fragments.playerGameScoreInterface}
  ` as TypedDocumentNode<BriefPlayerScore_playerGameScoreInterface>,
};
