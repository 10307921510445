import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useMemo } from 'react';

import {
  NullableTypographyVariant,
  Text16,
  TypographyVariant,
} from '@sorare/core/src/atoms/typography';
import { CardDescriptionFromProps } from '@sorare/core/src/components/token/CardDescriptionFromProps';
import { TokenMetas } from '@sorare/core/src/components/token/TokenMetas';
import { LEGACY_PLAYER_SHOW } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { Color } from '@sorare/core/src/style/types';

import { CardDescription_anyCard } from './__generated__/index.graphql';

type Props = {
  card: CardDescription_anyCard;
  separator?: string;
  Title?: TypographyVariant;
  titleColor?: Color;
  Details?: NullableTypographyVariant;
  detailsColor?: Color;
  withoutLink?: boolean;
  row?: boolean;
  bonus?: ReactNode;
};

const CardDescription = ({
  card,
  Title,
  titleColor = 'var(--c-white)',
  separator = ' \u2013 ',
  Details = Title,
  detailsColor = titleColor,
  withoutLink = false,
  bonus,
  ...styleProps
}: Props) => {
  const { anyPlayer: player, sport } = card;

  const TokenMetasDatas = useMemo(() => {
    return <TokenMetas card={card} separator={separator} />;
  }, [card, separator]);

  const { generateSportContextPath } = useSportContext();

  return (
    <CardDescriptionFromProps
      displayName={player.displayName}
      path={generateSportContextPath(LEGACY_PLAYER_SHOW, {
        params: { slug: player.slug },
        sport,
      })}
      description={TokenMetasDatas}
      withoutLink={withoutLink}
      Details={Details}
      detailsColor={detailsColor}
      Title={Title || Text16}
      titleColor={titleColor}
      bonus={bonus}
      {...styleProps}
    />
  );
};

CardDescription.fragments = {
  anyCard: gql`
    fragment CardDescription_anyCard on AnyCardInterface {
      slug
      sport
      anyPlayer {
        slug
        displayName
      }
      ...TokenMetas_anyCard
    }
    ${TokenMetas.fragments.anyCard}
  ` as TypedDocumentNode<CardDescription_anyCard>,
};

export default CardDescription;
