import styled from 'styled-components';

export const VerticalDivider = styled.div`
  width: 1px;
  height: 1em;
  margin: 0 var(--unit);
  background-color: var(--c-white);
`;

export const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--c-nd-200);
`;
