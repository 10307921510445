import { TypedDocumentNode, gql } from '@apollo/client';
import { Suspense, useMemo } from 'react';
import {
  Navigate,
  Outlet,
  generatePath,
  useOutletContext,
} from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { MLB_PLAY_PRO_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { getFixtureQueryVariables } from '@sorare/gameplay/src/lib/so5';

import {
  BaseballPlayProFixtureChaseLayoutQuery,
  BaseballPlayProFixtureChaseLayoutQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const query = gql`
  query BaseballPlayProFixtureChaseLayoutQuery($type: So5State, $slug: String) {
    so5 {
      so5Fixture(type: $type, slug: $slug, sport: BASEBALL) {
        slug
        gameWeek
        so5Leaderboards {
          slug
          rarityType
          universalSo5UserGroups {
            slug
            rarityType
          }
        }
      }
    }
  }
` as TypedDocumentNode<
  BaseballPlayProFixtureChaseLayoutQuery,
  BaseballPlayProFixtureChaseLayoutQueryVariables
>;

type LayoutData = {
  chaseLeaderboards: NonNullable<
    BaseballPlayProFixtureChaseLayoutQuery['so5']['so5Fixture']
  >['so5Leaderboards'];
};

export const useChaseLayoutData = () => {
  return useOutletContext<LayoutData>();
};

const PlayProFixtureChaseLayout = () => {
  const { fixture: fixtureSlug } = useTypedParams<LayoutParams>();

  const { data } = useQuery(query, {
    variables: {
      ...getFixtureQueryVariables(fixtureSlug),
    },
  });

  const chaseLeaderboards = useMemo(() => {
    return (
      data?.so5.so5Fixture?.so5Leaderboards.filter(
        l => l.universalSo5UserGroups.length > 0
      ) ?? []
    );
  }, [data?.so5.so5Fixture?.so5Leaderboards]);

  if (data && chaseLeaderboards.length === 0) {
    return (
      <Navigate
        to={generatePath(MLB_PLAY_PRO_FIXTURE, { fixture: fixtureSlug })}
        replace
      />
    );
  }

  return (
    <Suspense fallback={<LoadingIndicator fullHeight />}>
      <Outlet context={{ chaseLeaderboards }} />
    </Suspense>
  );
};

export default PlayProFixtureChaseLayout;
