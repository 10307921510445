import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';

import { IconButton } from 'atoms/buttons/IconButton';
import { glossary } from 'lib/glossary';

type Props = {
  onClose: () => void;
  transparent?: boolean;
  className?: string;
};

export const CloseButton = ({ onClose, transparent, className }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <IconButton
      onClick={onClose}
      icon={faTimes}
      color={transparent ? 'transparent' : 'tertiary'}
      aria-label={formatMessage(glossary.close)}
      className={className}
    />
  );
};
