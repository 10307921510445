import { TypedDocumentNode, gql } from '@apollo/client';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ShirtSize } from '@sorare/core/src/__generated__/globalTypes';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { CoinAmount } from '@sorare/core/src/components/clubShop/CoinAmount';
import {
  GoogleReCAPTCHA,
  ReCAPTCHA,
} from '@sorare/core/src/components/recaptcha';
import { PostalAddressForm as PostalAdressFormDumb } from '@sorare/core/src/components/settings/PostalAddressForm';

import { PostalAdressForm_shopItem } from './__generated__/index.graphql';
import useBuyDeliverableShopItem from './useBuyDeliverableShopItem';

const Root = styled(Vertical).attrs({ gap: 2 })`
  flex: 1;
`;

const ItemImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
`;
const ItemDetails = styled(SBHorizontal)`
  width: 100%;
  padding: var(--unit);
  background-color: var(--c-nd-100);
  border-radius: var(--intermediate-unit);
`;
const ButtonWrapper = styled.div`
  display: inline-flex;
  margin: auto;
`;

type Props = {
  item: PostalAdressForm_shopItem;
  selectedSize?: ShirtSize;
  nextStep: () => void;
};
const PostalAdressForm = ({ item, selectedSize, nextStep }: Props) => {
  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);
  const [validFormValues, setValidFormValues] = useState(false);
  const buyDeliverableShopItem = useBuyDeliverableShopItem();
  const realPrice = item.salePrice ?? item.price;

  return (
    <Root>
      <ItemDetails>
        <Horizontal>
          <ItemImage src={item.pictureUrl} alt={item.name} />
          {item.name} {selectedSize && `- ${selectedSize}`}
        </Horizontal>
        <CoinAmount amount={realPrice} />
      </ItemDetails>
      <PostalAdressFormDumb
        onSubmit={(attributes, onResult) => {
          recaptchaRef.current?.reset();
          recaptchaRef.current?.executeAsync().then(recaptchaTokenV2 => {
            buyDeliverableShopItem({
              shopItemId: item.id,
              shirtSize: selectedSize,
              postalAddress: {
                ...attributes,
              },
              recaptchaTokenV2,
            }).then(onResult);
          });
        }}
        onSuccess={nextStep}
        onChange={({
          countryCode,
          firstName,
          lastName,
          streetAddress,
          zipcode,
          city,
        }) =>
          setValidFormValues(
            !!(
              countryCode &&
              firstName &&
              lastName &&
              streetAddress &&
              zipcode &&
              city
            )
          )
        }
        button={SubmitButton => (
          <ButtonWrapper>
            <SubmitButton disabled={!validFormValues} size="medium">
              <FormattedMessage
                id="ItemPreviewDialog.PostalAddressForm.Cta.confirm"
                defaultMessage="Confirm your purchase"
              />
            </SubmitButton>
          </ButtonWrapper>
        )}
      />
      <ReCAPTCHA ref={recaptchaRef} />
    </Root>
  );
};

PostalAdressForm.fragments = {
  shopItem: gql`
    fragment PostalAdressForm_shopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
        name
        price
        salePrice
        pictureUrl
      }
    }
  ` as TypedDocumentNode<PostalAdressForm_shopItem>,
};

export default PostalAdressForm;
