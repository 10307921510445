import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { FOOTBALL_MY_CARDS_COLLECTION_COLLECTIONSLUG_CARDS } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const CollectionPage = () => {
  const { collectionSlug } = useTypedParams<PageParams>();

  return (
    <Navigate
      to={generatePath(FOOTBALL_MY_CARDS_COLLECTION_COLLECTIONSLUG_CARDS, {
        collectionSlug,
      })}
      replace
    />
  );
};

export default CollectionPage;
