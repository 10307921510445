import { ReactNode } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { RainbowBox } from 'atoms/layout/RainbowBox';

const Root = styled(RainbowBox)`
  --inside: linear-gradient(var(--c-nd-50), var(--c-nd-50));
  display: inline-flex;
  align-items: center;
  gap: var(--half-unit);
  color: var(--c-white);
  font-weight: var(--t-bold);
  border: 2px solid transparent;
  border-radius: var(--double-unit);
  box-sizing: border-box;
  padding: 0 var(--unit);
`;

type Props = {
  children: ReactNode;
  to: string;
};

export const ClubShopButton = ({ children, to }: Props) => {
  return (
    <Root to={to} as={Link}>
      {children}
    </Root>
  );
};
