import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FiatCurrency } from '__generated__/globalTypes';
import { FontAwesomeIcon } from 'atoms/icons';
import { FormControlLabel } from 'atoms/inputs/FormControlLabel';
import { NativeSelect } from 'atoms/inputs/NativeSelect';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelM, Text16, Title3 } from 'atoms/typography';
import { GraphQLResult, GraphqlForm } from 'components/form/Form';
import { useIntlContext } from 'contexts/intl';
import { glossary } from 'lib/glossary';

import { StepWrapper } from '../StepWrapper';
import { LEARN_MORE_ABOUT_FIAT_WALLET } from '../externalLinks';
import { CreateFiatWalletSteps } from '../type';
import { useCurrencyOptions } from '../useCurrencyOptions';

const Content = styled(Vertical).attrs({ gap: 3 })`
  justify-content: flex-start;
  height: 100%;
`;

const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-grow: 1;
`;

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: var(--c-nd-600);
`;

const Block = styled(Horizontal).attrs({ gap: 2 })`
  padding: var(--intermediate-unit) var(--double-unit);
  border-radius: var(--double-unit);
  background-color: var(--c-nd-100);
`;

const Asterisk = styled.span`
  color: var(--c-red-600);
`;

type Props = {
  presetCurrency?: FiatCurrency;
  onChange: (currency: FiatCurrency) => void;
  onSubmit: () => Promise<GraphQLResult>;
  onSuccess?: () => void;
  setStep: (step: CreateFiatWalletSteps) => void;
};

export const ChooseCurrency = ({
  presetCurrency = undefined,
  onSubmit: doOnSubmit,
  onChange,
  setStep,
  onSuccess,
}: Props) => {
  const { formatMessage } = useIntlContext();
  const [currency, setCurrency] = useState<FiatCurrency | undefined>(
    presetCurrency
  );
  const currencies = useCurrencyOptions();

  const onSubmit = async (
    values: any,
    onResult: (result: GraphQLResult) => void
  ) => {
    const res = await doOnSubmit();
    onResult(res);
  };

  const disabled = !currency;

  return (
    <StyledGraphqlForm
      onSubmit={(variables, onResult) => {
        onSubmit(variables, onResult);
      }}
      onSuccess={() => {
        if (onSuccess) onSuccess();
        setStep(CreateFiatWalletSteps.REVIEW_INFORMATION);
      }}
      render={(Error, SubmitButton) => (
        <StepWrapper
          setStep={setStep}
          step={CreateFiatWalletSteps.CHOOSE_CURRENCY}
          submitButton={
            <SubmitButton size="medium" disabled={disabled}>
              <FormattedMessage {...glossary.next} />
            </SubmitButton>
          }
        >
          <Content>
            <>
              <Title3>
                <FormattedMessage
                  id="createFiatWallet.chooseCurrency.title"
                  defaultMessage="Choose your currency"
                />
              </Title3>
              <Text16 color="var(--c-nd-600)">
                <FormattedMessage
                  id="createFiatWallet.chooseCurrency.description"
                  defaultMessage="Choose the currency you prefer for your Cash Wallet. Card prices will also be shown in the currency you select. <link>Learn more</link>"
                  values={{
                    link: (...children) => (
                      <StyledLink
                        href={LEARN_MORE_ABOUT_FIAT_WALLET}
                        target="_blank"
                      >
                        {children}
                      </StyledLink>
                    ),
                  }}
                />
              </Text16>
              <Block>
                <FontAwesomeIcon icon={faInfoCircle} color="var(--c-nd-600)" />
                <LabelM color="var(--c-nd-700)" bold>
                  <FormattedMessage
                    id="createFiatWallet.currency.info"
                    defaultMessage="Once selected, you cannot change it."
                  />
                </LabelM>
              </Block>
              <FormControlLabel
                name="currency"
                labelPlacement="top"
                control={
                  <NativeSelect
                    fullWidth
                    placeholder={formatMessage({
                      id: 'createFiatWallet.chooseCurrency.placeholder',
                      defaultMessage: 'Choose your currency',
                    })}
                    name="currency"
                    values={currencies}
                    value={currency ?? ''}
                    onChange={value => {
                      setCurrency(value as FiatCurrency);
                      onChange(value as FiatCurrency);
                    }}
                  />
                }
                label={
                  <Text16 bold color="var(--c-white)">
                    <FormattedMessage
                      id="createFiatWallet.currency"
                      defaultMessage="Currency"
                    />{' '}
                    <Asterisk>*</Asterisk>
                  </Text16>
                }
              />
              <Error />
            </>
          </Content>
        </StepWrapper>
      )}
    />
  );
};
