import classNames from 'classnames';
import styled from 'styled-components';

import {
  Text14,
  Text16,
  TypographyVariant,
} from '@sorare/core/src/atoms/typography';
import {
  Props as UseAmountWithConversionProps,
  useAmountWithConversion,
} from '@sorare/core/src/hooks/useAmountWithConversion';
import { Color } from '@sorare/core/src/style/types';

type Props = UseAmountWithConversionProps & {
  color?: Color;
  bold?: boolean;
  hideExponent?: boolean;
  inline?: boolean;
  reverse?: boolean;
  MainAmountComponent?: TypographyVariant;
};

const Root = styled.div<{ inline?: boolean }>`
  &.inline {
    display: flex;
    gap: var(--unit);
    flex-wrap: wrap;
    align-items: baseline;

    &.reverse {
      flex-direction: row-reverse;
    }
  }
`;

export const PaymentBoxAmountWithConversion = ({
  bold = false,
  color,
  hideExponent = false,
  inline,
  reverse,
  MainAmountComponent = Text16,
  ...props
}: Props) => {
  const { main, exponent } = useAmountWithConversion(props);

  return (
    <Root className={classNames({ inline, reverse, 'text-right': true })}>
      <MainAmountComponent bold={bold} color={color || 'var(--c-white)'}>
        {main}
      </MainAmountComponent>
      {!hideExponent && exponent && (
        <Text14 color={color || 'var(--c-nd-600)'}>{exponent}</Text14>
      )}
    </Root>
  );
};
