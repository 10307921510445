import styled from 'styled-components';

export const RewardName = styled.div<{ small?: boolean }>`
  color: #ff2b2b;
  text-align: center;
  text-shadow: 3.068px 1.753px 30.678px #f00;
  font-family: var(--rigid-square);
  font-size: ${({ small }) => (small ? '24px' : '48px')};
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.96px;
  text-transform: uppercase;
`;
