import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

import { unitMapping } from 'lib/style';

const Root = styled.span`
  isolation: isolate;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;
`;

const AbsoluteSpan = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  z-index: 1;
  border-radius: var(--intermediate-unit);
  color: var(--c-white);
  background-color: var(--c-red-600);
  font: var(--t-12);
  font-weight: var(--t-bold);
  min-width: var(--double-and-a-half-unit);
  height: var(--double-and-a-half-unit);
  transform: scale(1) translate(50%, -50%);
  transition: transform 0.2s ease-in-out;

  &.circularOverlap {
    top: 14%;
    right: 14%;
  }

  &.dot {
    min-width: var(--unit);
    height: var(--unit);
  }

  &.invisible {
    transform: scale(0);
  }

  &.blue {
    background-color: var(--c-brand-600);
  }
`;

type Props = {
  children?: ReactNode;
  variant?: 'dot' | 'standard';
  overlap?: 'circular' | 'rectangular';
  badgeContent?: ReactNode;
  invisible?: boolean;
  className?: string;
  top?: keyof typeof unitMapping;
  right?: keyof typeof unitMapping;
  color?: 'red' | 'blue';
  'aria-label'?: string;
};
export const Badge = forwardRef<HTMLElement, Props>(function Badge(props, ref) {
  const {
    children,
    variant = 'standard',
    overlap = 'rectangular',
    badgeContent,
    invisible,
    className,
    top,
    right,
    color = 'red',
    'aria-label': ariaLabel,
  } = props;
  const hideBadge = invisible || (!badgeContent && variant !== 'dot');

  const content =
    typeof badgeContent === 'number' && badgeContent > 99
      ? '99+'
      : badgeContent;

  return (
    <Root className={className} aria-label={ariaLabel} ref={ref}>
      {children}
      <AbsoluteSpan
        className={classNames({
          circularOverlap: overlap === 'circular',
          dot: variant === 'dot',
          invisible: hideBadge,
          blue: color === 'blue',
        })}
        style={{
          top: top ? unitMapping[top] : undefined,
          right: right ? unitMapping[right] : undefined,
        }}
      >
        {content}
      </AbsoluteSpan>
    </Root>
  );
});
