import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';
import { LabelM } from 'atoms/typography';
import { Color } from 'style/types';

const Root = styled(ButtonBase)`
  position: relative;
  isolation: isolate;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--c-white);
  &:hover,
  &:focus {
    color: var(--c-white);
  }
`;
const RoundedButtonIcon = styled.div<{ color: Color }>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  border-radius: 50%;
  overflow: hidden;
  background: ${({ color }) => color};
  font-size: 24px;
  margin-bottom: var(--half-unit);
  cursor: pointer;
  ${Root} &:hover {
    filter: brightness(1.1);
    color: var(--c-white);
  }
  ${Root}:disabled & {
    opacity: 0.5;
  }
`;
const appearIn = keyframes`
  30%, 99% {
    opacity: 1;
    transform: translateY(-100%);
  }
  100% {
    opacity: 0;
  }
`;
const PopMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  opacity: 0;
  transform: translateY(-50%);
  animation: ${appearIn} 1s ease infinite;
  color: var(--c-white);
`;

type Props = {
  onClick: () => void;
  label: string | MessageDescriptor;
  color: Color;
  icon: IconDefinition;
  href?: string;
  download?: boolean;
  disabled?: boolean;
  popMessage?: ReactNode;
};

export const RoundedButton = ({
  onClick,
  label,
  color = 'var(--c-nd-200-opaque)',
  icon,
  href,
  download,
  disabled,
  popMessage,
}: Props) => {
  const tagProps = href
    ? { href, target: '_blank', rel: 'noopener noreferrer', download }
    : { disabled };
  return (
    <Root onClick={onClick} as={href ? 'a' : 'button'} {...tagProps}>
      {popMessage && <PopMessage>{popMessage}</PopMessage>}
      <RoundedButtonIcon color={color}>
        <FontAwesomeIcon icon={icon} />
      </RoundedButtonIcon>
      <LabelM>
        {typeof label === 'string' ? label : <FormattedMessage {...label} />}
      </LabelM>
    </Root>
  );
};
