import type {
  OperationVariables,
  PreloadQueryFetchPolicy,
  TypedDocumentNode,
} from '@apollo/client';
import type { Params } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import { preloadQuery } from 'contexts/graphql/Provider';
import { stripPrefix } from 'i18n/useTranslations';
import { sportFromPathAndLocalStorage } from 'lib/routing/sportFromPathAndLocalStorage';

import { shouldRevalidateFn } from './shouldRevalidateFn';

export const queryLoader = <
  TParams extends Params,
  TData = any,
  TVariables extends OperationVariables = any,
>(
  query: TypedDocumentNode<TData, TVariables>,
  getVariables?: (
    options: TParams & {
      sport: Sport;
      searchParams: URLSearchParams;
    }
  ) => TVariables,
  loaderOptions?: {
    fetchPolicy?: PreloadQueryFetchPolicy;
    preventTransitionBeforeLoaded?: boolean;
  }
) => {
  const getVariablesFn = ({
    params,
    requestUrl,
  }: {
    params: TParams;
    requestUrl: URL;
  }) => {
    return getVariables?.({
      ...params,
      sport: sportFromPathAndLocalStorage(stripPrefix(requestUrl.pathname)),
      searchParams: requestUrl.searchParams,
    });
  };

  const loaderFn = async ({
    params,
    request,
  }: {
    params: TParams;
    request: Request;
  }) => {
    const { fetchPolicy, preventTransitionBeforeLoaded } = loaderOptions || {};

    const preloadedQueryRef = preloadQuery.current!(
      query,
      // @ts-expect-error this apollo version types badly PreloadQueryFunction
      // so an {} object is needed even when the query asks for no variables
      {
        fetchPolicy:
          fetchPolicy ||
          // Default fetchPolicy is 'cache-first', override to 'cache-and-network'
          'cache-and-network',
        variables: getVariablesFn({ params, requestUrl: new URL(request.url) }),
      }
    );

    return preventTransitionBeforeLoaded
      ? preloadedQueryRef.toPromise()
      : preloadedQueryRef;
  };

  loaderFn.shouldRevalidate = getVariables
    ? shouldRevalidateFn(
        (params, requestUrl) =>
          getVariablesFn({
            params,
            requestUrl,
          }) || {}
      )
    : undefined;

  return loaderFn;
};
