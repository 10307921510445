import { NotificationPreferences } from '@sorare/core/src/components/settings/NotificationPreferences';
import {
  SettingsPage,
  TITLES,
} from '@sorare/core/src/components/settings/pages';

import { SettingsWrapper } from 'components/settings/SettingsWrapper';

export const Notifications = () => {
  return (
    <SettingsWrapper pageTitle={TITLES[SettingsPage.NOTIFICATIONS]}>
      <NotificationPreferences />
    </SettingsWrapper>
  );
};

export default Notifications;
