import { TypedDocumentNode, gql } from '@apollo/client';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedTime,
} from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { LiveDot } from '@sorare/core/src/components/live/LiveDot';
import { HeaderTeamsMatchup as CoreHeader } from '@sorare/core/src/components/rivals/HeaderTeamsMatchup';
import { isType } from '@sorare/core/src/gql';
import { fantasy } from '@sorare/core/src/lib/glossary';

import {
  getTeamName,
  isGameFinished,
  isGameLive,
} from '@sorare/gameplay/src/lib/so5';

import { HeaderTeamsMatchup_game } from './__generated__/index.graphql';

const SubtitleWrapper = styled(Horizontal)`
  border-radius: var(--half-unit);
  padding: var(--half-unit);
`;
const GreyWrapper = styled(SubtitleWrapper)`
  background-color: var(--c-nd-150);
  color: var(--c-white);
`;
const LiveWrapper = styled(SubtitleWrapper)`
  background-color: rgba(var(--c-rgb-red-600), 0.16);
  color: var(--c-red-800);
`;

type TitleProps = { game: HeaderTeamsMatchup_game };
const Title = ({ game }: TitleProps) => {
  const { status, date, homeGoals, awayGoals } = game;

  if (isGameLive(status) || isGameFinished(status)) {
    return (
      <Horizontal gap={1}>
        {homeGoals} - {awayGoals}
      </Horizontal>
    );
  }

  return <FormattedTime value={date} hour="numeric" minute="numeric" />;
};

type SubtitleProps = { game: HeaderTeamsMatchup_game };
const Subtitle = ({ game }: SubtitleProps) => {
  const { status, minute, date } = game;

  if (isGameLive(status)) {
    return (
      <LiveWrapper gap={0.5}>
        <LiveDot animate />
        <LabelS as="span">
          <FormattedNumber value={minute} />
          &apos;
        </LabelS>
      </LiveWrapper>
    );
  }

  if (isGameFinished(status)) {
    return (
      <GreyWrapper>
        <LabelS as="span">
          <FormattedMessage {...fantasy.fullTime} />
        </LabelS>
      </GreyWrapper>
    );
  }

  return (
    <GreyWrapper>
      <LabelS as="span">
        <FormattedDate value={date} month="short" day="numeric" />
      </LabelS>
    </GreyWrapper>
  );
};

type Props = {
  game: HeaderTeamsMatchup_game;
  displayRankings?: boolean;
  noLinks?: boolean;
};
export const HeaderTeamsMatchup = ({
  game,
  displayRankings = true,
  noLinks,
}: Props) => {
  const { awayTeam, homeTeam } = game;

  const awayTeamRanking =
    isType(awayTeam, 'Club') && displayRankings
      ? awayTeam.domesticLeagueRanking
      : null;
  const homeTeamRanking =
    isType(homeTeam, 'Club') && displayRankings
      ? homeTeam.domesticLeagueRanking
      : null;

  if (!awayTeam || !homeTeam) {
    return null;
  }

  return (
    <CoreHeader
      noLinks={noLinks}
      awayTeam={{
        slug: awayTeam.slug,
        country: awayTeam.country,
        name: getTeamName(awayTeam),
        avatarUrl: awayTeam.pictureUrl,
        currentRanking: awayTeamRanking,
        isNationalTeam: isType(awayTeam, 'NationalTeam'),
      }}
      homeTeam={{
        slug: homeTeam.slug,
        country: homeTeam.country,
        name: getTeamName(homeTeam),
        avatarUrl: homeTeam.pictureUrl,
        currentRanking: homeTeamRanking,
        isNationalTeam: isType(homeTeam, 'NationalTeam'),
      }}
      subtitle={<Subtitle game={game} />}
      title={<Title game={game} />}
    />
  );
};

HeaderTeamsMatchup.fragments = {
  game: gql`
    fragment HeaderTeamsMatchup_game on Game {
      id
      date
      status: statusTyped
      minute
      homeGoals
      awayGoals
      awayTeam {
        slug
        shortName
        pictureUrl
        country {
          slug
        }
        ... on Club {
          slug
          domesticLeagueRanking
        }
        ...getTeamName_team
      }
      homeTeam {
        slug
        shortName
        pictureUrl
        country {
          slug
        }
        ... on Club {
          slug
          domesticLeagueRanking
        }
        ...getTeamName_team
      }
    }
    ${getTeamName.fragments.team}
  ` as TypedDocumentNode<HeaderTeamsMatchup_game>,
};
