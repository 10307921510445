import { TypedDocumentNode, gql } from '@apollo/client';
import { Suspense } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { useChaseLayoutData } from 'pages/play/pro/_$fixture/postseason/layout';

import {
  BaseballPlayProFixturePostseasonUserGroupLayoutQuery,
  BaseballPlayProFixturePostseasonUserGroupLayoutQueryVariables,
} from './__generated__/layout.graphql';
import { LayoutParams } from './__generated__/routeParams';

const query = gql`
  query BaseballPlayProFixturePostseasonUserGroupLayoutQuery(
    $userGroupSlug: String!
  ) {
    so5 {
      so5UserGroup(slug: $userGroupSlug) {
        slug
        rarityType
        displayName
        topScoresCount
        startGameWeek
        endGameWeek
      }
    }
  }
` as TypedDocumentNode<
  BaseballPlayProFixturePostseasonUserGroupLayoutQuery,
  BaseballPlayProFixturePostseasonUserGroupLayoutQueryVariables
>;

type LayoutData = {
  so5UserGroup: NonNullable<
    BaseballPlayProFixturePostseasonUserGroupLayoutQuery['so5']['so5UserGroup']
  >;
  chaseLeaderboards: ReturnType<typeof useChaseLayoutData>['chaseLeaderboards'];
  layoutLoading: boolean;
};

export const useChaseUserGroupLayoutData = () => {
  return useOutletContext<LayoutData>();
};

const PlayProFixturePostseasonUserGroupLayout = () => {
  const { chaseLeaderboards } = useChaseLayoutData();
  const { userGroup: userGroupSlug } = useTypedParams<LayoutParams>();

  const { data, loading: layoutLoading } = useQuery(query, {
    variables: {
      userGroupSlug,
    },
    skip: chaseLeaderboards.length === 0,
  });

  const so5UserGroup = data?.so5?.so5UserGroup;

  return (
    <Suspense fallback={<LoadingIndicator fullHeight />}>
      <Outlet context={{ chaseLeaderboards, so5UserGroup, layoutLoading }} />
    </Suspense>
  );
};

export default PlayProFixturePostseasonUserGroupLayout;
