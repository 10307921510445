import { TypedDocumentNode, gql } from '@apollo/client';

import {
  GameStatus,
  PlayerFieldStatus,
} from '@sorare/core/src/__generated__/globalTypes';
import { Bench } from '@sorare/core/src/atoms/icons/Bench';
import {
  Score,
  Props as ScoreProps,
} from '@sorare/core/src/components/scoring/Score';
import { isType } from '@sorare/core/src/gql';

import { So5AppearanceScore_so5Appearance } from './__generated__/index.graphql';

type Props = {
  so5Appearance: So5AppearanceScore_so5Appearance;

  shape?: 'TEXT' | 'PILL';
} & Pick<
  ScoreProps,
  | 'withDate'
  | 'withTooltip'
  | 'hideReviewing'
  | 'forceScoreStatus'
  | 'hideClock'
>;

export const So5AppearanceScore = ({
  so5Appearance,
  shape = 'PILL',
  ...rest
}: Props) => {
  const {
    score,
    scoreStatus,
    upcomingGame,
    playedGames,
    eligiblePlayerGameScores,
  } = so5Appearance;

  const playingGamesPgs = eligiblePlayerGameScores.filter(
    pgs => pgs.anyGame.status === GameStatus.playing
  );
  const isBenched =
    !!playingGamesPgs.length &&
    playingGamesPgs.every(
      ({ anyPlayerGameStats }) =>
        isType(anyPlayerGameStats, 'PlayerGameStats') &&
        anyPlayerGameStats.fieldStatus === PlayerFieldStatus.ON_BENCH
    );

  if (shape === 'TEXT' && isBenched) {
    return (
      <div className="text-center">
        <Bench color="var(--c-nd-600)" />
      </div>
    );
  }

  return (
    <Score
      shape={shape}
      score={score}
      scoreStatus={scoreStatus}
      upcomingGame={upcomingGame || undefined}
      hasPlayedGames={playedGames.length > 0}
      {...rest}
    />
  );
};

So5AppearanceScore.fragments = {
  so5Appearance: gql`
    fragment So5AppearanceScore_so5Appearance on So5AppearanceInterface {
      id
      score
      scoreStatus
      upcomingGame {
        id
        ...Score_anyGameInterface
      }
      playedGames {
        id
      }
      eligiblePlayerGameScores {
        id
        anyGame {
          id
          status: statusTyped
        }
        anyPlayerGameStats {
          id
          ... on PlayerGameStats {
            id
            fieldStatus
          }
        }
      }
    }
    ${Score.fragments.anyGameInterface}
  ` as TypedDocumentNode<So5AppearanceScore_so5Appearance>,
};
