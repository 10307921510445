import { TypedDocumentNode, gql } from '@apollo/client';
import { FC, useState } from 'react';
import styled from 'styled-components';

import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { Vertical } from 'atoms/layout/flex';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { CloseButton } from 'components/dialog/CloseButton';
import SimpleSelect from 'components/form/Form/SimpleSelect';
import { useQuery } from 'hooks/graphql/useQuery';
import { useCurrentSport } from 'hooks/useCurrentSport';
import { isType } from 'lib/gql';

import { Content } from './Content';
import { NeonChristmasWrapper } from './NeonChristmasWrapper';
import type {
  RaffleTicketDialogContentQuery,
  RaffleTicketDialogContentQueryVariables,
} from './__generated__/index.graphql';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  left: var(--double-unit);
  top: var(--double-unit);
`;
const query = gql`
  query RaffleTicketDialogContentQuery($sport: Sport) {
    shopItems(sport: $sport, types: [RAFFLE], unlockedOnly: true) {
      nodes {
        ... on RaffleShopItem {
          id
          myBalance
          ...Content_RaffleShopItem
        }
      }
    }
  }
  ${Content.fragments.RaffleShopItem}
` as TypedDocumentNode<
  RaffleTicketDialogContentQuery,
  RaffleTicketDialogContentQueryVariables
>;

const possibleWrappers = {
  'Neon Christmas': NeonChristmasWrapper,
};

type Props = {
  onClose: () => void;
};
export const RaffleTicketDialogContent = ({ onClose }: Props) => {
  const sport = useCurrentSport();
  const { data } = useQuery(query, { variables: { sport } });

  const shopItems = data?.shopItems.nodes ?? [];
  const raffleItems = shopItems
    .filter(t => isType(t, 'RaffleShopItem'))
    .filter(t => t.myBalance > 0);

  const [index, setIndex] = useState(0);
  const raffleItem = raffleItems[index];
  if (raffleItem === undefined) {
    return <LoadingIndicator />;
  }

  const wrapperkey = Object.keys(possibleWrappers).find(pattern =>
    raffleItem.name.includes(pattern)
  ) as keyof typeof possibleWrappers | undefined;
  const Wrapper = wrapperkey && possibleWrappers[wrapperkey];

  return (
    <ConditionalWrapper Wrapper={Wrapper as FC} wrap={!!Wrapper}>
      <StyledCloseButton onClose={onClose} />
      <Vertical center>
        {raffleItems.length > 1 && (
          <SimpleSelect
            options={raffleItems.map(s => ({
              label: s.name,
              value: s.id,
            }))}
            value={raffleItem.id}
            onChange={event => {
              setIndex(
                Math.max(
                  raffleItems.findIndex(
                    s => s.id === (event.target as HTMLInputElement).value
                  ),
                  0
                )
              );
            }}
            name="shopItem"
          />
        )}
        <Content raffleTicket={raffleItem} />
      </Vertical>
    </ConditionalWrapper>
  );
};
