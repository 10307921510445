import { TypedDocumentNode, gql } from '@apollo/client';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, Text18 } from '@sorare/core/src/atoms/typography';
import { OpenItemDialogLink } from '@sorare/core/src/components/link/OpenItemDialogLink';
import { getHumanReadableSerialNumber } from '@sorare/core/src/lib/cards';

import FlexCard from 'components/token/FlexCard';
import { useClickCardEvent } from 'hooks/events/useClickCardEvent';

import { CardPreview_anyCard } from './__generated__/index.graphql';

const CardContainer = styled(Vertical)`
  height: 100%;
  padding: var(--double-unit);
  background-color: var(--c-nd-100);
  flex: 1;
  border-radius: var(--unit);
`;
const CardInfoContainer = styled(Vertical).attrs({ gap: 2 })`
  align-items: flex-start;
`;
const PlayerName = styled(Text18)`
  color: var(--c-white);
`;
const TokenDetails = styled(LabelM)`
  color: var(--c-white);
`;

const CardPreview = ({
  card,
  cardPositions,
  cardProperties,
}: {
  card: CardPreview_anyCard;
  cardPositions?: ReactNode;
  cardProperties: ReactNode;
}) => {
  const trackClickCard = useClickCardEvent();

  return (
    <OpenItemDialogLink
      item={card}
      onClick={() => trackClickCard(card, false)}
      sport={card.sport}
    >
      <CardContainer key={card.slug}>
        <FlexCard card={card} />
        <CardInfoContainer>
          {cardProperties}
          <div>
            <PlayerName>
              <strong>{card.anyPlayer.displayName}</strong>
            </PlayerName>
            <TokenDetails>
              {cardPositions}
              {getHumanReadableSerialNumber(card, {
                separator: ' • ',
              })}
            </TokenDetails>
          </div>
        </CardInfoContainer>
      </CardContainer>
    </OpenItemDialogLink>
  );
};

CardPreview.fragments = {
  anyCard: gql`
    fragment CardPreview_anyCard on AnyCardInterface {
      slug
      sport
      anyPlayer {
        slug
        displayName
      }
      ...FlexCard_anyCard
      ...getHumanReadableSerialNumber_anyCard
      ...useClickCardEvent_anyCard
    }
    ${FlexCard.fragments.anyCard}
    ${getHumanReadableSerialNumber.fragments.anyCard}
    ${useClickCardEvent.fragments.anyCard}
  ` as TypedDocumentNode<CardPreview_anyCard>,
};

export default CardPreview;
