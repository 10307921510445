import { ReactElement, cloneElement, useId } from 'react';

export type IconColor = string | ReactElement;

export const useIconColor = (color: IconColor | undefined) => {
  const gradientId = useId();
  const fill =
    typeof color === 'object' ? `url(#${gradientId})` : color ?? 'currentColor';
  const svgNode =
    typeof color === 'object' && cloneElement(color, { id: gradientId });
  return { fill, svgNode };
};
