import { TypedDocumentNode, gql } from '@apollo/client';

import {
  CardQuality,
  Rarity,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { PlayerWithSupply } from 'components/rewards/PlayerWithSupply';

import {
  RewardsEssenceQualityRarityPageQuery,
  RewardsEssenceQualityRarityPageQueryVariables,
} from './__generated__/index.graphql';

const REWARDS_ESSENCE_QUALITY_RARITY_PAGE_QUERY = gql`
  query RewardsEssenceQualityRarityPageQuery(
    $sport: Sport!
    $rarity: Rarity!
    $quality: CardQuality!
  ) {
    cardShardsPool(sport: $sport, rarity: $rarity, quality: $quality) {
      slug
      ...PlayerWithSupply_playerWithSupply
    }
  }
  ${PlayerWithSupply.fragments.playerWithSupply}
  ${PlayerWithSupply.fragments.so5League}
` as TypedDocumentNode<
  RewardsEssenceQualityRarityPageQuery,
  RewardsEssenceQualityRarityPageQueryVariables
>;

type Props = {
  sport: Sport;
  quality: CardQuality;
  rarity: Rarity;
};

export const RewardsEssenceQualityRarityPage = ({
  sport,
  quality,
  rarity,
}: Props) => {
  const { data, loading } = useQuery(
    REWARDS_ESSENCE_QUALITY_RARITY_PAGE_QUERY,
    {
      variables: {
        sport,
        quality,
        rarity,
      },
    }
  );
  const { cardShardsPool } = data || {};

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!cardShardsPool) {
    return null;
  }

  return (
    <PlayerWithSupply players={cardShardsPool} rarity={rarity as Rarity} />
  );
};
