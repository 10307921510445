import { matchPath } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { MLB_PATH, NBA_PATH } from 'constants/routes';
import { STORAGE } from 'hooks/useLocalStorage';
import { local as localStorage } from 'lib/storage';

export const sportFromPathAndLocalStorage = (path: string) => {
  if (matchPath(catchAll(NBA_PATH), path)) {
    return Sport.NBA;
  }
  if (matchPath(catchAll(MLB_PATH), path)) {
    return Sport.BASEBALL;
  }
  if (matchPath('/', path)) {
    switch (localStorage.getItem(STORAGE.lastVisitedSport)) {
      case 'NBA':
        return Sport.NBA;
      case 'BASEBALL':
        return Sport.BASEBALL;
      case 'FOOTBALL':
        return Sport.FOOTBALL;
    }
  }
  return Sport.FOOTBALL;
};
