import classNames from 'classnames';
import qs, { ParsedQs } from 'qs';
import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  Fragment,
  KeyboardEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { FullViewportScroll } from 'atoms/container/FullViewportScroll';
import { ORIGIN } from 'config';
import { useIsVisibleInViewport } from 'hooks/ui/useIsVisibleInViewport';
import { useEmitClickButton } from 'hooks/useEmitClickButton';
import { tabletAndAbove } from 'style/mediaQuery';
import { hideScrollbar } from 'style/utils';

type LinkProps = ComponentPropsWithoutRef<typeof Link>;
type ContextProps = {
  value?: string | null;
  currentPathname: string;
  qsFromLocation: ParsedQs;
  onValueChange: (value?: string) => void;
  scrollableContainerNode?: HTMLElement | null;
  activeTabNode: HTMLElement | null;
  setActiveTabNode: (node: HTMLElement | null) => void;
};

const Context = createContext<ContextProps | undefined>(undefined);

function useTabBarContext(consumerName: string) {
  const context = useContext(Context);
  if (context) return context;
  throw new Error(`\`${consumerName}\` must be used within \`AnimatedTabBar\``);
}

const Wrapper = styled.div`
  --bar-padding: var(--half-unit);
  --bar-bg-color: rgba(var(--c-rgb-neutral-1000), 0.075);
  --bar-border-bottom: none;
  --bar-border: none;
  --bar-border-radius: var(--quadruple-unit);
  --tab-padding-x: var(--double-unit);
  --tab-padding-y: var(--intermediate-unit);
  --fg-color: var(--c-white);
  --hover-fg-color: var(--c-white);
  --hover-bg-color: rgb(var(--c-rgb-neutral-1000), 0.1);
  --active-bg-color: var(--c-white);
  --active-fg-color: var(--c-black);
  --active-badge-bg-color: var(--c-nd-700);
  --button-border-radius: var(--triple-unit);
  --animated-border-bottom: none;

  ${hideScrollbar}
  position: relative;
  isolation: isolate;
  display: inline-block;
  &.primary {
    --tab-padding-x: var(--intermediate-unit);
    --tab-padding-y: calc(var(--half-unit) - 1px);
    --bar-bg-color: var(--c-black);
    --bar-border: 1px solid var(--c-nd-150);
    --bar-border-radius: var(--triple-unit);
  }
  &.secondary {
    --bar-bg-color: var(--c-nd-50);
    --fg-color: var(--c-nd-600);
    --hover-fg-color: var(--c-nd-600);
    --active-bg-color: var(--c-nd-200);
    --active-fg-color: var(--c-white);
    --active-badge-bg-color: var(--c-nd-400);
  }
  &.compact {
    --tab-padding-x: var(--intermediate-unit);
    --tab-padding-y: calc(var(--half-unit) - 1px);
    --bar-bg-color: var(--c-black);
    --bar-border: 1px solid var(--c-nd-150);
    --bar-border-radius: var(--triple-unit);
  }
  &.flat {
    --bar-bg-color: var(--bar-bg-color-custom, var(--c-black));
    --hover-bg-color: transparent;
    --active-bg-color: transparent;
    --fg-color: var(--c-nd-600);
    --hover-fg-color: var(--c-white);
    --active-fg-color: var(--c-white);
    --bar-padding: 0;
    --button-border-radius: 0;
    --animated-border-bottom: 2px solid var(--c-white);
    --bar-border-bottom: 1px solid var(--c-nd-100);
    --bar-border-radius: 0;
  }
  &.center ul {
    justify-content: center;
  }
  &.fullWidth {
    flex: 1;
    --tab-padding-x: var(--intermediate-unit);
    & li {
      flex: 1 1 0%;
    }
    &:not(.forceMobileLayout) {
      @media ${tabletAndAbove} {
        & > ul {
          padding-left: var(--double-unit);
        }
      }
      & li {
        @media ${tabletAndAbove} {
          flex: 0 0 auto;
        }
      }
    }
  }

  &.transparent {
    --bar-bg-color: transparent;
  }
`;

type Props = {
  children?: ReactNode;
  className?: string;
  value?: string | null;
  compact?: boolean;
  fullWidth?: boolean;
  scrollable?: boolean;
  variant?: 'primary' | 'secondary' | 'flat';
  ignoreQueryParams?: boolean;
  onValueChange?: (value: string) => void | Promise<void>;
  transparent?: boolean;
  scrollableContainerNode?: HTMLElement | null;
  forceMobileLayout?: boolean;
};

const Root = ({
  value: valueProp,
  variant = 'primary',
  compact,
  className,
  onValueChange: onValueChangeProp,
  fullWidth,
  ignoreQueryParams,
  transparent,
  scrollableContainerNode,
  forceMobileLayout,
  ...divProps
}: Props) => {
  const [value, setValue] = useState(valueProp);
  const [activeTabNode, setActiveTabNode] = useState<HTMLElement | null>(null);

  // This is used to make sure the value is always synced with the prop
  // even if the prop changes without the user clicking on a tab
  const valueRef = useRef(valueProp);
  if (valueRef.current !== valueProp) {
    setValue(valueProp);
    valueRef.current = valueProp;
  }

  const location = useLocation();
  const qsFromLocation = ignoreQueryParams
    ? {}
    : qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
  const { pathname: currentPathname = '' } = location;

  const onValueChange = useCallback(
    (change?: string) => {
      setValue(change);
      if (change !== undefined) onValueChangeProp?.(change);
    },
    [onValueChangeProp]
  );

  return (
    <Context.Provider
      value={{
        value,
        qsFromLocation,
        currentPathname,
        onValueChange,
        scrollableContainerNode,
        activeTabNode,
        setActiveTabNode,
      }}
    >
      <Wrapper
        className={classNames(className, variant, {
          compact,
          fullWidth,
          transparent,
          forceMobileLayout,
        })}
        {...divProps}
      />
    </Context.Provider>
  );
};

export const Bar = styled.ul.attrs({
  role: 'tablist',
  'aria-orientation': 'horizontal',
})`
  display: flex;
  align-items: center;
  gap: var(--half-unit);
  margin: 0;
  padding: var(--bar-padding);
  background-color: var(--bar-bg-color);
  border-radius: var(--bar-border-radius);
  border-bottom: var(--bar-border-bottom);
  .compact &,
  .primary & {
    border: var(--bar-border);
  }
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--double-unit);
  padding: var(--half-unit) var(--unit);
  line-height: var(--double-unit);
  text-align: center;
  font-weight: var(--t-bold);
  white-space: nowrap;
  min-width: 1.5em;
  background-color: var(--bar-bg-color);
  color: var(--fg-color);
  &:first-child {
    margin-inline-start: calc(-1 * var(--unit));
  }
  &:last-child {
    margin-inline-end: calc(-1 * var(--unit));
  }
`;

const Button = styled(ButtonBase)`
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--unit);
  padding: var(--tab-padding-y) var(--tab-padding-x);
  font: var(--t-bold) var(--t-16);
  .compact & {
    font: var(--t-bold) var(--t-label-m);
  }
  color: var(--fg-color);
  text-align: center;
  border-radius: var(--button-border-radius);
  scroll-snap-align: start;
  cursor: pointer;
  white-space: nowrap;
  transition:
    color 0.2s ease-in-out,
    background-color 0.1s ease-in-out;
  & img {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.5;
  }
  &:not(.active):hover,
  &:not(.active):focus {
    color: var(--hover-fg-color);
    background-color: var(--hover-bg-color);
    & img {
      opacity: 1;
    }
  }
  &.active {
    color: var(--active-fg-color);
    cursor: default;
    & ${Badge} {
      color: var(--active-fg-color);
      background-color: var(--active-badge-bg-color);
      box-shadow: var(--shadow-100);
    }
    & img {
      opacity: 1;
    }
  }
  &.active,
  &.disabled {
    pointer-events: none;
  }
  &.disabled {
    opacity: 0.4;
  }
`;

type TabProps = {
  value?: string;
  to?: string;
  matches?: (string | undefined | null | false)[];
} & Partial<Omit<LinkProps, 'to'> & ButtonHTMLAttributes<HTMLButtonElement>>;

function getActiveAndPath(
  currentPathname: string,
  to: string,
  qsFromLocation: ParsedQs,
  matches?: (string | undefined | null | false)[]
) {
  const origin = ORIGIN;
  const { pathname = '', search } = new URL(to, origin);
  const isRelative = !to.startsWith('/');
  const itemPathname = isRelative ? pathname.replace(/^\//, '') : pathname;
  const qsFromTo = qs.parse(search || '', {
    ignoreQueryPrefix: true,
  });
  const pathMatch = matchPath(pathname, currentPathname);
  const match =
    Boolean(pathMatch) ||
    matches?.some(m => m && matchPath(m, currentPathname));
  const path =
    itemPathname +
    qs.stringify(
      Object.fromEntries(
        Object.entries({ ...qsFromLocation, ...qsFromTo }).filter(([, v]) =>
          Boolean(v)
        )
      ),
      { addQueryPrefix: true }
    );
  return [Boolean(match), path] as const;
}

export const Tab = ({
  value,
  to,
  matches,
  disabled,
  replace = true,
  className,
  onClick,
  ...props
}: TabProps) => {
  const {
    value: barValue,
    currentPathname,
    qsFromLocation,
    onValueChange,
    scrollableContainerNode,
    activeTabNode,
    setActiveTabNode,
  } = useTabBarContext('Tab');
  const emitClickButton = useEmitClickButton();
  const { handleRef, node, isVisible } = useIsVisibleInViewport<
    HTMLAnchorElement | HTMLButtonElement
  >();

  const scrollHandled = useRef(false);

  const [active, path] = useMemo((): readonly [boolean, string?] => {
    if (!to) return [value === barValue];
    const pair = getActiveAndPath(currentPathname, to, qsFromLocation, matches);
    return value === undefined ? pair : [value === barValue, pair[1]];
  }, [to, value, barValue, currentPathname, qsFromLocation, matches]);

  useEffect(() => {
    if (active && node && node !== activeTabNode) {
      setActiveTabNode(node);
    }
  }, [active, activeTabNode, node, setActiveTabNode]);

  if (
    active &&
    node &&
    scrollableContainerNode &&
    isVisible !== undefined &&
    !scrollHandled.current
  ) {
    scrollHandled.current = true;
    if (!isVisible) {
      // We need to use Math.round on scrollLeft as this value can be a float
      // Whereas offsetLeft is rounded to an integer
      // This mismatch causes scrolling when none is needed
      const offset =
        node.offsetLeft - Math.round(scrollableContainerNode.scrollLeft);
      scrollableContainerNode.scrollBy?.({
        left: offset,
        behavior: 'smooth',
      });
    }
  }

  return (
    <li className={className}>
      <Button
        ref={handleRef}
        as={to ? Link : 'button'}
        type={to ? undefined : 'button'}
        to={path}
        replace={replace}
        role="tab"
        aria-selected={active}
        className={classNames({ active, disabled })}
        {...props}
        onClick={(event: any) => {
          onValueChange(value);
          onClick?.(event);
          emitClickButton();
        }}
        onKeyDown={(event: KeyboardEvent) => {
          if (value !== undefined && [' ', 'Enter'].includes(event.key)) {
            onValueChange(value);
          }
        }}
      />
    </li>
  );
};

const SeparatorWrapper = styled.div`
  margin: 0 var(--half-unit);
`;

export const Separator = ({ className }: { className?: string }) => {
  return (
    <SeparatorWrapper className={className}>
      <svg width="1" height="24">
        <rect width="1" height="24" fill="var(--c-nd-200)" />
      </svg>
    </SeparatorWrapper>
  );
};

const ActiveDiv = styled.div`
  position: absolute;
  top: var(--bar-padding);
  bottom: var(--bar-padding);
  left: 0;
  width: 0;
  z-index: 1;
  background: var(--active-bg-color);
  border-radius: var(--button-border-radius);
  border-bottom: var(--animated-border-bottom);
  /** use CSS animation rather than react-spring for this; lighter */
  transition:
    left 0.3s ease-in-out,
    width 0.3s ease-in-out;
  will-change: left, width;
`;

type ActiveBackgroundProps = {
  className?: string;
};

export const ActiveBackground = (props: ActiveBackgroundProps) => {
  const context = useTabBarContext('ActiveBackground');
  const { activeTabNode } = context;

  if (!activeTabNode) return null;

  const { offsetLeft: left } = activeTabNode;
  const { width } = activeTabNode.getBoundingClientRect();

  return <ActiveDiv style={{ left, width }} {...props} />;
};

export const TabBar = ({ children, scrollable = true, ...props }: Props) => {
  const [scrollableContainerNode, setScrollableContainerNode] =
    useState<HTMLElement | null>(null);
  const ScrollableWrapper = scrollable ? FullViewportScroll : Fragment;
  return (
    <ScrollableWrapper
      ref={scrollable ? node => setScrollableContainerNode(node) : undefined}
    >
      <Root {...props} scrollableContainerNode={scrollableContainerNode}>
        <Bar>{children}</Bar>
        <ActiveBackground />
      </Root>
    </ScrollableWrapper>
  );
};
