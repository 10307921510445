import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { ButtonProps } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { RadioGroup } from '@sorare/core/src/atoms/inputs/RadioGroup';
import { Collapse } from '@sorare/core/src/atoms/layout/Collapse';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { BodyM, BodyS, HeadlineXS } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { ConversionCredit } from '@sorare/core/src/components/wallet/BankEthAccounting/ConversionCredits/ConversionCredit';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import {
  ConversionCreditWithAmounts,
  getBestConversionCredit,
} from '@sorare/core/src/hooks/useConversionCredits';
import {
  MonetaryAmountOutput,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { payment } from '@sorare/core/src/lib/glossary';
import { MonetaryAmountCurrency } from '@sorare/core/src/lib/monetaryAmount';

import { usePaymentContext } from 'components/buyActions/Context';

import { PaymentBoxBlockHeader } from '../ui';

const messages = defineMessages({
  title: {
    id: 'ConversionCredits.creditNotUsed',
    defaultMessage: 'Credit not used',
  },
  titleUsing: {
    id: 'ConversionCredits.usingCredit',
    defaultMessage: 'Credit applied',
  },
  availableCount: {
    id: 'ConversionCredits.availableCount',
    defaultMessage:
      '{count} {count, plural, one {available} other {available}}',
  },
  savedAmount: {
    id: 'ConversionCredits.savedAmount',
    defaultMessage: '{amount} saved',
  },
});

const Root = styled(Vertical).attrs({ gap: 2 })`
  width: 100%;
`;

const DropdownContent = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--double-unit);
`;

const FullWidth = styled(Vertical)`
  width: 100%;
`;

const AvailableCredits = styled(BodyM)`
  color: blue;
  background: linear-gradient(
    92deg,
    #fff 0%,
    #eabfe2 28.88%,
    #fff 48.67%,
    #bedecb 64.58%,
    #fff 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

type Props = {
  amount: MonetaryAmountOutput;
  referenceCurrency: SupportedCurrency;
  showConversionCredit?: boolean;
  conversionCredit: ConversionCreditWithAmounts | undefined;
  conversionCreditMonetaryAmount?: MonetaryAmountOutput;
  conversionCredits: ConversionCreditWithAmounts[] | undefined;
  row?: boolean;
  setConversionCredit: (value: ConversionCreditWithAmounts | undefined) => void;
};

type HeaderProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  conversionCredits: NonNullable<Props['conversionCredits']>;
} & Pick<Props, 'amount' | 'conversionCredit'> &
  ButtonProps;

const Header = forwardRef<HTMLButtonElement, HeaderProps>(
  (
    {
      open,
      setOpen,
      amount,
      conversionCredits,
      conversionCredit,
      ...buttonProps
    },
    ref
  ) => {
    const { formatNumber } = useIntl();
    const { fiatCurrency } = useCurrentUserContext();

    const conversionCreditUsedForPreview =
      conversionCredit || getBestConversionCredit(amount, conversionCredits);

    const currencyCode = fiatCurrency.code.toLowerCase() as Exclude<
      MonetaryAmountCurrency,
      'wei'
    >;

    const maxDiscountInFiat = conversionCreditUsedForPreview
      ? Math.min(
          conversionCreditUsedForPreview.maxDiscount?.[currencyCode],
          conversionCreditUsedForPreview.percentageDiscount *
            amount[currencyCode]
        ) / 100
      : 0;

    return (
      <PaymentBoxBlockHeader
        {...buttonProps}
        ref={ref}
        withArrow
        reverseArrow={open}
        onToggle={() => setOpen(!open)}
      >
        <Horizontal>
          <BodyM as="span" color="var(--c-white)" bold>
            <FormattedMessage
              {...(conversionCredit ? messages.titleUsing : messages.title)}
            />
          </BodyM>
          <Tooltip
            title={
              <BodyS as="p" color="var(--c-nd-600)">
                <FormattedMessage {...payment.conversionCreditDefinition} />
              </BodyS>
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
          {conversionCredit ? (
            <Chip size="medium" color="yellow">
              <FormattedMessage
                {...messages.savedAmount}
                values={{
                  amount: formatNumber(maxDiscountInFiat, {
                    style: 'currency',
                    currency: fiatCurrency.code,
                  }),
                }}
              />
            </Chip>
          ) : (
            <AvailableCredits>
              <FormattedMessage
                {...messages.availableCount}
                values={{
                  count: conversionCredits.length,
                }}
              />
            </AvailableCredits>
          )}
        </Horizontal>
      </PaymentBoxBlockHeader>
    );
  }
);

Header.displayName = 'Header';

export const ConversionCredits = ({
  amount,
  referenceCurrency,
  showConversionCredit,
  row,
  conversionCredit,
  setConversionCredit,
  conversionCredits,
  conversionCreditMonetaryAmount = zeroMonetaryAmount,
}: Props) => {
  const isLaptop = useIsLaptop();
  const [open, setOpen] = useState(false);

  if (!showConversionCredit || !conversionCredits?.length) return null;

  const content = (
    <Root>
      <RadioGroup
        allowUnselect
        hideRadio
        withSpacing
        modal
        rounded
        row={row}
        flexWrap={row}
        value={conversionCredit?.id}
        options={conversionCredits.map(conversionCreditItem => {
          return {
            value: conversionCreditItem.id,
            disabled: conversionCreditItem.disabled,
            label: (
              <ConversionCredit
                referenceCurrency={referenceCurrency}
                conversionCredit={conversionCreditItem}
                {...(conversionCreditItem.id === conversionCredit?.id
                  ? {
                      spentConversionCreditMonetaryAmount:
                        conversionCreditMonetaryAmount,
                    }
                  : {})}
              />
            ),
          };
        })}
        onChange={value => {
          const cc = conversionCredits.find(ccItem => ccItem.id === value);
          setConversionCredit(cc);
        }}
        clean={() => setConversionCredit(undefined)}
        name="conversionCredit"
      />
    </Root>
  );

  if (isLaptop)
    return (
      <FullWidth gap={0}>
        <Header
          open={open}
          setOpen={setOpen}
          conversionCredit={conversionCredit}
          conversionCredits={conversionCredits}
          amount={amount}
        />
        <Collapse open={open}>{content}</Collapse>
      </FullWidth>
    );

  return (
    <Dropdown
      onClose={() => setOpen(false)}
      open={open}
      label={props => (
        <Header
          {...props}
          open={open}
          setOpen={setOpen}
          conversionCredit={conversionCredit}
          conversionCredits={conversionCredits}
          amount={amount}
        />
      )}
    >
      <DropdownContent>
        <SBHorizontal>
          <HeadlineXS>
            <FormattedMessage
              id="ConversionCredits.select.title"
              defaultMessage="Select your credit"
            />
          </HeadlineXS>
        </SBHorizontal>
        {content}
      </DropdownContent>
    </Dropdown>
  );
};

export const PaymentBoxConversionCredits = (props: Pick<Props, 'amount'>) => {
  const {
    conversionCredit,
    conversionCredits,
    setConversionCredit,
    showConversionCredit,
    conversionCreditMonetaryAmount,
    referenceCurrency,
  } = usePaymentContext();

  return (
    <ConversionCredits
      referenceCurrency={referenceCurrency}
      conversionCredit={conversionCredit}
      conversionCreditMonetaryAmount={conversionCreditMonetaryAmount}
      setConversionCredit={setConversionCredit}
      showConversionCredit={showConversionCredit}
      conversionCredits={conversionCredits}
      {...props}
    />
  );
};
