import styled, { css } from 'styled-components';

export const rainbow = css`
  background:
    var(--inside, linear-gradient(var(--c-black), var(--c-black))) padding-box,
    linear-gradient(var(--c-black), var(--c-black)) padding-box,
    linear-gradient(90deg, #ffc700, #db00ff 63.2%, #0038ff 100%) border-box;
`;

export const RainbowBox = styled.div`
  ${rainbow}
  border-radius: var(--unit);
  border: 1px solid transparent;
`;
