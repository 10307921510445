import { ReactNode } from 'react';
import styled from 'styled-components';

import { LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { Image } from '@sorare/core/src/components/collections/DetailedScoreLine/Image';
import { Score } from '@sorare/core/src/components/collections/Score';

const Root = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'img label score'
    'img explanation score';
  justify-content: start;
  align-items: center;
  column-gap: var(--double-unit);
`;
const Label = styled(LabelM).attrs({ bold: true })`
  grid-area: label;
`;
const Explanation = styled(LabelS).attrs({ color: 'var(--c-nd-600)' })`
  grid-area: explanation;
`;
const ScoreWrapper = styled.div`
  grid-area: score;
  margin-left: auto;
`;

type Props = {
  img: string;
  label: ReactNode;
  value: number;
  explanation: ReactNode;
  listed?: boolean;
};
export const DetailedScoreLine = ({
  img,
  label,
  value,
  listed,
  explanation,
}: Props) => {
  return (
    <Root>
      <Image img={img} listed={listed} width={32} />
      <Label>{label}</Label>
      <Explanation>{explanation}</Explanation>
      <ScoreWrapper>
        <Score score={value} />
      </ScoreWrapper>
    </Root>
  );
};
