import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { BodyL } from 'atoms/typography';
import { SlideShow } from 'atoms/ui/SlideShow';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { IS_PRERENDER } from 'config';
import useScreenSize from 'hooks/device/useScreenSize';
import { breakpoints, laptopAndAbove, tabletAndAbove } from 'style/mediaQuery';

const Wrapper = styled(ContentContainer)`
  margin-top: calc(var(--unit) * 5);
  @media ${tabletAndAbove} {
    margin-top: calc(var(--unit) * 14);
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-family: 'Druk Wide';
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  margin-bottom: calc(var(--unit) * 7);

  @media ${tabletAndAbove} {
    font-size: 40px;
    margin-bottom: calc(var(--unit) * 14);
  }
  @media ${laptopAndAbove} {
    font-size: 56px;
  }
`;

const BlockList = styled.div`
  display: grid;
  gap: calc(var(--unit) * 7);

  @media ${tabletAndAbove} {
    gap: calc(var(--unit) * 10);
  }

  @media ${laptopAndAbove} {
    gap: 0;
  }
`;

const BlockWrapper = styled.div`
  display: grid;
  border: 1px solid var(--c-nd-100-opaque);

  @media ${laptopAndAbove} {
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'text image';
    gap: calc(var(--unit) * 5);
    padding: calc(var(--unit) * 5);
    &.reverse {
      grid-template-columns: 3fr 1fr;
      grid-template-areas: ' image text';
    }
  }
`;

const BlockContent = styled.div`
  padding: var(--triple-unit) var(--double-and-a-half-unit);

  @media ${tabletAndAbove} {
    display: grid;
    gap: calc(var(--unit) * 5);
    grid-template-columns: 1fr 2fr;
    row-gap: var(--double-and-a-half-unit);
    padding: var(--quadruple-unit) var(--double-and-a-half-unit);
  }

  @media ${laptopAndAbove} {
    display: flex;
    grid-area: text;
    gap: var(--double-unit);
    flex-direction: column;
    text-align: left;
    padding: 0;
  }
`;

const BlockNumber = styled(BodyL)`
  display: flex;
  font-size: 16px;
  line-height: 1.5;
  align-items: center;

  @media ${laptopAndAbove} {
    font-size: 18px;
  }
`;

const Line = styled.span`
  flex-grow: 1;
  height: 1px;
  background: var(--c-nd-600);
  margin-left: 16px;
`;

const BlockTitle = styled.h3`
  font-size: 20px;
  line-height: 1;
  font-family: 'Druk Wide';
  text-transform: uppercase;
  margin-top: var(--double-and-a-half-unit);

  @media ${tabletAndAbove} {
    font-size: 24px;
    margin-top: var(--unit);
  }

  @media ${laptopAndAbove} {
    margin-top: var(--double-unit);
  }
`;

const BlockText = styled(BodyL)`
  font-size: 16px;
  line-height: 1.5;
  margin-top: var(--unit);

  @media ${laptopAndAbove} {
    font-size: 18px;
  }
`;

const Picture = styled.picture`
  grid-row: 1;
  align-self: center;
  position: relative;

  @media ${laptopAndAbove} {
    grid-row: auto;
    grid-area: image;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;

  @media ${tabletAndAbove} {
    aspect-ratio: 1.7;
  }

  @media ${laptopAndAbove} {
    grid-row: auto;
    border-radius: var(--double-unit);
  }
`;

const Video = styled.video`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  align-self: center;
  grid-row: 1;

  @media ${tabletAndAbove} {
    aspect-ratio: initial;
  }

  @media ${laptopAndAbove} {
    grid-row: auto;
    grid-area: image;
    border-radius: var(--double-unit);
  }
`;

const CTA = styled.div`
  margin-top: var(--double-unit);
  @media ${tabletAndAbove} {
    margin-top: 0;
  }
  @media ${laptopAndAbove} {
    margin-top: var(--double-unit);
  }
`;

const SlideShowWrapper = styled.div`
  grid-row: 1;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;

  @media ${tabletAndAbove} {
    aspect-ratio: 1.7;
  }
`;

type ExperienceVideoProps = {
  poster: string;
  videoSrc: string;
  slideShow?: string[];
};

const ExperienceVideo = ({
  poster,
  videoSrc,
  slideShow,
}: ExperienceVideoProps) => {
  const { up: isLaptop } = useScreenSize('laptop');

  if (!isLaptop && slideShow)
    return (
      <SlideShowWrapper>
        <SlideShow images={slideShow} />
      </SlideShowWrapper>
    );
  return (
    <Video
      {...(IS_PRERENDER ? { preload: 'none' } : { autoPlay: true })}
      loop
      muted
      playsInline
      poster={poster}
    >
      <source src={videoSrc} type="video/webm" />
    </Video>
  );
};

type BlockWithCtaAction = {
  title: string | ReactNode;
  text: string | ReactNode;
  cta: string | ReactNode;
  ctaAction: () => void;
  imageSrc: { default: string; mobile?: string };
  videoSrc?: { default?: string; mobile?: string };
  slideShow?: string[];
};

type BlockWithoutCtaAction = {
  title: string | ReactNode;
  text: string | ReactNode;
  cta?: never;
  ctaAction?: never;
  imageSrc: { default: string; mobile?: string };
  videoSrc?: { default?: string; mobile?: string };
  slideShow?: string[];
};

type Block = BlockWithCtaAction | BlockWithoutCtaAction;

export type Props = {
  title: string | ReactNode;
  blocks: Block[];
};

export const ExperienceBlock = ({ title, blocks }: Props) => {
  const { up: isTablet } = useScreenSize('tablet');

  return (
    <Wrapper>
      <Title>{title}</Title>
      <BlockList>
        {blocks.map((block, index) => (
          <BlockWrapper
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames({ reverse: index % 2 })}
          >
            <BlockContent>
              <div>
                <BlockNumber
                  as="p"
                  color="var(--c-nd-400-opaque)"
                  className={classNames({ reverse: index % 2 })}
                >
                  0{index + 1}
                  <Line />
                </BlockNumber>
                <BlockTitle>{block.title}</BlockTitle>
              </div>
              <BlockText as="div" color="var(--c-nd-400-opaque)">
                {block.text}
              </BlockText>
              {block.cta && (
                <CTA>
                  <Button
                    color="tertiary"
                    size="medium"
                    onClick={block?.ctaAction}
                  >
                    {block.cta}
                  </Button>
                </CTA>
              )}
            </BlockContent>
            {block.videoSrc?.default ? (
              <ExperienceVideo
                poster={
                  isTablet
                    ? block.imageSrc?.default || ''
                    : block.imageSrc?.mobile || block.imageSrc?.default || ''
                }
                videoSrc={
                  isTablet
                    ? block.videoSrc.default
                    : block.videoSrc?.mobile || block.videoSrc.default
                }
                slideShow={block.slideShow}
              />
            ) : (
              <Picture>
                {block.imageSrc.mobile && (
                  <source
                    media={`(max-width: ${breakpoints.tablet}px)`}
                    srcSet={block.imageSrc.mobile}
                  />
                )}
                <Image src={block.imageSrc.default} />
              </Picture>
            )}
          </BlockWrapper>
        ))}
      </BlockList>
    </Wrapper>
  );
};
