import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { CSSProperties, keyframes } from 'styled-components';

import {
  ConversionCreditStatus,
  Currency,
  SupportedCurrency,
} from '__generated__/globalTypes';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, BodyS } from 'atoms/typography';
import { Gauge } from 'atoms/ui/Gauge';
import { sportLogos } from 'components/SportLogo';
import { OpenItemDialogLink } from 'components/link/OpenItemDialogLink';
import { useIntlContext } from 'contexts/intl';
import TimeLeft from 'contexts/ticker/TimeLeft';
import { isType } from 'gql';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { ConversionCreditWithAmounts } from 'hooks/useConversionCredits';
import {
  MonetaryAmountOutput,
  useMonetaryAmount,
  zeroMonetaryAmount,
} from 'hooks/useMonetaryAmount';

const Root = styled(Vertical)`
  width: 100%;
  text-align: left;
  min-width: 180px;
`;

const Header = styled(Horizontal)`
  justify-content: space-between;
`;

const LogosContainer = styled(Horizontal)`
  :not(:first-child) {
    margin-left: calc(-1 * var(--unit));
  }
`;

const enterAnimation = keyframes`
  from {
    background-position: 0 0;
  }
`;

const Filled = styled.div`
  background: linear-gradient(
    to right,
    #ffa724 50%,
    #f2f3d0 50%,
    #f23b27 50%,
    #f23b27 100%
  );
  background-size: 200%;
  background-position: var(--discountPercentage) 0;
  transition: background-position 0.4s var(--c-cb-ease-out);
  height: 100%;
  border-radius: 3px;
  max-width: 100%;
  animation: ${enterAnimation} 0.8s normal 0.3s;
`;

const LogoWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: 50%;
  background-color: var(--c-white);
  border: 1px solid var(--c-nd-200);
  padding: 2px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ConversionCreditPurchaseLink = ({
  purchase,
  children,
}: PropsWithChildren<{
  purchase: ConversionCreditWithAmounts['purchase'];
}>) => {
  if (!purchase) return children;

  if (purchase.anyCards.length > 1 && isType(purchase, 'TokenAuction')) {
    return (
      <OpenItemDialogLink item={purchase} sport={purchase.anyCards[0].sport}>
        {children}
      </OpenItemDialogLink>
    );
  }

  return (
    <OpenItemDialogLink
      item={purchase.anyCards[0]}
      sport={purchase.anyCards[0].sport}
    >
      {children}
    </OpenItemDialogLink>
  );
};

export const ConversionCredit = ({
  conversionCredit,
  referenceCurrency,
  spentConversionCreditMonetaryAmount,
  className,
}: {
  referenceCurrency: SupportedCurrency;
  conversionCredit: ConversionCreditWithAmounts;
  spentConversionCreditMonetaryAmount?: MonetaryAmountOutput;
  className?: string;
}) => {
  const { formatNumber } = useIntlContext();
  const { toMonetaryAmount } = useMonetaryAmount();

  const getProjectedConversionCreditAmountLeft = () => {
    const refCurrency = referenceCurrency.toLowerCase() as
      | 'eur'
      | 'usd'
      | 'gbp'
      | 'wei';
    if (conversionCredit.singleUse) return zeroMonetaryAmount;
    if (!spentConversionCreditMonetaryAmount)
      return conversionCredit.maxDiscount;
    return toMonetaryAmount({
      [referenceCurrency.toLowerCase()]:
        Number(conversionCredit.maxDiscount[refCurrency]) +
        Number(spentConversionCreditMonetaryAmount[refCurrency]),
      referenceCurrency,
    });
  };

  const projectedConversionCreditAmountLeft =
    getProjectedConversionCreditAmountLeft();

  const primaryCurrency =
    referenceCurrency === SupportedCurrency.WEI ? Currency.ETH : Currency.FIAT;

  const { main } = useAmountWithConversion({
    monetaryAmount: conversionCredit.maxDiscount,
    primaryCurrency,
  });

  const { main: totalMain } = useAmountWithConversion({
    monetaryAmount: conversionCredit.totalDiscount,
    primaryCurrency,
  });

  const { main: projectedMain } = useAmountWithConversion({
    monetaryAmount: projectedConversionCreditAmountLeft,
    primaryCurrency,
  });

  const percentage =
    (conversionCredit.maxDiscount.eur / conversionCredit.totalDiscount.eur) *
    100;

  const discountPercentage =
    (1 -
      projectedConversionCreditAmountLeft.eur /
        conversionCredit.maxDiscount.eur) *
    100;

  return (
    <Root
      className={className}
      style={
        { '--discountPercentage': `${discountPercentage}%` } as CSSProperties
      }
    >
      <Header>
        <BodyS color="var(--c-nd-600)" as="span">
          {conversionCredit.status === ConversionCreditStatus.USING ? (
            <BodyS color="var(--c-nd-600)">
              <ConversionCreditPurchaseLink
                purchase={conversionCredit.purchase}
              >
                <FormattedMessage
                  id="ConversionCredit.status.using"
                  defaultMessage="Currently used"
                />
              </ConversionCreditPurchaseLink>
            </BodyS>
          ) : (
            <>
              {conversionCredit.singleUse && (
                <FormattedMessage
                  id="conversionCredit.singleUse"
                  defaultMessage="Single use"
                />
              )}
            </>
          )}
        </BodyS>
        <BodyS color="var(--c-nd-600)" as="span">
          <TimeLeft time={conversionCredit.endDate} withExplicitTime />
        </BodyS>
      </Header>
      <Horizontal>
        {conversionCredit.sport ? (
          <LogoWrapper>{sportLogos[conversionCredit.sport]}</LogoWrapper>
        ) : (
          <LogosContainer gap={0}>
            {Object.values(sportLogos).map((logo, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <LogoWrapper key={i}>{logo}</LogoWrapper>
            ))}
          </LogosContainer>
        )}
        <BodyM color="var(--c-white)">
          {conversionCredit.percentageDiscount === 1 ? (
            <FormattedMessage
              id="conversionCredit.fullDisctount"
              defaultMessage="{maxDiscount} off"
              values={{
                maxDiscount: main,
              }}
            />
          ) : (
            <FormattedMessage
              id="conversionCredit.maxDiscount"
              defaultMessage="{percentageDiscount} off (up to {maxDiscount})"
              values={{
                percentageDiscount: formatNumber(
                  conversionCredit.percentageDiscount,
                  {
                    style: 'percent',
                    maximumFractionDigits: 0,
                  }
                ),
                maxDiscount: main,
              }}
            />
          )}
        </BodyM>
      </Horizontal>
      <Gauge>
        <Filled style={{ width: `${percentage}%` }} />
      </Gauge>
      <BodyS color="var(--c-nd-600)">
        <FormattedMessage
          id="conversionCredit.availableVsTotal"
          defaultMessage="{maxDiscount}/{totalDiscout} available"
          values={{
            maxDiscount: projectedMain,
            totalDiscout: totalMain,
          }}
        />
      </BodyS>
    </Root>
  );
};
