import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { LabelM } from 'atoms/typography';

const AmountRoot = styled.span`
  font-weight: var(--t-bold);
  color: var(--c-nd-700);
`;

type Props = { amount: number; name: string };
export const InventoryCounterInline = ({ amount, name }: Props) => (
  <LabelM color="var(--c-nd-400)">
    <FormattedMessage
      id="ClubShop.ItemPreviewDialog.Cta.InventoryText"
      defaultMessage="My Items: <Amount>{amount}</Amount> {name}"
      values={{
        Amount: (...chunks) => <AmountRoot>{chunks}</AmountRoot>,
        amount,
        name,
      }}
    />
  </LabelM>
);
