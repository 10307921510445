import { faSearch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import { ChangeEvent, KeyboardEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { IconButton } from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { laptopAndAbove } from 'style/mediaQuery';

import { TextInput } from '../TextInput';

export type Props = {
  rounded?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  fullWidth?: boolean;
  withIcon?: boolean;
  withClearIcon?: boolean;
  doNotHideClearIcon?: boolean;
  onClear?: () => void;
  largeFont?: boolean;
  inputRef?: any;
  autoFocus?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  endAdornment?: ReactNode;
  small?: boolean;
  name?: string;
};

const Root = styled(TextInput)`
  /* https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/ */
  font-size: 16px;
  @media ${laptopAndAbove} {
    font-size: 15px;
  }
  &.fullWidth {
    width: 100%;
  }
  &.small {
    height: var(--quadruple-unit);
  }
`;

const SearchIcon = () => {
  return <FontAwesomeIcon icon={faSearch} />;
};

type ClearIconProps = {
  hidden: boolean;
  onClear: () => void;
};
const MaybeHidden = styled.div<{ $hidden: boolean }>`
  ${({ $hidden }) =>
    $hidden &&
    css`
      display: none;
    `}
`;

const ClearIcon = ({ hidden, onClear }: ClearIconProps) => {
  return (
    <MaybeHidden $hidden={hidden}>
      <IconButton
        color="transparent"
        icon={faXmark}
        smaller
        onClick={onClear}
      />
    </MaybeHidden>
  );
};

export const SearchInput = ({
  value,
  fullWidth = false,
  withIcon = false,
  withClearIcon = false,
  doNotHideClearIcon = false,
  onClear = () => {},
  endAdornment,
  inputRef,
  small = false,
  ...inputProps
}: Props) => {
  return (
    <Root
      value={value}
      className={classnames({ small, fullWidth })}
      inputRef={inputRef}
      autoCorrect="off"
      autoComplete="off"
      startAdornment={withIcon ? <SearchIcon /> : null}
      endAdornment={
        withClearIcon ? (
          <ClearIcon hidden={!doNotHideClearIcon && !value} onClear={onClear} />
        ) : (
          endAdornment
        )
      }
      {...inputProps}
    />
  );
};

SearchInput.displayName = 'SearchInput';
