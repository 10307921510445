import styled from 'styled-components';

import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { SidebarToolbar } from '@sorare/core/src/routing/SidebarLayout/SidebarToolbar';
import { desktopAndAbove } from '@sorare/core/src/style/mediaQuery';

interface Props {
  children: React.ReactNode;
  toolbar?: React.ReactNode;
}

const Toolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--unit);
  @media ${desktopAndAbove} {
    align-items: flex-end;
    justify-content: right;
  }
`;

const ContentHeader = styled.div`
  padding: var(--double-unit);
  border-bottom: 1px solid var(--c-nd-100);
`;
const Content = styled.div`
  padding: var(--triple-unit);
  min-height: 100%;
`;

export const MyPage = ({ children, toolbar }: Props) => {
  const { up: isGreaterThanTablet } = useScreenSize('tablet');
  return (
    <>
      {isGreaterThanTablet && toolbar && (
        <SidebarToolbar>
          <Toolbar>{toolbar}</Toolbar>
        </SidebarToolbar>
      )}
      {!isGreaterThanTablet && toolbar && (
        <ContentHeader>
          <Toolbar>{toolbar}</Toolbar>
        </ContentHeader>
      )}
      <Content>{children}</Content>
    </>
  );
};

export default MyPage;
