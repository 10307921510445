import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { ComponentProps, ReactNode, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Blockquote } from '@sorare/core/src/atoms/layout/Blockquote';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Text14, Text16 } from '@sorare/core/src/atoms/typography';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { glossary } from '@sorare/core/src/lib/glossary';

import TokenSummary from 'components/buyActions/TokenSummary';
import CalculatedFeesTooltip from 'components/offer/CalculatedFeesTooltip';
import { TokenTransferChildrenProps } from 'components/token/TokenTransferValidator/types';

import { ConfirmationDialogContent_anyCard } from './__generated__/index.graphql';

const Main = styled.span<{ $warning: boolean }>`
  font: var(--t-bold) var(--t-48);
  ${props => (props.$warning ? `color: var(--c-red-600);` : '')}
`;

const PriceRow = styled.div`
  display: flex;
  align-items: top;
  gap: var(--unit);
`;
const Exponent = styled(Text14)`
  color: var(--c-nd-600);
`;

type Props = {
  monetaryAmount: MonetaryAmountOutput;
  referenceCurrency: SupportedCurrency;
  onClose: () => void;
  showWarningMessage: boolean;
  submitting: boolean;
  submit: () => void;
  warningMessage: MessageDescriptor & {
    values?: ComponentProps<typeof FormattedMessage>['values'];
  };
  secondaryMarketFeesPercentagePoints: bigint;
  validationMessages?: ReactNode;
  ConsentMessage?: TokenTransferChildrenProps['ConsentMessage'];
  card: ConfirmationDialogContent_anyCard;
};

const Root = styled(Vertical).attrs({ gap: 0 })`
  gap: 20px;
`;
const Amount = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 auto;
  gap: 10px;
`;
const Actions = styled.div`
  display: flex;
  gap: 10px;
  & > * {
    flex-grow: 1;
  }
`;

const DetailsWrapper = styled(Vertical).attrs({ gap: 2 })`
  border-radius: var(--double-unit);
  background: var(--c-nd-100);
  margin: var(--double-unit) 0;
  padding: var(--double-unit);
`;

const Title = styled(Text16).attrs({ bold: true })`
  text-align: center;
  color: var(--c-nd-600);
`;

const ConfirmationDialogContent = ({
  showWarningMessage,
  monetaryAmount,
  referenceCurrency,
  onClose,
  submitting,
  submit,
  warningMessage,
  secondaryMarketFeesPercentagePoints,
  validationMessages,
  ConsentMessage,
  card,
}: Props) => {
  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: {
      referenceCurrency,
      ...monetaryAmount,
    },
  });
  const [consentAgreed, setConsentAgreed] = useState(false);

  return (
    <Root>
      <Amount>
        <Vertical gap={0.5}>
          <Title>
            <FormattedMessage
              id="OfferDialog.title"
              defaultMessage="Your price"
            />
          </Title>
          {main && (
            <PriceRow>
              <Main $warning={showWarningMessage}>{main}</Main>
              {secondaryMarketFeesPercentagePoints > 0 && (
                <Tooltip
                  placement="top"
                  title={
                    <CalculatedFeesTooltip
                      referenceCurrency={referenceCurrency}
                      monetaryAmount={monetaryAmount}
                      feesPercentagePoints={secondaryMarketFeesPercentagePoints}
                    />
                  }
                >
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </div>
                </Tooltip>
              )}
            </PriceRow>
          )}
          {exponent && <Exponent>{`≈ ${exponent}`}</Exponent>}
        </Vertical>
      </Amount>
      <DetailsWrapper>
        <TokenSummary card={card} context="Confirmation Dialog" />
        {showWarningMessage && (
          <Blockquote variant="red">
            <FormattedMessage {...warningMessage} />
          </Blockquote>
        )}
        {validationMessages}
      </DetailsWrapper>
      {ConsentMessage && (
        <ConsentMessage value={consentAgreed} onChange={setConsentAgreed} />
      )}
      <Actions>
        <Button
          size="medium"
          onClick={onClose}
          color="tertiary"
          disabled={submitting}
        >
          <FormattedMessage {...glossary.cancel} />
        </Button>
        <LoadingButton
          size="medium"
          onClick={submit}
          color="primary"
          loading={submitting}
          disabled={!!ConsentMessage && !consentAgreed}
        >
          <FormattedMessage {...glossary.confirm} />
        </LoadingButton>
      </Actions>
    </Root>
  );
};

ConfirmationDialogContent.fragments = {
  anyCard: gql`
    fragment ConfirmationDialogContent_anyCard on AnyCardInterface {
      slug
      ...TokenSummary_anyCard
    }
    ${TokenSummary.fragments.anyCard}
  ` as TypedDocumentNode<ConfirmationDialogContent_anyCard>,
};
export default ConfirmationDialogContent;
