import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sport, SportOrShared } from '__generated__/globalTypes';
import { FormControlLabel } from 'atoms/inputs/FormControlLabel';
import { Vertical } from 'atoms/layout/flex';
import { Text16 } from 'atoms/typography';
import useMutation from 'hooks/graphql/useMutation';
import { USE_NOTIFICATION_PREFERENCES_USER_SETTINGS } from 'hooks/useNotificationPreferences';

import { notificationSubtitles, notificationTitles } from '../messages';
import {
  DisplayableMarketingPreference,
  DisplayablePreference,
} from '../types';
import { BooleanSelector } from './BooleanSelector';
import { DropdownSelector } from './DropdownSelector';
import {
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
} from './__generated__/index.graphql';

const UPDATE_USER_SETTINGS_MUTATION = gql`
  mutation UpdateUserSettingsMutation($input: updateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      userSettings {
        id
        ...useNotificationPreferences_userSettings
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${USE_NOTIFICATION_PREFERENCES_USER_SETTINGS}
` as TypedDocumentNode<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

const StyledFormControlLabel = styled(FormControlLabel)`
  justify-content: space-between;
  text-transform: capitalize;
  gap: var(--double-unit);

  select {
    width: 140px;
  }
`;

const Label = styled(Vertical).attrs({ gap: 0 })`
  text-transform: none;
  font-weight: normal;
`;

type Props = {
  preference: {
    name: DisplayablePreference | DisplayableMarketingPreference;
    value: Json | null;
    defaultValue: Json;
    values: Json[];
    labels: string[] | null;
  };
  sport: Sport | SportOrShared;
};

const mapSportOrShared = (sport: Sport | SportOrShared): SportOrShared => {
  switch (sport) {
    case Sport.NBA:
      return SportOrShared.NBA;
    case Sport.BASEBALL:
      return SportOrShared.BASEBALL;
    case Sport.FOOTBALL:
      return SportOrShared.FOOTBALL;
    default:
      return sport;
  }
};

export const NotificationPreference = ({ preference, sport }: Props) => {
  const [mutate] = useMutation(UPDATE_USER_SETTINGS_MUTATION);
  const { name, value, defaultValue, values } = preference;

  const currentValue = value ?? defaultValue;

  const handleChange = (newValue: Json) => {
    mutate({
      variables: {
        input: {
          notificationPreference: {
            name,
            value: newValue,
            sport: mapSportOrShared(sport),
          },
        },
      },
    });
  };

  let controlComponent = (
    <DropdownSelector preference={preference} handleChange={handleChange} />
  );

  if (values.length === 2 && values.includes(true) && values.includes(false))
    controlComponent = (
      <BooleanSelector
        currentValue={currentValue}
        handleChange={handleChange}
      />
    );

  return (
    <StyledFormControlLabel
      control={controlComponent}
      label={
        <Label>
          <Text16 color="var(--c-white)">
            <FormattedMessage {...notificationTitles[name]} />
          </Text16>
          <Text16 color="var(--c-nd-600)">
            <FormattedMessage {...notificationSubtitles[name]} />
          </Text16>
        </Label>
      }
      labelPlacement="start"
    />
  );
};
