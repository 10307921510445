import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import { SBHorizontal, Vertical } from 'atoms/layout/flex';

import { InjuryTable_injury } from './__generated__/index.graphql';

export type PlayerInjuryColor = 'yellow' | 'red';

const messages = defineMessages({
  injury: {
    id: 'Nba.Cards.InjuryTable.injury',
    defaultMessage: 'Injury',
  },
  returnDate: {
    id: 'Nba.Cards.InjuryTable.returnDate',
    defaultMessage: 'Estimated Return',
  },
  returnDateAbbr: {
    id: 'Nba.Cards.InjuryTable.returnDateAbbr',
    defaultMessage: 'Est. Return',
  },
});

const ColorCell = styled.span`
  font-weight: var(--t-bold);
  &.red {
    color: var(--c-red-600);
  }
  &.yellow {
    color: var(--c-yellow-600);
  }
`;

type RowProps = {
  children: ReactNode;
  message: MessageDescriptor;
  color: PlayerInjuryColor;
};

const Row = ({ children, message, color }: RowProps) => {
  return (
    <SBHorizontal gap={3}>
      <span>
        <FormattedMessage {...message} />
      </span>
      <ColorCell className={color}>{children}</ColorCell>
    </SBHorizontal>
  );
};

type Props = {
  abbreviate?: boolean;
  injury: InjuryTable_injury;
  getPlayerInjuryColor: (status: string | null) => PlayerInjuryColor;
};

export const InjuryTable = ({
  injury,
  abbreviate,
  getPlayerInjuryColor,
}: Props) => {
  const { formatDate } = useIntl();
  const { status, kind, expectedEndDate } = injury;
  const color = getPlayerInjuryColor(status);
  return (
    <Vertical>
      <Row message={messages.injury} color={color}>
        {kind}
      </Row>
      {expectedEndDate && (
        <Row
          message={abbreviate ? messages.returnDateAbbr : messages.returnDate}
          color={color}
        >
          {formatDate(expectedEndDate)}
        </Row>
      )}
    </Vertical>
  );
};

InjuryTable.fragments = {
  injury: gql`
    fragment InjuryTable_injury on Injury {
      id
      status
      kind
      expectedEndDate
    }
  ` as TypedDocumentNode<InjuryTable_injury>,
};
