import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteHits } from 'react-instantsearch';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Blockquote } from '@sorare/core/src/atoms/layout/Blockquote';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import { InstantCardSearch } from '@sorare/core/src/components/search/InstantSearch';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import useInfiniteScroll from '@sorare/core/src/hooks/useInfiniteScroll';
import {
  CardHit as CardHitType,
  getUserCardFilters,
  mergeResults,
} from '@sorare/core/src/lib/algolia';
import { glossary } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import SearchBox from 'search/SearchBox';

import CardRow from '../CardRow';

type Props = {
  owner: { id: string };
  onClose: () => void;
  title: MessageDescriptor;
  selectedCards: CardHitType[];
  confirmSelectedCards: (cards: CardHitType[]) => void;
  counterOfferSport?: Sport;
  maxCards?: number;
};

interface EligibleCardProps {
  card: CardHitType;
  selectedCards: string[];
  selectCard: (card: CardHitType) => void;
  deselectCard: (card: CardHitType) => void;
}

const CardRoot = styled(ButtonBase)`
  position: relative;
  width: 100%;
  border-radius: var(--unit);
  cursor: pointer;
  background: var(--c-nd-100);
  outline: 1px solid var(--c-nd-200);
  &.selected {
    background: linear-gradient(
        0deg,
        rgba(var(--c-rgb-brand-600), 0.1),
        rgba(var(--c-rgb-brand-600), 0.1)
      ),
      var(--c-nd-100);
    outline: 1px solid var(--c-brand-600);
  }
`;

const ClearButton = styled(ButtonBase)`
  padding: var(--half-unit);
  height: var(--quadruple-unit);
  align-self: flex-start;
  display: flex;
  aspect-ratio: 1;
  > svg {
    width: 100%;
  }
`;

const EligibleCard = ({
  card,
  selectedCards,
  selectCard,
  deselectCard,
}: EligibleCardProps) => {
  const selected = useMemo(
    () => selectedCards.includes(card.objectID),
    [selectedCards, card]
  );

  return (
    <CardRoot
      className={classnames({ selected })}
      disableDebounce
      onClick={() => {
        if (selected) {
          deselectCard(card);
          return;
        }
        selectCard(card);
      }}
    >
      <CardRow card={card} selected={selected}>
        {selected && (
          <ClearButton as="span" color="transparent">
            <FontAwesomeIcon icon={faTimes} size="xl" />
          </ClearButton>
        )}
      </CardRow>
    </CardRoot>
  );
};

type PrivateProps = {
  selectedCards: CardHitType[];
  setSelectedCards: React.Dispatch<React.SetStateAction<CardHitType[]>>;
  maxCards?: number;
};

const Nothing = () => (
  <Blockquote variant="grey">
    <FormattedMessage
      id="OfferBuilder.CardPicker.noCardsFound"
      defaultMessage="No Cards found"
    />
  </Blockquote>
);

const Filters = styled.div`
  margin: 20px 0px;
`;

const Results = styled(Vertical)`
  flex-grow: 1;
  overflow-y: auto;
`;
const StyledError = styled.span`
  color: var(--c-red-600);
`;
const Root = styled(Vertical)`
  min-height: 0px;
`;

const CardPicker = ({
  selectedCards,
  setSelectedCards,
  maxCards,
}: PrivateProps) => {
  const [searching, setSearching] = useState<boolean>(false);

  const {
    items,
    results: algoliaResults,
    isLastPage,
    showMore,
  } = useInfiniteHits<CardHitType>();
  const [showMaxCardsError, setShowMaxCardsError] = useState<boolean>(false);

  const hasMore = !isLastPage;

  const refineNext = () => {
    setSearching(true);
    showMore();
  };

  const { InfiniteScrollLoader } = useInfiniteScroll(
    refineNext,
    hasMore,
    searching
  );

  const selectedCardIds = useMemo(
    () => selectedCards.map(card => card.objectID),
    [selectedCards]
  );

  const selectCard = useCallback(
    (card: CardHitType) => {
      if (maxCards && selectedCards.length < maxCards) {
        setSelectedCards(cards => [...cards, card]);
      } else {
        setShowMaxCardsError(true);
      }
    },
    [maxCards, selectedCards, setSelectedCards, setShowMaxCardsError]
  );

  const deselectCard = useCallback(
    (card: CardHitType) => {
      const { objectID } = card;
      setSelectedCards(cards => cards.filter(c => c.objectID !== objectID));
      setShowMaxCardsError(false);
    },
    [setSelectedCards]
  );

  const results = mergeResults(items, selectedCards);

  useEffect(() => {
    setSearching(false);
  }, [algoliaResults?.page]);
  return (
    <Root>
      <Filters>
        <SearchBox />
      </Filters>
      {showMaxCardsError && (
        <StyledError>
          <FormattedMessage
            id="CardPicker.maxCardsError"
            defaultMessage="You can only select {maxCards} cards"
            values={{ maxCards }}
          />
        </StyledError>
      )}
      {results.length === 0 && !searching && <Nothing />}
      {results.length > 0 && (
        <Results>
          {results.map(card => (
            <EligibleCard
              key={card.objectID}
              card={card}
              {...{
                selectCard,
                selectedCards: selectedCardIds,
                deselectCard,
              }}
            />
          ))}
          <InfiniteScrollLoader />
        </Results>
      )}
    </Root>
  );
};

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--triple-unit);
  height: 100%;
  @media ${tabletAndAbove} {
    max-height: 70vh;
  }
`;
const CtaWrapper = styled.div`
  position: sticky;
  bottom: 0;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.2);
  padding: var(--triple-unit);
`;

export const CardPickerDialog = ({
  onClose,
  owner,
  title,
  selectedCards: defaultSelectedCards,
  confirmSelectedCards,
  counterOfferSport,
  maxCards,
}: Props) => {
  const [selectedCards, setSelectedCards] =
    useState<CardHitType[]>(defaultSelectedCards);
  const onConfirmCb = useCallback(
    () => confirmSelectedCards(selectedCards),
    [confirmSelectedCards, selectedCards]
  );
  const { sport } = useSportContext();

  const filters = useMemo(
    () => [getUserCardFilters(idFromObject(owner.id))],
    [owner.id]
  );

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      scroll="paper"
      onBack={onClose}
      onClose={onClose}
      title={
        <Text16 className="text-center">
          <FormattedMessage {...title} />
        </Text16>
      }
      footer={
        <CtaWrapper>
          <Button color="primary" size="medium" onClick={onConfirmCb} fullWidth>
            <FormattedMessage {...glossary.done} />
          </Button>
        </CtaWrapper>
      }
    >
      <DialogContent>
        <InstantCardSearch
          indexes={['Ending Soon']}
          analyticsTags={['NewOffer']}
          defaultFilters={filters}
          sport={counterOfferSport || sport!}
          attributesToRetrieve={['*']}
        >
          <CardPicker
            selectedCards={selectedCards}
            setSelectedCards={setSelectedCards}
            maxCards={maxCards}
          />
        </InstantCardSearch>
      </DialogContent>
    </Dialog>
  );
};

export default CardPickerDialog;
