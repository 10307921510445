import classNames from 'classnames';
import { Children, Fragment, ReactNode } from 'react';
import styled from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Vertical } from 'atoms/layout/flex';

const Root = styled.div`
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: auto 424px;
  grid-template-areas: 'header header' 'main aside';
  padding-bottom: var(--triple-unit);
`;
const Header = styled.div`
  grid-area: header;
  border-bottom: 1px solid var(--c-nd-100);
`;
const Main = styled.div`
  grid-area: main;
  padding-top: var(--triple-unit);
  border-right: 1px solid var(--c-nd-100);
  overflow: hidden;
`;
const Aside = styled.div`
  grid-area: aside;
  padding-top: var(--triple-unit);
  overflow: hidden;
`;
const Separator = styled.div`
  border-bottom: 1px solid var(--c-nd-100);
`;
const StyledVertical = styled(Vertical)`
  &.topPadding {
    padding-top: var(--triple-unit);
  }
`;
export const Section: React.FC<
  React.PropsWithChildren<{ children: ReactNode; topPadding?: boolean }>
> = ({ topPadding, children }) => {
  return (
    <StyledVertical gap={3} className={classNames({ topPadding })}>
      {Children.map(children, (child, index) => {
        if (!child) return null;

        return (
          <>
            {/* eslint-disable-next-line react/no-array-index-key */}
            <Fragment key={index}>{child}</Fragment>
            {index < Children.count(children) - 1 && (
              <FullWidthContainer>
                <Separator />
              </FullWidthContainer>
            )}
          </>
        );
      })}
    </StyledVertical>
  );
};

type Props = {
  header?: ReactNode;
  aside: ReactNode[];
  children: ReactNode;
};

export const MainAsideLayout = ({ header, aside, children }: Props) => {
  return (
    <Root>
      {header && <Header>{header}</Header>}
      <Main>
        <Section>{children}</Section>
      </Main>
      <Aside>
        <Section>
          {Children.map(aside, (asideChild, index) => {
            if (!asideChild) return null;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>{asideChild}</Fragment>
            );
          })}
        </Section>
      </Aside>
    </Root>
  );
};
