import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Horizontal } from 'atoms/layout/flex';
import { Caption, LabelM } from 'atoms/typography';
import { CardsNumber } from 'components/collections/CardsNumber';
import { Score } from 'components/collections/Score';
import { Avatar, PlaceHolderAvatar } from 'components/user/Avatar';
import { ClubShield } from 'components/user/ClubShield';
import { UserName } from 'components/user/UserName';
import { regularMaterial } from 'style/utils';

import {
  CollectionLeaderboardRow_PublicUserInfoInterface,
  CollectionLeaderboardRow_UserSportProfileInterface,
} from './__generated__/index.graphql';

const Root = styled.div`
  --outline-width: 2px;
  display: grid;
  align-items: center;
  grid-template-areas:
    'rank logo name score'
    'rank logo name cards';
  grid-template-columns: var(--triple-unit) min-content 1fr min-content;
  column-gap: var(--double-unit);
  border-radius: var(--double-unit);
  padding: var(--intermediate-unit);
  text-align: left;
  width: 100%;
  &.showClub {
    grid-template-areas:
      'rank logo name score'
      'rank logo club cards';
  }
  &.highlighted {
    position: sticky;
    top: calc(var(--double-unit) + var(--outline-width));
    bottom: calc(var(--double-unit) + var(--outline-width));
    outline: var(--outline-width) solid var(--c-brand-600);
    ${regularMaterial};
  }
`;
const Rank = styled.div`
  grid-area: rank;
  justify-self: center;
`;
const AvatarWrapper = styled.div`
  grid-area: logo;
`;
const Name = styled(LabelM)`
  grid-area: name;
`;
const ClubWrapper = styled(Horizontal).attrs({ gap: 0.5 })`
  grid-area: club;
`;
const CardsWrapper = styled.div`
  grid-area: cards;
  color: var(--c-nd-600);
`;
const ScoreWrapper = styled.div`
  grid-area: score;
`;

type Props = {
  to?: To;
  rank?: number | null;
  user?: CollectionLeaderboardRow_PublicUserInfoInterface;
  userSportProfile?: Nullable<CollectionLeaderboardRow_UserSportProfileInterface>;
  score?: number;
  ownedCards?: number;
  totalCards?: number;
  highlighted?: boolean;
  showClub?: boolean;
};

export const CollectionLeaderboardRow = ({
  to,
  rank,
  user,
  userSportProfile,
  score = 0,
  ownedCards = 0,
  totalCards = 0,
  highlighted,
  showClub,
}: Props) => {
  return (
    <Root
      className={classnames({ highlighted, showClub })}
      as={to ? Link : 'div'}
      to={to}
      replace
    >
      <Rank>{rank || '-'}</Rank>
      <AvatarWrapper>
        {user ? (
          <Avatar variant="medium" user={user} />
        ) : (
          <PlaceHolderAvatar variant="medium" user={{ nickname: '?' }} />
        )}
      </AvatarWrapper>
      <Name bold>{user && <UserName user={user} />}</Name>
      {user && showClub && (
        <ClubWrapper>
          <ClubShield
            size={2}
            shieldUrl={userSportProfile?.clubBadge?.pictureUrl}
            clubName={userSportProfile?.clubName}
          />
          <Caption color="var(--c-nd-600)">
            {userSportProfile?.clubName}
          </Caption>
        </ClubWrapper>
      )}
      <CardsWrapper>
        <CardsNumber ownedCards={ownedCards} totalCards={totalCards} />
      </CardsWrapper>
      <ScoreWrapper>
        <Score score={score} />
      </ScoreWrapper>
    </Root>
  );
};

CollectionLeaderboardRow.fragments = {
  PublicUserInfoInterface: gql`
    fragment CollectionLeaderboardRow_PublicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...Avatar_publicUserInfoInterface
      ...UserName_publicUserInfoInterface
    }
    ${Avatar.fragments.publicUserInfoInterface}
    ${UserName.fragments.user}
  ` as TypedDocumentNode<CollectionLeaderboardRow_PublicUserInfoInterface>,
  UserSportProfileInterface: gql`
    fragment CollectionLeaderboardRow_UserSportProfileInterface on UserSportProfileInterface {
      id
      clubName
      clubBadge {
        id
        pictureUrl
      }
    }
  ` as TypedDocumentNode<CollectionLeaderboardRow_UserSportProfileInterface>,
};
