import { TypedDocumentNode, gql } from '@apollo/client';
import { faExchangeAlt } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Tradeable } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import {
  AndroidAppFeature,
  NotMobileAppFeature,
  UnvailableButtonForAndroid,
} from '@sorare/core/src/components/BuyableFeature';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import useLoggedCallback from '@sorare/core/src/hooks/useLoggedCallback';
import useToggle from '@sorare/core/src/hooks/useToggle';
import { useTokenBelongsToUser } from '@sorare/core/src/hooks/useTokenBelongsToUser';
import { isTransferable } from '@sorare/core/src/lib/deal';
import { glossary, mobileApp } from '@sorare/core/src/lib/glossary';

import NewOfferBuilder from 'components/directOffer/NewOfferBuilder';
import { useClickTradeEvent } from 'hooks/events/useClickTradeEvent';
import useCannotBuy from 'hooks/offers/useCannotBuy';

import { MakeOffer_anyCard } from './__generated__/index.graphql';

type Props = {
  card: MakeOffer_anyCard;
};

const statusMessages = defineMessages<Tradeable>({
  [Tradeable.NOT_YET]: {
    id: 'MakeOffer.not_yet',
    defaultMessage: 'This Card is not transferable yet. It will be soon!',
  },
  [Tradeable.NO]: {
    id: 'MakeOffer.no',
    defaultMessage:
      'This Card cannot be sold on Sorare. The owner must transfer it to their Sorare account.',
  },
  [Tradeable.YES]: {
    id: 'MakeOffer.internal',
    defaultMessage: 'You can make an offer on this Card.',
  },
  [Tradeable.UNDEFINED]: {
    id: 'MakeOffer.undefined',
    defaultMessage: 'This Card has no owner.',
  },
  [Tradeable.DEPOSIT_REQUIRED]: {
    id: 'MakeOffer.deposit_required',
    defaultMessage: 'You need to make a deposit',
  },
});

const Root = styled.div`
  display: flex;
`;
const StyledButton = styled(Button)`
  width: 100%;
  &.white:disabled {
    background: var(--c-nd-200);
    color: var(--c-nd-600);
    border: unset;
  }
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const ButtonContent = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;
  & > * + * {
    margin-left: 0;
  }
`;

export const MakeOffer = ({ card }: Props) => {
  const { formatMessage } = useIntl();
  const trackClickTrade = useClickTradeEvent();

  const [open, toggleOpen] = useToggle(false);
  const belongsToUser = useTokenBelongsToUser();
  const cannotBuy = useCannotBuy();
  const { currentUser } = useCurrentUserContext();

  const onClick = useLoggedCallback(() => {
    toggleOpen();
    trackClickTrade();
  });

  const { tradeableStatus, liveSingleBuyOffers, user } = card;
  if (belongsToUser(card) || !card.user) return null;

  if (!user || (liveSingleBuyOffers && liveSingleBuyOffers.length > 0))
    return null;

  return (
    <>
      <NotMobileAppFeature>
        <Root>
          <StyledTooltip
            title={formatMessage(
              cannotBuy(card) || statusMessages[tradeableStatus]
            )}
          >
            <StyledButton
              color="tertiary"
              onClick={onClick}
              disabled={Boolean(cannotBuy(card)) || !isTransferable(card)}
              size="medium"
            >
              <ButtonContent>
                <FontAwesomeIcon icon={faExchangeAlt} />
                <FormattedMessage tagName="span" {...glossary.trade} />
              </ButtonContent>
            </StyledButton>
          </StyledTooltip>
          {open && currentUser && (
            <NewOfferBuilder
              receiveCards={[card]}
              to={user}
              onClose={toggleOpen}
              currentUser={currentUser}
            />
          )}
        </Root>
      </NotMobileAppFeature>
      <AndroidAppFeature>
        <UnvailableButtonForAndroid
          color="secondary"
          onClick={() => {}}
          size="medium"
          fullWidth
          message={mobileApp.disabledTrade}
          icon={faExchangeAlt}
        />
      </AndroidAppFeature>
    </>
  );
};

MakeOffer.fragments = {
  anyCard: gql`
    fragment MakeOffer_anyCard on AnyCardInterface {
      slug
      tradeableStatus
      user {
        slug
        id
        sorareAddress
        nickname
        ...NewOfferBuilder_publicUserInfoInterface
      }
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
      }
      liveSingleBuyOffers @skip(if: $onlyPrimary) {
        id
      }
      ...useTokenBelongsToUser_anyCard
      ...useCannotBuy_anyCard
      ...NewOfferBuilder_anyCard
    }
    ${useCannotBuy.fragments.anyCard}
    ${NewOfferBuilder.fragments.anyCard}
    ${NewOfferBuilder.fragments.user}
    ${useTokenBelongsToUser.fragments.anyCard}
  ` as TypedDocumentNode<MakeOffer_anyCard>,
};

export default MakeOffer;
