import { TypedDocumentNode, gql } from '@apollo/client';

import { withFragments } from 'lib/gql';

import { drawPlayerName_AnyCardInterface } from './__generated__/drawPlayerName.graphql';

export const drawPlayerName = withFragments(
  async ({
    ctx,
    card,
    x,
    y,
    scale,
    fontName,
  }: {
    ctx: CanvasRenderingContext2D;
    card: drawPlayerName_AnyCardInterface;
    x: number;
    y: number;
    scale: number;
    fontName: string;
  }) => {
    ctx.textAlign = 'center';
    ctx.font = `bold ${35 * scale}px ${fontName}`;

    ctx.fillStyle = '#fff';
    ctx.fillText(card.anyPlayer.displayName.toLocaleUpperCase(), x, y);
  },
  {
    AnyCardInterface: gql`
      fragment drawPlayerName_AnyCardInterface on AnyCardInterface {
        slug

        anyPlayer {
          slug
          displayName
        }
      }
    ` as TypedDocumentNode<drawPlayerName_AnyCardInterface>,
  }
);
