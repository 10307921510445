import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';

import { Bold } from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import { useCurrentUserContext } from 'contexts/currentUser';
import { getName } from 'lib/cards';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { Rewards } from './Rewards';
import { AuctionNotification_auctionNotification } from './__generated__/index.graphql';
import { useAuctionLink } from './useAuctionLink';

type Props = CommonNotificationProps & {
  notification: AuctionNotification_auctionNotification;
};

const messages = defineMessages({
  card_bought: {
    id: 'Activity.Notifications.cardBought',
    defaultMessage: 'You won an auction on <b>{card}</b>',
  },
  higher_bid: {
    id: 'Activity.Notifications.higherBid',
    defaultMessage: 'Your bid on <b>{card}</b> has been outbid',
  },
});

export const AuctionNotification = ({ notification, ...rest }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { name, createdAt, tokenAuction, rewards, sport, read } = notification;
  const card = tokenAuction.cards.map(c => getName(c)).join(', ');
  const makeAuctionLink = useAuctionLink();

  const link = makeAuctionLink(tokenAuction);

  const title = messages[name as keyof typeof messages];

  const titleNode = (
    <>
      {title && <FormattedMessage {...title} values={{ b: Bold, card }} />}
      {name === 'card_bought' && <Rewards rewards={rewards} />}
    </>
  );

  return (
    <DumbNotification
      name={name}
      title={titleNode}
      userAvatar={currentUser}
      cardPicture={tokenAuction.cards[0]}
      link={link}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

AuctionNotification.fragments = {
  auctionNotification: gql`
    fragment AuctionNotification_auctionNotification on AuctionNotification {
      id
      ...Notification_notificationInterface
      tokenAuction {
        id
        ...AuctionLink_auction
        cards: anyCards {
          slug
          sport
          ...DumbNotification_cardPicture
          player: anyPlayer {
            slug
            displayName
          }
          ...getName_anyCard
        }
      }
      rewards {
        id
        ...Rewards_anyRewardInterface
      }
    }
    ${useAuctionLink.fragments.auctionLink}
    ${commonNotificationInterfaceFragment}
    ${DumbNotification.fragments.cardPicture}
    ${getName.fragments.anyCard}
    ${Rewards.fragments.anyReward}
  ` as TypedDocumentNode<AuctionNotification_auctionNotification>,
};
