import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { SmallCaps } from 'atoms/typography/SmallCaps';
import { glossary } from 'lib/glossary';

const DefaultSup = styled.sup`
  font-size: inherit;
`;

type Props = {
  i18n?: MessageDescriptor;
  align?: 'center' | 'top';
  ordinal: number;
  Sup?: React.ElementType<any>;
};
export const Ordinal = ({
  i18n = glossary.ordinalStyled,
  align = 'top',
  Sup = DefaultSup,
  ordinal,
}: Props) => {
  if (align === 'top') {
    return (
      <FormattedMessage
        {...i18n}
        values={{ ordinal, sup: c => <Sup>{c}</Sup> }}
      />
    );
  }
  return (
    <Horizontal gap={0.5} as="span">
      <FormattedMessage
        {...i18n}
        values={{
          ordinal,
          sup: c => <SmallCaps>{c}</SmallCaps>,
        }}
      />
    </Horizontal>
  );
};
