import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { PoweredByRotowire } from '@sorare/core/src/components/rotowire/PoweredByRotowire';

import {
  GameWeekProjection_AnyPlayerInterface,
  GameWeekProjection_PlayerGameScoreInterface,
} from './__generated__/index.graphql';

type Props = {
  player: GameWeekProjection_AnyPlayerInterface;
  playerGameScores?: (GameWeekProjection_PlayerGameScoreInterface | null)[];
};

const ScoreText = styled.text`
  font-weight: bold;
  font-size: var(--fs-12);
  font-family: 'Helvetica';
`;

export const GameWeekProjection = ({
  player,
  playerGameScores = [],
}: Props) => {
  let projectedScore = null;

  if (player.sport === Sport.NBA) {
    if (playerGameScores.length === 0) {
      projectedScore = 0;
    } else {
      projectedScore = Math.max.apply(
        null,
        playerGameScores.map(pGS => pGS?.projectedScore ?? 0)
      );
    }
  }
  if (projectedScore === null) return null;
  return (
    <Tooltip
      title={
        <Vertical gap={0.5} center>
          <FormattedMessage
            id="GameWeekProjection.tooltip"
            defaultMessage="Game Week Projection"
          />
          <PoweredByRotowire />
        </Vertical>
      }
    >
      <svg width={32} height={33} viewBox="0 0 32 33" fill="none">
        <path
          d="M12.6994 0.753381C14.4858 -0.251127 16.6866 -0.251127 18.4729 0.753381L27.6994 5.94173C29.4858 6.94623 30.5862 8.80264 30.5862 10.8117V21.1883C30.5862 23.1974 29.4858 25.0538 27.6994 26.0583L18.4729 31.2466C16.6866 32.2511 14.4858 32.2511 12.6994 31.2466L3.47293 26.0583C1.6866 25.0538 0.586182 23.1974 0.586182 21.1883V10.8117C0.586182 8.80264 1.68661 6.94623 3.47293 5.94173L12.6994 0.753381Z"
          fill="#122335"
        />
        <ScoreText
          x="50%"
          y="50%"
          fill="white"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {Math.floor(projectedScore)}
        </ScoreText>
        <path
          d="M18.2279 1.1892L27.4544 6.37754C29.0872 7.29572 30.0862 8.98777 30.0862 10.8117V21.1883C30.0862 23.0122 29.0872 24.7043 27.4544 25.6225L18.2279 30.8108C16.5937 31.7297 14.5787 31.7297 12.9445 30.8108L3.71801 25.6225C2.08521 24.7043 1.08618 23.0122 1.08618 21.1883V10.8117C1.08618 8.98777 2.08521 7.29572 3.71801 6.37754L12.9445 1.1892C14.5787 0.270267 16.5937 0.270267 18.2279 1.1892Z"
          stroke="white"
          strokeOpacity="0.1"
        />
      </svg>
    </Tooltip>
  );
};

GameWeekProjection.fragments = {
  playerGameScoreInterface: gql`
    fragment GameWeekProjection_PlayerGameScoreInterface on PlayerGameScoreInterface {
      id
      projectedScore
    }
  ` as TypedDocumentNode<GameWeekProjection_PlayerGameScoreInterface>,
  anyPlayerInterface: gql`
    fragment GameWeekProjection_AnyPlayerInterface on AnyPlayerInterface {
      slug
      sport
    }
  ` as TypedDocumentNode<GameWeekProjection_AnyPlayerInterface>,
};
