import { TypedDocumentNode, gql } from '@apollo/client';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { ComponentType, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMeasure } from 'react-use';
import styled, { keyframes } from 'styled-components';

import {
  BlueprintRevealStatus,
  Rarity,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { Block } from '@sorare/core/src/atoms/layout/Block';
import { BlockHeader } from '@sorare/core/src/atoms/layout/BlockHeader';
import { Container } from '@sorare/core/src/atoms/layout/Container';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  Text14,
  Title2,
  Title3,
  Title6,
} from '@sorare/core/src/atoms/typography';
import { Card3DWithFallback } from '@sorare/core/src/components/3d/Card3DWithFallback';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { CardDescriptionFromProps } from '@sorare/core/src/components/token/CardDescriptionFromProps';
import {
  LEGACY_PLAYER_SHOW,
  LEGACY_PLAYER_SHOW_CARDS,
} from '@sorare/core/src/constants/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { isType } from '@sorare/core/src/gql';
import { useDecks } from '@sorare/core/src/hooks/decks/useDecks';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { useViewItem } from '@sorare/core/src/hooks/events/useViewItem';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';
import {
  ScrollTo,
  useScrollTo,
  useScrollToQueryParameter,
} from '@sorare/core/src/hooks/scrollTo';
import { useSafePreviousNavigate } from '@sorare/core/src/hooks/useSafePreviousNavigate';
import { getHumanReadableSerialNumber } from '@sorare/core/src/lib/cards';
import { glossary } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { scarcityMessages } from '@sorare/core/src/lib/scarcity';
import { livePrimaryOffer } from '@sorare/core/src/lib/token';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import BidHistory from 'components/auction/BidHistory';
import OpenAuction from 'components/auction/OpenAuction';
import { AddTokenToDeck } from 'components/decks/AddTokenToDeck';
import DeckEditorDialog from 'components/decks/DeckEditorDialog';
import CurrentOwner from 'components/offer/CurrentOwner';
import SingleSaleOffer from 'components/offer/SingleSaleOffer';
import { AnyCardPriceHistory } from 'components/price/AnyCardPriceHistory';
import OpenPrimaryBuy from 'components/primaryBuy/OpenPrimaryBuy';
import OwnershipHistory from 'components/token/OwnershipHistory';
import BlockchainInfo from 'components/token/TokenPage/BlockchainInfo';
import TokensAvailableOnPrimaryWhenInsufficientFundsInWallet from 'components/token/TokenPage/TokensAvailableOnPrimaryWhenInsufficientFundsInWallet';
import { tokenPageMessages } from 'components/token/TokenPage/tokenPageMessages';
import TokenWithdrawal from 'components/token/TokenWithdrawal';

import { Actions } from './Actions';
import CardAttributes from './CardAttributes';
import { CompetitionsEligibility } from './CompetitionsEligibility';
import { EarlyAccessMetas } from './EarlyAccessMetas';
import MyOffers from './MyOffers';
import RevealDelayedWarning from './RevealDelayedWarning';
import { GenericCardPage_anyCard } from './__generated__/index.graphql';

type Props<T> = {
  card: T | undefined | null;
  loadMoreBids: (
    reload: boolean,
    variable: { bidCursor: string }
  ) => Promise<unknown>;
  loading?: boolean;
  InfiniteScrollLoader?: ReactNode;
  CardProperties: ComponentType<{ card: T; withTransferMalus: boolean }>;
  CardLevel: ComponentType<{ card: T }>;
  CardEligibility?: ComponentType<{ card: T }>;
  LastScores: ComponentType<{ card: T; InfiniteScrollLoader: ReactNode }>;
  News?: ComponentType<{ card: T }>;
  CollectionInfo?: ComponentType<{ card: T; header: ReactNode }>;
  PlayerUnavailability?: ComponentType<{ card: T }>;
};

const Sticky = styled.div`
  position: sticky;
  z-index: 1;
  top: 0;
`;
const Header = styled(SBHorizontal).attrs({ gap: 0 })`
  background: var(--c-black);
  padding: var(--unit) 0;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--triple-unit);
  @media ${laptopAndAbove} {
    flex-direction: row;
    align-items: flex-start;
  }
`;
const scaleDown = keyframes`
  to {
    transform: scale(0.8);
  }
`;
const CardContainer = styled.div<{ headerHeight: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 580px;
  margin: 0 auto;
  animation: ${scaleDown} auto linear;
  animation-timeline: scroll();
  @media screen and (min-height: 450px) {
    position: sticky;
    height: ${({ headerHeight }) =>
      `calc(var(--100vh) - ${headerHeight}px - 80px)`}; /** substracting the amount of visible px of the next block */
    top: ${({ headerHeight }) => `${headerHeight}px`};
  }
  @media ${laptopAndAbove} {
    animation: none;
    justify-content: flex-start;
    height: ${({ headerHeight }) =>
      `calc(var(--100vh) - ${headerHeight}px - var(--double-unit))`}; /** bottom margin */
  }
`;
const CardWrapper = styled.div`
  max-width: 340px;
  margin: 0 auto;
  max-height: calc(min(340px, 100vw) / (var(--card-aspect-ratio)));
  min-height: 0;
  width: 100%;
`;
const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--triple-unit);
  color: var(--c-white);
  background: var(--c-nd-100-opaque);
  border-radius: var(--double-unit) var(--double-unit) 0 0;
  padding: var(--double-unit);
  @media ${laptopAndAbove} {
    background: none;
    min-width: 0;
  }
`;

const auctionAreaId = (auction: { id: string }) => `bidding-${auction.id}`;

const Details = styled(Title6)`
  color: var(--c-nd-600);
`;

export const GenericCardPage = <T extends GenericCardPage_anyCard>(
  props: Props<T>
) => {
  const {
    card,
    loadMoreBids,
    loading,
    LastScores,
    CardProperties,
    CardLevel,
    CardEligibility,
    CollectionInfo,
    News,
    InfiniteScrollLoader,
    PlayerUnavailability,
  } = props;
  const scrollToQueryParameter = useScrollToQueryParameter();
  const cardDialogParameter = useDialogParam(DialogKey.card);
  const scrollTo = cardDialogParameter ? undefined : scrollToQueryParameter;
  const { up: isLaptop } = useScreenSize('laptop');
  const { sport, generateSportContextPath } = useSportContext();
  const { decks } = useDecks({ sport: sport || Sport.FOOTBALL });
  const [addToListDialogOpen, setAddToListDialogOpen] = useState(false);
  const [createListDialogOpen, setCreateListDialogOpen] = useState(false);
  const { formatMessage } = useIntl();
  const [measurementDiv, { height }] = useMeasure<HTMLDivElement>();
  const scrollToBidForAuction = useScrollTo(auctionAreaId);
  const goBack = useSafePreviousNavigate(
    generateSportContextPath(LEGACY_PLAYER_SHOW_CARDS, {
      params: {
        slug: card?.anyPlayer.slug || '',
      },
      sport,
    })
  );
  const { isMe } = useCurrentUserContext();

  const withTransferMalus =
    card?.liveSingleSaleOffer &&
    isType(card.liveSingleSaleOffer.sender, 'User') &&
    !isMe(card.liveSingleSaleOffer.sender);

  useEffect(() => {
    if (scrollTo === ScrollTo.Bid && card?.latestEnglishAuction) {
      scrollToBidForAuction(card?.latestEnglishAuction);
    }
  }, [scrollTo, card?.latestEnglishAuction, scrollToBidForAuction]);

  useViewItem({ id: `card-${card?.anyPlayer?.slug}`, skip: !card });

  if (!card) {
    return <LoadingIndicator fullHeight />;
  }

  const { latestEnglishAuction } = card;

  const cardTitle = (
    <CardDescriptionFromProps
      displayName={card.anyPlayer.displayName}
      description={`${card.seasonYear} - ${getHumanReadableSerialNumber(card)}`}
      Title={Title2}
      Details={Details}
      scarcity={card.rarityTyped}
      path={generateSportContextPath(LEGACY_PLAYER_SHOW, {
        params: { slug: card.anyPlayer.slug },
      })}
    />
  );

  const displayEarlyAccessMetas =
    card.blueprint &&
    !card.cardEditionName?.match(/neon.christmas/i) &&
    card.blueprint?.revealStatus !== BlueprintRevealStatus.REVEALED;

  const rarityForPriceSaleHistory =
    (card.rarityTyped === Rarity.common ? Rarity.limited : card.rarityTyped) ||
    Rarity.limited;

  return (
    <>
      <Container>
        <Sticky ref={measurementDiv}>
          <Header>
            {isLaptop ? (
              <Horizontal>
                <IconButton
                  icon={faChevronLeft}
                  color="tertiary"
                  small
                  onClick={goBack}
                />
                <Text14 color="var(--c-white)">
                  <FormattedMessage {...glossary.back} />
                </Text14>
              </Horizontal>
            ) : (
              cardTitle
            )}
            <Actions
              loading={loading}
              onBack={isLaptop ? undefined : goBack}
              onAddToListClick={() => {
                if (decks.length) {
                  setAddToListDialogOpen(true);
                } else {
                  setCreateListDialogOpen(true);
                }
              }}
              card={card}
            />
          </Header>
        </Sticky>
        <InnerContainer>
          <CardContainer headerHeight={height}>
            <CardWrapper>
              <Card3DWithFallback card={card} />
            </CardWrapper>
            <CardProperties
              card={card}
              withTransferMalus={!!withTransferMalus}
            />
          </CardContainer>
          <Content>
            {isLaptop && cardTitle}
            {displayEarlyAccessMetas && <EarlyAccessMetas />}
            <CardLevel card={card} />
            <CurrentOwner card={card} />
            {latestEnglishAuction?.open && (
              <>
                <div id={auctionAreaId(latestEnglishAuction)}>
                  <OpenAuction
                    auction={latestEnglishAuction}
                    showBundleContext
                  />
                </div>
                {latestEnglishAuction?.bids && (
                  <Vertical>
                    <Title3>
                      <FormattedMessage
                        {...tokenPageMessages.bidCount}
                        values={{
                          count: latestEnglishAuction.bids.totalCount,
                        }}
                      />
                    </Title3>
                    <BidHistory
                      bids={latestEnglishAuction.bids}
                      loadMoreBids={loadMoreBids}
                      loading={!!loading}
                    />
                  </Vertical>
                )}
              </>
            )}
            {card.latestPrimaryOffer && livePrimaryOffer(card) && (
              <div>
                <OpenPrimaryBuy card={card} />
              </div>
            )}
            <SingleSaleOffer card={card} />
            {card && <MyOffers card={card as GenericCardPage_anyCard} />}
            <RevealDelayedWarning card={card} />
            {CardEligibility && <CardEligibility card={card} />}
            <Vertical>
              <BlockHeader
                title={
                  <FormattedMessage
                    id="CardPage.details"
                    defaultMessage="Card details"
                  />
                }
              />
              <CardAttributes card={card} />
            </Vertical>
            <CompetitionsEligibility card={card} />
            {PlayerUnavailability && <PlayerUnavailability card={card} />}
            <LastScores
              card={card}
              InfiniteScrollLoader={InfiniteScrollLoader}
            />
            {News && <News card={card} />}
            {CollectionInfo && (
              <CollectionInfo
                card={card}
                header={
                  <BlockHeader
                    title={
                      <FormattedMessage
                        id="CardPage.collection"
                        defaultMessage="Collection"
                      />
                    }
                  />
                }
              />
            )}
            {card && !card.latestEnglishAuction?.open && (
              <TokensAvailableOnPrimaryWhenInsufficientFundsInWallet
                sport={card.sport}
                hitsPerRow={2}
                card={card}
              />
            )}
            {card && card.rarityTyped !== Rarity.unique && (
              <Vertical>
                <BlockHeader
                  title={
                    <FormattedMessage
                      {...tokenPageMessages.last5Sales}
                      values={{
                        rarity: formatMessage(
                          scarcityMessages[rarityForPriceSaleHistory]
                        ),
                      }}
                    />
                  }
                />
                <AnyCardPriceHistory context="card_page" card={card} />
              </Vertical>
            )}
            {card.ipfsUrl && (
              <Vertical>
                <BlockHeader
                  title={formatMessage(tokenPageMessages.blockchainInfoTitle)}
                />
                <Block>
                  <Vertical>
                    <BlockchainInfo card={card} />
                    <TokenWithdrawal card={card} />
                  </Vertical>
                </Block>
              </Vertical>
            )}
            {card && (
              <OwnershipHistory
                title={
                  <BlockHeader
                    title={formatMessage(
                      tokenPageMessages.ownershipHistoryTitle
                    )}
                  />
                }
                card={card}
              />
            )}
          </Content>
        </InnerContainer>
      </Container>
      {addToListDialogOpen && (
        <AddTokenToDeck
          card={card}
          onClose={() => setAddToListDialogOpen(false)}
          addList={() => {
            setAddToListDialogOpen(false);
            setCreateListDialogOpen(true);
          }}
        />
      )}
      {createListDialogOpen && (
        <DeckEditorDialog
          sport={card.sport}
          onClose={() => {
            setAddToListDialogOpen(true);
            setCreateListDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

GenericCardPage.fragments = {
  anyCard: gql`
    fragment GenericCardPage_anyCard on AnyCardInterface {
      slug
      anyPlayer {
        slug
        displayName
      }
      pictureUrl
      collection
      rarityTyped
      seasonYear
      cardEditionName
      blueprint {
        id
        revealStatus
      }
      latestEnglishAuction {
        id
        open
        bids(first: 5, after: $bidCursor) {
          ...BidHistory_tokenBidConnection
        }
        eligibleForBlueprintRewards
        ...OpenAuction_auction
      }
      user {
        slug
      }
      latestPrimaryOffer {
        id
        endDate
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
        eligibleForBlueprintRewards
      }
      liveSingleSaleOffer {
        id
        sender {
          ... on User {
            slug
          }
        }
      }
      ...Actions_card
      ...CardAttributes_anyCard
      ...CurrentOwner_anyCard
      ...SingleSaleOffer_anyCard
      ...AnyCardPriceHistory_anyCard
      ...CompetitionsEligibility_anyCard
      ...OpenPrimaryBuy_anyCard
      ...OwnershipHistory_anyCard
      ...BlockchainInfo_anyCard
      ...TokenWithdrawal_anyCard
      ...TokensAvailableOnPrimaryWhenInsufficientFundsInWallet_anyCard
      ...MyOffers_anyCard
      ...AddTokenToDeck_anyCard
      ...Card3DWithFallback_anyCardInterface
      ...getHumanReadableSerialNumber_anyCard
      ...RevealDelayedWarning_anyCard
    }
    ${MyOffers.fragments.anyCard}
    ${OpenAuction.fragments.auction}
    ${TokenWithdrawal.fragments.anyCard}
    ${BidHistory.fragments.bid}
    ${OwnershipHistory.fragments.anyCard}
    ${BlockchainInfo.fragments.anyCard}
    ${TokensAvailableOnPrimaryWhenInsufficientFundsInWallet.fragments.anyCard}
    ${AddTokenToDeck.fragments.anyCard}
    ${OpenPrimaryBuy.fragments.anyCard}
    ${CompetitionsEligibility.fragments.anyCard}
    ${monetaryAmountFragment}
    ${CurrentOwner.fragments.anyCard}
    ${Actions.fragments.card}
    ${CardAttributes.fragments.card}
    ${SingleSaleOffer.fragments.anyCard}
    ${AnyCardPriceHistory.fragments.anyCard}
    ${Card3DWithFallback.fragments.anyCardInterface}
    ${getHumanReadableSerialNumber.fragments.anyCard}
    ${RevealDelayedWarning.fragments.anyCard}
  ` as TypedDocumentNode<GenericCardPage_anyCard>,
};

export default GenericCardPage;
