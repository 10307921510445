import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { CardScore } from '@sorare/core/src/components/collections/CardScore';
import { Warning } from '@sorare/core/src/components/collections/Warning';
import { isMyCardListedOnMarket } from '@sorare/core/src/lib/cards';
import { fantasy } from '@sorare/core/src/lib/glossary';

import useCancelOffer from '@sorare/marketplace/src/hooks/offers/useCancelOffer';

import { CollectionBonuses } from '@sorare/gameplay/src/components/collections/CollectionBonuses';

import { Bonuses_card } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 1.5 })`
  border-radius: var(--double-unit);
  padding: var(--double-unit);
  background: var(--c-nd-50);
`;

type Props = { card: Bonuses_card };

export const Bonuses = ({ card }: Props) => {
  const cancelOffer = useCancelOffer();
  const [unlisting, setUnlisting] = useState(false);
  const [unlisted, setUnlisted] = useState(false);
  const cardCollectionCard = card.cardCollectionCards[0] as
    | Bonuses_card['cardCollectionCards'][0]
    | undefined;

  if (!cardCollectionCard) return null;
  const { scoreBreakdown } = cardCollectionCard;
  const { __typename, total, ...scores } = scoreBreakdown;
  const theoricalScore = Object.values(scores).reduce(
    (sum, value) => sum + value
  );
  const cardListed = isMyCardListedOnMarket(card);
  const displayWarning = cardListed && !unlisted;
  const onUnlist = async () => {
    const offer = card.myMintedSingleSaleOffer;
    if (offer?.blockchainId) {
      setUnlisting(true);
      const errors = await cancelOffer(offer.blockchainId);
      setUnlisted(!errors?.length);
      setUnlisting(false);
    }
  };

  return (
    <Root>
      <SBHorizontal gap={0}>
        <Text16 bold>
          <FormattedMessage {...fantasy.total} />
        </Text16>
        <CardScore
          score={unlisted ? theoricalScore : total}
          listed={displayWarning}
        />
      </SBHorizontal>
      {displayWarning && <Warning onClick={onUnlist} loading={unlisting} />}

      <CollectionBonuses
        cardCollectionCard={cardCollectionCard}
        displayWarning={false}
      />
    </Root>
  );
};

Bonuses.fragments = {
  card: gql`
    fragment Bonuses_card on Card {
      slug
      cardCollectionCards {
        id
        scoreBreakdown {
          firstOwner
          firstSerialNumber
          holding
          owner
          shirtMatchingSerialNumber
          specialEdition
          total
        }
        ...CollectionBonuses_cardCollectionCard
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        blockchainId
      }
      ...isMyCardListedOnMarket_anyCard
    }
    ${CollectionBonuses.fragments.cardCollectionCard}
    ${isMyCardListedOnMarket.fragments.anyCard}
  ` as TypedDocumentNode<Bonuses_card>,
};
