import styled from 'styled-components';

import { Caption } from 'atoms/typography';

import { useIntlContext } from '..';

export type Props = {
  date: Date;
};

const Root = styled(Caption)`
  white-space: nowrap;
`;

export const Since = ({ date }: Props) => {
  const { formatDistanceToNow } = useIntlContext();

  return <Root color="var(--c-nd-600)">{formatDistanceToNow(date)}</Root>;
};

export default Since;
