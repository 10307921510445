import { ReactNode } from 'react';
import styled from 'styled-components';

import { NavLink } from 'atoms/buttons/NavLink';

const Root = styled.nav`
  display: flex;
  white-space: nowrap;
  overflow: auto;
  scroll-snap-type: x mandatory;
  border-bottom: 1px solid var(--c-nd-50);
  min-height: 40px;
`;
const Tab = styled(NavLink)`
  display: block;
  scroll-snap-align: start;
  padding: var(--unit) var(--double-unit);
  border-bottom: 2px solid transparent;
  color: var(--c-nd-600-opaque);
  &.active {
    border-color: var(--c-brand-600);
    font-weight: var(--t-bold);
    color: var(--c-white);
  }
  &:hover,
  &:active,
  &:focus {
    color: var(--c-white);
  }
`;

type Props = {
  items: { to: string; label: ReactNode; end?: false; replace?: boolean }[];
};

export const NavigationTabs = ({ items }: Props) => {
  return (
    <Root>
      {items.map(({ to, label, end = true, replace }) => (
        <Tab key={to} to={to} end={end} replace={replace}>
          {label}
        </Tab>
      ))}
    </Root>
  );
};
