import classNames from 'classnames';
import styled from 'styled-components';

import { PLAYER_PLACEHOLDER } from 'constants/assets';

import { VariantStyle } from './types';

export interface Props {
  name: string;
  avatarUrl: string | null;
  variant?: VariantStyle;
  className?: string;
}

const Root = styled.img`
  width: var(--size, calc(var(--unit) * 5));
  height: var(--size, calc(var(--unit) * 5));
  user-select: none;
  border-radius: var(--unit);
  object-fit: contain;
  &.medallion {
    border-radius: 50%;
  }
`;

export const DumbPlayerAvatar = (props: Props) => {
  const { name, avatarUrl, variant = 'rounded', className } = props;

  return (
    <Root
      className={classNames(variant, className)}
      alt={name}
      src={avatarUrl || PLAYER_PLACEHOLDER}
    />
  );
};
