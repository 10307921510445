import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Bold } from 'atoms/typography/Bold';
import { DumbNotification } from 'components/activity/DumbNotification';
import {
  FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID,
  FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { idFromObject } from 'gql/idFromObject';
import { getName } from 'lib/cards';

import { commonNotificationInterfaceFragment } from '../fragments';
import { So5LineupNotification_so5LineupNotification } from './__generated__/index.graphql';

type Props = {
  notification: So5LineupNotification_so5LineupNotification;
};

const messages = defineMessages({
  so5_lineup_too_powerful: {
    id: 'Activity.Notifications.so5LineupTooPowerful',
    defaultMessage: 'Your lineup for <b>{leaderboard}</b> is too powerful',
  },
  so5_lineup_invalid: {
    id: 'Activity.Notifications.so5LineupInvalid',
    defaultMessage: 'Your lineup for <b>{leaderboard}</b> is not valid anymore',
  },
  appearance_destroyed: {
    id: 'Activity.Notifications.appearanceRemoved',
    defaultMessage:
      'Your card <b>{card}</b> has been removed from <b>{leaderboard}</b>',
  },
  so5_lineup_cancelled: {
    id: 'Activity.Notifications.so5LineupCancelled',
    defaultMessage: 'Your lineup for <b>{leaderboard}</b> has been cancelled',
  },
});

export const So5LineupNotification = ({ notification, ...rest }: Props) => {
  const { currentUser } = useCurrentUserContext();

  const { createdAt, name, so5Lineup, card, sport, read } = notification;
  if (!so5Lineup.so5Leaderboard) return null;
  if (!card) return null;

  const link =
    name === 'so5_lineup_cancelled' || name === 'appearance_destroyed'
      ? generatePath(
          FOOTBALL_PLAY_PRO_FIXTURE_COMPETITION_CONTENDER_CONTENDER,
          {
            fixture: so5Lineup.so5Fixture.slug,
            competition: so5Lineup.so5Leaderboard.so5League.slug,
            contender: so5Lineup.so5LeaderboardContender.slug,
          }
        )
      : generatePath(FOOTBALL_COMPOSE_TEAM_LEADERBOARDSLUG_LINEUP_LINEUPID, {
          leaderboardSlug: so5Lineup.so5Leaderboard.slug,
          lineupId: idFromObject(so5Lineup.id),
        });

  const title = messages[name as keyof typeof messages];

  return (
    <DumbNotification
      name={name}
      title={
        title && (
          <FormattedMessage
            {...title}
            values={{
              b: Bold,
              leaderboard: so5Lineup.so5Leaderboard.displayName,
              card: getName(card),
            }}
          />
        )
      }
      userAvatar={currentUser}
      link={link}
      createdAt={createdAt}
      sport={sport}
      read={read}
      {...rest}
    />
  );
};

So5LineupNotification.fragments = {
  so5LineupNotification: gql`
    fragment So5LineupNotification_so5LineupNotification on So5LineupNotification {
      id
      ...Notification_notificationInterface
      card: anyCard {
        slug
        ...getName_anyCard
      }
      so5Lineup {
        id
        so5Fixture {
          slug
        }
        so5LeaderboardContender {
          slug
        }
        so5Leaderboard {
          slug
          displayName
          rarityType
          so5League {
            slug
            name
            displayName
            shortDisplayName
            category
          }
        }
      }
    }
    ${commonNotificationInterfaceFragment}
    ${getName.fragments.anyCard}
  ` as TypedDocumentNode<So5LineupNotification_so5LineupNotification>,
};
