import { TypedDocumentNode, gql } from '@apollo/client';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { ActivityIndicator } from '@sorare/core/src/components/user/ActivityIndicator';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { Block } from '@sorare/core/src/components/user/Block';
import { DiscordUser } from '@sorare/core/src/components/user/DiscordUser';
import { MyClubLink } from '@sorare/core/src/components/user/MyClubLink';
import { TwitterUser } from '@sorare/core/src/components/user/TwitterUser';
import { UserName } from '@sorare/core/src/components/user/UserName';
import TimeLeft from '@sorare/core/src/contexts/ticker/TimeLeft';

import {
  CounterpartUser_publicUserInfoInterface,
  CounterpartUser_tokenOffer,
} from './__generated__/index.graphql';

type Nature = 'sender' | 'receiver';

export interface Props {
  user: CounterpartUser_publicUserInfoInterface;
  offer?: CounterpartUser_tokenOffer;
  nature: Nature;
  block?: () => Promise<void>;
}

const messages = defineMessages({
  to: {
    id: 'CounterpartUser.to',
    defaultMessage: 'To',
  },
  from: {
    id: 'CounterpartUser.from',
    defaultMessage: 'From',
  },
});

const messagesPerNature: { [nature in Nature]: MessageDescriptor } = {
  sender: messages.from,
  receiver: messages.to,
} as const;

const Container = styled(Horizontal)`
  justify-content: left;
`;
const UserInfo = styled.div`
  flex: 1;
  color: var(--c-nd-800);
`;
const UserOrigin = styled.div`
  display: flex;
  gap: var(--half-unit);
`;
const TimeLeftCtn = styled.div`
  display: flex;
  font: var(--t-14);
  color: var(--c-nd-600);
  gap: var(--half-unit);
`;

const DisabledStyledLink = styled.span`
  color: var(--c-white);
  opacity: 0.5;
`;

const SocialWrapper = styled.div`
  display: flex;
  gap: var(--double-unit);
  flex-direction: row;
  color: var(--c-nd-600);
  font: var(--t-14);
`;

export const CounterpartUser = (props: Props) => {
  const { offer, user, nature, block } = props;
  const { formatMessage } = useIntl();
  const { profile } = user;

  return (
    <Container>
      <ActivityIndicator user={user}>
        <Avatar user={user} variant="medium" />
      </ActivityIndicator>
      <UserInfo>
        <UserOrigin>
          <Text16>{formatMessage(messagesPerNature[nature])}</Text16>
          <MyClubLink
            user={user}
            WhenSuspended={DisabledStyledLink}
            sport={
              offer?.senderSide?.cards?.[0]?.sport ||
              offer?.receiverSide?.cards?.[0]?.sport
            }
          >
            <Text16 bold as="div">
              <UserName user={user} />
            </Text16>
          </MyClubLink>
          {block && <Block user={user} block={block} />}
        </UserOrigin>
        {profile && (
          <SocialWrapper>
            <DiscordUser userProfile={profile} />
            <TwitterUser userProfile={profile} />
          </SocialWrapper>
        )}
      </UserInfo>
      {offer?.status && ['minted', 'opened'].includes(offer?.status) && (
        <TimeLeftCtn>
          <FormattedMessage
            id="CounterpartUser.timeleft"
            defaultMessage="{timeleft} left"
            values={{
              timeleft: <TimeLeft time={offer.endDate} />,
            }}
          />
        </TimeLeftCtn>
      )}
    </Container>
  );
};

CounterpartUser.fragments = {
  user: gql`
    fragment CounterpartUser_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...Avatar_publicUserInfoInterface
      ...ActivityIndicator_user
      ...BlockButton_publicUserInfoInterface
      ...UserName_publicUserInfoInterface
      nickname
      profile {
        id
        ...DiscordUser_userProfile
        ...TwitterUser_userProfile
      }
    }
    ${Avatar.fragments.publicUserInfoInterface}
    ${ActivityIndicator.fragments.user}
    ${DiscordUser.fragments.userProfile}
    ${TwitterUser.fragments.userProfile}
    ${Block.fragments.user}
    ${UserName.fragments.user}
  ` as TypedDocumentNode<CounterpartUser_publicUserInfoInterface>,
  tokenOffer: gql`
    fragment CounterpartUser_tokenOffer on TokenOffer {
      id
      status
      endDate
      receiverSide {
        id
        cards: anyCards {
          slug
          sport
        }
      }
      senderSide {
        id
        cards: anyCards {
          slug
          sport
        }
      }
    }
  ` as TypedDocumentNode<CounterpartUser_tokenOffer>,
};

export default CounterpartUser;
