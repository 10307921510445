import { TypedDocumentNode, gql } from '@apollo/client';
import { useState } from 'react';
import { defineMessages } from 'react-intl';

import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import usePrevious from '@sorare/core/src/hooks/usePrevious';
import { sendSafeError } from '@sorare/core/src/lib/error';

import useBestBidBelongsToUser from 'hooks/auctions/useBestBidBelongsToUser';

import BidBundleSummary from '../../BidBundleSummary';
import BidTokenSummary from '../../BidTokenSummary';
import BidConfirmedDialog from '../BidConfirmedDialog';
import BidPaymentModal from '../BidPaymentModal';
import {
  BidPaymentFlowQuery,
  BidPaymentFlowQueryVariables,
} from './__generated__/index.graphql';

export const messages = defineMessages({
  increaseBid: { id: 'BidField.increase', defaultMessage: 'Increase bid' },
  placeBid: { id: 'BidField.placeBid', defaultMessage: 'Place bid' },
});

const BID_PAYMENT_FLOW_QUERY = gql`
  query BidPaymentFlowQuery($id: String!) {
    tokens {
      auction(id: $id) {
        id
        anyCards {
          slug
          sport
          ...BidTokenSummary_anyCard
        }
        bestBid {
          id
          bidder {
            ... on User {
              slug
            }
          }
          ...UseBestBidBelongsToUser_bestBid
        }
        ...BidBundleSummary_auction
        ...BidPaymentModal_auction
        ...BidConfirmedDialogContent_tokenAuction
      }
    }
  }
  ${BidPaymentModal.fragments.auction}
  ${BidConfirmedDialog.fragments.tokenAuction}
  ${BidBundleSummary.fragments.auction}
  ${BidTokenSummary.fragments.anyCard}
  ${useBestBidBelongsToUser.fragments.bestBid}
` as TypedDocumentNode<BidPaymentFlowQuery, BidPaymentFlowQueryVariables>;

const LazyBidPaymentFlow = ({
  auctionId,
  onClose,
}: {
  auctionId: string;
  onClose: () => void;
}) => {
  const { showNotification } = useSnackNotificationContext();
  const [showPaymentModal, setShowPaymentModal] = useState(true);
  const [showBidConfirmationModal, setShowBidConfirmationModal] =
    useState(false);
  const { data, error } = useQuery(BID_PAYMENT_FLOW_QUERY, {
    variables: {
      id: idFromObject(auctionId),
    },
  });

  const auction = data?.tokens?.auction;
  const doesBestBidBelongsToUser = useBestBidBelongsToUser();
  const previousBestBid = usePrevious(auction);

  if (error) {
    // This should not happen. Temporary added for monitoring purposes.
    sendSafeError(`Auction not found for id: ${auctionId}`);
    showNotification('errors', { errors: [error.message] });
    onClose();
    return null;
  }

  if (!auction) return null;

  const { anyCards } = auction;

  const bestBidBelongsToUser =
    auction?.bestBid && doesBestBidBelongsToUser(auction.bestBid);

  if (
    !bestBidBelongsToUser &&
    showBidConfirmationModal &&
    auction?.bestBid &&
    previousBestBid?.id !== auction.bestBid.id
  )
    setShowBidConfirmationModal(false);

  return (
    <>
      {showPaymentModal && (
        <BidPaymentModal
          auction={auction}
          onSuccess={() => {
            setShowBidConfirmationModal(true);
            setShowPaymentModal(false);
          }}
          onClose={onClose}
        />
      )}
      <BidConfirmedDialog
        onClose={onClose}
        assetsPreview={
          anyCards.length > 1 ? (
            <BidBundleSummary auction={auction} />
          ) : (
            <BidTokenSummary withoutRecentSales card={anyCards[0]} />
          )
        }
        auction={auction}
        open={showBidConfirmationModal}
      />
    </>
  );
};

export default LazyBidPaymentFlow;
