import { TypedDocumentNode, gql } from '@apollo/client';
import { FC, MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

import { ConversionCreditWithAmounts } from '@sorare/core/src/hooks/useConversionCredits';
import useLoggedCallback from '@sorare/core/src/hooks/useLoggedCallback';
import {
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useHandleWalletStateBeforePayment } from '@sorare/core/src/hooks/wallets/useHandleWalletStateBeforePayment';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { fromWei } from '@sorare/core/src/lib/wei';
import { ClickInstantBuy_Flow } from '@sorare/core/src/protos/events/so5/shared/events';

import { PrimaryBuyConfirmationOptions } from 'contexts/buyingConfirmation';

import PrimaryBuyFlow from '../PrimaryBuyFlow';
import { WebPrimaryOfferBuyFieldWrapper_anyCard } from './__generated__/index.graphql';

export interface Props {
  card: WebPrimaryOfferBuyFieldWrapper_anyCard;
  primaryBuyConfirmationOptions?: PrimaryBuyConfirmationOptions;
  onSuccess?: () => void;
  onBuyConfirmationClose?: () => void;
  origin: ClickInstantBuy_Flow;
  gameSlug?: string;
  showBuyConfirmation?: boolean;
  onlyCreditCards?: boolean;
  conversionCreditDisclaimer?: ReactNode;
  defaultConversionCredit?: ConversionCreditWithAmounts;
  composeSessionId?: string;
  children: FC<
    React.PropsWithChildren<{
      onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
      loading: boolean;
      disabled?: boolean;
    }>
  >;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export const WebPrimaryOfferBuyFieldWrapper = ({
  children,
  card,
  primaryBuyConfirmationOptions,
  onSuccess,
  onBuyConfirmationClose,
  origin,
  gameSlug,
  showBuyConfirmation = true,
  onlyCreditCards = false,
  conversionCreditDisclaimer,
  defaultConversionCredit,
  onClick,
  composeSessionId = '',
}: Props) => {
  const handleWalletStateBeforePayment = useHandleWalletStateBeforePayment();

  const primaryOffer = card.latestPrimaryOffer!;
  const { id, price, signedAmount } = primaryOffer;
  const { toMonetaryAmount } = useMonetaryAmount();
  const [paymentStarted, setPaymentStarted] = useState(false);

  const track = useEvents();
  const loggedTogglePaymentStarted = useLoggedCallback<boolean>(b =>
    handleWalletStateBeforePayment(() => {
      setPaymentStarted(b);
    })
  );

  const priceMonetaryAmount =
    (price && toMonetaryAmount(price)) || zeroMonetaryAmount;
  const onBuyButtonClick = (
    e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    track('Click Instant Buy', {
      offerId: id,
      cardSlug: card.slug,
      ethAmount: fromWei(priceMonetaryAmount.wei),
      eurAmount: priceMonetaryAmount.eur / 100,
      gameSlug,
      origin,
      scarcity: card.rarityTyped,
      sport: card.sport,
      composeSessionId,
    });
    loggedTogglePaymentStarted(true);
    onClick?.(e);
  };

  if (!price || !signedAmount) return null;

  return (
    <>
      {children({
        loading: paymentStarted,
        onClick: onBuyButtonClick,
      })}
      {paymentStarted && (
        <PrimaryBuyFlow
          offerId={id}
          onSuccess={onSuccess}
          onClose={() => setPaymentStarted(false)}
          defaultConversionCredit={defaultConversionCredit}
          primaryBuyConfirmationOptions={primaryBuyConfirmationOptions}
          onBuyConfirmationClose={onBuyConfirmationClose}
          conversionCreditDisclaimer={conversionCreditDisclaimer}
          onlyCreditCards={onlyCreditCards}
          showBuyConfirmation={showBuyConfirmation}
        />
      )}
    </>
  );
};

WebPrimaryOfferBuyFieldWrapper.fragments = {
  anyCard: gql`
    fragment WebPrimaryOfferBuyFieldWrapper_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
      latestPrimaryOffer {
        id
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
        signedAmount
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<WebPrimaryOfferBuyFieldWrapper_anyCard>,
};

export default WebPrimaryOfferBuyFieldWrapper;
