import styled, { css } from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

export const Root = styled(Horizontal)`
  @media ${tabletAndAbove} {
    flex-wrap: wrap;
  }
`;

export const FilterWithLeftDivider = styled.div`
  position: relative;
  padding-left: var(--unit);
  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 60%;
    top: 20%;
    width: 1px;
    background-color: var(--c-nd-100);
  }
`;

export const SegmentButton = styled(ButtonBase)<{
  $customCss?: ReturnType<typeof css>;
}>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: var(--unit);
  padding: var(--half-unit) var(--unit);
  border-radius: var(--unit);
  box-shadow: inset 0px 0px 0px 1px var(--c-nd-100);
  ${({ $customCss }) => $customCss || ''}

  &.checked {
    background: var(--c-white);
    color: var(--c-black);
  }
`;
