import { TypedDocumentNode, gql } from '@apollo/client';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';

import Follow from '../Follow';
import { Subscriptions_subscription } from './__generated__/index.graphql';

interface Props {
  subscriptions: Subscriptions_subscription[];
}

export const Subscriptions = ({ subscriptions }: Props) => (
  <Vertical>
    {subscriptions.map(f => (
      <Follow key={f.slug} subscription={f} />
    ))}
  </Vertical>
);

Subscriptions.fragments = {
  subscription: gql`
    fragment Subscriptions_subscription on EmailSubscription {
      slug
      ...Follow_subscription
    }
    ${Follow.fragments.subscription}
  ` as TypedDocumentNode<Subscriptions_subscription>,
};

export default Subscriptions;
