import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { DisplayL, LabelM } from '@sorare/core/src/atoms/typography';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { Step } from 'components/rewards/BoxOpeningOnboardingDialog/CardOpeningOnboardingDialog/Step';

import { CardTransaction } from './CardTransaction';
import { Price_anyCard, Price_tokenPrice } from './__generated__/index.graphql';

const BigPrice = styled(DisplayL)`
  padding-top: var(--unit);
  background: linear-gradient(329.81deg, #048a3d 2.3%, #bff6d6 95.33%);
  background-clip: text;
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  width: min-content;
`;
const Description = styled(LabelM)`
  padding-top: var(--unit);
`;

type Props = {
  tokenPrice: Price_tokenPrice;
  card: Nullable<Price_anyCard>;
};

export const Price = ({ tokenPrice, card }: Props) => {
  const { amounts } = tokenPrice;

  const { main: price } = useAmountWithConversion({ monetaryAmount: amounts });

  return (
    <Step
      card={card}
      badge={
        <LabelM color="var(--c-score-high)" bold>
          {price}
        </LabelM>
      }
      color="#33ce78"
      description={
        <Vertical gap={1}>
          <FormattedMessage
            id="BoxOpeningOnboardingDialog.priceStep.mostRecentSale"
            defaultMessage="<xs>Most Recent Sale</xs><xl>{price}</xl>"
            values={{
              br: <br />,
              xs: (content: ReactNode[]) => <LabelM>{content}</LabelM>,
              xl: (content: ReactNode[]) => <BigPrice>{content}</BigPrice>,
              price,
            }}
          />
          <CardTransaction tokenPrice={tokenPrice} />
          <Description>
            <FormattedMessage
              id="PostCraftEarlyAccessCardDialog.TokenPrice.description"
              defaultMessage="Limited Cards you purchase, craft, or win can be sold to other Managers."
            />
          </Description>
        </Vertical>
      }
    />
  );
};

Price.fragments = {
  tokenPrice: gql`
    fragment Price_tokenPrice on TokenPrice {
      id
      amounts {
        ...MonetaryAmountFragment_monetaryAmount
      }
      ...CardTransaction_tokenPrice
    }
    ${CardTransaction.fragments.tokenPrice}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<Price_tokenPrice>,
  anyCard: gql`
    fragment Price_anyCard on AnyCardInterface {
      slug
      ...Step_anyCard
    }
    ${Step.fragments.anyCard}
  ` as TypedDocumentNode<Price_anyCard>,
};
