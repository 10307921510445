import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LiveIndicator } from '@sorare/core/src/atoms/ui/LiveIndicator';
import { playerGameStatusLabels } from '@sorare/core/src/lib/glossary';
import {
  THRESHOLD_COLORS,
  findThreshold,
} from '@sorare/core/src/lib/usSportsThresholds';

const BaseScoreStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.33em 0.5em;
  min-width: 2.5em;
  font-weight: var(--t-bold);
  font-size: 1em;
  line-height: 1em;
  color: var(--c-black);
  background-color: rgba(var(--c-rgb-neutral-1000), 0.15);
  border-radius: 0.35em;
  white-space: nowrap;
`;

const NeutralScore = styled(BaseScoreStyle)`
  color: var(--c-white);
  background: var(--c-nd-200);
`;

const DNPScore = styled(NeutralScore)`
  opacity: 0.5;
`;
const SmallLabel = styled.span`
  font-size: 10px !important;
`;

const LiveDot = styled(LiveIndicator)`
  width: 6px;
  height: 6px;
  position: absolute;
  left: calc(-1 * 0.75em);
`;

const Wrapper = styled(Horizontal)`
  position: relative;
`;

export const ColoredScore = ({
  score,
  playedInGame = true,
  noGame = false,
  isLive = false,
}: {
  score: number | string;
  playedInGame?: boolean;
  noGame?: boolean;
  isLive?: boolean;
}) => {
  const { formatNumber } = useIntl();

  if (noGame) {
    return (
      <DNPScore>
        <SmallLabel as="span">
          <FormattedMessage {...playerGameStatusLabels.no_game_short} />
        </SmallLabel>
      </DNPScore>
    );
  }
  if (!playedInGame) {
    return (
      <DNPScore>
        <SmallLabel as="span">
          <FormattedMessage {...playerGameStatusLabels.did_not_play_short} />
        </SmallLabel>
      </DNPScore>
    );
  }

  const formattedScore =
    typeof score === 'number'
      ? formatNumber(score, { maximumFractionDigits: 0 })
      : score;

  const scoreWithoutBonus = +score;
  const threshold = findThreshold(scoreWithoutBonus);
  return (
    <Wrapper>
      {isLive && <LiveDot />}
      <NeutralScore
        style={{
          background: THRESHOLD_COLORS[threshold],
          color: 'var(--c-black)',
        }}
      >
        {formattedScore}
      </NeutralScore>
    </Wrapper>
  );
};
