import styled from 'styled-components';

import { Block } from '@sorare/core/src/atoms/layout/Block';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

export const StyledBlock = styled(Block)`
  &.disabled {
    background-color: var(--c-nd-100);
  }
`;

export const Header = styled(Vertical)`
  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Owner = styled.div`
  width: 100%;
  padding-bottom: var(--double-unit);
  border-bottom: 1px solid var(--c-nd-200);
  @media ${tabletAndAbove} {
    width: auto;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  @media ${tabletAndAbove} {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const Helper = styled.div`
  @media ${tabletAndAbove} {
    padding-top: var(--double-unit);
    border-top: 1px solid var(--c-nd-200);
  }
`;
