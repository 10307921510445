import { TypedDocumentNode, gql } from '@apollo/client';
import { ComponentPropsWithoutRef } from 'react';

import CardProperties from '@sorare/marketplace/src/components/card/BaseballCardProperties';
import { InjuryTag } from '@sorare/marketplace/src/components/player/InjuryTag';

import { CardPropertiesWithInjury_anyCard } from './__generated__/index.graphql';

type CardPropertiesProps = ComponentPropsWithoutRef<typeof CardProperties>;

type Props = Omit<CardPropertiesProps, 'extras' | 'card'> & {
  card: CardPropertiesWithInjury_anyCard;
};

export const CardPropertiesWithInjury = (props: Props) => {
  const { card } = props;
  const { anyPlayer } = card;
  return (
    <CardProperties {...props} extras={<InjuryTag player={anyPlayer} />} />
  );
};

CardPropertiesWithInjury.fragments = {
  anyCard: gql`
    fragment CardPropertiesWithInjury_anyCard on AnyCardInterface {
      slug
      anyPlayer {
        slug
        ...InjuryTag_anyPlayer
      }
      ...BaseballCardProperties_anyCard
    }
    ${InjuryTag.fragments.anyPlayer}
    ${CardProperties.fragments.anyCard}
  ` as TypedDocumentNode<CardPropertiesWithInjury_anyCard>,
};
