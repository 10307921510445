import { FormattedMessage } from 'react-intl';

import { LabelL } from 'atoms/typography';

import { Ranking } from '../Ranking';

type Props = {
  liveRankingRatio: number;
  liveRanking: number;
};
export const RankingRatio = ({ liveRankingRatio, liveRanking }: Props) => {
  const showDash = liveRankingRatio === 0 && liveRanking === 0;

  const showRatio = !showDash && liveRankingRatio > 0 && liveRanking > 20;
  const showOrdinal = !showDash && !showRatio;

  return (
    <LabelL bold>
      {showDash && '-'}
      {showRatio && (
        <FormattedMessage
          id="Collections.RankingRatio.liveRankingRatio"
          defaultMessage="Top {liveRankingRatio}%"
          values={{ liveRankingRatio }}
        />
      )}
      {showOrdinal && <Ranking liveRanking={liveRanking} />}
    </LabelL>
  );
};
