import { TypedDocumentNode, gql } from '@apollo/client';
import { useMemo } from 'react';
import styled from 'styled-components';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { arrayToObject } from '@sorare/core/src/lib/arrays';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { Token } from 'components/token/Token';

import {
  TokensRowsByObjectIds,
  TokensRowsByObjectIdsVariables,
  TokensRows_anyCard,
} from './__generated__/index.graphql';

const CardsWrapper = styled.div<{ hitsPerRow: number }>`
  display: grid;
  gap: var(--unit);
  @media ${tabletAndAbove} {
    overflow: auto;
    scroll-snap-type: x mandatory;
    grid-auto-flow: column;
    grid-template-columns: repeat(${({ hitsPerRow }) => hitsPerRow}, 1fr);
    & > * {
      scroll-snap-align: center;
      min-width: 320px;
    }
  }
`;

const Frame = styled.div`
  padding: var(--unit);
  background-color: var(--c-nd-100);
  border-radius: var(--unit);
`;

type Props = {
  hitsPerRow: number;
  assetIds?: string[];
};

const card = gql`
  fragment TokensRows_anyCard on AnyCardInterface {
    slug
    ...Token_anyCard
  }
  ${Token.fragments.anyCard}
` as TypedDocumentNode<TokensRows_anyCard>;

export const TOKENS_ROWS_BY_OBJECT_IDS_QUERY = gql`
  query TokensRowsByObjectIds(
    $assetIds: [String!]!
    $onlyPrimary: Boolean = true
    $googlePlayStoreCountryCode: String
    $isAndroidApp: Boolean = false
    $isIosApp: Boolean = false
  ) {
    anyCards(assetIds: $assetIds) {
      slug
      ...TokensRows_anyCard
    }
  }
  ${card}
` as TypedDocumentNode<TokensRowsByObjectIds, TokensRowsByObjectIdsVariables>;

type MappedTokens = Record<string, TokensRows_anyCard>;

export const TokensRow = ({ hitsPerRow, assetIds = [] }: Props) => {
  const { isIosApp, isAndroidApp, googlePlayStoreCountryCode } =
    useIsMobileApp();
  const { data, loading } = useQuery(TOKENS_ROWS_BY_OBJECT_IDS_QUERY, {
    variables: {
      assetIds,
      isIosApp,
      isAndroidApp,
      googlePlayStoreCountryCode,
    },
    skip: assetIds.length === 0,
  });

  const mappedTokensFromAssetIds: MappedTokens = useMemo(
    () =>
      arrayToObject<TokensRows_anyCard>({
        key: t => t.assetId,
        value: t => t,
        list: data?.anyCards || [],
      }),
    [data?.anyCards]
  );

  if (!data || loading) return <LoadingIndicator />;

  return (
    <CardsWrapper hitsPerRow={hitsPerRow}>
      {assetIds.map(
        assetId =>
          mappedTokensFromAssetIds[assetId] && (
            <Frame key={assetId}>
              <Token
                forceMobileLayout
                disableSportSpecific
                card={mappedTokensFromAssetIds[assetId]}
                onlyPrimary
              />
            </Frame>
          )
      )}
    </CardsWrapper>
  );
};

TokensRow.fragments = {
  card,
};

export default TokensRow;
