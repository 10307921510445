import { TypedDocumentNode, gql } from '@apollo/client';

import { InjuryTagTemplate } from '@sorare/core/src/components/player/InjuryTagTemplate';
import { isType } from '@sorare/core/src/gql';
import {
  getPlayerInjuryColor as getPlayerInjuryColorBaseball,
  getPlayerInjuryMessage as getPlayerInjuryMessageBaseball,
} from '@sorare/core/src/lib/baseball';
import {
  getPlayerInjuryColor as getPlayerInjuryColorNba,
  getPlayerInjuryMessage as getPlayerInjuryMessageNba,
} from '@sorare/core/src/lib/nba';

import { InjuryTag_anyPlayer } from './__generated__/index.graphql';

type Props = {
  player: Nullable<InjuryTag_anyPlayer>;
};

export const InjuryTag = ({ player }: Props) => {
  if (isType(player, 'Player') || player?.activeInjuries.length === 0)
    return null;

  if (isType(player, 'BaseballPlayer')) {
    return (
      <InjuryTagTemplate
        injury={player.activeInjuries[0]}
        getPlayerInjuryColor={getPlayerInjuryColorBaseball}
        getPlayerInjuryMessage={getPlayerInjuryMessageBaseball}
      />
    );
  }

  return (
    <InjuryTagTemplate
      injury={player?.activeInjuries[0]}
      getPlayerInjuryColor={getPlayerInjuryColorNba}
      getPlayerInjuryMessage={getPlayerInjuryMessageNba}
    />
  );
};

InjuryTag.fragments = {
  anyPlayer: gql`
    fragment InjuryTag_anyPlayer on AnyPlayerInterface {
      slug
      activeInjuries {
        id
        ...InjuryTagTemplate_injury
      }
    }
    ${InjuryTagTemplate.fragments.injury}
  ` as TypedDocumentNode<InjuryTag_anyPlayer>,
};
