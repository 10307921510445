import { Color } from 'style/types';

export const getRGBFromHex = (
  hex: string
): { r: number; g: number; b: number } => {
  let strippedHex = hex.replace('#', '');

  if (strippedHex.length === 3) {
    strippedHex = strippedHex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const r = parseInt(strippedHex.substring(0, 2), 16);
  const g = parseInt(strippedHex.substring(2, 4), 16);
  const b = parseInt(strippedHex.substring(4, 6), 16);

  return { r, g, b };
};

export const getRgbColorFromHex = (hex: string): string => {
  const { r, g, b } = getRGBFromHex(hex);
  return `${r}, ${g}, ${b}`;
};

export const isLightColor = (color: string) => {
  const { r, g, b } = getRGBFromHex(color);

  // Based on https://stackoverflow.com/a/3943023 There are more precise (and more complex) ways to calculate this: https://stackoverflow.com/a/56678483
  if (r * 0.299 + g * 0.587 + b * 0.114 > 186) {
    return true;
  }
  return false;
};

export const getTextColorFomBackgroundColor = (bgColor: string): Color => {
  if (isLightColor(bgColor)) {
    return 'var(--c-black)';
  }
  return 'var(--c-white)';
};
