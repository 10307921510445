import { ChangeEvent } from 'react';

import { Switch } from 'atoms/inputs/Switch';

type Props = {
  currentValue: Json;
  handleChange: (newValue: Json) => void;
};
export const BooleanSelector = ({ currentValue, handleChange }: Props) => {
  // This is for transition purpose from multiple values to boolean values only
  const actualValue =
    typeof currentValue === 'string'
      ? currentValue === 'both' || currentValue === 'email'
      : !!currentValue;

  return (
    <Switch
      checked={actualValue}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event.target.checked);
      }}
    />
  );
};
