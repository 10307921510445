export const buildApplePayApprovals = ({
  approvals,
  applePayPaymentToken,
}: {
  approvals: { fingerprint: string }[];
  applePayPaymentToken: ApplePayJS.ApplePayPaymentToken;
}) => {
  return approvals.map(approval => {
    return {
      fingerprint: approval.fingerprint,
      mangopayApplePayApproval: {
        applePaymentData: {
          network: applePayPaymentToken.paymentMethod.network,
          transactionId: applePayPaymentToken.transactionIdentifier,
          tokenData: JSON.stringify(applePayPaymentToken.paymentData),
        },
      },
    };
  });
};
