import { TypedDocumentNode, gql } from '@apollo/client';
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { ReactElement, useState } from 'react';
import styled from 'styled-components';

import { Button, Props as ButtonProps } from 'atoms/buttons/Button';
import { IconDefinition } from 'atoms/icons';
import { Share } from 'atoms/icons/Share';
import { useIntlContext } from 'contexts/intl';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { UTM_CAMPAIGNS } from 'hooks/useUtmParams';
import { SocialShareEventContext, SocialShareEventName } from 'lib/events';
import { glossary } from 'lib/glossary';

import Dialog from './Dialog';
import { SocialShare_SocialPictures } from './__generated__/index.graphql';

const StyledDialog = styled(Dialog)`
  max-height: 80vh;
`;

export type Props = {
  url?: string;
  title?: string;
  description?: string;
  message?: string | ((link: string | undefined) => string);
  image?: Omit<SocialShare_SocialPictures, '__typename'> | null;
  trackingEventName: SocialShareEventName;
  trackingEventContext: SocialShareEventContext;
  sharedItemId?: string;
  sharedItem?: UTM_CAMPAIGNS;
  renderButton: (props: {
    onClick: () => void;
    ShareButton: (props: ButtonProps) => ReactElement;
    Icon: ReactElement;
    icon: IconDefinition;
    label: string;
  }) => React.ReactNode;
  onShareClose?: () => void;
  onShareOpen?: () => void;
};

export const SocialShare = (props: Props) => {
  const {
    title,
    description,
    url,
    image,
    message,
    renderButton,
    trackingEventName,
    trackingEventContext,
    sharedItemId,
    sharedItem,
    onShareClose,
    onShareOpen,
  } = props;
  const [opened, setOpened] = useState(false);
  const { formatMessage } = useIntlContext();
  const { isAndroidApp, postMessage } = useIsMobileApp();

  return (
    <>
      {renderButton({
        onClick: () => {
          onShareOpen?.();
          if (isAndroidApp) {
            postMessage('socialShare', {
              title,
              description,
              url,
              image: image
                ? {
                    post: image.post || undefined,
                    story: image.story || undefined,
                    square: image.square || undefined,
                  }
                : undefined,
              message: typeof message === 'function' ? message(url) : message,
              trackingEventName,
              trackingEventContext,
              sharedItemId,
              sharedItem,
            });
          } else {
            setOpened(!opened);
          }
        },
        ShareButton: ({ onClick: onButtonClick, ...rest }) => (
          <Button
            color="tertiary"
            onClick={event => {
              setOpened(!opened);
              onButtonClick?.(event);
              onShareOpen?.();
            }}
            aria-label={formatMessage(glossary.share)}
            {...rest}
          />
        ),
        Icon: <Share />,
        icon: faArrowUpFromBracket,
        label: formatMessage(glossary.share),
      })}
      <StyledDialog
        close={() => {
          setOpened(false);
          onShareClose?.();
        }}
        opened={opened}
        title={title || formatMessage(glossary.share)}
        description={description}
        message={message}
        image={image}
        shareProps={{
          url,
          trackingEventName,
          trackingEventContext,
          sharedItemId,
          sharedItem,
        }}
      />
    </>
  );
};

SocialShare.fragments = {
  socialPictures: gql`
    fragment SocialShare_SocialPictures on SocialPictureDerivative {
      ...SocialShare_SocialPictures_Dialog
    }
    ${Dialog.fragments.socialPictures}
  ` as TypedDocumentNode<SocialShare_SocialPictures>,
};
