import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { MLB_PLAY_PRO_FIXTURE_MY_LINEUPS } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const FixturePage = () => {
  const { fixture } = useTypedParams<PageParams>();

  return (
    <Navigate to={generatePath(MLB_PLAY_PRO_FIXTURE_MY_LINEUPS, { fixture })} />
  );
};
export default FixturePage;
