import { TypedDocumentNode, gql } from '@apollo/client';
import { faCircleInfo, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { ChevronRightBold } from '@sorare/core/src/atoms/icons/ChevronRightBold';
import { RadioGroup } from '@sorare/core/src/atoms/inputs/RadioGroup';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Text14 } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { CreateFiatWalletWrapper } from '@sorare/core/src/components/fiatWallet/CreateFiatWalletWrapper';
import { EthWallet } from '@sorare/core/src/components/paymentMethods/EthWallet';
import { FiatWallet } from '@sorare/core/src/components/paymentMethods/FiatWallet';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import {
  WalletTab,
  useWalletDrawerContext,
} from '@sorare/core/src/contexts/walletDrawer';
import {
  AcceptedCurrenciesValue,
  useAcceptedCurrencies,
} from '@sorare/core/src/hooks/useAcceptedCurrencies';
import { useFiatBalance } from '@sorare/core/src/hooks/wallets/useFiatBalance';
import { usePreferredPaymentMethod } from '@sorare/core/src/hooks/wallets/usePreferredPaymentMethod';
import { wallet } from '@sorare/core/src/lib/glossary';
import {
  PaymentMethod,
  WalletPaymentMethod,
} from '@sorare/core/src/lib/paymentMethod';

import useHasInsufficientFundsInWallets from 'hooks/useHasInsufficientFundsInWallets';

import { setCurrencyAndPaymentMethod } from '../../actions';
import { CardDataType, StateProps } from '../../types';
import { TradePaymentMethods_publicUserInfoInterface } from './__generated__/index.graphql';

const Frame = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  width: 100%;
  padding: var(--double-unit);
  border: 1px solid var(--c-nd-200);
  border-radius: var(--unit);
  background-color: var(--c-nd-100);
`;
const Row = styled(SBHorizontal).attrs({ gap: 0 })`
  width: 100%;
  text-align: left;
`;
const Selected = styled(Vertical).attrs({ gap: 0 })`
  justify-content: flex-start;
`;

const StyledChevronRightBold = styled(ChevronRightBold)`
  transform: rotate(0);
  &.expanded {
    transform: rotate(90deg);
  }
  transition: transform 0.25s ease-out;
`;
const Text = styled(Vertical).attrs({ gap: 0 })`
  align-items: flex-start;
`;

const SimpleButton = styled(ButtonBase)`
  color: var(--c-link);
  text-decoration: underline;
`;

const HelperRoot = styled(Horizontal).attrs({ gap: 1.5 })`
  width: 100%;
  background-color: rgba(var(--c-rgb-red-600), 0.05);
  padding: var(--unit);
  border-radius: var(--unit);
  border: solid 1px var(--c-red-600);
`;

const WarningRoot = styled.div`
  width: 100%;
  padding: 0 var(--double-unit) var(--unit);
`;

const Warning = styled(Horizontal)`
  padding: var(--intermediate-unit);
  background-color: var(--c-nd-100);
  border: 1px solid var(--c-nd-200);
  border-radius: var(--unit);
  color: var(--c-white);
`;

const HelperPadding = styled.div`
  padding: 0 var(--double-unit) var(--double-unit);
`;

const Helper = ({
  diffAmount,
  onClick,
}: {
  diffAmount?: string;
  onClick: () => void;
}) => (
  <HelperRoot>
    <FontAwesomeIcon icon={faInfoCircle} color="var(--c-red-600)" />
    <Text>
      <Text14 color="var(--c-white)">
        <FormattedMessage
          id="tradePaymentMethods.insufficientFundsInWallet"
          defaultMessage="You’ll need to add {diffAmount} to your wallet to send this trade."
          values={{
            diffAmount,
          }}
        />
      </Text14>
      <SimpleButton
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        <Text14 bold color="var(--c-link)">
          <FormattedMessage {...wallet.addFunds} />
        </Text14>
      </SimpleButton>
    </Text>
  </HelperRoot>
);

export const TradePaymentMethods = <D extends CardDataType>({
  state: { sendAmount, paymentMethod: selectedPaymentMethod },
  dispatch,
  onClose,
  to,
}: StateProps<D> & {
  onClose: () => void;
  to: TradePaymentMethods_publicUserInfoInterface;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { setCurrentTab, showDrawer } = useWalletDrawerContext();
  const { formatNumber, formatWei } = useIntlContext();
  const { acceptedCurrencies } = useAcceptedCurrencies({ user: to });

  const {
    fiatCurrency: { code: currencyCode },
    walletPreferences: { showEthWallet, showFiatWallet },
  } = useCurrentUserContext();
  const { canDepositAndWithdraw } = useFiatBalance();
  const { preferredPaymentMethod } = usePreferredPaymentMethod();
  const hasInsufficientFundsInWallets = useHasInsufficientFundsInWallets();
  const {
    insufficientFundsInEthWallet,
    diffInWeiForEthWallet,
    insufficientFundsInFiatWallet,
    diffInFiatCentsForFiatWallet,
  } = hasInsufficientFundsInWallets(sendAmount);

  const acceptEthWallet = acceptedCurrencies !== AcceptedCurrenciesValue.FIAT;

  const acceptCashWallet = acceptedCurrencies !== AcceptedCurrenciesValue.ETH;

  useEffect(() => {
    if (selectedPaymentMethod === null) {
      if (!acceptEthWallet) {
        dispatch(
          setCurrencyAndPaymentMethod({
            paymentMethod: WalletPaymentMethod.FIAT_WALLET,
            referenceCurrency: currencyCode as SupportedCurrency,
          })
        );
        return;
      }
      if (!acceptCashWallet) {
        dispatch(
          setCurrencyAndPaymentMethod({
            paymentMethod: WalletPaymentMethod.ETH_WALLET,
            referenceCurrency: SupportedCurrency.WEI,
          })
        );
        return;
      }
      if (showEthWallet) {
        dispatch(
          setCurrencyAndPaymentMethod({
            paymentMethod:
              preferredPaymentMethod === PaymentMethod.FIAT_WALLET
                ? WalletPaymentMethod.FIAT_WALLET
                : WalletPaymentMethod.ETH_WALLET,
            referenceCurrency:
              preferredPaymentMethod === PaymentMethod.FIAT_WALLET
                ? (currencyCode as SupportedCurrency)
                : SupportedCurrency.WEI,
          })
        );
        return;
      }
      dispatch(
        setCurrencyAndPaymentMethod({
          paymentMethod: WalletPaymentMethod.FIAT_WALLET,
          referenceCurrency: currencyCode as SupportedCurrency,
        })
      );
    }
  }, [
    currencyCode,
    preferredPaymentMethod,
    dispatch,
    selectedPaymentMethod,
    acceptEthWallet,
    acceptCashWallet,
    acceptedCurrencies,
    showEthWallet,
  ]);

  if (!selectedPaymentMethod) return null;

  const onAddFunds = (tab: WalletTab) => {
    onClose();
    setCurrentTab(tab);
    showDrawer();
  };

  const ethWalletHelper = (
    <Helper
      diffAmount={formatWei(
        (diffInWeiForEthWallet || '0').toString(),
        undefined,
        {
          maximumFractionDigits: 4,
        }
      )}
      onClick={() => onAddFunds(WalletTab.ADD_FUNDS_TO_ETH_WALLET)}
    />
  );

  const fiatWalletHelper = (
    <CreateFiatWalletWrapper canDismissAfterActivation={false}>
      {({ setShowCreateFiatWallet }) => (
        <Helper
          diffAmount={formatNumber(
            (diffInFiatCentsForFiatWallet || 0n) / 100n,
            {
              style: 'currency',
              currency: currencyCode,
            }
          )}
          onClick={() => {
            if (canDepositAndWithdraw) {
              onAddFunds(WalletTab.ADD_FUNDS_TO_FIAT_WALLET);
            } else {
              setShowCreateFiatWallet(true);
            }
          }}
        />
      )}
    </CreateFiatWalletWrapper>
  );
  const paymentMethods = {
    [WalletPaymentMethod.ETH_WALLET]: {
      label: <EthWallet />,
      disabled: !acceptEthWallet,
      value: WalletPaymentMethod.ETH_WALLET,
      referenceCurrency: SupportedCurrency.WEI,
      helper: (
        <>
          {insufficientFundsInEthWallet && acceptEthWallet && (
            <HelperPadding>{ethWalletHelper}</HelperPadding>
          )}
          {!acceptEthWallet && (
            <WarningRoot>
              <Warning>
                <FontAwesomeIcon icon={faCircleInfo} />
                <Text14>
                  <FormattedMessage
                    id="NewOfferBuilder.TradePaymentMethods.cantAcceptETH"
                    defaultMessage="<b>{nickname}</b> can't accept ETH."
                    values={{ b: Bold, nickname: to.nickname }}
                  />
                </Text14>
              </Warning>
            </WarningRoot>
          )}
        </>
      ),
    },
    ...(showFiatWallet
      ? {
          [WalletPaymentMethod.FIAT_WALLET]: {
            label: <FiatWallet />,
            disabled: !acceptCashWallet,
            value: WalletPaymentMethod.FIAT_WALLET,
            referenceCurrency: currencyCode as SupportedCurrency,
            helper: (
              <>
                {insufficientFundsInFiatWallet && acceptCashWallet && (
                  <HelperPadding>{fiatWalletHelper}</HelperPadding>
                )}
                {!acceptCashWallet && (
                  <WarningRoot>
                    <Warning>
                      <FontAwesomeIcon icon={faCircleInfo} />
                      <Text14>
                        <FormattedMessage
                          id="NewOfferBuilder.TradePaymentMethods.cantAcceptCash"
                          defaultMessage="<b>{nickname}</b> can't accept Cash."
                          values={{ b: Bold, nickname: to.nickname }}
                        />
                      </Text14>
                    </Warning>
                  </WarningRoot>
                )}
              </>
            ),
          },
        }
      : {}),
  };

  const paymentOptions = [
    ...(showEthWallet ? [paymentMethods[WalletPaymentMethod.ETH_WALLET]] : []),
    paymentMethods[WalletPaymentMethod.FIAT_WALLET],
  ].filter(Boolean);

  const disabled = paymentOptions.length <= 1;

  return (
    <Vertical>
      <Dropdown
        fullWidth
        align="right"
        gap={8}
        onOpen={() => setExpanded(true)}
        onClose={() => setExpanded(false)}
        label={props => (
          <Frame {...props} disabled={disabled} disableDebounce>
            <Row>
              <Selected>
                {paymentMethods[selectedPaymentMethod]?.label}
              </Selected>
              {!disabled && (
                <StyledChevronRightBold className={classNames({ expanded })} />
              )}
            </Row>
          </Frame>
        )}
      >
        {({ closeDropdown }) => (
          <RadioGroup
            options={paymentOptions}
            value={paymentMethods[selectedPaymentMethod]?.value}
            name="select-trade-payment-method"
            onChange={(value: string) => {
              const method = paymentMethods[value as WalletPaymentMethod];
              if (method) {
                dispatch(
                  setCurrencyAndPaymentMethod({
                    referenceCurrency: method.referenceCurrency,
                    paymentMethod: method.value,
                  })
                );
                closeDropdown();
              }
            }}
          />
        )}
      </Dropdown>
      {insufficientFundsInEthWallet &&
        selectedPaymentMethod === WalletPaymentMethod.ETH_WALLET &&
        ethWalletHelper}
      {insufficientFundsInFiatWallet &&
        selectedPaymentMethod === WalletPaymentMethod.FIAT_WALLET &&
        fiatWalletHelper}
    </Vertical>
  );
};

TradePaymentMethods.fragments = {
  publicUserInfoInterface: gql`
    fragment TradePaymentMethods_publicUserInfoInterface on PublicUserInfoInterface {
      id
      slug
      nickname
      profile {
        id
        enabledWallets
      }
      ...useAcceptedCurrencies_publicUserInfoInterface
    }
    ${useAcceptedCurrencies.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<TradePaymentMethods_publicUserInfoInterface>,
};
