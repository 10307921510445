import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  BlueprintRevealStatus,
  DealStatus,
} from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { NotIOsAppFeature } from '@sorare/core/src/components/BuyableFeature';
import { ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useTokenBelongsToUser } from '@sorare/core/src/hooks/useTokenBelongsToUser';
import useOAuthEthWallet from '@sorare/core/src/hooks/wallets/useOAuthEthWallet';
import { isSettlementDelayed, isTransferable } from '@sorare/core/src/lib/deal';
import { isType } from '@sorare/core/src/lib/gql';

import MakeOffer from 'components/directOffer/MakeOffer';
import Sell from 'components/offer/Sell';
import {
  singleSaleOfferContextFragments,
  useSingleSaleOfferContext,
} from 'contexts/singleSaleOffer';
import useBelongsToUser from 'hooks/offers/useBelongsToUser';

import { AvailableForTrade } from '../AvailableForTrade';
import DelayedSettlementHelpers from '../DelayedSettlementHelpers';
import OwnerAccount from '../OwnerAccount';
import { CurrentOwner_anyCard } from './__generated__/index.graphql';
import { Header, Helper, Owner, Right, StyledBlock } from './ui';

type Props = {
  card: CurrentOwner_anyCard;
};

const StyledButtonBase = styled(ButtonBase)`
  text-decoration: underline;
`;

export const CurrentOwner = ({ card }: Props) => {
  const {
    latestEnglishAuction,
    liveSingleSaleOffer,
    myMintedSingleSaleOffer,
    tokenOwner: owner,
    slug,
  } = card;
  const belongsToUser = useBelongsToUser();
  const { generateSportContextPath } = useSportContext();
  const { showPopin } = useSingleSaleOfferContext();
  const tokenBelongsToUser = useTokenBelongsToUser();
  const { needsCreateEthWallet, promptGenerateKeys } = useOAuthEthWallet();

  const notOwnedOrOwnByAContract =
    !owner || isType(owner.account?.owner, 'Contract');

  const myCard = tokenBelongsToUser(card);
  const onSale =
    myMintedSingleSaleOffer && belongsToUser(myMintedSingleSaleOffer);

  useEffect(() => {
    if (onSale) {
      showPopin({ slug });
    }
  }, [onSale, showPopin, slug]);

  if (notOwnedOrOwnByAContract && !owner?.user) return null;
  if (latestEnglishAuction?.open || liveSingleSaleOffer) return null;
  if (onSale) return null;

  const delayedSettlement = isSettlementDelayed(card);

  const isTransfering = !!owner.optimistic && !delayedSettlement;

  const showAvailableForTrade = myCard && !isTransfering && !delayedSettlement;
  const showUnavailableForTradeHelper =
    !myCard && !isTransfering && !isTransferable(card);
  const showHelper =
    isTransfering || showUnavailableForTradeHelper || delayedSettlement;

  const showNeedsCreateEthWallet =
    myCard && isTransfering && needsCreateEthWallet;
  const showPendingReview =
    myCard && owner.deal?.dealStatus === DealStatus.PENDING_REVIEW;

  return (
    <Vertical>
      <StyledBlock className={classNames({ disabled: isTransfering })}>
        <Vertical gap={2}>
          <Header gap={2}>
            <Owner>
              <OwnerAccount account={owner.account} />
            </Owner>
            <NotIOsAppFeature>
              {!owner?.user?.suspended && (
                <Right>
                  {card.blueprint?.revealStatus ===
                    BlueprintRevealStatus.REVEALABLE &&
                    myCard && (
                      <Button
                        to={`${generateSportContextPath(
                          ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
                          {
                            params: {
                              type: card.rarityTyped,
                            },
                          }
                        )}?slug=${card.slug}`}
                        color="quaternary"
                        size="medium"
                      >
                        <FormattedMessage
                          id="currentOwner.blueprint.reveal"
                          defaultMessage="Reveal"
                        />
                      </Button>
                    )}
                  <MakeOffer card={card} />
                  <Sell card={card} />
                </Right>
              )}
            </NotIOsAppFeature>
          </Header>
          {showAvailableForTrade && <AvailableForTrade card={card} />}

          {showHelper && (
            <Helper>
              {showUnavailableForTradeHelper && (
                <LabelM color="var(--c-nd-600)">
                  <FormattedMessage
                    id="currentOwner.helper.unavailableForTrade"
                    defaultMessage="This card is not available for trade offers."
                  />
                </LabelM>
              )}
              {isTransfering && (
                <LabelM color="var(--c-nd-600)">
                  <FormattedMessage
                    id="currentOwner.helper.transfering"
                    defaultMessage="Transfer in progress"
                  />
                  {showPendingReview && (
                    <>
                      {', '}
                      <FormattedMessage
                        id="currentOwner.pendingReviewDescription"
                        defaultMessage="currently <b>under review</b>. You can use the Card immediately, but its ownership will not be confirmed until the review process is finished, which may take up to 48 hours."
                        values={{
                          b: Bold,
                        }}
                      />
                    </>
                  )}
                  {!showPendingReview && showNeedsCreateEthWallet && (
                    <>
                      {', '}
                      <FormattedMessage
                        id="currentOwner.helper.createWallet"
                        defaultMessage="you’ll need to <link>create your wallet</link> to receive the card."
                        values={{
                          link: content => (
                            <StyledButtonBase onClick={promptGenerateKeys}>
                              {content}
                            </StyledButtonBase>
                          ),
                        }}
                      />
                    </>
                  )}
                </LabelM>
              )}
              {delayedSettlement && <DelayedSettlementHelpers card={card} />}
            </Helper>
          )}
        </Vertical>
      </StyledBlock>
    </Vertical>
  );
};

CurrentOwner.fragments = {
  anyCard: gql`
    fragment CurrentOwner_anyCard on AnyCardInterface {
      slug
      collection
      tokenOwner {
        id
        optimistic
        deal {
          ... on Node {
            id
          }
          ... on DealInterface {
            dealStatus
          }
        }
        account {
          id
          owner {
            ... on User {
              slug
            }
            ... on Contract {
              id
            }
          }
          ...OwnerAccount_account
        }
        user {
          slug
          suspended
        }
      }
      blueprint {
        id
        revealStatus
      }
      latestEnglishAuction {
        id
        open
      }
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        sender {
          ... on User {
            slug
          }
        }
      }
      ...SellCard_anyCard
      ...SingleSaleOfferContext_anyCard
      ...AvailableForTrade_anyCard
      ...DelayedSettlementHelpers_anyCard
      ...MakeOffer_anyCard
    }
    ${Sell.fragments.anyCard}
    ${singleSaleOfferContextFragments.anyCard}
    ${OwnerAccount.fragments.account}
    ${AvailableForTrade.fragments.anyCard}
    ${DelayedSettlementHelpers.fragments.anyCard}
    ${MakeOffer.fragments.anyCard}
  ` as TypedDocumentNode<CurrentOwner_anyCard>,
};

export default CurrentOwner;
