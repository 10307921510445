import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import {
  PrimaryOfferBuyerQuery,
  PrimaryOfferBuyerQueryVariables,
  usePollPrimaryOfferBuyer_primaryOffer,
} from './__generated__/usePollPrimaryOfferBuyer.graphql';

const PRIMARY_OFFER_BUYER_QUERY = gql`
  query PrimaryOfferBuyerQuery($id: String!) {
    tokens {
      primaryOffer(id: $id) {
        id
        buyer {
          slug
        }
      }
    }
  }
` as TypedDocumentNode<PrimaryOfferBuyerQuery, PrimaryOfferBuyerQueryVariables>;

const usePollPrimaryOfferBuyer = (
  enabled: boolean,
  primaryOffer: usePollPrimaryOfferBuyer_primaryOffer | undefined,
  cb: (winning: boolean) => void
) => {
  const { currentUser } = useCurrentUserContext();
  const primaryOfferId = idFromObject(primaryOffer?.id) || '';
  useQuery(PRIMARY_OFFER_BUYER_QUERY, {
    variables: {
      id: primaryOfferId,
    },
    pollInterval: enabled ? 1000 : 0,
    skip: !enabled || !primaryOfferId,
  });

  const userIsBuyer = useMemo(
    () =>
      primaryOffer?.buyer &&
      currentUser &&
      primaryOffer.buyer.slug === currentUser.slug,
    [primaryOffer?.buyer, currentUser]
  );

  useEffect(() => {
    if (userIsBuyer && enabled) {
      cb(userIsBuyer);
    }
  }, [cb, enabled, userIsBuyer]);
};

usePollPrimaryOfferBuyer.fragments = {
  primaryOffer: gql`
    fragment usePollPrimaryOfferBuyer_primaryOffer on TokenPrimaryOffer {
      id
      buyer {
        slug
      }
    }
  ` as TypedDocumentNode<usePollPrimaryOfferBuyer_primaryOffer>,
};

export default usePollPrimaryOfferBuyer;
