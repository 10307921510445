import { TypedDocumentNode, gql } from '@apollo/client';

import { BundledAuctionPreview } from 'components/auction/BundledAuctionPreview';
import FlexCard from 'components/token/FlexCard';

import { AuctionImg_auction } from './__generated__/index.graphql';

type Props = {
  auction: AuctionImg_auction;
};

export const AuctionImg = ({ auction }: Props) => {
  const isDesktopLayout = false;

  if (auction && auction.cards.length > 1) {
    return (
      <BundledAuctionPreview
        bundledAuction={auction}
        displayOneCard={!isDesktopLayout}
      />
    );
  }

  return <FlexCard card={auction.cards[0]} width={160} withLink />;
};

AuctionImg.fragments = {
  auction: gql`
    fragment AuctionImg_auction on TokenAuction {
      id
      cards: anyCards {
        slug
        ...FlexCard_anyCard
      }
      ...BundledAuctionPreview_auction
    }
    ${BundledAuctionPreview.fragments.auction}
    ${FlexCard.fragments.anyCard}
  ` as TypedDocumentNode<AuctionImg_auction>,
};
