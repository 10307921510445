import classNames from 'classnames';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import {
  Box,
  BoxProps,
  hoverStyle,
  selectedStyle,
} from '@sorare/core/src/atoms/ui/Box';
import { getTextColorFomBackgroundColor } from '@sorare/core/src/lib/colors';

import lightningBg from './assets/lightning-bg.svg';

const Wrapper = styled(Box)`
  --bg-color: linear-gradient(to bottom, var(--c-nd-50), var(--c-nd-50));
  --border-color: transparent;
  --mask-color: linear-gradient(to bottom, transparent, transparent);

  border: 2px solid transparent;
  background:
    var(--mask-color) padding-box,
    center / cover no-repeat url(${lightningBg}) padding-box,
    var(--bg-color) padding-box,
    var(--border-color) border-box;

  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;

  &.selectable:hover,
  &.selectable:focus {
    cursor: pointer;
    ${hoverStyle}
  }
  &.selected {
    &:not(.boosted) {
      ${selectedStyle}
    }
    &.boosted {
      background:
        linear-gradient(
            to bottom,
            rgba(var(--c-rgb-rivals-arena), 0.1),
            rgba(var(--c-rgb-rivals-arena), 0.1)
          )
          padding-box,
        linear-gradient(to bottom, var(--c-black), var(--c-black)) padding-box,
        var(--c-rivals-arena) border-box;
    }
  }
  &.locked {
    --border-color: linear-gradient(to bottom, var(--c-black), var(--c-black));
  }
`;

type Props = {
  children: ReactNode;
  lightningColor?: string | null;
  bgGradientColor?: string | null;
  borderColor?: string | null;
  maskColor?: string;
  boosted?: boolean;
} & BoxProps;
export const GameWrapper = ({
  children,
  lightningColor,
  bgGradientColor,
  borderColor,
  className,
  maskColor,
  boosted,
  ...rest
}: Props) => {
  const color = lightningColor
    ? getTextColorFomBackgroundColor(lightningColor)
    : 'var(--c-white)';

  return (
    <Wrapper
      {...rest}
      className={classNames(className, { boosted })}
      style={
        {
          '--mask-color': maskColor,
          '--bg-color':
            bgGradientColor ||
            (lightningColor
              ? `linear-gradient(to bottom, ${lightningColor}, ${lightningColor})`
              : undefined),
          '--border-color': borderColor,
          color,
        } as CSSProperties
      }
    >
      {children}
    </Wrapper>
  );
};
