import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  Currency,
  EnabledWallet,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { MonetaryInput } from '@sorare/core/src/atoms/inputs/MonetaryInput';
import { Block } from '@sorare/core/src/atoms/layout/Block';
import { Caption, Text14 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';
import { WalletPaymentMethod } from '@sorare/core/src/lib/paymentMethod';

import FeesDetailsTooltip from 'components/offer/FeesDetailsTooltip';
import { useCalculateDirectOfferMarketFee } from 'hooks/offers/useCalculateDirectOfferMarketFee';
import { useStateOffer } from 'hooks/offers/useStateOffer';
import {
  MarketFeeStatus,
  isMarketFeeEnabled,
} from 'hooks/useMarketFeesHelperStatus';

import { setReceiveAmount, setSendAmount } from '../../actions';
import { CardDataType, StateProps } from '../../types';

const InputContainer = styled(Block)<{ $disabled: boolean }>`
  padding: var(--double-unit);
  border-radius: var(--unit);
  border: 1px solid var(--c-nd-200);
  ${props =>
    props.$disabled &&
    css`
      background-color: var(--c-nd-100);
    `}
`;
const MarketFeeHelper = styled.div`
  display: flex;
  gap: var(--unit);
`;

const AmountInput = <D extends CardDataType>({
  state,
  dispatch,
  receiver,
  marketFeeStatus = MarketFeeStatus.DISABLED,
}: StateProps<D> & {
  receiver?: boolean;
  marketFeeStatus?: MarketFeeStatus;
}) => {
  const {
    fiatCurrency: { code: currencyCode },
    currency,
    walletPreferences: { onlyShowFiatCurrency, enabledWallets },
  } = useCurrentUserContext();
  const getMarketFeeAmount = useCalculateDirectOfferMarketFee();
  const {
    sendAmount,
    receiveAmount,
    sendAmountCurrency,
    receiveAmountCurrency,
    paymentMethod,
  } = state;
  const { receiveMinimumPrice, sendMinimumPrice } = useStateOffer(state);

  const referenceCurrency = receiver
    ? receiveAmountCurrency
    : sendAmountCurrency;
  const monetaryAmountIndex = getMonetaryAmountIndex(referenceCurrency);
  const amount = receiver ? receiveAmount : sendAmount;
  const minAmount = receiver ? receiveMinimumPrice : sendMinimumPrice;

  const marketFeeAmount = getMarketFeeAmount(amount, referenceCurrency);

  const { main: feesMainAmount } = useAmountWithConversion({
    monetaryAmount: marketFeeAmount,
    primaryCurrency:
      referenceCurrency === SupportedCurrency.WEI
        ? Currency.ETH
        : Currency.FIAT,
  });

  const localCallback = useCallback(
    (newAmount: MonetaryAmountOutput) => {
      if (receiver) {
        const newMarketFeesAmount =
          newAmount.wei > 0 && getMarketFeeAmount(newAmount, referenceCurrency);
        dispatch(setReceiveAmount(newAmount, newMarketFeesAmount || undefined));
        return;
      }
      dispatch(setSendAmount(newAmount));
    },
    [referenceCurrency, receiver, dispatch, getMarketFeeAmount]
  );

  const disabled = useMemo(() => {
    if (receiver) {
      if (state.sendAmount.eur > 0) return true;
    } else if (state.receiveAmount.eur > 0) return true;
    return false;
  }, [receiver, state.receiveAmount.eur, state.sendAmount.eur]);

  const marketFeeEnabled = isMarketFeeEnabled(marketFeeStatus);

  const showMarketFeesHelper = marketFeeEnabled && amount.eur > 0 && !!receiver;

  return (
    <div>
      <InputContainer variant="square" border="around" $disabled={disabled}>
        <MonetaryInput
          defaultValue={amount}
          fiatCurrency={currencyCode}
          onChange={localCallback}
          defaultCurrency={
            referenceCurrency === SupportedCurrency.WEI
              ? currency
              : Currency.FIAT
          }
          error={
            BigInt(minAmount[monetaryAmountIndex]) >
            BigInt(amount[monetaryAmountIndex])
          }
          placeholder="0"
          disabled={disabled}
          onlyShowFiatCurrency={
            referenceCurrency !== SupportedCurrency.WEI &&
            ((paymentMethod === WalletPaymentMethod.FIAT_WALLET &&
              enabledWallets?.length === 1 &&
              enabledWallets[0] === EnabledWallet.FIAT) ||
              onlyShowFiatCurrency)
          }
        />
        {showMarketFeesHelper && (
          <>
            <MarketFeeHelper>
              <Text14 color="var(--c-nd-600)">
                <FormattedMessage
                  id="AmountInput.marketFeeHelper"
                  defaultMessage="Market fee (incl. tax, if applicable):"
                />
              </Text14>
              <FeesDetailsTooltip
                monetaryAmount={amount}
                marketFeeMonetaryAmount={marketFeeAmount}
                marketFeeStatus={marketFeeStatus}
                referenceCurrency={referenceCurrency}
              />
            </MarketFeeHelper>
            <Text14 color="var(--c-nd-600)">
              {marketFeeStatus === MarketFeeStatus.PARTIALLY_ENABLED ? (
                <FormattedMessage
                  id="AmountInput.marketFeeAmountPartial"
                  defaultMessage="Up to {marketFeeAmount}"
                  values={{ marketFeeAmount: feesMainAmount }}
                />
              ) : (
                feesMainAmount
              )}
            </Text14>
          </>
        )}
        {disabled && (
          <Caption style={{ marginTop: 8 }}>
            <FormattedMessage
              id="AmountInput.helper"
              defaultMessage="You cannot both receive and send funds in the same trade"
            />
          </Caption>
        )}
      </InputContainer>
    </div>
  );
};

export default AmountInput;
