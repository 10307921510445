import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import american_express from 'assets/wallet/icon-american_express.png';
import apple_pay from 'assets/wallet/icon-apple_pay.png';
import diners_club from 'assets/wallet/icon-diners_club.png';
import google_pay from 'assets/wallet/icon-g_pay.png';
import jcb from 'assets/wallet/icon-jcb.png';
import mastercard from 'assets/wallet/icon-mastercard.png';
import unknown from 'assets/wallet/icon-unknown_cc.png';
import visa from 'assets/wallet/icon-visa.png';
import { Horizontal } from 'atoms/layout/flex';
import { LabelM, Text16 } from 'atoms/typography';
import { Color } from 'style/types';

import { CreditCard_creditCard } from './__generated__/index.graphql';

type DumbProps = {
  creditCard: { brand: string; last4: string } & { wallet?: { type: string } };
  suffixText?: ReactNode;
  color?: Color;
};

type Props = {
  creditCard: CreditCard_creditCard & { wallet?: { type: string } };
  suffixText?: ReactNode;
  color?: Color;
};

const brandImage: { [key: string]: string } = {
  american_express,
  amex: american_express,
  diners_club,
  jcb,
  mastercard,
  visa,
  apple_pay,
  google_pay,
};

const Root = styled(Horizontal)`
  color: var(--c-white);
`;

const Picto = styled.img`
  height: 30px;
  max-width: calc(5 * var(--unit));
  object-fit: cover;
  border-radius: var(--half-unit);
`;

export const DumbCreditCard = ({
  creditCard,
  color = 'var(--c-white)',
  suffixText,
}: DumbProps) => {
  const { brand, last4, wallet } = creditCard;
  const icon = wallet ? wallet.type : brand;

  return (
    <Root>
      <Picto src={brandImage[icon.toLocaleLowerCase()] || unknown} alt={icon} />
      <Text16 color={color}>••• {last4}</Text16>
      {suffixText && <LabelM color={color}>{suffixText}</LabelM>}
    </Root>
  );
};

export const CreditCard = (props: Props) => {
  return <DumbCreditCard {...props} />;
};

CreditCard.fragments = {
  creditCard: gql`
    fragment CreditCard_creditCard on CreditCard {
      last4
      brand
    }
  ` as TypedDocumentNode<CreditCard_creditCard>,
};
