import { TypedDocumentNode, gql } from '@apollo/client';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { CollectionLeaderboardRow } from '@sorare/core/src/components/collections/CollectionLeaderboardRow';
import { ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';

import { LeaderboardRow_userCardCollection } from './__generated__/index.graphql';

type Props = {
  userCardCollection: LeaderboardRow_userCardCollection;
  slotsCount: number;
  collectionSlug: string;
  highlighted?: boolean;
};
const LeaderboardRow = ({
  userCardCollection,
  slotsCount,
  highlighted,
  collectionSlug,
}: Props) => {
  const { generateSportContextPath, sport = Sport.FOOTBALL } =
    useSportContext();
  const { liveRanking, fulfilledSlotsCount, score, user } = userCardCollection;
  const { slug: userSlug } = user;
  const { footballUserProfile, baseballUserProfile, nbaUserProfile } = user;
  const userProfiles = {
    [Sport.FOOTBALL]: footballUserProfile,
    [Sport.BASEBALL]: baseballUserProfile,
    [Sport.NBA]: nbaUserProfile,
  };

  return (
    <CollectionLeaderboardRow
      to={generateSportContextPath(
        ANY_SPORT_MY_CLUB_SLUG_COLLECTIONS_COLLECTIONSLUG,
        {
          params: {
            slug: userSlug,
            collection: collectionSlug,
            collectionSlug,
          },
        }
      )}
      rank={liveRanking}
      user={user}
      userSportProfile={userProfiles[sport]}
      score={score}
      ownedCards={fulfilledSlotsCount}
      totalCards={slotsCount}
      highlighted={highlighted}
      showClub
    />
  );
};

LeaderboardRow.fragments = {
  userCardCollection: gql`
    fragment LeaderboardRow_userCardCollection on UserCardCollection {
      id
      liveRanking
      score
      fulfilledSlotsCount
      user {
        slug
        footballUserProfile {
          id
          ...CollectionLeaderboardRow_UserSportProfileInterface
        }
        baseballUserProfile {
          id
          ...CollectionLeaderboardRow_UserSportProfileInterface
        }
        nbaUserProfile {
          id
          ...CollectionLeaderboardRow_UserSportProfileInterface
        }
        ...CollectionLeaderboardRow_PublicUserInfoInterface
      }
    }
    ${CollectionLeaderboardRow.fragments.PublicUserInfoInterface}
    ${CollectionLeaderboardRow.fragments.UserSportProfileInterface}
  ` as TypedDocumentNode<LeaderboardRow_userCardCollection>,
};

export default LeaderboardRow;
