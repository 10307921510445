import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ScoreStatus } from '@sorare/core/src/__generated__/globalTypes';
import { CloseButton } from '@sorare/core/src/atoms/buttons/CloseButton';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { BodyM } from '@sorare/core/src/atoms/typography';
import { PlayerGameScoreScore } from '@sorare/core/src/components/scoring/PlayerGameScoreScore';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { PrimaryBuyForAPlayer } from 'components/primaryBuy/PrimaryBuyForAPlayer';
import { BriefGame } from 'components/so5/BriefGame';
import { BriefPlayerScore } from 'components/stats/BriefPlayerScore';
import { PlayerGameScoreStats } from 'components/stats/PlayerGameScoreStats';
import { isGameStarted } from 'lib/so5';

import {
  MatchViewPlayerDetailsQuery,
  MatchViewPlayerDetailsQueryVariables,
} from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 3 })`
  isolation: isolate;
  position: relative;
  padding: var(--double-unit);
  background-color: var(--c-black);
  color: var(--c-white);
  min-height: 100%;
  width: 100vw;

  @media ${tabletAndAbove} {
    width: 480px;
  }
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const MATCH_VIEW_PLAYER_SCORE_QUERY = gql`
  query MatchViewPlayerDetailsQuery($playerSlug: String!, $gameId: ID!) {
    anyGame(id: $gameId) {
      id
      status: statusTyped
      playerGameScore(playerSlug: $playerSlug) {
        id
        scoreStatus
        anyPlayer {
          slug
          displayName
          avatarPictureUrl
        }
        ...BriefPlayerScore_playerGameScoreInterface
        ...PlayerGameScoreScore_playerGameScoreInterface
        ...PlayerGameScoreStats_playerGameScoreInterface
      }
      ...BriefGame_anyGameInterface
    }
  }
  ${BriefPlayerScore.fragments.anyPlayerInterface}
  ${BriefPlayerScore.fragments.playerGameScoreInterface}
  ${PlayerGameScoreScore.fragments.playerGameScoreInterface}
  ${PlayerGameScoreStats.fragments.playerGameScoreInterface}
  ${BriefGame.fragments.anyGameInterface}
` as TypedDocumentNode<
  MatchViewPlayerDetailsQuery,
  MatchViewPlayerDetailsQueryVariables
>;

type Props = { playerSlug: string; gameId: string; onClose: () => void };

const PlayerDetails = ({ playerSlug, gameId, onClose }: Props) => {
  const { data, loading } = useQuery(MATCH_VIEW_PLAYER_SCORE_QUERY, {
    variables: {
      playerSlug,
      gameId,
    },
  });

  if (loading) {
    return (
      <Root>
        <LoaderContainer>
          <LoadingIndicator small />
        </LoaderContainer>
      </Root>
    );
  }

  const { anyGame } = data || {};
  const { playerGameScore } = anyGame || {};
  const { anyPlayer } = playerGameScore || {};

  if (!anyGame || !playerGameScore || !anyPlayer) {
    return null;
  }

  return (
    <Root>
      <SBHorizontal>
        <BodyM as="p" bold>
          <FormattedMessage {...fantasy.playerPerformance} />
        </BodyM>
        <CloseButton onClose={onClose} />
      </SBHorizontal>
      <BriefPlayerScore
        pictureUrl={anyPlayer.avatarPictureUrl}
        anyPlayer={anyPlayer}
        playerGameScores={[playerGameScore]}
      >
        <PlayerGameScoreScore
          key={playerGameScore.id}
          playerGameScore={playerGameScore}
        />
      </BriefPlayerScore>
      <BriefGame game={anyGame} />
      <PrimaryBuyForAPlayer playerSlug={anyPlayer.slug} />
      {playerGameScore.scoreStatus !== ScoreStatus.DID_NOT_PLAY &&
        isGameStarted(anyGame.status) && (
          <PlayerGameScoreStats playerGameScores={[playerGameScore]} />
        )}
    </Root>
  );
};

export default PlayerDetails;
