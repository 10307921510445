import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { Chip } from 'atoms/ui/Chip';
import { InjuryTable, PlayerInjuryColor } from 'components/player/InjuryTable';

import { InjuryTagTemplate_injury } from './__generated__/index.graphql';

const StyledChip = styled(Chip)`
  &.red {
    background-color: var(--c-red-600);
  }
  &.yellow {
    background-color: var(--c-yellow-800);
    color: var(--c-black);
  }
`;

type Props = {
  injury?: InjuryTagTemplate_injury | null;
  getPlayerInjuryColor: (status: string | null) => PlayerInjuryColor;
  getPlayerInjuryMessage: (status: string | null) => MessageDescriptor;
};

export const InjuryTagTemplate = ({
  injury,
  getPlayerInjuryColor,
  getPlayerInjuryMessage,
}: Props) => {
  if (!injury) return null;
  const { status } = injury;
  const bgColor = getPlayerInjuryColor(status);

  return (
    <Tooltip
      title={
        <InjuryTable
          injury={injury}
          abbreviate
          getPlayerInjuryColor={getPlayerInjuryColor}
        />
      }
    >
      <StyledChip size="medium" className={classNames(bgColor)}>
        <FormattedMessage {...getPlayerInjuryMessage(status)} />
      </StyledChip>
    </Tooltip>
  );
};

InjuryTagTemplate.fragments = {
  injury: gql`
    fragment InjuryTagTemplate_injury on Injury {
      id
      status
      ...InjuryTable_injury
    }
    ${InjuryTable.fragments.injury}
  ` as TypedDocumentNode<InjuryTagTemplate_injury>,
};
