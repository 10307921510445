import { CSSProperties } from 'react';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { SBHorizontal } from 'atoms/layout/flex';
import { Color } from 'style/types';

const StepperWrapper = styled(SBHorizontal)`
  width: 100%;
  padding: var(--half-unit);
`;
const Step = styled(ButtonBase)<{ $isActive: boolean }>`
  width: 100%;
  height: var(--half-unit);
  background-color: ${({ $isActive }) =>
    $isActive ? 'var(--c-white)' : 'var(--background-color)'};
  border-radius: 2px;
`;

type Props = {
  setStep?: (step: number) => void;
  step: number;
  length: number;
  backgroundColor?: Color;
};

export const Stepper = ({
  setStep,
  step,
  length,
  backgroundColor = 'var(--c-nd-200)',
}: Props) => {
  return (
    <StepperWrapper
      style={
        {
          '--background-color': backgroundColor,
        } as CSSProperties
      }
    >
      {Array.from(Array(length).keys()).map(i => {
        return (
          <Step key={i} $isActive={i === step} onClick={() => setStep?.(i)} />
        );
      })}
    </StepperWrapper>
  );
};
