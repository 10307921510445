import { TypedDocumentNode, gql } from '@apollo/client';

import { coinBalancesFragment } from '@sorare/core/src/contexts/currentUser/queries';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { formatGqlErrors } from '@sorare/core/src/gql';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';

import {
  ClaimRewardsMutation,
  ClaimRewardsMutationVariables,
} from './__generated__/useClaimRewards.graphql';

const CLAIM_REWARDS_MUTATION = gql`
  mutation ClaimRewardsMutation($input: claimRewardsInput!, $sport: Sport!) {
    claimRewards(input: $input) {
      currentUser {
        slug
        unclaimedSo5Rewards(sport: $sport) {
          slug
        }
        unopenedProbabilisticBundles(sport: $sport, first: 100) {
          totalCount
        }
        ...CurrentUserProvider_coinBalances
      }
      so5Rewards {
        slug
        aasmState
        so5Fixture {
          slug
          mySo5Rewards {
            slug
            aasmState
          }
        }
      }
      errors {
        message
        code
      }
    }
  }
  ${coinBalancesFragment}
` as TypedDocumentNode<ClaimRewardsMutation, ClaimRewardsMutationVariables>;

export default () => {
  const [claim, { loading }] = useMutation(CLAIM_REWARDS_MUTATION);
  const { showNotification } = useSnackNotificationContext();
  const { isAndroidApp, postMessage } = useIsMobileApp();
  const { sport } = useSportContext();

  return [
    async (so5RewardIds: string[]) => {
      const result = await claim({
        variables: {
          input: {
            so5RewardIds,
          },
          sport: sport!,
        },
      });

      const errors = result.data?.claimRewards?.errors || [];

      if (errors.length) {
        showNotification('errors', { errors: formatGqlErrors(errors) });
        return errors;
      }
      if (isAndroidApp) postMessage('rewardClaimed');
      return null;
    },
    { loading },
  ] as const;
};
