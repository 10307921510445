import classnames from 'classnames';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { glossary } from 'lib/glossary';

import loadingGif from './loading.gif';

export interface Props {
  error?: string;
  style?: any;
  small?: boolean;
  smaller?: boolean;
  fullHeight?: boolean;
  grow?: boolean;
  fullScreen?: boolean;
}

const Container = styled.div<{ $white?: boolean }>`
  position: relative;
  color: inherit;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 1em;
`;

const starAnimation = keyframes`
  0% {
    transform: rotate(-10deg) scale(1.15);
  }
  30% {
    transform: rotate(-92deg) scale(1);
  }
  100% {
    transform: rotate(-72deg) scale(1.15);
  }
`;

const Svg = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  animation: ${starAnimation} 1.35s linear infinite;
`;

export const BaseLoadingIndicator = () => {
  const { formatMessage } = useIntl();
  return (
    <Container aria-label={formatMessage(glossary.loading)}>
      <Svg
        width="60"
        height="60"
        viewBox="0 0 34 34"
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17" cy="17" r="14.5" strokeWidth={5} />
        <path
          d="M22.22 14.04Q19.98 17.34 21.43 21.05Q17.60 19.94 14.51 22.46Q14.39 18.48 11.04 16.32Q14.79 14.97 15.80 11.12Q18.24 14.27 22.22 14.04Z"
          strokeLinejoin="round"
          strokeWidth={1}
          fill="currentColor"
        />
      </Svg>
    </Container>
  );
};

const Root = styled(Horizontal).attrs({ gap: 0, center: true })`
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: auto;
  vertical-align: top;
  &.fullHeight {
    height: 100vh;
  }
  &.fullHeight.white {
    background-color: var(--c-white);
  }
  &.grow {
    flex: 1;
  }
  &.fullScreen {
    position: fixed;
    inset: 0;
    overflow: auto;
    z-index: 10;
    background: var(--c-black);
  }
`;
const Error = styled.span``;
const StyledContainer = styled.span`
  position: relative;
  font-size: calc(8 * var(--unit));
  ${Root}.small & {
    font-size: calc(4 * var(--unit));
  }
  ${Root}.smaller & {
    font-size: calc(2 * var(--unit));
  }
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const LoadingIndicator = ({
  error,
  style = null,
  small,
  smaller,
  fullHeight,
  fullScreen,
  grow,
}: Props) => {
  const { formatMessage } = useIntl();
  const { isMobileApp } = useIsMobileApp();

  return (
    <Root
      className={classnames({
        small,
        smaller,
        fullHeight,
        fullScreen,
        grow,
      })}
      style={style}
    >
      {error ? (
        <Error>{error}</Error>
      ) : (
        <StyledContainer>
          {isMobileApp ? (
            <Container aria-label={formatMessage(glossary.loading)}>
              <img src={loadingGif} alt={formatMessage(glossary.loading)} />
            </Container>
          ) : (
            <BaseLoadingIndicator />
          )}
        </StyledContainer>
      )}
    </Root>
  );
};
