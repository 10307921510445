import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { BuyConfirmation } from 'components/buyActions/BuyConfirmation';
import { ANY_SPORT_MY_CLUB_SLUG_CARDS } from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { zeroMonetaryAmount } from 'hooks/useMonetaryAmount';
import { glossary } from 'lib/glossary';
import { monetaryAmountFragment } from 'lib/monetaryAmount';
import { generateSportPath } from 'lib/routing/generateSportPath';

import {
  AuctionNotificationContent_anyReward,
  AuctionWonContent_tokenAuction,
} from './__generated__/index.graphql';

export type Props = {
  tokenAuction: AuctionWonContent_tokenAuction;
  rewards: Nullable<AuctionNotificationContent_anyReward[]>;
  isRendering?: boolean;
  onClick?: () => void;
};

export const AuctionWonContent = ({
  tokenAuction,
  rewards,
  onClick,
}: Props) => {
  const { sport } = tokenAuction.cards[0];
  const { currentUser } = useCurrentUserContext();
  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: tokenAuction.bestBid?.amounts || zeroMonetaryAmount,
  });

  const { bidsCount } = tokenAuction;

  return (
    <BuyConfirmation
      cards={tokenAuction.cards}
      title={
        <FormattedMessage
          id="buyTokenConfirmation.auction.title"
          defaultMessage="Auction{br}Won!"
          values={{ br: <br /> }}
        />
      }
      subtitle={
        <FormattedMessage
          id="buyTokenConfirmation.auction.subtitle"
          defaultMessage="{price} • {bidsCount} {bidsCount, plural, one {bid} other {bids}}"
          values={{
            br: <br />,
            price: `${main}${exponent ? ` - ${exponent}` : ''}`,
            bidsCount,
          }}
        />
      }
      additionalRewards={rewards}
      actions={
        <Vertical>
          <Button onClick={onClick} color="secondary" size="medium">
            <FormattedMessage {...glossary.continue} />
          </Button>
          <Button
            onClick={onClick}
            color="transparent"
            size="medium"
            to={generateSportPath(ANY_SPORT_MY_CLUB_SLUG_CARDS, {
              params: {
                slug: currentUser?.slug,
              },
              sport,
            })}
          >
            <FormattedMessage {...glossary.viewGallery} />
          </Button>
        </Vertical>
      }
    />
  );
};

AuctionWonContent.fragments = {
  tokenAuction: gql`
    fragment AuctionWonContent_tokenAuction on TokenAuction {
      id
      bidsCount
      bestBid {
        id
        amounts {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
      cards: anyCards {
        slug
        sport
        ...BuyConfirmation_anyCard
      }
    }
    ${BuyConfirmation.fragments.anyCard}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<AuctionWonContent_tokenAuction>,
  anyReward: gql`
    fragment AuctionNotificationContent_anyReward on AnyRewardInterface {
      id
      ...BuyConfirmation_anyReward
    }
    ${BuyConfirmation.fragments.anyReward}
  ` as TypedDocumentNode<AuctionNotificationContent_anyReward>,
};

export default AuctionWonContent;
