import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { ShopItemType } from '@sorare/core/src/__generated__/globalTypes';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text14 } from '@sorare/core/src/atoms/typography';
import { CoinAmount } from '@sorare/core/src/components/clubShop/CoinAmount';
import Dialog from '@sorare/core/src/components/dialog';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useCoinBalance } from '@sorare/core/src/hooks/clubShop/useCoinBalance';
import { isType } from '@sorare/core/src/lib/gql';

import { AppearanceBoostContent } from './Content/AppearanceBoostContent';
import { CommonCardSwapContent } from './Content/CommonCardSwapContent';
import { DefaultContent } from './Content/DefaultContent';
import { DeliverableWithNoVariantContent } from './Content/DeliverableWithNoVariantContent';
import { ExtraSwapContent } from './Content/ExtraSwapContent';
import { ExtraTeamsContent } from './Content/ExtraTeamsContent';
import { JerseyContent } from './Content/JerseyContent';
import { LevelUpContent } from './Content/LevelUpContent';
import { LogoContent } from './Content/LogoContent';
import { RaffleContent } from './Content/RaffleContent';
import { SkinContent } from './Content/SkinContent';
import { ItemPreviewDialog_shopItem } from './__generated__/index.graphql';

const Body = styled(Vertical).attrs({ gap: 3 })`
  padding: var(--triple-unit);
`;
const MyBalance = styled.div`
  justify-self: flex-end;
  display: flex;
  justify-content: space-between;
  padding: var(--double-unit);
  border-top: 1px solid var(--c-nd-100);
`;

type DialogContentProps = {
  item: ItemPreviewDialog_shopItem;
  inventory: boolean;
  onClose: () => void;
  userCoinBalance: number;
  userSlug?: string;
  onBuy?: () => void;
  itemEquipped?: boolean;
  onSelect?: () => void;
};
const DialogContent = ({
  item,
  onClose,
  inventory,
  userCoinBalance,
  userSlug,
  onBuy,
  itemEquipped,
  onSelect,
}: DialogContentProps) => {
  if (isType(item, 'JerseyShopItem')) {
    return (
      <JerseyContent
        item={item}
        onClose={onClose}
        userCoinBalance={userCoinBalance}
        inventory={inventory}
      />
    );
  }
  if (isType(item, 'DeliverableWithNoVariantShopItem')) {
    return (
      <DeliverableWithNoVariantContent
        item={item}
        onClose={onClose}
        userCoinBalance={userCoinBalance}
        inventory={inventory}
      />
    );
  }
  if (isType(item, 'LevelUpShopItem')) {
    return (
      <LevelUpContent
        item={item}
        onClose={onClose}
        inventory={inventory}
        userCoinBalance={userCoinBalance}
        onBuy={onBuy}
      />
    );
  }
  if (isType(item, 'ExtraSwapShopItem')) {
    return (
      <ExtraSwapContent
        item={item}
        inventory={inventory}
        userCoinBalance={userCoinBalance}
        onBuy={onBuy}
      />
    );
  }
  if (isType(item, 'SkinShopItem')) {
    if (item.position === ShopItemType.LOGO) {
      return <LogoContent item={item} onSelect={onSelect} />;
    }

    return (
      <SkinContent
        inventory={inventory}
        item={item}
        itemEquipped={itemEquipped}
        userCoinBalance={userCoinBalance}
        onBuy={onBuy}
        onSelect={onSelect}
      />
    );
  }

  if (item.position === ShopItemType.EXTRA_TEAMS_CAP) {
    return (
      <ExtraTeamsContent
        item={item}
        inventory={inventory}
        onBuy={onBuy}
        userCoinBalance={userCoinBalance}
        onClose={onClose}
      />
    );
  }

  if (item.position === ShopItemType.RAFFLE) {
    return (
      <RaffleContent
        item={item}
        inventory={inventory}
        onBuy={onBuy}
        userCoinBalance={userCoinBalance}
        onClose={onClose}
      />
    );
  }

  if (item.position === ShopItemType.APPEARANCE_BOOST) {
    return (
      <AppearanceBoostContent
        item={item}
        inventory={inventory}
        onBuy={onBuy}
        userCoinBalance={userCoinBalance}
        onClose={onClose}
      />
    );
  }

  if (item.position === ShopItemType.COMMON_CARD_SWAP) {
    return (
      <CommonCardSwapContent
        item={item}
        inventory={inventory}
        onBuy={onBuy}
        userCoinBalance={userCoinBalance}
        userSlug={userSlug}
        onClose={onClose}
      />
    );
  }

  return (
    <DefaultContent
      item={item}
      inventory={inventory}
      onBuy={onBuy}
      userCoinBalance={userCoinBalance}
    />
  );
};

type Props = {
  open: boolean;
  onSelect?: () => void;
  item: ItemPreviewDialog_shopItem;
  itemEquipped?: boolean;
  inventory?: boolean;
  onClose: () => void;
  onBuy?: () => void;
};
export const ItemPreviewDialog = ({
  open,
  item,
  inventory,
  onClose,
  onBuy,
  itemEquipped,
  onSelect,
}: Props) => {
  const { coinBalance } = useCoinBalance();
  const { currentUser } = useCurrentUserContext();

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={onClose}
      footer={
        <MyBalance>
          <Text14 color="var(--c-nd-600)">
            <FormattedMessage
              id="ClubShop.ItemPreviewDialog.CurrentBalance"
              defaultMessage="Current balance"
            />
          </Text14>
          <CoinAmount amount={coinBalance} />
        </MyBalance>
      }
    >
      <Body>
        <DialogContent
          item={item}
          onClose={onClose}
          inventory={!!inventory}
          userCoinBalance={coinBalance}
          onBuy={onBuy}
          itemEquipped={itemEquipped}
          onSelect={onSelect}
          userSlug={currentUser?.slug}
        />
      </Body>
    </Dialog>
  );
};

ItemPreviewDialog.fragments = {
  shopItem: gql`
    fragment ItemPreviewDialog_shopItem on ClubShopItem {
      ... on ShopItemInterface {
        id
      }
      ...JerseyContent_clubShopItem
      ...DeliverableWithNoVariantContent_clubShopItem
      ...LevelUpContent_clubShopItem
      ...ExtraSwapContent_clubShopItem
      ...SkinContent_clubShopItem
      ...LogoContent_clubShopItem
      ...ExtraTeamsContent_clubShopItem
      ...RaffleContent_clubShopItem
      ...AppearanceBoostContent_clubShopItem
      ...CommonCardSwapContent_clubShopItem
      ...DefaultContent_clubShopItem
    }
    ${JerseyContent.fragments.clubShopItem}
    ${DeliverableWithNoVariantContent.fragments.clubShopItem}
    ${LevelUpContent.fragments.clubShopItem}
    ${ExtraSwapContent.fragments.clubShopItem}
    ${LogoContent.fragments.clubShopItem}
    ${SkinContent.fragments.clubShopItem}
    ${ExtraTeamsContent.fragments.clubShopItem}
    ${RaffleContent.fragments.clubShopItem}
    ${AppearanceBoostContent.fragments.clubShopItem}
    ${CommonCardSwapContent.fragments.clubShopItem}
    ${DefaultContent.fragments.clubShopItem}
  ` as TypedDocumentNode<ItemPreviewDialog_shopItem>,
};
