import { TypedDocumentNode, gql } from '@apollo/client';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { isType } from '@sorare/core/src/gql';
import { glossary } from '@sorare/core/src/lib/glossary';
import { getSafePreviousLocation } from '@sorare/core/src/lib/navigation/getSafePreviousLocation';
import { desktopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { HeaderTeamsMatchup } from 'components/rivals/HeaderTeamsMatchup';

import { PlayHeader_game } from './__generated__/index.graphql';

const Root = styled(Vertical)`
  position: relative;
  isolation: isolate;
  padding: var(--unit) 0;
  background-color: var(--c-black);
  @media ${desktopAndAbove} and (min-height: 800px) {
    padding: var(--triple-unit) 0;
  }
`;
const Wrapper = styled.div`
  padding: var(--double-unit) var(--double-unit) 0;
  @media ${desktopAndAbove} {
    padding: 0 var(--double-unit);
  }
`;
const MobileWrapper = styled.div`
  display: block;
  position: absolute;
  top: var(--unit);
  left: var(--unit);
  @media ${desktopAndAbove} {
    display: none;
  }
`;

type Props = { game: PlayHeader_game; backUrl: string };
export const PlayHeader = ({ game, backUrl }: Props) => {
  const { formatMessage } = useIntl();

  const displayRankings =
    isType(game.homeTeam, 'Club') &&
    game.competition.slug === game.homeTeam?.domesticLeague?.slug;

  return (
    <FullWidthContainer.NoPadding>
      <Root>
        <MobileWrapper>
          <IconButton
            color="transparent"
            to={getSafePreviousLocation(backUrl)}
            icon={faChevronLeft}
            aria-label={formatMessage(glossary.back)}
          />
        </MobileWrapper>
        {game && (
          <Wrapper>
            <HeaderTeamsMatchup game={game} displayRankings={displayRankings} />
          </Wrapper>
        )}
      </Root>
    </FullWidthContainer.NoPadding>
  );
};

PlayHeader.fragments = {
  game: gql`
    fragment PlayHeader_game on Game {
      id
      competition {
        slug
      }
      homeTeam {
        slug
        ... on Club {
          slug
          domesticLeague {
            slug
          }
        }
      }
      ...HeaderTeamsMatchup_game
    }
    ${HeaderTeamsMatchup.fragments.game}
  ` as TypedDocumentNode<PlayHeader_game>,
};
