import { useReadQuery } from '@apollo/client';
import { useLoaderData } from 'react-router-dom';

export const useQueryLoaderResult = <TData>() => {
  const loaderData = useLoaderData();
  if (!loaderData) {
    throw new Error(
      'useQueryLoaderResult must be used within a route that has a query loader. Did you forget to export the loader?'
    );
  }
  const preloadedQueryRef = loaderData;
  const queryResult = useReadQuery(preloadedQueryRef);
  return queryResult.data as TData;
};
