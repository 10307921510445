import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { DisplayL, LabelL, LabelM } from '@sorare/core/src/atoms/typography';

import { Step } from 'components/rewards/BoxOpeningOnboardingDialog/CardOpeningOnboardingDialog/Step';

import { Rarity_anyCard } from './__generated__/index.graphql';

type Props = { card: Nullable<Rarity_anyCard>; description?: ReactNode };

const gradientText = css`
  background: linear-gradient(323deg, #ae5e13 14.3%, #dfad46 85.7%);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;
const BadgeText = styled(LabelL)`
  ${gradientText}
`;

const Title = styled(DisplayL)`
  ${gradientText}
`;

export const Rarity = ({ card, description }: Props) => {
  return (
    <Step
      card={card}
      badge={
        <BadgeText color="var(--c-rivals-reputation)" bold>
          +20%
        </BadgeText>
      }
      color="#ae5e13"
      description={
        description || (
          <Vertical gap={0.5}>
            <FormattedMessage
              id="BoxOpeningOnboardingDialog.rarityStep.description"
              defaultMessage="<xs>You won a</xs><xl>Limited {br}Card</xl><md>Gain 20% bonus fantasy points on every Arena match with this card.</md>"
              values={{
                br: <br />,
                xs: content => <LabelM>{content}</LabelM>,
                xl: content => (
                  <Title color="var(--c-rivals-reputation)">{content}</Title>
                ),
                md: content => <LabelM>{content}</LabelM>,
              }}
            />
          </Vertical>
        )
      }
    />
  );
};

Rarity.fragments = {
  anyCard: gql`
    fragment Rarity_anyCard on AnyCardInterface {
      slug

      ...Step_anyCard
    }
    ${Step.fragments.anyCard}
  ` as TypedDocumentNode<Rarity_anyCard>,
};
