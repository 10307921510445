import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { BodyS } from '@sorare/core/src/atoms/typography';

import { InSeasonWarning_anyCard } from './__generated__/index.graphql';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: var(--half-unit);
`;

type Props = {
  card: InSeasonWarning_anyCard;
};

export const InSeasonWarning = ({ card }: Props) => {
  if (card.inSeasonEligible) {
    return null;
  }
  return (
    <BodyS color="var(--c-nd-600)" as="p">
      <StyledFontAwesomeIcon icon={faInfoCircle} />
      <FormattedMessage
        id="tokenSummary.inSeasonWarning"
        defaultMessage="Card may not be eligible for in season competitions."
      />
    </BodyS>
  );
};

InSeasonWarning.fragments = {
  anyCard: gql`
    fragment InSeasonWarning_anyCard on AnyCardInterface {
      slug
      inSeasonEligible
    }
  ` as TypedDocumentNode<InSeasonWarning_anyCard>,
};
