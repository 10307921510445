import { ReactNode } from 'react';
import styled from 'styled-components';

import { Title3 } from 'atoms/typography';

const Root = styled(Title3)`
  color: var(--c-white);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  title: string | ReactNode;
};

export const BlockHeader = ({ title }: Props) => {
  return <Root>{title}</Root>;
};
