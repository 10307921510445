import { FormattedNumber } from 'react-intl';

export const Probability = ({ probability }: { probability: number }) => {
  if (probability < 0.001) {
    return (
      <>
        {'<'}
        <FormattedNumber
          value={0.001}
          maximumFractionDigits={2}
          style="percent"
        />
      </>
    );
  }

  return (
    <FormattedNumber
      value={probability}
      maximumFractionDigits={2}
      style="percent"
    />
  );
};
