import { Outlet } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { PlayLayout } from 'components/rivals/PlayLayout';

import { LayoutParams } from './__generated__/routeParams';

const RivalsMyLineupsGameSlugLayout = () => {
  const { gameSlug } = useTypedParams<LayoutParams>();

  return (
    <PlayLayout slug={gameSlug}>
      <Outlet />
    </PlayLayout>
  );
};
export default RivalsMyLineupsGameSlugLayout;
