import { TypedDocumentNode, gql } from '@apollo/client';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Tradeable } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Text16 } from '@sorare/core/src/atoms/typography';
import {
  AndroidAppFeature,
  NotMobileAppFeature,
  UnvailableButtonForAndroid,
} from '@sorare/core/src/components/BuyableFeature';
import { useTokenBelongsToUser } from '@sorare/core/src/hooks/useTokenBelongsToUser';
import { mobileApp } from '@sorare/core/src/lib/glossary';

import NewSaleDialog from 'components/offer/NewSaleDialog';
import { TokenTransferValidator } from 'components/token/TokenTransferValidator';
import useCannotSell from 'hooks/offers/useCannotSell';
import { useMarketplaceEvents } from 'lib/events';

import { SellCard_anyCard } from './__generated__/index.graphql';

const messages = defineMessages<Tradeable>({
  [Tradeable.NOT_YET]: {
    id: 'Sell.not_yet',
    defaultMessage: 'Your Card is not transferable yet. It will be soon!',
  },
  [Tradeable.NO]: {
    id: 'Sell.no',
    defaultMessage:
      'Your Card cannot be sold. You must transfer it to your Sorare account.',
  },
  [Tradeable.YES]: {
    id: 'Sell.yes',
    defaultMessage: 'Your Card can be sold on the Manager Sales Market.',
  },
  [Tradeable.UNDEFINED]: {
    id: 'Sell.undefined',
    defaultMessage: 'This Card has no owner.',
  },
  [Tradeable.DEPOSIT_REQUIRED]: {
    id: 'Sell.deposit_required',
    defaultMessage: 'You need to make a deposit.',
  },
});

interface SellProps {
  card: SellCard_anyCard;
}

const ListButton = styled(Button)`
  flex-grow: 1;
`;

const Sell = ({ card }: SellProps) => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();
  const belongsToUser = useTokenBelongsToUser();
  const cannotSell = useCannotSell();
  const track = useMarketplaceEvents();
  const cannotSellValue = useMemo(() => cannotSell(card), [card, cannotSell]);

  const { myMintedSingleSaleOffer, tradeableStatus } = card;

  if (!belongsToUser(card) || myMintedSingleSaleOffer) return null;

  return (
    <>
      <NotMobileAppFeature>
        <TokenTransferValidator cards={[card]} transferContext="list">
          {({ validationMessages }) => (
            <>
              <Tooltip
                disableFocusListener
                title={
                  Object.keys(validationMessages).length === 0 ? (
                    <Text16 color="var(--c-white)">
                      {formatMessage(
                        cannotSellValue || messages[tradeableStatus]
                      )}
                    </Text16>
                  ) : (
                    ''
                  )
                }
              >
                <ListButton
                  disabled={Boolean(cannotSellValue)}
                  onClick={() => {
                    setOpen(true);
                    track('Click List Card', {
                      cardSlug: card.slug,
                      hasWarnings: Object.keys(validationMessages).length > 0,
                    });
                  }}
                  color="secondary"
                  size="medium"
                  startIcon={
                    !cannotSellValue &&
                    validationMessages[card.slug] && (
                      <Tooltip title={validationMessages[card.slug]}>
                        <FontAwesomeIcon icon={faWarning} />
                      </Tooltip>
                    )
                  }
                >
                  <FormattedMessage
                    id="Sell.cta"
                    defaultMessage="List my Card"
                  />
                </ListButton>
              </Tooltip>
              {open && (
                <NewSaleDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  card={card}
                />
              )}
            </>
          )}
        </TokenTransferValidator>
      </NotMobileAppFeature>
      <AndroidAppFeature>
        <UnvailableButtonForAndroid
          message={mobileApp.disabledSale}
          size="medium"
          color="secondary"
          onClick={() => {}}
          fullWidth
        />
      </AndroidAppFeature>
    </>
  );
};

Sell.fragments = {
  anyCard: gql`
    fragment SellCard_anyCard on AnyCardInterface {
      slug
      tradeableStatus
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
      }
      tokenOwner {
        id
        settleAt
      }
      ...useTokenBelongsToUser_anyCard
      ...useCannotSell_anyCard
      ...NewSaleDialog_anyCard
    }
    ${useCannotSell.fragments.anyCard}
    ${useTokenBelongsToUser.fragments.anyCard}
    ${NewSaleDialog.fragments.anyCard}
  ` as TypedDocumentNode<SellCard_anyCard>,
};

export default Sell;
