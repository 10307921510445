import {
  faDollar,
  faHourglassStart,
  faRectangleVerticalHistory,
  faRightLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, FormattedNumber, defineMessages } from 'react-intl';
import styled, { CSSProperties } from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, DisplayS, LabelL } from '@sorare/core/src/atoms/typography';

import { DialogDescription } from '../DialogDescription';

const Wrapper = styled(Vertical).attrs({ center: true, gap: 4 })`
  height: 100%;
  padding: 0 var(--double-unit);
  background: var(--c-black);
`;

const SellTimelineWrapper = styled(Vertical).attrs({ center: true })`
  flex: 1;
`;

const SellTimeline = styled(Vertical).attrs({ gap: 6 })`
  position: relative;
  isolation: isolate;
`;

const IconWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: 50%;
  background: var(--icon-bg-color);
  & > svg {
    color: var(--c-black);
    width: var(--double-unit);
  }
`;

const TimelineColumn = styled.div`
  position: absolute;
  z-index: -1;
  top: 20px;
  left: 11px;
  bottom: 30px;
  width: 10px;
  background: linear-gradient(180deg, #ffffff 0%, #33ce78 94.62%);
`;

const StepContent = styled(Vertical).attrs({ gap: 0 })`
  flex: 1;
`;

const messages = defineMessages({
  chooseCardTitle: {
    id: 'SellExplanation.chooseCardTitle',
    defaultMessage: 'Choose the card',
  },
  chooseCardSubtitle: {
    id: 'SellExplanation.chooseCardSubtitle',
    defaultMessage: 'Find all your cards in “My Club”',
  },
  listCardTitle: {
    id: 'SellExplanation.listCardTitle',
    defaultMessage: 'Tap the “List My Card” button',
  },
  listCardSubtitle: {
    id: 'SellExplanation.listCardSubtitle',
    defaultMessage: 'Tap the “List My Card” button',
  },
  waitForOfferTitle: {
    id: 'SellExplanation.waitForOfferTitle',
    defaultMessage: 'Wait for an offer',
  },
  waitForOfferSubtitle: {
    id: 'SellExplanation.waitForOfferSubtitle',
    defaultMessage:
      'Managers can accept your offer or counter-offer with cash and/or cards',
  },
  sellCardTitle: {
    id: 'SellExplanation.sellCardTitle',
    defaultMessage: 'Sell your card!',
  },
  sellCardSubtitle: {
    id: 'SellExplanation.sellCardSubtitle2',
    defaultMessage: "You'll get the cash immediately",
  },
});

const STEPS = [
  {
    title: messages.chooseCardTitle,
    subtitle: messages.chooseCardSubtitle,
    icon: <FontAwesomeIcon icon={faRectangleVerticalHistory} />,
    iconBgColor: '#FFFFFF',
  },
  {
    title: messages.listCardTitle,
    subtitle: messages.listCardSubtitle,
    icon: <FontAwesomeIcon icon={faRightLeft} />,
    iconBgColor: '#D2F4E1',
  },
  {
    title: messages.waitForOfferTitle,
    subtitle: messages.waitForOfferSubtitle,
    icon: <FontAwesomeIcon icon={faHourglassStart} />,
    iconBgColor: '#8CE4B3',
  },
  {
    title: messages.sellCardTitle,
    subtitle: messages.sellCardSubtitle,
    icon: <FontAwesomeIcon icon={faDollar} />,
    iconBgColor: '#33CE78',
  },
];

export const SellExplanation = () => {
  return (
    <Wrapper>
      <Vertical center gap={0}>
        <DisplayS>
          <FormattedMessage
            id="SellExplanation.title"
            defaultMessage="Selling is easy"
          />
        </DisplayS>
        <LabelL color="var(--c-nd-600)">
          <FormattedMessage
            id="SellExplanation.subtitle"
            defaultMessage="{count} Managers sold a card in the last 7 days"
            values={{
              count: <FormattedNumber value={10000} notation="compact" />,
            }}
          />
        </LabelL>
      </Vertical>
      <SellTimelineWrapper>
        <SellTimeline>
          <TimelineColumn />
          {STEPS.map(({ title, subtitle, icon, iconBgColor }) => (
            <Horizontal key={title.id} gap={2}>
              <IconWrapper
                style={
                  {
                    '--icon-bg-color': iconBgColor,
                  } as CSSProperties
                }
              >
                {icon}
              </IconWrapper>
              <StepContent gap={0}>
                <LabelL bold>
                  <FormattedMessage {...title} />
                </LabelL>
                <BodyS color="var(--c-nd-600)">
                  <FormattedMessage {...subtitle} />
                </BodyS>
              </StepContent>
            </Horizontal>
          ))}
        </SellTimeline>
      </SellTimelineWrapper>
      <DialogDescription>
        <FormattedMessage
          id="SellExplanation.description"
          defaultMessage="Scout the best talent and sell when the price is right!"
        />
      </DialogDescription>
    </Wrapper>
  );
};
