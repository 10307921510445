import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { DumbPlayerAvatar } from '@sorare/core/src/components/player/DumbPlayerAvatar';
import { LEGACY_PLAYER_SHOW } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';

interface Props {
  displayName: string;
  squaredPictureUrl: string | null;
  slug: string;
  positions: string[];
  shirtNumber: number | null;
  activeClubName?: string;
  sport?: Sport;
}

const Root = styled.div`
  display: flex;
  gap: var(--unit);
`;
const StyledDumbPlayerAvatar = styled(DumbPlayerAvatar)`
  width: 48px;
  height: 48px;
`;

export const DumbPlayerDescription = (props: Props) => {
  const {
    displayName,
    squaredPictureUrl,
    slug,
    positions,
    shirtNumber,
    activeClubName,
    sport,
  } = props;
  const { generateSportContextPath } = useSportContext();

  return (
    <Root>
      <StyledDumbPlayerAvatar
        name={displayName}
        avatarUrl={squaredPictureUrl}
      />
      <div>
        <Link
          to={generateSportContextPath(LEGACY_PLAYER_SHOW, {
            params: { slug },
            sport,
          })}
        >
          <Text16 bold>{displayName}</Text16>
        </Link>
        <Text16 color="var(--c-nd-600)">
          {`${positions.join(', ')} #${shirtNumber} | ${activeClubName}`}
        </Text16>
      </div>
    </Root>
  );
};

export default DumbPlayerDescription;
