import { useTypedParams } from '@sorare/routing';

import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

import GameplayLeaderboardPage from '@sorare/gameplay/src/components/collections/LeaderboardPage';

import { PageParams } from './__generated__/routeParams';

const LeaderboardPage = () => {
  const { collectionSlug } = useTypedParams<PageParams>();
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) {
    return null;
  }

  return (
    <GameplayLeaderboardPage
      collection={collectionSlug}
      slug={currentUser.slug}
    />
  );
};

export default LeaderboardPage;
