import classnames from 'classnames';
import { ReactNode, RefObject, createContext, useContext } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { resetStack } from 'atoms/layout/ContentWithStickyHeader';
import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import Dialog from 'components/dialog';
import { useIsDesktopAndAbove } from 'hooks/device/useIsDesktopAndAbove';
import { desktopAndAbove } from 'style/mediaQuery';

import { ListWithDetailVariants } from './types';
import { useNavigateToListPath } from './useNavigateToListPath';

const listLayout = css`
  display: grid;
  grid-template-columns: 424px 1fr;
  flex: 1;
  & > *:first-child {
    border-right: 1px solid var(--c-nd-100);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
export const ListWithDetailLayoutWrapper = styled.div<{
  variant?: ListWithDetailVariants;
}>`
  --area-height: calc(100vh - var(--current-stack-height));
  @media ${desktopAndAbove} {
    min-height: var(--area-height);
    ${({ variant = ListWithDetailVariants.LIST }) =>
      variant === ListWithDetailVariants.LIST && listLayout}
  }
  & > *.reverse:first-child {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const MainContent = styled(FullWidthContainer)`
  &.noPadding {
    padding: 0;
  }
  > *:only-child {
    min-height: 100%;
  }
  @media ${desktopAndAbove} {
    &.list {
      position: sticky;
      top: var(--current-stack-height);
      max-height: var(--area-height);
      > * {
        ${resetStack}
      }
    }
  }
`;

const RightPanel = styled(FullWidthContainer)`
  padding-bottom: var(--triple-unit);
  min-width: 0;
  > *:only-child {
    height: 100%;
  }
  @media ${desktopAndAbove} {
    padding-bottom: 0;
  }
`;

const DialogWrapper = styled(FullWidthContainer.NoPadding)`
  height: 100%;
`;

export { ListWithDetailVariants };

type ListWithDetailLayoutContextType = {
  variant: ListWithDetailVariants;
  isDesktop: boolean;
};

export const ListWithDetailLayoutContext =
  createContext<ListWithDetailLayoutContextType>({
    variant: ListWithDetailVariants.LIST,
    isDesktop: true,
  });

export const useListWithDetailLayoutContext = () =>
  useContext(ListWithDetailLayoutContext);

type Props = {
  children?: ReactNode;
  variant?: ListWithDetailVariants;
  outletFallback?: ReactNode;
  outletContext?: unknown;
  loading?: boolean;
  noPadding?: boolean;
  reverse?: boolean;
  /**
   * Combine with <LineupsLayoutSwitch /> to navigate back
   * to the list path when switching from list to grid
   */
  listPath?: string;
  scrollRef?: RefObject<HTMLDivElement>;
  className?: string;
  forceNoMobileDialog?: boolean;
};

export const ListWithDetailLayout = ({
  children,
  variant = ListWithDetailVariants.LIST,
  outletFallback,
  outletContext,
  loading,
  listPath,
  noPadding,
  reverse,
  scrollRef,
  className,
  forceNoMobileDialog,
}: Props) => {
  const outlet = useOutlet(outletContext);
  const isDesktopAndAbove = useIsDesktopAndAbove();
  const navigate = useNavigate();
  const navigating = useNavigateToListPath(variant, listPath);
  // do not render outlet while navigating to avoid flash
  const renderedOutlet = navigating ? null : outlet;

  const finalVariant =
    variant === ListWithDetailVariants.GRID ||
    (!isDesktopAndAbove && !forceNoMobileDialog)
      ? ListWithDetailVariants.GRID
      : ListWithDetailVariants.LIST;

  return (
    <ListWithDetailLayoutWrapper variant={finalVariant} className={className}>
      <ListWithDetailLayoutContext.Provider
        value={{ variant: finalVariant, isDesktop: isDesktopAndAbove }}
      >
        <MainContent
          className={classnames({ noPadding, reverse }, finalVariant)}
          ref={scrollRef}
        >
          {children}
        </MainContent>
        {finalVariant === ListWithDetailVariants.GRID && !loading && (
          <Dialog
            open={!!renderedOutlet}
            onClose={isDesktopAndAbove ? () => navigate('.') : undefined}
            fullScreen={!isDesktopAndAbove}
          >
            <DialogWrapper>{renderedOutlet}</DialogWrapper>
          </Dialog>
        )}
      </ListWithDetailLayoutContext.Provider>
      {finalVariant === ListWithDetailVariants.LIST && (
        <RightPanel>
          {loading ? null : renderedOutlet || outletFallback}
        </RightPanel>
      )}
    </ListWithDetailLayoutWrapper>
  );
};
