import { TypedDocumentNode } from '@apollo/client';
import classNames from 'classnames';
import gql from 'graphql-tag.macro';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Collapse } from 'atoms/layout/Collapse';
import { Vertical } from 'atoms/layout/flex';
import { BodyS, HeadlineXS, LabelM, LabelS } from 'atoms/typography';
import useToggle from 'hooks/useToggle';
import { glossary } from 'lib/glossary';
import { hideScrollbar } from 'style/utils';

import { NewsUpdates_PlayerNewsUpdate } from './__generated__/index.graphql';

const messages = defineMessages({
  analysis: {
    id: 'Player.News.analysis',
    defaultMessage: 'Analysis',
  },
  noRecentNews: {
    id: 'Player.News.noRecentNews',
    defaultMessage: 'There is no recent news for {playerName}',
  },
});

const divide = css`
  > * + * {
    border-top: 1px solid var(--c-nd-200);
    padding-top: var(--triple-unit);
  }
`;

const ScrollableStack = styled(Vertical).attrs({ gap: 3 })`
  ${divide}
  ${hideScrollbar}
  max-height: calc(115 * var(--unit));
  overflow: auto;
`;

const Wrapper = styled(Vertical)`
  position: relative;
  overflow: hidden;
  &.open {
    ${ScrollableStack} {
      mask-image: linear-gradient(
        180deg,
        white 0%,
        white calc(100% - (10 * var(--unit))),
        transparent
      );
    }
  }
`;

// Delegates hiding content overflow to Wrapper to allow scrolling
const StyledCollapsible = styled(Collapse)`
  overflow: 'hidden';
  &.open {
    overflow: initial;
  }
`;

const VerticalDivided = styled(Vertical).attrs({ gap: 3 })`
  ${divide}
  padding-block-end: calc(8 * var(--unit));
`;

const ToggleButton = styled(Button)`
  position: absolute;
  inset: auto 0 0;
  background-color: rgba(var(--c-rgb-neutral-1000), 0.11);
  backdrop-filter: blur(4px);
`;

const Empty = styled(BodyS).attrs({ as: 'p' })`
  color: var(--c-nd-600);
  margin-top: calc(-1 * var(--half-unit));
`;

type Props = {
  className?: string;
  updates: NewsUpdates_PlayerNewsUpdate[];
  playerName: string;
  hideAnalysis?: boolean;
};

const NewsUpdate = ({
  headline,
  date,
  notes,
  analysis,
  hideAnalysis,
}: NewsUpdates_PlayerNewsUpdate & Pick<Props, 'hideAnalysis'>) => {
  const { formatDate } = useIntl();
  return (
    <Vertical>
      {date && (
        <LabelS as="p" color="var(--c-nd-600)">
          {formatDate(date, { dateStyle: 'long' })}
        </LabelS>
      )}
      <HeadlineXS as="h3">{headline}</HeadlineXS>
      <BodyS as="p" color="var(--c-nd-700)">
        {notes}
      </BodyS>
      {!hideAnalysis && (
        <>
          <LabelM as="h4" bold>
            <FormattedMessage {...messages.analysis} />
          </LabelM>
          <BodyS as="p" color="var(--c-nd-700)">
            {analysis}
          </BodyS>
        </>
      )}
    </Vertical>
  );
};

export const NewsUpdates = ({
  className,
  updates,
  playerName,
  hideAnalysis,
}: Props) => {
  const [open, toggleOpen] = useToggle(false);
  const [latestUpdate, ...hiddenUpdates] = updates;
  const hasHiddenUpdates = hiddenUpdates.length > 0;
  return (
    <Wrapper className={classNames(open && 'open', className)}>
      <ScrollableStack>
        {latestUpdate ? (
          <NewsUpdate {...latestUpdate} hideAnalysis={hideAnalysis} />
        ) : (
          <Empty>
            <FormattedMessage
              {...messages.noRecentNews}
              values={{ playerName }}
            />
          </Empty>
        )}
        {hasHiddenUpdates && (
          <StyledCollapsible
            className={classNames({ open })}
            open={open}
            hideGap
          >
            <VerticalDivided>
              {hiddenUpdates.map((update, i) => (
                <NewsUpdate key={`${i + 1}_${update.headline}`} {...update} />
              ))}
            </VerticalDivided>
          </StyledCollapsible>
        )}
      </ScrollableStack>
      {hasHiddenUpdates && (
        <ToggleButton onClick={toggleOpen} size="small" color="quaternary">
          <FormattedMessage
            {...(open ? glossary.showLess : glossary.showMore)}
          />
        </ToggleButton>
      )}
    </Wrapper>
  );
};

NewsUpdates.fragments = {
  PlayerNewsUpdate: gql`
    fragment NewsUpdates_PlayerNewsUpdate on AnyPlayerNewsUpdate {
      headline
      date
      notes
      analysis
    }
  ` as TypedDocumentNode<NewsUpdates_PlayerNewsUpdate>,
};
