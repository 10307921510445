import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { FollowButton } from '@sorare/core/src/components/user/FollowButton';
import { isType } from '@sorare/core/src/gql';

import FollowDescription from './FollowDescription';
import { Follow_subscription } from './__generated__/index.graphql';

interface Props {
  subscription: Follow_subscription;
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Follow = ({ subscription }: Props) => {
  if (
    !(
      isType(subscription.anySubscribable, 'Player') ||
      isType(subscription.anySubscribable, 'NBAPlayer') ||
      isType(subscription.anySubscribable, 'BaseballPlayer') ||
      isType(subscription.anySubscribable, 'Club') ||
      isType(subscription.anySubscribable, 'Country')
    )
  ) {
    return null;
  }

  return (
    <Root>
      <FollowDescription followedItem={subscription.anySubscribable} />
      <FollowButton
        size="medium"
        subscribable={subscription.anySubscribable}
        initialSubscription={subscription}
      />
    </Root>
  );
};

Follow.fragments = {
  subscription: gql`
    fragment Follow_subscription on EmailSubscription {
      slug
      anySubscribable {
        slug
        ... on WithSubscriptionsInterface {
          slug
        }
        ... on AnyPlayerInterface {
          slug
          ...FollowDescription_anyPlayer
        }
        ... on Country {
          slug
          ...FollowDescription_country
        }
        ... on Club {
          slug
          ...FollowDescription_club
        }
      }
    }
    ${FollowDescription.fragments.anyPlayer}
    ${FollowDescription.fragments.club}
    ${FollowDescription.fragments.country}
  ` as TypedDocumentNode<Follow_subscription>,
};

export default Follow;
