import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { OwnerTransfer } from '@sorare/core/src/__generated__/globalTypes';
import { Caption } from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';

import { ItemOwnerSince_anyCard } from './__generated__/index.graphql';

interface Props {
  card: ItemOwnerSince_anyCard;
}

const TYPES: Record<
  OwnerTransfer,
  'bought' | 'won' | 'traded' | 'transfered' | 'crafted' | undefined
> = {
  [OwnerTransfer.ENGLISH_AUCTION]: 'bought',
  [OwnerTransfer.BUNDLED_ENGLISH_AUCTION]: 'bought',
  [OwnerTransfer.PACK]: 'bought',
  [OwnerTransfer.SINGLE_SALE_OFFER]: 'bought',
  [OwnerTransfer.INSTANT_BUY]: 'bought',
  [OwnerTransfer.SINGLE_BUY_OFFER]: 'bought',
  [OwnerTransfer.REFERRAL]: 'won',
  [OwnerTransfer.REWARD]: 'won',
  [OwnerTransfer.DIRECT_OFFER]: 'traded',
  [OwnerTransfer.DEPOSIT]: 'transfered',
  [OwnerTransfer.WITHDRAWAL]: 'transfered',
  [OwnerTransfer.TRANSFER]: 'transfered',
  [OwnerTransfer.SHARDS]: 'crafted',
  [OwnerTransfer.MINT]: undefined,
};

const messages = defineMessages({
  bought: {
    id: 'ItemOwnerSince.bought',
    defaultMessage: 'Bought {timeAgo}',
  },
  won: {
    id: 'ItemOwnerSince.won',
    defaultMessage: 'Won {timeAgo}',
  },
  traded: {
    id: 'ItemOwnerSince.traded',
    defaultMessage: 'Traded {timeAgo}',
  },
  transfered: {
    id: 'ItemOwnerSince.transfered',
    defaultMessage: 'Transfered {timeAgo}',
  },
  crafted: {
    id: 'ItemOwnerSince.crafted',
    defaultMessage: 'Crafted {timeAgo}',
  },
});

const Root = styled(Caption)`
  white-space: nowrap;
`;

export const ItemOwnerSince = ({ card }: Props) => {
  const { formatDistanceToNow } = useIntlContext();

  if (!card.tokenOwner?.from) return null;

  const type = TYPES[card.tokenOwner.transferType];
  const timeAgo = formatDistanceToNow(card.tokenOwner.from);

  return (
    <Root color="var(--c-nd-600)">
      {type ? (
        <FormattedMessage {...messages[type]} values={{ timeAgo }} />
      ) : (
        timeAgo
      )}
    </Root>
  );
};

ItemOwnerSince.fragments = {
  anyCard: gql`
    fragment ItemOwnerSince_anyCard on AnyCardInterface {
      slug
      tokenOwner {
        id
        from
        transferType
      }
    }
  ` as TypedDocumentNode<ItemOwnerSince_anyCard>,
};

export default ItemOwnerSince;
