import { Sport, SportOrShared } from '__generated__/globalTypes';
import { useIntlContext } from 'contexts/intl';

import { glossary } from './glossary';

export const formattedSports: Record<Sport, string> = {
  [Sport.FOOTBALL]: 'Football',
  [Sport.NBA]: 'NBA',
  [Sport.BASEBALL]: 'MLB',
};

export const sportToSportOrShared: Record<Sport, SportOrShared> = {
  [Sport.FOOTBALL]: SportOrShared.FOOTBALL,
  [Sport.NBA]: SportOrShared.NBA,
  [Sport.BASEBALL]: SportOrShared.BASEBALL,
};

export const sportOrSharedToSport: Record<SportOrShared, Sport | null> = {
  [SportOrShared.FOOTBALL]: Sport.FOOTBALL,
  [SportOrShared.NBA]: Sport.NBA,
  [SportOrShared.BASEBALL]: Sport.BASEBALL,
  [SportOrShared.GLOBAL]: null,
};

export const getSportFromSportOrShared = (
  sportOrShared: SportOrShared | null | undefined
) => {
  if (!sportOrShared) return null;
  return sportOrSharedToSport[sportOrShared];
};

export const getSportOrSharedFromSport = (sport: Sport | null | undefined) => {
  if (!sport) return null;
  return sportToSportOrShared[sport];
};

export const getFormattedSport = (sport: Sport) => formattedSports[sport];

export const useFormattedSport =
  () => (sport: SportOrShared | null | undefined) => {
    const { formatMessage } = useIntlContext();
    if (sport && sport in Sport) {
      return getFormattedSport(sport as unknown as Sport);
    }
    return formatMessage(glossary.global);
  };
