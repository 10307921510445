import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { So5FixtureEvent } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Caption } from '@sorare/core/src/atoms/typography';
import { SorareUser } from '@sorare/core/src/components/user/SorareUser';
import { ANY_SPORT_PLAY_EVENTTYPE_FIXTURE } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { glossary, transferMarket } from '@sorare/core/src/lib/glossary';
import { ClickInstantBuy_Flow } from '@sorare/core/src/protos/events/so5/shared/events';

import { TokenDetailsRow } from 'components/ItemPreview/ui';

import PrimaryBuyBuyField, {
  Props as PrimaryBuyBuyFieldProps,
} from '../PrimaryBuyBuyField';
import { PrimaryOfferPrice } from '../PrimaryBuyBuyField/Price';
import { PrimaryBuyDetails_anyCard } from './__generated__/index.graphql';

export type Props = {
  card: PrimaryBuyDetails_anyCard;
  hideSorareUser?: boolean;
  isDesktopLayout: boolean;
  primaryBuyConfirmationOptions?: PrimaryBuyBuyFieldProps['primaryBuyConfirmationOptions'];
  onPrimaryBuyConfirmationClose?: () => void;
};

export const PrimaryBuyDetails = ({
  card,
  hideSorareUser,
  isDesktopLayout,
  primaryBuyConfirmationOptions,
  onPrimaryBuyConfirmationClose,
}: Props) => {
  const { generateSportContextPath } = useSportContext();
  if (!card.latestPrimaryOffer?.price) return null;

  return (
    <>
      <PrimaryOfferPrice primaryOffer={card.latestPrimaryOffer} />
      {!hideSorareUser && (
        <Caption color="var(--c-nd-600)">
          <FormattedMessage {...transferMarket.instantBuy} />
        </Caption>
      )}
      {isDesktopLayout && !hideSorareUser && (
        <TokenDetailsRow>
          <SorareUser />
        </TokenDetailsRow>
      )}
      <Horizontal>
        <PrimaryBuyBuyField
          size="small"
          color="quaternary"
          card={card}
          primaryBuyConfirmationOptions={
            primaryBuyConfirmationOptions || {
              variant: 'pro',
              actions: {
                primary: {
                  label: <FormattedMessage {...glossary.gotIt} />,
                },
                secondary: {
                  to: generateSportContextPath(
                    ANY_SPORT_PLAY_EVENTTYPE_FIXTURE,
                    {
                      sport: card.sport,
                      params: {
                        eventType: So5FixtureEvent.CLASSIC.toLowerCase(),
                        fixture: '',
                      },
                    }
                  ),
                  label: <FormattedMessage {...glossary.playInPro} />,
                },
              },
            }
          }
          onBuyConfirmationClose={onPrimaryBuyConfirmationClose}
          fullWidth={isDesktopLayout}
          origin={ClickInstantBuy_Flow.MARKETPLACE}
        />
      </Horizontal>
    </>
  );
};

PrimaryBuyDetails.fragments = {
  anyCard: gql`
    fragment PrimaryBuyDetails_anyCard on AnyCardInterface {
      slug
      sport
      latestPrimaryOffer {
        id
        endDate
      }
      ...PrimaryBuyBuyField_anyCard
    }
    ${PrimaryBuyBuyField.fragments.anyCard}
  ` as TypedDocumentNode<PrimaryBuyDetails_anyCard>,
};
