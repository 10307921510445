import { TypedDocumentNode, gql } from '@apollo/client';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { NBA_PLAY } from '@sorare/core/src/constants/__generated__/routes';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { FixtureState } from '@sorare/core/src/lib/so5';

import { PlayerInLineupLinkBox as DumbPlayerInLineupLinkBox } from '@sorare/us-sports/src/components/game/GameView/PlayerInLineupLinkBox';

import { PlayerCardScore } from 'components/lineup/PlayerCardScore';

import {
  PlayerInLineupLinkBox_so5Appearance,
  PlayerInLineupLinkBox_so5Fixture,
} from './__generated__/index.graphql';

const LeaderboardIcon = styled.img`
  height: var(--triple-unit);
`;
type Props = {
  fixture: PlayerInLineupLinkBox_so5Fixture;
  so5Appearance: PlayerInLineupLinkBox_so5Appearance;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
};

export const PlayerInLineupLinkBox = ({
  fixture,
  so5Appearance,
  className,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const searchParams = `${qs.stringify(
    {
      lineupId: idFromObject(idFromObject(so5Appearance.so5Lineup.id)),
    },
    { addQueryPrefix: true }
  )}`;
  const routeToLineupPlayerStats = `${generatePath(
    // NEEDS TO UPDATE THIS ROUTE
    //NBA_LIVE_PRO_COMPETITIONS_LEADERBOARDSLUG_TEAM,
    NBA_PLAY,
    {
      leaderboardSlug: so5Appearance.so5Lineup.so5Leaderboard?.slug,
    }
  )}${searchParams}`;

  return (
    <DumbPlayerInLineupLinkBox
      to={routeToLineupPlayerStats}
      label={fixture.aasmState === FixtureState.opened ? '✔︎' : undefined}
      fullImageUrl={so5Appearance.anyCard!.pictureUrl!}
      playerCardScore={
        <PlayerCardScore fixture={fixture} so5Appearance={so5Appearance} />
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      tooltip={
        <Horizontal>
          <LeaderboardIcon
            src={so5Appearance.so5Lineup.so5Leaderboard?.iconUrl || ''}
            alt=""
          />
          {so5Appearance.so5Lineup.so5Leaderboard?.displayName}
        </Horizontal>
      }
    />
  );
};

PlayerInLineupLinkBox.fragments = {
  so5Fixture: gql`
    fragment PlayerInLineupLinkBox_so5Fixture on So5Fixture {
      slug
      aasmState
      ...PlayerCardScore_so5Fixture
    }
    ${PlayerCardScore.fragments.so5Fixture}
  ` as TypedDocumentNode<PlayerInLineupLinkBox_so5Fixture>,
  so5Appearance: gql`
    fragment PlayerInLineupLinkBox_so5Appearance on So5Appearance {
      id
      so5Lineup {
        id
        so5Leaderboard {
          slug
          displayName
          iconUrl
        }
      }
      anyCard {
        slug
        pictureUrl
      }
      ...PlayerCardScore_so5Appearance
    }
    ${PlayerCardScore.fragments.so5Appearance}
  ` as TypedDocumentNode<PlayerInLineupLinkBox_so5Appearance>,
};
