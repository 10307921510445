import { TypedDocumentNode, gql } from '@apollo/client';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ConversionCreditCampaignStatus,
  Sport,
} from '__generated__/globalTypes';
import { FOOTBALL_BESTOFEUROPE, LANDING } from 'constants/__generated__/routes';
import { LANDING_BY_SPORT } from 'constants/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useQuery } from 'hooks/graphql/useQuery';
import { SESSION_STORAGE, useSessionStorage } from 'hooks/useSessionStorage';
import { monetaryAmountFragment } from 'lib/monetaryAmount';

import {
  CampaignConversionCreditQuery,
  CampaignConversionCreditQueryVariables,
} from './__generated__/usePromoSignup.graphql';

const CAMPAIGN_CONVERSION_CREDIT_QUERY = gql`
  query CampaignConversionCreditQuery($code: String!) {
    tokens {
      conversionCreditCampaign(code: $code) {
        id
        sport
        maxDiscount {
          ...MonetaryAmountFragment_monetaryAmount
        }
        status
        percentageDiscount
        endDate
        campaignCode
      }
    }
  }
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  CampaignConversionCreditQuery,
  CampaignConversionCreditQueryVariables
>;

export const usePromoSignup = (code?: string) => {
  const { currentUser } = useCurrentUserContext();
  const [, setSignupPromo] = useSessionStorage(SESSION_STORAGE.signupPromo);
  const { showNotification } = useSnackNotificationContext();

  const { search } = useLocation();
  const navigate = useNavigate();
  const { data } = useQuery(CAMPAIGN_CONVERSION_CREDIT_QUERY, {
    variables: { code: code! },
    skip: !code,
  });

  useEffect(() => {
    if (!currentUser && data) {
      const campaign = data.tokens.conversionCreditCampaign;
      if (
        campaign?.sport &&
        campaign?.status === ConversionCreditCampaignStatus.ACTIVE
      ) {
        setSignupPromo({
          ...campaign,
          endDate: campaign.endDate.toISOString(),
        });
        showNotification('promoApplied');
        // Temporary redirect to the best of Europe page for one defined campaign code
        if (
          campaign.sport === Sport.FOOTBALL &&
          campaign.campaignCode === 'BESTOF'
        ) {
          navigate(`${FOOTBALL_BESTOFEUROPE}${search}`);
        } else {
          navigate(`${LANDING_BY_SPORT[campaign.sport]}${search}`);
        }
      } else {
        navigate(LANDING, { replace: true });
      }
    }
  }, [currentUser, data, navigate, search, setSignupPromo, showNotification]);
};
