import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Currency,
  MonetaryAmount,
  SupportedCurrency,
  TokenPaymentMethod,
} from '@sorare/core/src/__generated__/globalTypes';
import { Title5 } from '@sorare/core/src/atoms/typography';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { formatGqlErrors } from '@sorare/core/src/gql';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { ConversionCreditWithAmounts } from '@sorare/core/src/hooks/useConversionCredits';
import { useMangopayCreditCardsEnabled } from '@sorare/core/src/hooks/useMangopayCreditCardsEnabled';
import { sendSafeError } from '@sorare/core/src/lib/error';
import { payment } from '@sorare/core/src/lib/glossary';
import {
  getMonetaryAmountIndex,
  monetaryAmountFragment,
} from '@sorare/core/src/lib/monetaryAmount';

import LazyPaymentProvider from 'components/buyActions/LazyPaymentProvider';
import CardOverview from 'components/buyActions/PaymentBox/CardOverview';
import { PromoBanners } from 'components/market/PromoBanners';
import {
  PrimaryBuyConfirmationOptions,
  useBuyConfirmationContext,
} from 'contexts/buyingConfirmation';
import useAcceptOffer from 'hooks/offers/useAcceptOffer';
import usePollPrimaryOfferBuyer from 'hooks/usePollPrimaryOfferBuyer';

import type {
  PrimaryBuyPaymentFlowQuery,
  PrimaryBuyPaymentFlowQueryVariables,
} from './__generated__/index.graphql';
import { useRefreshSignedAmount } from './useRefreshSignedAmount';

export interface Props {
  offerId: string;
  primaryBuyConfirmationOptions?: PrimaryBuyConfirmationOptions;
  onSuccess?: () => void;
  onClose: () => void;
  onBuyConfirmationClose?: () => void;
  showBuyConfirmation?: boolean;
  onlyCreditCards?: boolean;
  conversionCreditDisclaimer?: ReactNode;
  defaultConversionCredit?: ConversionCreditWithAmounts;
}

const PRIMARY_BUY_PAYMENT_FLOW_QUERY = gql`
  query PrimaryBuyPaymentFlowQuery($id: String!) {
    tokens {
      primaryOffer(id: $id) {
        id
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
        signedAmount
        anyCards {
          slug
          sport
          ...CardOverview_anyCard
          ...useAcceptOffer_anyCard
        }
        ...usePollPrimaryOfferBuyer_primaryOffer
        ...PromoBanners_PromotionalEventsInterface
      }
    }
  }
  ${useAcceptOffer.fragments.anyCard}
  ${CardOverview.fragments.anyCard}
  ${usePollPrimaryOfferBuyer.fragments.primaryOffer}
  ${PromoBanners.fragments.PromotionalEventsInterface}
  ${monetaryAmountFragment}
` as TypedDocumentNode<
  PrimaryBuyPaymentFlowQuery,
  PrimaryBuyPaymentFlowQueryVariables
>;

export const PrimaryBuyFlow = ({
  offerId,
  onClose,
  primaryBuyConfirmationOptions,
  onSuccess,
  onBuyConfirmationClose,
  showBuyConfirmation = true,
  onlyCreditCards = false,
  conversionCreditDisclaimer,
  defaultConversionCredit,
}: Props) => {
  const useMangopayCreditCards = useMangopayCreditCardsEnabled();
  const { formatMessage } = useIntlContext();
  const acceptOffer = useAcceptOffer();
  const { setShowBuyingConfirmation } = useBuyConfirmationContext();
  const refresh = useRefreshSignedAmount(idFromObject(offerId));
  const [polling, setPolling] = useState(false);
  const [timeoutPolling, setTimeoutPolling] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const { showNotification } = useSnackNotificationContext();

  const { data, error } = useQuery(PRIMARY_BUY_PAYMENT_FLOW_QUERY, {
    variables: {
      id: idFromObject(offerId),
    },
  });

  const primaryOffer = data?.tokens?.primaryOffer;

  const onPaymentSuccess = useCallback(() => {
    if (showBuyConfirmation) {
      setShowBuyingConfirmation(true);
    }
    onSuccess?.();
    onClose();
  }, [showBuyConfirmation, setShowBuyingConfirmation, onSuccess, onClose]);

  const onPollingEnd = (success: boolean) => {
    setPolling(false);
    if (timeoutPolling) clearTimeout(timeoutPolling);
    if (success) onPaymentSuccess();
  };

  usePollPrimaryOfferBuyer(polling, primaryOffer, onPollingEnd);

  const [priceInfos, setPriceInfos] = useState<
    | {
        price: MonetaryAmount | null;
        signedAmount: string | null;
      }
    | undefined
  >(undefined);

  if (!priceInfos && primaryOffer) {
    setPriceInfos({
      price: primaryOffer.price,
      signedAmount: primaryOffer.signedAmount,
    });
  }

  if (!primaryOffer) return null;

  if (error) {
    // This should not happen. Temporary added for monitoring purposes.
    sendSafeError(`Offer not found for id: ${offerId}`);
    showNotification('errors', { errors: [error.message] });
    onClose();
    return null;
  }

  const { price, anyCards, signedAmount } = primaryOffer;

  const refreshPriceAndSignedAMountBeforeBuy = async () => {
    const refreshResult = await refresh();
    if (refreshResult?.error)
      return { err: formatGqlErrors([refreshResult.error]) };
    const { price: refreshPrice, signedAmount: refreshSignedAmount } =
      refreshResult?.data?.tokens?.primaryOffer || {};
    if (
      price &&
      refreshSignedAmount &&
      refreshPrice &&
      (!priceInfos || refreshSignedAmount !== priceInfos.signedAmount)
    ) {
      setPriceInfos({ price: refreshPrice, signedAmount: refreshSignedAmount });
      if (
        refreshPrice[getMonetaryAmountIndex(refreshPrice.referenceCurrency)] !==
        price[getMonetaryAmountIndex(price.referenceCurrency)]
      ) {
        // if price has changed, we need to update the payment box
        return {
          warnings: [
            formatMessage({
              id: 'primaryBuyField.Error.paymentBox.priceChangedV2',
              defaultMessage:
                'The price has changed, please review the offer and try again.',
            }),
          ],
        };
      }
    }
    return { signedAmount: refreshSignedAmount };
  };

  const buy = async ({
    conversionCreditId,
    supportedCurrency,
    tokenPaymentMethod,
    applePayPaymentToken,
  }: {
    conversionCreditId?: string;
    supportedCurrency: SupportedCurrency;
    tokenPaymentMethod?: TokenPaymentMethod;
    applePayPaymentToken?: ApplePayJS.ApplePayPaymentToken;
  }) => {
    const refreshResult = await refreshPriceAndSignedAMountBeforeBuy();
    if (refreshResult?.err?.length) return refreshResult;
    if (!refreshResult.signedAmount || !signedAmount)
      return {
        err: [
          formatMessage({
            id: 'primaryBuyField.Error.paymentBox.priceUnvailable',
            defaultMessage: 'The card is unavailable, please try again later.',
          }),
        ],
      };

    const errors = await acceptOffer({
      offerId,
      receiveCards: anyCards,
      conversionCreditId,
      supportedCurrency,
      attemptReference: null,
      tokenPaymentMethod,
      signedAmount: refreshResult.signedAmount || signedAmount || undefined,
      applePayPaymentToken,
    });

    if (!errors || errors.length === 0) {
      return onPaymentSuccess();
    }

    return { err: errors };
  };

  // For mangopay, we do not know if the 3DS has failed or not. We need to fetch the primary offer and check buyer
  const onPaymentSuccessWrapper = () => {
    if (useMangopayCreditCards) {
      setPolling(true);
      setTimeoutPolling(
        setTimeout(() => {
          setPolling(false);
        }, 10000)
      );
    } else {
      onPaymentSuccess();
    }
  };

  if (!price || !signedAmount) return null;

  return (
    <LazyPaymentProvider
      paymentProps={{
        canChangeRefCurrency: true,
        objectId: offerId,
        onSuccess: onPaymentSuccessWrapper,
        onBeforeBuyWithCreditCard: refreshPriceAndSignedAMountBeforeBuy,
        signedAmount: signedAmount || undefined,
        onSubmit: buy,
        price,
        cta: payment.confirmAndPay,
        canUseConversionCredit: true,
        currencies: [Currency.FIAT, Currency.ETH],
        conversionCreditDisclaimer,
        sport: anyCards[0].sport,
        onlyCreditCards,
        defaultConversionCredit,
        confirmationProviderStateProps: {
          primaryBuyId: offerId,
          primaryBuyConfirmationOptions,
          onClose: onBuyConfirmationClose,
        },
      }}
      paymentBoxProps={{
        loadingPolling: polling,
        onClose,
        title: (
          <Title5>
            <FormattedMessage {...payment.paymentBoxTitle} />
          </Title5>
        ),
        tokenPreview: <CardOverview cards={anyCards} soldOnPrimary />,
        banner: <PromoBanners promotionalEvents={primaryOffer} />,
      }}
    />
  );
};

export default PrimaryBuyFlow;
