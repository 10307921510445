import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faChevronRight,
  faCircleCheck,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { So5LeaderboardSeasonality } from '@sorare/core/src/__generated__/globalTypes';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { ScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { ContentWithStickyHeader } from '@sorare/core/src/atoms/layout/ContentWithStickyHeader';
import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Tab, TabBar } from '@sorare/core/src/atoms/navigation/TabBar';
import { BodyS, LabelL, LabelM } from '@sorare/core/src/atoms/typography';
import {
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
} from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useIsDesktopAndAbove } from '@sorare/core/src/hooks/device/useIsDesktopAndAbove';
import { getSafePreviousLocation } from '@sorare/core/src/lib/navigation/getSafePreviousLocation';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import { FixtureEnterSeasonalityCompetitionLayout_so5LeaderboardGroupInterface } from './__generated__/index.graphql';

const HeaderContainer = styled.div`
  position: relative;
  min-height: 150px;

  background: linear-gradient(
      180deg,
      rgba(var(--c-rgb-neutral-100), 0.7) 0%,
      rgba(var(--c-rgb-neutral-300), 0) 70%
    ),
    var(--bg);
  background-size: cover;
  background-position: center top;
`;
const Header = styled(SBHorizontal)`
  padding: var(--intermediate-unit) var(--intermediate-unit)
    var(--intermediate-unit) 0;

  @media ${tabletAndAbove} {
    padding: var(--intermediate-unit) var(--double-unit);
  }
`;
const HeaderImg = styled.img`
  position: absolute;
  inset: auto 0 0;
  margin: auto;
  max-width: 100%;
`;

type Props = {
  so5LeaderboardGroupInterface: FixtureEnterSeasonalityCompetitionLayout_so5LeaderboardGroupInterface;
  inSeasonName?: string;
  allSeasonsName?: string;
  competition: string;
  seasonality: string;
  trck?: string;
};

export const FixtureEnterSeasonalityCompetitionLayout = ({
  so5LeaderboardGroupInterface,
  inSeasonName,
  allSeasonsName,
  competition,
  seasonality,
  trck,
}: Props) => {
  const { generateSportContextPath } = useSportContext();
  const isDesktopAndAbove = useIsDesktopAndAbove();
  const outletContext = useOutletContext();
  const { so5Fixture } = so5LeaderboardGroupInterface;
  const seasonalityTyped =
    So5LeaderboardSeasonality[
      seasonality.toUpperCase() as keyof typeof So5LeaderboardSeasonality
    ];

  const displayedTracks = so5LeaderboardGroupInterface.so5LeagueTracks.filter(
    track =>
      track.entrySo5Leaderboard.seasonality === seasonalityTyped ||
      (seasonalityTyped === So5LeaderboardSeasonality.ALL_SEASONS &&
        track.entrySo5Leaderboard.seasonality === null)
  );

  const sortedDisplayedTracks = displayedTracks.sort(
    (a, b) => Number(a.completed) - Number(b.completed)
  );

  const selectedTrack = sortedDisplayedTracks.find(t => t.slug === trck);

  if (!sortedDisplayedTracks.length) {
    return null;
  }

  if (!selectedTrack) {
    return (
      <Navigate
        replace
        to={generateSportContextPath(
          ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
          {
            params: {
              eventType: so5Fixture.type.toLowerCase(),
              fixture: so5Fixture.slug,
              competition,
              seasonality,
              trck: sortedDisplayedTracks[0].slug,
            },
          }
        )}
      />
    );
  }

  const safePreviousLocation = getSafePreviousLocation(
    generateSportContextPath(ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER, {
      params: {
        eventType: so5Fixture.type.toLowerCase(),
        fixture: so5Fixture.slug,
      },
    }),
    -1
  );

  const headerBackgroundUrl =
    so5LeaderboardGroupInterface.seasonalities.includes(seasonalityTyped)
      ? so5LeaderboardGroupInterface.seasonalHeaderBackgroundUrl
      : so5LeaderboardGroupInterface.headerBackgroundUrl;

  return (
    <FullWidthContainer.NoPadding>
      {!isDesktopAndAbove && (
        <HeaderContainer
          style={{ '--bg': `url(${headerBackgroundUrl})` } as CSSProperties}
        >
          <Header>
            <Horizontal>
              <IconButton
                color="transparent"
                icon={faTimes}
                to={safePreviousLocation}
              />
              <Vertical gap={0.5}>
                <LabelL as="h1" bold>
                  {so5LeaderboardGroupInterface.displayName}
                </LabelL>
                <LabelM as="p" color="var(--c-nd-600)">
                  {selectedTrack.entrySo5Leaderboard.seasonalityName}
                </LabelM>
              </Vertical>
            </Horizontal>
            {so5LeaderboardGroupInterface.seasonalities.length > 1 && (
              <Link
                to={generateSportContextPath(
                  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION,
                  {
                    params: {
                      eventType: so5Fixture.type.toLowerCase(),
                      fixture: so5Fixture.slug,
                      competition,
                      seasonality:
                        seasonalityTyped ===
                        So5LeaderboardSeasonality.ALL_SEASONS
                          ? So5LeaderboardSeasonality.IN_SEASON
                          : So5LeaderboardSeasonality.ALL_SEASONS,
                    },
                  }
                )}
                replace
              >
                <Horizontal>
                  <BodyS as="p" color="var(--c-nd-800)">
                    <FormattedMessage
                      id="play.pro.fixture.enter.seasonality.competition.switchToSeasonality"
                      defaultMessage="Switch to {seasonality}"
                      values={{
                        seasonality:
                          seasonalityTyped ===
                          So5LeaderboardSeasonality.ALL_SEASONS
                            ? inSeasonName
                            : allSeasonsName,
                      }}
                    />
                  </BodyS>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="xs"
                    color="var(--c-nd-800)"
                  />
                </Horizontal>
              </Link>
            )}
          </Header>
          <HeaderImg src={so5LeaderboardGroupInterface.headerUrl} alt="" />
        </HeaderContainer>
      )}
      <ContentWithStickyHeader
        header={
          <TabBar value={selectedTrack?.slug} variant="flat" fullWidth>
            {sortedDisplayedTracks.map(track => (
              <Tab
                key={track.slug}
                value={track.slug}
                disabled={!!track.completed}
                to={generateSportContextPath(
                  ANY_SPORT_PLAY_EVENTTYPE_FIXTURE_ENTER_SEASONALITY_COMPETITION_TRCK,
                  {
                    params: {
                      eventType: so5Fixture.type.toLowerCase(),
                      fixture: so5Fixture.slug,
                      competition,
                      seasonality,
                      trck: track.slug,
                    },
                  }
                )}
                replace
              >
                {track.completed ? (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    color="var(--c-rivals-win)"
                  />
                ) : (
                  <ScarcityIcon
                    scarcity={track.entrySo5Leaderboard.mainRarityType || 'mix'}
                  />
                )}
                {track.displayName}
              </Tab>
            ))}
          </TabBar>
        }
      >
        <Outlet context={outletContext} />
      </ContentWithStickyHeader>
    </FullWidthContainer.NoPadding>
  );
};

FixtureEnterSeasonalityCompetitionLayout.fragments = {
  so5LeaderboardGroupInterface: gql`
    fragment FixtureEnterSeasonalityCompetitionLayout_so5LeaderboardGroupInterface on So5LeaderboardGroupInterface {
      slug
      displayName
      headerUrl
      seasonalities
      headerBackgroundUrl
      seasonalHeaderBackgroundUrl: headerBackgroundUrl(
        seasonality: $seasonality
      )
      so5Fixture {
        slug
        type
      }
      pictureUrl(seasonality: $seasonality)
      so5LeagueTracks {
        slug
        displayName
        completed
        entrySo5Leaderboard {
          slug
          seasonality
          seasonalityName
          mainRarityType
        }
      }
    }
  ` as TypedDocumentNode<FixtureEnterSeasonalityCompetitionLayout_so5LeaderboardGroupInterface>,
};
