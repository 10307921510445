import { TypedDocumentNode, gql } from '@apollo/client';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { ScarcityBall } from '@sorare/core/src/components/card/ScarcityBall';
import { ANY_SPORT_CARDS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { getHumanReadableSerialNumber } from '@sorare/core/src/lib/cards';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { format as formatSeason } from '@sorare/core/src/lib/seasons';

import { TokenName_anyCard } from './__generated__/index.graphql';

const Root = styled.div`
  display: flex;
`;

type Props = {
  card: TokenName_anyCard;
  withLink?: boolean;
};

export const TokenName = ({ card, withLink }: Props) => {
  const {
    anyPlayer: player,
    rarityTyped,
    seasonYear,
    singleCivilYear,
    sport,
  } = card;

  return (
    <Root key={player.slug}>
      <ScarcityBall scarcity={rarityTyped} iconOnly />
      <Horizontal>
        <Text16 bold>{player.displayName}</Text16>
        <Text16 color="var(--c-nd-600)">
          {formatSeason(seasonYear, { singleCivilYear })} •{' '}
          {getHumanReadableSerialNumber(card)}
        </Text16>
        {withLink && (
          <Link
            to={generateSportPath(ANY_SPORT_CARDS_SLUG, {
              params: {
                slug: card.slug,
              },
              sport,
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              size="sm"
              color="var(--c-nd-400)"
            />
          </Link>
        )}
      </Horizontal>
    </Root>
  );
};

TokenName.fragments = {
  anyCard: gql`
    fragment TokenName_anyCard on AnyCardInterface {
      slug
      sport
      anyPlayer {
        slug
        displayName
      }
      rarityTyped
      seasonYear
      serialNumber
      singleCivilYear
      ...getHumanReadableSerialNumber_anyCard
    }
    ${getHumanReadableSerialNumber.fragments.anyCard}
  ` as TypedDocumentNode<TokenName_anyCard>,
};
