import { TypedDocumentNode, gql } from '@apollo/client';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { categoryLabels } from '@sorare/core/src/lib/scoring';

import { isGameScheduled } from 'lib/so5';

import { Cell, Row } from '../../ui';
import { CustomStatCategory } from '../../utils';
import { CategoryHeader_playerGameScoreInterface } from './__generated__/index.graphql';

const StyledRow = styled(Row)`
  &[role='button'] {
    cursor: pointer;
  }
`;
const CategoryName = styled(BodyS)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const getScoreColor = (score: number | null) => {
  if (!score) {
    return 'var(--c-nd-600)';
  }
  if (score > 0) {
    return 'var(--c-score-high)';
  }
  return 'var(--c-score-veryLow)';
};

type Props = {
  category: Exclude<CustomStatCategory, 'DECISIVE'>;
  playerGameScores: CategoryHeader_playerGameScoreInterface[];
  onClick: () => void;
  showExpandedColumn: boolean;
  expanded: boolean;
};

export const CategoryHeader = ({
  category,
  playerGameScores,
  onClick,
  showExpandedColumn,
  expanded,
}: Props) => {
  return (
    <StyledRow
      role={showExpandedColumn ? 'button' : undefined}
      onClick={showExpandedColumn ? onClick : undefined}
      className={classNames('header')}
    >
      <Cell>
        <Horizontal>
          {showExpandedColumn && (
            <FontAwesomeIcon
              icon={expanded ? faAngleUp : faAngleDown}
              size="xs"
            />
          )}
          <CategoryName as="p" bold>
            {category in categoryLabels ? (
              <FormattedMessage {...categoryLabels[category]} />
            ) : (
              category
            )}
          </CategoryName>
        </Horizontal>
      </Cell>
      {playerGameScores.map(playerGameScore => {
        const categoryStats = playerGameScore.detailedScore.filter(
          statScore => statScore.category === category
        );
        const totalScore = categoryStats.reduce(
          (acc, statScore) => acc + statScore.totalScore,
          0
        );

        return (
          <Cell key={playerGameScore.id}>
            <BodyS as="p" bold color={getScoreColor(totalScore)}>
              {categoryStats.length &&
              !isGameScheduled(playerGameScore.anyGame.status) ? (
                <FormattedNumber value={totalScore} maximumFractionDigits={2} />
              ) : (
                '-'
              )}
            </BodyS>
          </Cell>
        );
      })}
    </StyledRow>
  );
};

CategoryHeader.fragments = {
  playerGameScoreInterface: gql`
    fragment CategoryHeader_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      anyGame {
        id
        status: statusTyped
      }
      detailedScore {
        category
        totalScore
      }
      ... on PlayerGameScore {
        id
        decisiveScore {
          totalScore
        }
      }
    }
  ` as TypedDocumentNode<CategoryHeader_playerGameScoreInterface>,
};
