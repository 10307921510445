import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { useTimeLeft } from 'hooks/useTimeLeft';

type Props = { expirationDate: Date };
export const ExpiresIn = ({ expirationDate }: Props) => {
  const { message } = useTimeLeft(expirationDate);

  return (
    <Horizontal gap={0.5}>
      <FontAwesomeIcon icon={faClock} size="xs" color="var(--c-nd-600)" />
      <LabelS as="span" color="var(--c-nd-600)">
        <FormattedMessage
          id="ClubShop.Item.Label.ExpiresIn"
          defaultMessage="Expires in {remaining}"
          values={{ remaining: message }}
        />
      </LabelS>
    </Horizontal>
  );
};
