import { TypedDocumentNode, gql } from '@apollo/client';
import { isFuture } from 'date-fns';

import { LiveSaleDetails } from 'components/sale/Sale/SaleDetails/LiveSaleDetails';

import { TokenOfferDetails_anyCard } from './__generated__/index.graphql';

type Props = {
  card: TokenOfferDetails_anyCard;
  isDesktopLayout: boolean;
  stackedTokensCount?: number;
  displayMarketplaceOnboardingTooltip?: boolean;
  allowColumnLayout?: boolean;
};

export const TokenOfferDetails = ({ card, ...rest }: Props) => {
  const singleSaleOffer =
    card.liveSingleSaleOffer || card.myMintedSingleSaleOffer;

  const isLive = singleSaleOffer?.endDate
    ? isFuture(singleSaleOffer?.endDate)
    : true;

  if (!singleSaleOffer || !isLive) return null;

  return <LiveSaleDetails sale={singleSaleOffer} card={card} {...rest} />;
};

TokenOfferDetails.fragments = {
  anyCard: gql`
    fragment TokenOfferDetails_anyCard on AnyCardInterface {
      slug
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        ...LiveSaleDetails_offer
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        ...LiveSaleDetails_offer
      }
      ...LiveSaleDetails_anyCard
    }
    ${LiveSaleDetails.fragments.offer}
    ${LiveSaleDetails.fragments.anyCard}
  ` as TypedDocumentNode<TokenOfferDetails_anyCard>,
};
