import { SVGProps } from 'react';

interface SVGRProps {
  width?: number;
}

export const RaffleTicket = ({
  width = 20,
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg
      width={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.998 2.5h-20v5a2.5 2.5 0 110 5v5h20v-5a2.5 2.5 0 010-5v-5zm-10 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="currentColor"
      />
    </svg>
  );
};
