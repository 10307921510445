import styled from 'styled-components';

import { Lightning } from 'atoms/icons/Lightning';
import { Horizontal } from 'atoms/layout/flex';
import { LabelL } from 'atoms/typography';
import { Color } from 'style/types';

const Wrapper = styled(Horizontal).attrs({ gap: 0 })`
  display: inline-flex;
`;

const ImgWrapper = styled(Horizontal).attrs({ center: true, as: 'span' })`
  width: var(--intermediate-unit);
`;

type Props = { score: number; suffix?: string | null; color?: Color };

export const Score = ({ score, suffix, color }: Props) => {
  return (
    <Wrapper as="span">
      <LabelL bold color={color}>
        {score}
        {suffix}
      </LabelL>
      <ImgWrapper>
        <Lightning color={color} />
      </ImgWrapper>
    </Wrapper>
  );
};
