import styled, { css } from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { desktopAndAbove, laptopAndAbove } from 'style/mediaQuery';

export const SearchLayoutRoot = styled.div`
  padding: 0 var(--double-unit);
  min-height: inherit;
  position: relative;
  @media ${laptopAndAbove} {
    padding: 0 var(--triple-unit);
  }
`;

export const SearchLayoutContainer = styled.div`
  display: flex;
  gap: var(--unit);
  align-items: stretch;
  height: 100%;
  & > * {
    max-width: 100%;
  }
`;

export const SearchLayoutMain = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--double-unit) 0;
  width: 100%;
  @media ${desktopAndAbove} {
    padding-top: var(--triple-unit);
  }
`;

export const LeftFilters = styled.aside<{ visible: boolean }>`
  padding-top: var(--triple-unit);
  isolation: isolate;
  border-radius: 8px;
  overflow: hidden;
  height: max-content;
  flex: none;
  width: 0px;
  opacity: 0;
  margin-right: 0px;
  transition:
    all 0.2s ease-in-out 0.2s,
    opacity 0.2s linear;
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          width: 260px;
          transition:
            all 0.2s ease-in-out,
            opacity 0.2s linear 0.2s;
          @media ${desktopAndAbove} {
            width: 300px;
          }
        `
      : ''}
  display: block;
  &:not(:has(.FilterWidget.visible)) {
    display: none;
  }
`;

export const VerticalScroll = styled.div`
  overflow: auto;
  flex-shrink: 0;
  height: 100%;
  position: sticky;
  top: var(--current-stack-height);
  max-height: calc(var(--100vh) - var(--current-stack-height));
  padding-right: var(--triple-unit);
`;
