import classnames from 'classnames';
import {
  Children,
  ComponentProps,
  ReactNode,
  UIEventHandler,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useDebounce, useTimeoutFn } from 'react-use';
import styled from 'styled-components';

import { Dots } from 'atoms/navigation/Dots';
import { range } from 'lib/arrays';
import { unitMapping } from 'lib/style';
import { desktopAndAbove } from 'style/mediaQuery';
import { hideScrollbar } from 'style/utils';

import { WithScrollButtons } from '../WithScrollButtons';

const DotsContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: var(--half-unit);
  z-index: 1;
  transform: translateX(-50%);
`;

const Row = styled.div`
  position: relative;
`;

const ScrollableContent = styled.div<{
  itemToDisplay: number;
  withMask?: boolean;
  maskSize: number;
  shouldOverhang?: boolean;
  overhang: keyof typeof unitMapping;
  itemGap: keyof typeof unitMapping;
}>`
  --mask-size: calc(${({ maskSize }) => maskSize} * var(--unit));
  --item-gap: ${({ itemGap }) => unitMapping[itemGap]};
  --overhang: ${({ overhang }) => unitMapping[overhang]};
  --final-overhang: ${({ shouldOverhang }) =>
    shouldOverhang ? 'var(--overhang)' : '0px'};
  --item-wrapper-width: calc(
    (
        (100% - var(--final-overhang)) - var(--item-gap) *
          ${({ itemToDisplay }) => itemToDisplay - 1}
      ) / ${({ itemToDisplay }) => itemToDisplay}
  );
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  gap: var(--item-gap);

  /* With this negative margin technique, Scrollable won't work with flex-direction: row-reverse
   * See https://gitlab.com/sorare/frontend/-/merge_requests/15065
   */
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
  ${hideScrollbar}

  @media ${desktopAndAbove} {
    &.withMask {
      padding-inline: var(--mask-size);
      scroll-padding-inline: var(--mask-size);
      margin-inline: calc(-1 * var(--mask-size));
      mask-image: linear-gradient(
        to right,
        transparent,
        white var(--mask-size),
        white calc(100% - var(--mask-size)),
        transparent
      );
    }
  }
`;

const ItemWrapper = styled.div`
  scroll-snap-align: center;
  min-width: var(--item-wrapper-width, 100%);
  max-width: var(--item-wrapper-width, 100%);

  &:empty {
    display: none;
  }
`;
type CommonProps = {
  children: ReactNode;
  itemToDisplay: number;
  withMask?: boolean;
  contained?: boolean;
  /**
   * In a mobile viewport when there are multiple items to display, show a
   * partial edge of the next offscreen item to indicate to the user that
   * there are more items to scroll to (since no arrow buttons shown in mobile).
   */
  overhang?: boolean | keyof typeof unitMapping;
  itemGap?: keyof typeof unitMapping;
  scrollButtonsProps?: Partial<ComponentProps<typeof WithScrollButtons>>;
  autoPlay?: number;
  dots?: string[];
};
type ControlledProps = CommonProps & {
  indexToScroll: number;
  onVisibleItemsChanged: (items: number[]) => void;
};
type UnControlledProps = CommonProps & {
  indexToScroll?: never;
  onVisibleItemsChanged?: (items: number[]) => void;
};

type Props = ControlledProps | UnControlledProps;

export const Scrollable = ({
  children,
  withMask,
  itemToDisplay,
  indexToScroll,
  onVisibleItemsChanged,
  contained,
  overhang,
  itemGap = 2,
  scrollButtonsProps,
  autoPlay,
  dots,
}: Props) => {
  const numberOfItems = Children.toArray(children).length;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const manualScroll = useRef(false);
  const [visibleIndexes, setVisibleIndexes] = useState(range(itemToDisplay));
  const containerRef = useRef<HTMLDivElement>(null);
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(visibleIndexes[0]);
  const scrollToIndex = useCallback((index: number) => {
    if (!containerRef.current) {
      return;
    }
    const selectedItem = containerRef.current.childNodes[index];
    if (selectedItem instanceof HTMLElement) {
      const scrollValue = selectedItem.offsetLeft;
      containerRef.current?.scroll({
        left: scrollValue,
        behavior: 'smooth',
      });
    }
  }, []);

  let overhangSize: keyof typeof unitMapping = 0;
  if (overhang) {
    if (typeof overhang === 'number') {
      overhangSize = overhang;
    } else {
      overhangSize = 2;
    }
  }
  const detectVisibleIndexes: UIEventHandler<HTMLDivElement> = e => {
    const { scrollLeft, children: child } = e.target as HTMLDivElement;
    const itemWidth = child[0].clientWidth;

    const firstVisibleItem = Math.round(scrollLeft / (itemWidth + itemGap));
    const indexes = range(itemToDisplay).map(i =>
      Math.min(firstVisibleItem + i, Children.count(children) - 1)
    );
    setVisibleIndexes(indexes);
    if (!manualScroll.current) {
      setFirstVisibleIndex(Math.ceil(firstVisibleItem / itemToDisplay));
    }
  };

  const [, cancel, reset] = useTimeoutFn(() => {
    if (numberOfItems > 1 && !indexToScroll && autoPlay) {
      const newIndex = (firstVisibleIndex + 1) % numberOfItems;
      scrollToIndex(newIndex);
      reset();
    }
  }, autoPlay);

  useLayoutEffect(() => {
    if (typeof indexToScroll === 'number') {
      scrollToIndex(indexToScroll);
    }
  }, [scrollToIndex, indexToScroll]);

  useDebounce(() => onVisibleItemsChanged?.(visibleIndexes), 50, [
    visibleIndexes,
  ]);

  const to = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    if (containerRef.current) {
      const callback = () => {
        manualScroll.current = false;
      };
      if ('onscrollend' in window) {
        containerRef.current.onscrollend = callback;
      } else {
        containerRef.current.onscroll = () => {
          clearTimeout(to.current);
          to.current = setTimeout(callback, 100);
        };
      }
    }
    return () => {
      clearTimeout(to.current);
    };
  }, []);

  return (
    <WithScrollButtons
      contained={contained}
      wrapperRef={wrapperRef}
      scrollContainerRef={containerRef}
      itemGap={itemGap}
      overhangSize={overhangSize}
      {...scrollButtonsProps}
    >
      <Row onMouseEnter={cancel} onMouseLeave={reset}>
        <ScrollableContent
          itemToDisplay={itemToDisplay}
          ref={containerRef}
          onScroll={detectVisibleIndexes}
          role="navigation"
          className={classnames({ withMask })}
          shouldOverhang={!!overhang && numberOfItems > itemToDisplay}
          overhang={overhangSize}
          itemGap={itemGap}
          maskSize={5}
        >
          {Children.toArray(children).map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ItemWrapper key={index}>{child}</ItemWrapper>
          ))}
        </ScrollableContent>

        {numberOfItems > itemToDisplay && dots && (
          <DotsContainer>
            <Dots
              titles={
                itemToDisplay === 1
                  ? dots
                  : dots.filter((_, i) => i % (itemToDisplay - 1))
              }
              selectedIndex={firstVisibleIndex}
              setSelectedIndex={index => {
                manualScroll.current = true;
                setFirstVisibleIndex(index);
                if (itemToDisplay === 1) {
                  scrollToIndex(index);
                } else {
                  scrollToIndex(index * (itemToDisplay - 1));
                }
              }}
            />
          </DotsContainer>
        )}
      </Row>
    </WithScrollButtons>
  );
};
