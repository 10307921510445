import { API_ROOT } from 'config';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { client, clientWithoutCredentials } from 'lib/http';

type PresignResponse = {
  url: string;
  fields: {
    key: string;
    policy: string;
    'x-amz-credential': string;
    'x-amz-algorithm': string;
    'x-amz-date': string;
    'x-amz-security-token': string;
    'x-amz-signature': string;
  };
};

export const useUploadWithPresign = () => {
  const { showNotification } = useSnackNotificationContext();

  return async (file: File): Promise<Nullable<string>> => {
    const url = new URL(`${API_ROOT}/presigns`);
    url.searchParams.set('filename', file.name);
    url.searchParams.set('content_type', file.type);
    // Get presigned POST URL and form fields
    const json: PresignResponse = (await client.post(url.toString())).data;

    // Build a form for the request body
    const form = new FormData();
    const keys = Object.keys(json.fields) as (keyof typeof json.fields)[];
    keys.forEach(key => form.append(key, json.fields[key]));
    form.append('file', file);

    // Send the POST request
    try {
      await clientWithoutCredentials.post(json.url, form);
    } catch {
      showNotification('uploadError');
      return null;
    }

    return json.fields.key;
  };
};
