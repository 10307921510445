import classNames from 'classnames';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Color, HexColorOnly } from '@sorare/core/src/style/types';

const inlineSvg = ({
  color = '#ff0974',
  intersectionOnCenter,
}: {
  color?: HexColorOnly | null;
  intersectionOnCenter: boolean;
}) => {
  if (color === null) {
    return '';
  }
  const path = intersectionOnCenter
    ? 'd="M 0 0 L 80 80 M 0 80 L 80 0"'
    : 'd="M 40 0 L 80 40 L 40 80 L 0 40 L 40 0"';
  return `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="80 80"%3E%3Cpath ${path} fill="none" stroke="${color?.replace(
    '#',
    '%23'
  )}BB" strokeWidth="0.5" /%3E%3C/svg%3E')`;
};

const Root = styled.div`
  position: relative;
  isolation: isolate;
  width: 100%;
  height: var(--height);
`;
const Pattern = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background:
    var(--gradient),
    var(--bg-pattern) repeat;
  background-position: center center;
  transform-origin: center center;

  &.originOnTop {
    background-position: center top;
  }
`;

type Props = {
  height: number | string;
  children?: ReactNode;
  color?: HexColorOnly | null;
  bgColor?: Color;
  className?: string;
  originOnTop?: boolean;
  customGradient?: string;
  intersectionOnCenter?: boolean;
  borderRadius?: number;
};
export const GridBackground = ({
  children,
  height,
  color,
  bgColor = 'var(--c-black)',
  className,
  originOnTop,
  customGradient,
  intersectionOnCenter = false,
  borderRadius,
}: Props) => {
  const gradient =
    customGradient || 'radial-gradient(transparent 0%, var(--bg-color) 80%)';
  return (
    <Root
      className={className}
      style={
        {
          '--gradient': gradient,
          '--height': +height === height ? `${height}px` : height,
          '--bg-pattern': inlineSvg({ color, intersectionOnCenter }),
          '--bg-color': bgColor,
          '--vertical-offset': intersectionOnCenter ? '40px' : '0px',
        } as CSSProperties
      }
    >
      <Pattern
        className={classNames({ originOnTop })}
        style={{ borderRadius }}
      />
      {children}
    </Root>
  );
};
