import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

export type Props = {
  color?: Color;
  size?: keyof typeof unitMapping;
} & React.JSX.IntrinsicElements['svg'];
export const Bench = ({
  color = 'var(--c-black)',
  size = 2,
  ...rest
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    viewBox="0 0 16 16"
    fill="none"
    {...rest}
  >
    <path
      d="M13.6 8.8001V6.4001H15.2V2.4001H13.6V1.6001H12V2.4001H4V1.6001H2.4V2.4001H0.8V6.4001H2.4V8.8001H0V10.4001H0.8V14.4001H2.4V10.4001H13.6V14.4001H15.2V10.4001H16V8.8001H13.6ZM4 6.4001H12V8.8001H4V6.4001Z"
      fill={color}
    />
  </svg>
);
