import styled from 'styled-components';

import { Ordinal } from 'atoms/typography/Ordinal';

const DefaultSup = styled.sup`
  font-size: inherit;
`;

type Props = {
  rank?: number | null;
  Sup?: React.ElementType<any>;
  align?: 'center' | 'top';
};

export const Rank = ({ rank, align = 'top', Sup = DefaultSup }: Props) => {
  return typeof rank === 'number' ? (
    <Ordinal ordinal={rank} Sup={Sup} align={align} />
  ) : null;
};
