import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { BodyL } from 'atoms/typography';
import { SlideShow } from 'atoms/ui/SlideShow';
import { VIDEO_FORMAT, Video } from 'atoms/ui/Video';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { useIntlContext } from 'contexts/intl';
import useScreenSize from 'hooks/device/useScreenSize';
import { absCenter } from 'lib/style';
import {
  breakpoints,
  desktopAndAbove,
  laptopAndAbove,
  tabletAndAbove,
} from 'style/mediaQuery';

import {
  shapeAnimationFromLeft,
  shapeAnimationFromLeftMobile,
  shapeAnimationFromRight,
  shapeAnimationFromRightMobile,
} from './animations';
import actionImg from './assets/actionImg.jpg';
import actionImgMobile from './assets/actionImgMobile.jpg';
import buildLineupsImg from './assets/buildLineupsImg.jpg';
import buildLineupsImgMobile from './assets/buildLineupsImgMobile.jpg';
import challengeImg from './assets/challengeImg.jpg';
import challengeImgMobile from './assets/challengeImgMobile.jpg';
import prizesImg from './assets/prizesImg.jpg';
import prizesImgMobile from './assets/prizesImgMobile.jpg';
import respectImg from './assets/respectImg.jpg';
import respectImgMobile from './assets/respectImgMobile.jpg';
import respectPhoneVideo from './assets/respectPhoneVideo.gif';
import shapeOne from './assets/shapes/01.svg';
import shapeTwo from './assets/shapes/02.svg';
import shapeThree from './assets/shapes/03.svg';
import shapeFour from './assets/shapes/04.svg';
import shapeFive from './assets/shapes/05.svg';
import shapeSix from './assets/shapes/06.svg';
import oneSvg from './assets/svgNumbers/01.svg';
import twoSvg from './assets/svgNumbers/02.svg';
import threeSvg from './assets/svgNumbers/03.svg';
import fourSvg from './assets/svgNumbers/04.svg';
import fiveSvg from './assets/svgNumbers/05.svg';
import sixSvg from './assets/svgNumbers/06.svg';
import tacticMobilePoster from './assets/tacticMobilePoster.jpg';
import tacticsImg from './assets/tacticsImg.png';
import tacticsVideo from './assets/tacticsVideo.webm';
import tacticsVideoMobile from './assets/tacticsVideoMobile.webm';
import { rivalsExperienceMessages } from './messages';

const Wrapper = styled(ContentContainer)`
  margin-top: calc(var(--unit) * 5);
  @media ${tabletAndAbove} {
    margin-top: calc(var(--unit) * 14);
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-family: 'Druk Text Wide';
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  margin-bottom: calc(var(--unit) * 7);

  @media ${tabletAndAbove} {
    font-size: 40px;
    margin-bottom: calc(var(--unit) * 14);
  }
  @media ${laptopAndAbove} {
    font-size: 56px;
  }
`;

const BlockList = styled.div`
  display: grid;
  gap: calc(var(--unit) * 7);

  @media ${tabletAndAbove} {
    gap: calc(var(--unit) * 10);
  }

  @media ${laptopAndAbove} {
    gap: 0;
  }
`;

const BlockWrapper = styled.div`
  display: grid;
  position: relative;
  background: black;
  border: 1px solid var(--c-nd-100-opaque);

  @media ${laptopAndAbove} {
    border: none;
    border-top: 1px solid var(--c-nd-100-opaque);
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'text image';
    gap: calc(var(--unit) * 5);
    padding: calc(var(--unit) * 5);
    &.reverse {
      grid-template-columns: 3fr 1fr;
      grid-template-areas: ' image text';
    }
  }

  &.index_1,
  &.index_2,
  &.index_5,
  &.index_6 {
    &:before {
      content: '';
      inset: 0;
      left: auto;
      width: 100vw;
      position: absolute;
      animation: ${shapeAnimationFromLeftMobile} 5s ease-in-out infinite;

      background: linear-gradient(to right, black, transparent 5%),
        var(--shape-src), var(--shape-src);
      background-repeat: no-repeat;
      background-size: contain;

      @media ${laptopAndAbove} {
        width: 100%;
        animation: ${shapeAnimationFromLeft} 5s ease-in-out infinite;
      }
    }
  }

  &.index_3,
  &.index_4 {
    &:before {
      content: '';
      inset: 0;
      top: -45%;
      right: auto;
      width: 100vw;
      position: absolute;
      animation: ${shapeAnimationFromRightMobile} 5s ease-in-out infinite;

      background: linear-gradient(to left, black, transparent 5%),
        var(--shape-src), var(--shape-src);
      background-repeat: no-repeat;
      background-size: contain;

      @media ${laptopAndAbove} {
        top: 0;
        width: 100%;
        animation: ${shapeAnimationFromRight} 5s ease-in-out infinite;
      }
    }
  }

  &.index_2,
  &.index_5,
  &.index_6 {
    &:before {
      top: -35%;
      @media ${desktopAndAbove} {
        top: 0;
      }
    }
  }
`;

const BlockContent = styled.div`
  z-index: 1;
  background-color: black;
  padding: var(--triple-unit) var(--double-and-a-half-unit);

  @media ${tabletAndAbove} {
    display: grid;
    gap: calc(var(--unit) * 5);
    grid-template-columns: 1fr 2fr;
    row-gap: var(--double-and-a-half-unit);
    padding: var(--quadruple-unit) var(--double-and-a-half-unit);
  }

  @media ${laptopAndAbove} {
    display: flex;
    grid-area: text;
    gap: var(--double-unit);
    flex-direction: column;
    text-align: left;
    padding: 0;
    background-color: transparent;
  }
`;

const BlockTitle = styled.h3`
  font-size: 20px;
  line-height: 1;
  font-family: 'Druk Text Wide';
  text-transform: uppercase;
  letter-spacing: -1px;
  margin-top: var(--double-and-a-half-unit);

  @media ${tabletAndAbove} {
    font-size: 31px;
    margin-top: var(--unit);
  }

  @media ${laptopAndAbove} {
    margin-top: var(--double-unit);
  }
`;

const BlockText = styled(BodyL)`
  font-size: 18px;
  line-height: 1.5;
  margin-top: var(--unit);

  @media ${laptopAndAbove} {
    font-size: 18px;
    margin-top: 0;
  }
`;

const Picture = styled.picture`
  grid-row: 1;
  align-self: center;
  position: relative;

  @media ${laptopAndAbove} {
    text-align: end;
    grid-row: auto;
    grid-area: image;
  }
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;

  @media ${tabletAndAbove} {
    width: 100%;
    aspect-ratio: 1.9;
  }

  @media ${laptopAndAbove} {
    grid-row: auto;
    border-radius: var(--double-unit);
  }
`;

const ImageOverlay = styled.div`
  height: 95%;
  ${absCenter}

  @media ${tabletAndAbove} {
    height: 100%;
    left: auto;
    right: var(--double-unit);
    transform: translateY(-50%);
  }

  img {
    height: 100%;
  }
`;

const VideoPlayer = styled(Video)`
  width: 100%;
  align-self: center;
  grid-row: 1;
  z-index: 1;

  @media ${laptopAndAbove} {
    grid-row: auto;
    grid-area: image;
    border-radius: var(--double-unit);
  }
`;

const SlideShowWrapper = styled.div`
  z-index: 1;
  grid-row: 1;
  width: 100%;
  height: 480px;
  background-color: black;

  @media ${tabletAndAbove} {
    aspect-ratio: 1.7;
  }
`;

type ExperienceVideoProps = {
  poster: string;
  videoSrc: string;
  slideShow?: string[];
};

const ExperienceVideo = ({
  poster,
  videoSrc,
  slideShow,
}: ExperienceVideoProps) => {
  const { up: isTablet } = useScreenSize('tablet');

  if (!isTablet && slideShow)
    return (
      <SlideShowWrapper>
        <SlideShow images={slideShow} />
      </SlideShowWrapper>
    );

  return (
    <VideoPlayer
      loop
      muted
      autoPlay
      playsInline
      poster={poster}
      sources={[{ src: videoSrc, type: VIDEO_FORMAT.WEBM }]}
    />
  );
};

type Block = {
  title: string | ReactNode;
  text: string | ReactNode;
  imageSrc: {
    default?: string;
    mobile?: string;
  };
  videoSrc?: {
    default?: string;
    mobile?: string;
  };
  imgOverlay?: ReactNode;
};

export interface Props {
  title?: ReactNode;
  blocks?: Block[];
}

export const RivalsExperience = ({ title, blocks }: Props) => {
  const { formatMessage } = useIntlContext();
  const { up: isTablet } = useScreenSize('tablet');

  const shapes = [
    shapeOne,
    shapeTwo,
    shapeThree,
    shapeFour,
    shapeFive,
    shapeSix,
  ];
  const numbers = [oneSvg, twoSvg, threeSvg, fourSvg, fiveSvg, sixSvg];

  const defaultBlocks: Block[] = [
    {
      title: formatMessage(rivalsExperienceMessages.challengeTitle),
      text: formatMessage(rivalsExperienceMessages.challengeText),
      imageSrc: { default: challengeImg, mobile: challengeImgMobile },
    },
    {
      title: formatMessage(rivalsExperienceMessages.buildTitle),
      text: formatMessage(rivalsExperienceMessages.buildText),
      imageSrc: { default: buildLineupsImg, mobile: buildLineupsImgMobile },
    },
    {
      title: formatMessage(rivalsExperienceMessages.tacticsTitle),
      text: formatMessage(rivalsExperienceMessages.tacticsText),
      imageSrc: { default: tacticsImg, mobile: tacticMobilePoster },
      videoSrc: { default: tacticsVideo, mobile: tacticsVideoMobile },
    },
    {
      title: formatMessage(rivalsExperienceMessages.actionTitle),
      text: formatMessage(rivalsExperienceMessages.actionText),
      imageSrc: { default: actionImg, mobile: actionImgMobile },
    },
    {
      title: formatMessage(rivalsExperienceMessages.prizesTitle),
      text: formatMessage(rivalsExperienceMessages.prizesText),
      imageSrc: { default: prizesImg, mobile: prizesImgMobile },
    },
    {
      title: formatMessage(rivalsExperienceMessages.respectTitle),
      text: formatMessage(rivalsExperienceMessages.respectText),
      imageSrc: { default: respectImg, mobile: respectImgMobile },
      imgOverlay: (
        <ImageOverlay>
          <img src={respectPhoneVideo} alt="" />
        </ImageOverlay>
      ),
    },
  ];

  return (
    <Wrapper>
      <Title>
        {title || formatMessage(rivalsExperienceMessages.title, { br: <br /> })}
      </Title>
      <BlockList>
        {(blocks || defaultBlocks).map((block, index) => (
          <BlockWrapper
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classNames({
              reverse: index % 2,
              [`index_${index + 1}`]: true,
            })}
            style={{
              zIndex: 7 - index,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--shape-src': `url(${shapes[index]})`,
            }}
          >
            <BlockContent>
              <div>
                <img src={numbers[index]} alt={`0${index + 1}`} />
                <BlockTitle>{block.title}</BlockTitle>
              </div>
              <BlockText as="div" color="var(--c-white)">
                {block.text}
              </BlockText>
            </BlockContent>
            {block.videoSrc?.default ? (
              <ExperienceVideo
                poster={
                  isTablet
                    ? block.imageSrc.default || ''
                    : block.imageSrc.mobile || block.imageSrc.default || ''
                }
                videoSrc={
                  isTablet
                    ? block.videoSrc.default
                    : block.videoSrc.mobile || block.videoSrc.default
                }
              />
            ) : (
              <Picture>
                {block.imageSrc.mobile && (
                  <source
                    media={`(max-width: ${breakpoints.tablet}px)`}
                    srcSet={block.imageSrc.mobile}
                  />
                )}
                <Image src={block.imageSrc.default} />
                {block?.imgOverlay}
              </Picture>
            )}
          </BlockWrapper>
        ))}
      </BlockList>
    </Wrapper>
  );
};
