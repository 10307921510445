import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';

import MyOwnClubRedirect from 'MyOwnClubRedirect';

const MyOwnClub = () => {
  return (
    <RequireAuth>
      <MyOwnClubRedirect />
    </RequireAuth>
  );
};

export default MyOwnClub;
