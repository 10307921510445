/* eslint-disable react/no-unknown-property */
import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { BlockHeader } from '@sorare/core/src/atoms/layout/BlockHeader';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { useSeoContext } from '@sorare/core/src/contexts/seo';
import { fantasy } from '@sorare/core/src/lib/glossary';

import GenericCardPage from '@sorare/marketplace/src/components/GenericCardPage';
import FootballCardProperties from '@sorare/marketplace/src/components/card/FootballCardProperties';

import PlayerUnavailabilityPanel from '@sorare/gameplay/src/components/player/PlayerDetails/PlayerUnavailabilityPanel';
import LastScores from '@sorare/gameplay/src/components/stats/LastScores';

import { CollectionInfo } from './CollectionInfo';
import ItemEligibility from './ItemEligibility';
import { Level } from './Level';
import { CardPage_card } from './__generated__/index.graphql';

type Props = {
  card: CardPage_card | undefined | null;
  loadMoreBids: (
    reload: boolean,
    variable: { bidCursor: string }
  ) => Promise<any>;
  InfiniteScrollLoader?: ReactNode;
};

const AllLastScores = ({
  card,
  InfiniteScrollLoader,
}: {
  card: CardPage_card;
  InfiniteScrollLoader: ReactNode;
}) => {
  return (
    card.allSo5Scores.nodes.length > 0 && (
      <Vertical>
        <BlockHeader title={<FormattedMessage {...fantasy.lastScores} />} />
        <LastScores
          player={card.footballPlayer}
          so5Scores={card.allSo5Scores.nodes}
          InfiniteScrollLoader={InfiniteScrollLoader}
        />
      </Vertical>
    )
  );
};

const PlayerUnavailability = ({ card }: { card: CardPage_card }) => {
  return <PlayerUnavailabilityPanel player={card.footballPlayer} />;
};

export const CardPageContent = (props: Props) => {
  const { card, loadMoreBids, InfiniteScrollLoader } = props;
  const { setPageMetadata } = useSeoContext();

  useEffect(() => {
    if (card) {
      return setPageMetadata(card.name, { img: card?.pictureUrlForTwitter });
    }
    return () => {};
  }, [card, setPageMetadata]);

  if (!card) {
    return <LoadingIndicator fullHeight />;
  }

  return (
    <GenericCardPage<CardPage_card>
      loadMoreBids={loadMoreBids}
      card={card}
      CardProperties={FootballCardProperties}
      CardLevel={Level}
      LastScores={AllLastScores}
      CardEligibility={ItemEligibility}
      InfiniteScrollLoader={InfiniteScrollLoader}
      CollectionInfo={CollectionInfo}
      PlayerUnavailability={PlayerUnavailability}
    />
  );
};

CardPageContent.fragments = {
  card: gql`
    fragment CardPage_card on Card {
      slug
      ...GenericCardPage_anyCard
      name
      allSo5Scores(first: $first, after: $scoreCursor) {
        nodes {
          id
          ...LastScores_playerGameScoreInterface
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      footballPlayer {
        slug
        country {
          code
          slug
        }
        ...LastScores_anyPlayer
        ...PlayerUnavailabilityPanel_anyPlayer
      }
      ...CardPage_ItemEligibility_card
      ...CollectionInfo_card
      ...FootballCardProperties_card
      ...Level_card
    }
    ${GenericCardPage.fragments.anyCard}
    ${LastScores.fragments.anyPlayer}
    ${LastScores.fragments.playerGameScoreInterface}
    ${ItemEligibility.fragments.card}
    ${CollectionInfo.fragments.card}
    ${FootballCardProperties.fragments.card}
    ${Level.fragments.card}
    ${PlayerUnavailabilityPanel.fragments.anyPlayer}
  ` as TypedDocumentNode<CardPage_card>,
};

export default CardPageContent;
