import classNames from 'classnames';
import { SVGProps } from 'react';
import styled from 'styled-components';

interface SVGRProps {
  title?: string;
  titleId?: string;
  shiny?: boolean;
}

const StyledSVG = styled.svg`
  &.shiny {
    filter: drop-shadow(0px 1px 2px #f0c956);
  }
`;

export const CollectionArrow = ({
  title,
  titleId,
  shiny,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <StyledSVG
    viewBox="0 0 12 12"
    fill="none"
    aria-labelledby={titleId}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={classNames({ shiny })}
  >
    <path
      d="M3.65217 6.54545L-4.76852e-07 6.54545L-5.96064e-07 5.18182L6 5.24536e-07L12 5.18182L12 6.54545L8.34783 6.54545L8.34783 12L3.65217 12L3.65217 6.54545Z"
      fill="currentColor"
    />
  </StyledSVG>
);
