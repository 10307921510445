import { TypedDocumentNode, gql } from '@apollo/client';

import {
  SupportedCurrency,
  TokenPaymentMethod,
} from '@sorare/core/src/__generated__/globalTypes';
import { UserBalance } from '@sorare/core/src/components/wallet/UserBalance';
import { useEventsContext } from '@sorare/core/src/contexts/events';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { formatGqlErrors } from '@sorare/core/src/gql';
import useMutation from '@sorare/core/src/hooks/graphql/useMutation';
import { useCreateEthMigration } from '@sorare/core/src/hooks/starkware/useCreateEthMigration';
import { sendSafeError } from '@sorare/core/src/lib/error';

import SmallUser from 'components/user/SmallUser';
import { buildApplePayApprovals } from 'lib/buildApplePayApprovals';

import {
  AcceptOfferMutation,
  AcceptOfferMutationVariables,
  useAcceptOffer_anyCard,
} from './__generated__/useAcceptOffer.graphql';
import useApproveMigrator from './useApproveMigrator';
import useMigrateCards from './useMigrateCards';
import usePrepareAcceptOffer from './usePrepareAcceptOffer';

const ACCEPT_OFFER_MUTATION = gql`
  mutation AcceptOfferMutation($input: acceptOfferInput!) {
    acceptOffer(input: $input) {
      deal {
        ... on TokenPrimaryOffer {
          id
          status
          endDate
          buyer {
            slug
            ...SmallUser_user
          }
          cards: anyCards {
            slug
            tokenOwner {
              id
              from
              transferType
              user {
                slug
              }
            }
          }
        }
        ... on TokenOffer {
          id
          status
          senderSide {
            id
            wei
            cards: anyCards {
              slug
              tokenOwner {
                id
                from
                transferType
              }
              liveSingleSaleOffer {
                id
              }
            }
          }
        }
      }
      currentUser {
        slug
        ...UserBalances
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${SmallUser.fragments.user}
  ${UserBalance.fragments.balances}
` as TypedDocumentNode<AcceptOfferMutation, AcceptOfferMutationVariables>;

type AcceptOfferArgs = {
  supportedCurrency: SupportedCurrency;
  offerId: string;
  conversionCreditId?: string;
  receiveCards: useAcceptOffer_anyCard[];
  attemptReference: string | null;
  signedAmount?: string;
  tokenPaymentMethod?: TokenPaymentMethod;
  applePayPaymentToken?: ApplePayJS.ApplePayPaymentToken;
};

const useAcceptOffer = () => {
  const [acceptOffer] = useMutation(ACCEPT_OFFER_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const { showNotification } = useSnackNotificationContext();
  const approveMigrator = useApproveMigrator();
  const migrateCards = useMigrateCards();
  const createEthMigration = useCreateEthMigration();
  const { prepareAcceptOffer } = usePrepareAcceptOffer();
  const { track } = useEventsContext();

  const prepareAndAcceptOffer = async ({
    offerId,
    receiveCards,
    conversionCreditId,
    supportedCurrency,
    attemptReference,
    signedAmount,
    tokenPaymentMethod = TokenPaymentMethod.WALLET,
    applePayPaymentToken,
  }: AcceptOfferArgs) => {
    const isApplePay = tokenPaymentMethod === TokenPaymentMethod.APPLE_PAY;
    const { settlementInfo, approvals, authorizations } =
      await prepareAcceptOffer({
        offerId,
        supportedCurrency,
        tokenPaymentMethod,
        conversionCreditId,
        attemptReference,
        signedAmount,
        signAuthorizations: !isApplePay,
      });
    if (isApplePay) {
      if (!applePayPaymentToken) {
        sendSafeError(
          'Missing applePayPaymentToken for ApplePay payment method'
        );
        return null;
      }
      const applePayApprovals = buildApplePayApprovals({
        approvals: authorizations,
        applePayPaymentToken,
      });
      await approveMigrator(receiveCards);
      const migrationData = await migrateCards(receiveCards);
      const result = await acceptOffer({
        variables: {
          input: {
            offerId,
            migrationData,
            settlementInfo,
            approvals: applePayApprovals,
            signedAmount,
          },
        },
      });
      track('Accept Direct Offer', {
        offer_id: offerId,
      });
      return result;
    }

    if (!approvals) {
      showNotification('unlockWallet');
      throw new Error('Missing password');
    }
    await approveMigrator(receiveCards);
    await createEthMigration();
    const migrationData = await migrateCards(receiveCards);

    const result = await acceptOffer({
      variables: {
        input: {
          offerId,
          migrationData,
          settlementInfo,
          approvals,
          signedAmount,
        },
      },
    });
    track('Accept Direct Offer', {
      offer_id: offerId,
    });
    return result;
  };

  return async (props: AcceptOfferArgs) => {
    const result = await prepareAndAcceptOffer(props);
    if (result?.errors) return formatGqlErrors(result.errors);
    if (
      result?.data?.acceptOffer?.errors &&
      result.data.acceptOffer.errors.length > 0
    )
      return result.data.acceptOffer.errors.map(e => e.message);
    return null;
  };
};

useAcceptOffer.fragments = {
  anyCard: gql`
    fragment useAcceptOffer_anyCard on AnyCardInterface {
      slug
      ...useApproveMigrator_anyCard
      ...useMigrateCards_anyCard
    }
    ${useApproveMigrator.fragments.anyCard}
    ${useMigrateCards.fragments.anyCard}
  ` as TypedDocumentNode<useAcceptOffer_anyCard>,
};

export default useAcceptOffer;
