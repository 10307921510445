import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Switch } from 'atoms/inputs/Switch';
import { Vertical } from 'atoms/layout/flex';
import { HeadlineM, LabelM, Text16 } from 'atoms/typography';
import Dialog from 'components/dialog';
import { useCurrentUserContext } from 'contexts/currentUser';
import useScreenSize from 'hooks/device/useScreenSize';
import useMutation from 'hooks/graphql/useMutation';
import { glossary } from 'lib/glossary';

import {
  GetUserStatus,
  GetUserStatusVariables,
  UpdateUserSettingsDiscoverableMutation,
  UpdateUserSettingsDiscoverableMutationVariables,
} from './__generated__/index.graphql';
import discoverableNo from './assets/discoverable_no.png';
import discoverableYes from './assets/discoverable_yes.png';

const Container = styled(Vertical).attrs({ gap: 0, center: true })`
  padding: var(--triple-unit);
`;
const Footer = styled.div`
  padding: 0 var(--triple-unit) var(--triple-unit);
`;
const ImageContainer = styled(Vertical).attrs({ gap: 0, center: true })`
  height: 152px;
  width: 173px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: var(--double-unit);
`;
const Visual = styled.img`
  height: 152px;
`;
const SwitchContainer = styled(Vertical).attrs({ gap: 0, center: true })`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--double-unit);
  margin: var(--double-unit);
  border-radius: var(--unit);
  border: 2px solid var(--c-nd-100);
`;

const GET_USER_STATUS = gql`
  query GetUserStatus {
    currentUser {
      slug
      footballLast30DaysLineupsCount
    }
    football {
      rivals {
        id
        currentManager {
          id
          pastChallenges(first: 1) {
            nodes {
              id
            }
          }
        }
      }
    }
  }
` as TypedDocumentNode<GetUserStatus, GetUserStatusVariables>;

const UPDATE_USER_SETTINGS_MUTATION = gql`
  mutation UpdateUserSettingsDiscoverableMutation(
    $input: updateUserSettingsInput!
  ) {
    updateUserSettings(input: $input) {
      userSettings {
        id
        discoverable
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  UpdateUserSettingsDiscoverableMutation,
  UpdateUserSettingsDiscoverableMutationVariables
>;

const DiscoverablePrompt = () => {
  const { currentUser } = useCurrentUserContext();
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const [promptUser, setPromptUser] = useState<boolean>(false);
  const [discoverable, setDiscoverable] = useState<boolean>(true);
  const [updating, setUpdating] = useState<boolean>(false);
  const [getUserStatus, { data, loading }] = useLazyQuery(GET_USER_STATUS);
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS_MUTATION);

  useEffect(() => {
    if (
      currentUser?.phoneNumberVerified &&
      currentUser?.userSettings?.discoverable == null &&
      !promptUser
    ) {
      if (!loading && !data) {
        getUserStatus();
      }
      if (data && !promptUser) {
        if (
          (data.currentUser?.footballLast30DaysLineupsCount || 0) > 0 &&
          (data?.football?.rivals?.currentManager?.pastChallenges?.nodes
            ?.length || 0) > 0
        ) {
          setPromptUser(true);
        }
      }
    }
  }, [currentUser, data, loading, promptUser, getUserStatus]);

  const setDiscoverableSettings = async () => {
    setUpdating(true);
    await updateUserSettings({ variables: { input: { discoverable } } });
    setPromptUser(false);
    setUpdating(false);
  };

  return (
    <>
      {currentUser && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={promptUser}
          fullScreen={!isTabletOrDesktop}
          onClose={() => setPromptUser(false)}
          title={
            <HeadlineM as="h3" className="text-center">
              <FormattedMessage
                id="discoverablePrompt.title"
                defaultMessage="Connect with Friends"
              />
            </HeadlineM>
          }
          footer={
            <Footer>
              <Button
                onClick={setDiscoverableSettings}
                disabled={updating}
                color="primary"
                size="medium"
                fullWidth
              >
                <FormattedMessage {...glossary.submit} />
              </Button>
            </Footer>
          }
        >
          <Container>
            <ImageContainer>
              <Visual
                src={discoverable ? discoverableYes : discoverableNo}
                alt={discoverable ? 'Discoverable' : 'Not Discoverable'}
              />
            </ImageContainer>
            <Text16 color="var(--c-nd-600)">
              <FormattedMessage
                id="discoverablePrompt.description"
                defaultMessage="Embrace the sports fan community! Let friends discover you through their phone contacts and enjoy Sorare together."
              />
            </Text16>
            <SwitchContainer>
              <LabelM bold>
                {discoverable ? (
                  <FormattedMessage
                    id="discoverablePrompt.labelYes"
                    defaultMessage="Allow"
                  />
                ) : (
                  <FormattedMessage
                    id="discoverablePrompt.labelNo"
                    defaultMessage="Don't Allow"
                  />
                )}
              </LabelM>
              <Switch
                checked={discoverable}
                onChange={() => setDiscoverable(!discoverable)}
              />
            </SwitchContainer>
          </Container>
        </Dialog>
      )}
    </>
  );
};

export default DiscoverablePrompt;
