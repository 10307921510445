import classNames from 'classnames';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyM, text14 } from '@sorare/core/src/atoms/typography';
import { ScarcityBall } from '@sorare/core/src/components/card/ScarcityBall';
import { fantasy } from '@sorare/core/src/lib/glossary';
import { ScarcityType } from '@sorare/core/src/lib/scarcity';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { inferRuleSetProps } from '@sorare/core/src/style/utils';

const OptionsWrapper = styled(Horizontal)`
  flex-wrap: wrap;
`;

const Option = styled.label<inferRuleSetProps<typeof text14>>`
  white-space: nowrap;
  padding: var(--unit);
  background: rgba(0, 0, 0, 0.5);
  border-radius: var(--unit);
  font: var(--t-label-s);
  color: var(--c-nd-600);
  &.selected {
    background: var(--c-white);
    color: var(--c-black);
  }
  cursor: pointer;

  @media ${tabletAndAbove} {
    font: var(--t-body-m);
    padding: var(--half-unit) var(--intermediate-unit);
  }
`;

type PropsWithoutAllOption = {
  onChange: (selected: ScarcityType[]) => void;
  selectedValues: ScarcityType[];
  scarcitiesOptions: readonly ScarcityType[];
  selectMultiple?: boolean;
};

type Props = PropsWithoutAllOption;

export const ScarcityFilter = ({
  onChange,
  selectedValues,
  scarcitiesOptions,
  selectMultiple,
}: Props) => {
  const { formatMessage } = useIntl();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value: currentValue } = e.currentTarget;
    if (selectMultiple) {
      const newValues = checked
        ? [...selectedValues, currentValue as ScarcityType]
        : selectedValues.filter(v => v !== currentValue);

      onChange(newValues as ScarcityType[]);
    } else {
      onChange(
        selectedValues.includes(currentValue as ScarcityType)
          ? []
          : [currentValue as ScarcityType]
      );
    }
  };
  return (
    <Vertical>
      <BodyM as="p" bold>
        {formatMessage(fantasy.scarcities)}{' '}
        {selectMultiple && (
          <BodyM as="span" color="var(--c-nd-600)">
            ({selectedValues.length})
          </BodyM>
        )}
      </BodyM>
      <OptionsWrapper>
        {scarcitiesOptions.map(scarcity => (
          <Option
            key={scarcity}
            htmlFor={scarcity}
            className={classNames({
              selected: selectedValues.includes(scarcity),
            })}
          >
            <ScarcityBall scarcity={scarcity} />
            <input
              className="sr-only"
              type="checkbox"
              checked={selectedValues.includes(scarcity)}
              id={scarcity}
              value={scarcity}
              onChange={onInputChange}
            />
          </Option>
        ))}
      </OptionsWrapper>
    </Vertical>
  );
};

export default ScarcityFilter;
