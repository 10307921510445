import { TypedDocumentNode, gql } from '@apollo/client';
import { faBell } from '@fortawesome/pro-solid-svg-icons';

import { DumbNotification } from 'components/activity/DumbNotification';
import { LEGACY_PLAYER_SHOW_CARDS } from 'constants/routes';
import { useSportContext } from 'contexts/sport';
import { isType } from 'lib/gql';

import { useAuctionLink } from '../AuctionNotification/useAuctionLink';
import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import {
  ReminderNotification_auctionReminderNotification,
  TokenAuction_auctionReminderNotification,
} from './__generated__/index.graphql';

type Props = CommonNotificationProps & {
  notification: ReminderNotification_auctionReminderNotification;
};

const useLinkForReminderNotification = () => {
  const { generateSportContextPath } = useSportContext();
  const makeLink = useAuctionLink();

  return (notification: ReminderNotification_auctionReminderNotification) => {
    const { name, remindable, similar } = notification;
    if (!isType(remindable, 'TokenAuction')) {
      return undefined;
    }
    if (name === 'auction_missed') {
      if (remindable.cards.length === 1) {
        const { sport, anyPlayer, rarityTyped, seasonYear } =
          remindable.cards[0];
        return generateSportContextPath(
          `${LEGACY_PLAYER_SHOW_CARDS}?sale=true&rarity=${rarityTyped}&season=${seasonYear}`,
          {
            params: {
              slug: anyPlayer.slug,
            },
            sport,
          }
        );
      }
      return makeLink(remindable);
    }
    const ofInterest = similar || remindable;
    if (isType(ofInterest, 'TokenAuction')) {
      return makeLink(ofInterest);
    }
    return undefined;
  };
};

export const AuctionReminderNotification = ({
  notification,
  ...rest
}: Props) => {
  const { createdAt, name, read, sport, reminderNotificationBody } =
    notification;
  const makeLink = useLinkForReminderNotification();

  const link = makeLink(notification);

  return (
    <DumbNotification
      name={name}
      title={reminderNotificationBody}
      link={link}
      createdAt={createdAt}
      read={read}
      sport={sport}
      icon={faBell}
      {...rest}
    />
  );
};

const auctionOfInterest = gql`
  fragment TokenAuction_auctionReminderNotification on TokenAuction {
    id
    cards: anyCards {
      slug
      sport
      pictureUrl
    }
  }
` as TypedDocumentNode<TokenAuction_auctionReminderNotification>;

AuctionReminderNotification.fragments = {
  auctionReminderNotification: gql`
    fragment ReminderNotification_auctionReminderNotification on ReminderNotification {
      id
      ...Notification_notificationInterface
      reminderNotificationBody: body
      remindable {
        ... on TokenAuction {
          id
          ...TokenAuction_auctionReminderNotification
          ...AuctionLink_auction
          cards: anyCards {
            slug
            anyPlayer {
              slug
            }
            rarityTyped
            seasonYear
          }
        }
      }
      similar {
        ... on TokenAuction {
          id
          ...TokenAuction_auctionReminderNotification
          ...AuctionLink_auction
        }
      }
    }
    ${commonNotificationInterfaceFragment}
    ${useAuctionLink.fragments.auctionLink}
    ${auctionOfInterest}
  ` as TypedDocumentNode<ReminderNotification_auctionReminderNotification>,
};
