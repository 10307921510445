import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FullWidthContainer } from 'atoms/layout/FullWidthContainer';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, BodyS, HeadlineS } from 'atoms/typography';
import { PlayerAvatar } from 'components/seo/PlayerAvatar';
import { FRONTEND_ASSET_HOST } from 'constants/assets';

const Flag = styled.img`
  width: var(--double-unit);
`;

type Props = {
  displayName: string;
  manager: {
    displayName: string;
    pictureUrl: string | null;
    startDate: Date;
    country: {
      slug: string;
    };
  };
};

export const Manager = ({ displayName, manager }: Props) => {
  return (
    <FullWidthContainer>
      <Vertical gap={1.5}>
        <HeadlineS as="h2">
          <FormattedMessage
            id="Seo.Team.Manager.teamManager"
            defaultMessage="{team} manager"
            values={{ team: displayName }}
          />
        </HeadlineS>
        <Horizontal gap={1}>
          <PlayerAvatar src={manager.pictureUrl} alt={manager.displayName} />
          <Vertical gap={0}>
            <Horizontal gap={1}>
              <BodyM as="p">{manager.displayName}</BodyM>
              <Flag
                src={`${FRONTEND_ASSET_HOST}/flags/${manager.country.slug}.svg`}
                alt={manager.country.slug}
              />
            </Horizontal>
            <BodyS as="p" color="var(--c-nd-600)">
              <FormattedMessage
                id="Seo.Team.Manager.joinedIn"
                defaultMessage="Joined in <span>{date}</span>"
                values={{
                  date: (
                    <FormattedDate
                      value={manager.startDate}
                      month="long"
                      day="numeric"
                      year="numeric"
                    />
                  ),
                  span: chunks => (
                    <BodyM as="span" color="var(--c-white)">
                      {chunks}
                    </BodyM>
                  ),
                }}
              />
            </BodyS>
          </Vertical>
        </Horizontal>
      </Vertical>
    </FullWidthContainer>
  );
};
