import styled from 'styled-components';

import { Sorare } from 'atoms/icons/Sorare';

export const FootballLogo = styled(Sorare)`
  color: var(--c-brand-600);
  fill: var(--c-black);
`;

export const Football = () => (
  <>
    <FootballLogo />
    <svg
      width="99"
      height="11"
      viewBox="0 0 99 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.468 1.072V10H4.452V6.676H10.524V4.324H4.452V3.436H10.632V1.072H0.468ZM17.5808 0.904C11.6768 0.904 10.8368 3.544 10.8368 5.332V5.692C10.8368 7.456 11.6768 10.168 17.5808 10.168H18.1568C24.0728 10.168 24.9128 7.456 24.9128 5.692V5.332C24.9128 3.544 24.0728 0.904 18.1568 0.904H17.5808ZM20.7248 5.572C20.7248 6.448 20.1128 7.504 17.8688 7.504C15.6248 7.504 15.0128 6.424 15.0128 5.548V5.404C15.0128 4.54 15.5648 3.544 17.8688 3.544C20.1128 3.544 20.7248 4.54 20.7248 5.404V5.572ZM31.752 0.904C25.848 0.904 25.008 3.544 25.008 5.332V5.692C25.008 7.456 25.848 10.168 31.752 10.168H32.328C38.244 10.168 39.084 7.456 39.084 5.692V5.332C39.084 3.544 38.244 0.904 32.328 0.904H31.752ZM34.896 5.572C34.896 6.448 34.284 7.504 32.04 7.504C29.796 7.504 29.184 6.424 29.184 5.548V5.404C29.184 4.54 29.736 3.544 32.04 3.544C34.284 3.544 34.896 4.54 34.896 5.404V5.572ZM42.7977 10H46.8537V3.868H50.7057V1.072H38.9457V3.868H42.7977V10ZM51.0827 10H60.2867C63.2147 10 64.1387 8.8 64.1387 7.396V7.276C64.1387 5.692 62.9387 5.14 61.9427 4.948C62.6987 4.792 63.6227 4.252 63.6227 3.184V3.088C63.6227 2.08 62.9267 1.072 60.2747 1.072H51.0827V10ZM58.6547 3.256C59.2907 3.256 59.5067 3.412 59.5067 3.76V3.784C59.5067 4.108 59.2907 4.3 58.6547 4.3H55.1267V3.256H58.6547ZM58.8587 6.436C59.6747 6.436 59.8547 6.7 59.8547 7.048V7.072C59.8547 7.42 59.6747 7.732 58.8587 7.732H55.1267V6.436H58.8587ZM71.9599 6.304H69.4399L70.6879 3.556L71.9599 6.304ZM73.6639 10H78.1519L73.5799 1.072H67.9879L63.6919 10H67.7839L68.3359 8.764H73.0999L73.6639 10ZM78.0658 1.072V10H88.3018V7.096H82.1098V1.072H78.0658ZM88.6863 1.072V10H98.9223V7.096H92.7303V1.072H88.6863Z"
        fill="white"
      />
    </svg>
  </>
);
