export const SpreadLineupIcon = () => {
  return (
    <svg width="15" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x=".5"
        y=".5"
        width="14"
        height="17"
        rx="2.5"
        stroke="currentColor"
      />
      <rect x="8.5" y="4" width="1.25" height="4" rx=".5" fill="currentColor" />
      <rect x="5.5" y="4" width="1.25" height="4" rx=".5" fill="currentColor" />
      <rect x="10" y="10" width="1.25" height="4" rx=".5" fill="currentColor" />
      <rect
        x="7"
        y="10.5"
        width="1.25"
        height="4"
        rx=".5"
        fill="currentColor"
      />
      <rect x="4" y="10" width="1.25" height="4" rx=".5" fill="currentColor" />
    </svg>
  );
};
