import { useMemo } from 'react';

import { NativeSelect } from 'atoms/inputs/NativeSelect';

type Option = {
  value: string;
  label: string;
  jsonValue: Json;
};

const deprecatedValues: Json[] = [true];

const backwardsCompatibility = (v: Json) => {
  if (deprecatedValues.includes(v)) return 'email';
  return v;
};

const ignoreValues = (initialValue: Json) => {
  if (deprecatedValues.includes(initialValue)) {
    return true;
  }
  return false;
};

const formatValue = (value: Json) => {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return value.toString();
  return JSON.stringify(value);
};

type Props = {
  preference: {
    value: Json | null;
    defaultValue: Json;
    values: Json[];
    labels: string[] | null;
  };
  handleChange: (newValue: Json) => void;
};
export const DropdownSelector = ({ preference, handleChange }: Props) => {
  const { value, defaultValue, values, labels } = preference;

  const options = useMemo(
    () =>
      (labels || []).reduce<Option[]>((acc, label, index) => {
        const v = values[index];
        if (ignoreValues(v)) return acc;
        return [
          ...acc,
          {
            value: formatValue(v),
            label,
            jsonValue: v,
          },
        ];
      }, []),
    [labels, values]
  );

  const currentJsonValue = backwardsCompatibility(
    value !== null ? value : defaultValue
  );
  const currentValue = options.find(o => o.jsonValue === currentJsonValue);

  const mappedValues = useMemo(
    () =>
      options.reduce<Record<string, Json>>((acc, option) => {
        return {
          ...acc,
          [option.value]: option.jsonValue,
        };
      }, {}),
    [options]
  );

  return (
    <NativeSelect
      value={currentValue!.value}
      values={options}
      onChange={v => {
        handleChange(mappedValues[v]);
      }}
    />
  );
};
