import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';

const Root = styled(Horizontal)`
  width: fit-content;
  padding: var(--half-unit) var(--unit);
  background: linear-gradient(
    84.1deg,
    #f8d3da 0%,
    #b3a9f4 28.32%,
    #fbe9fb 54.01%,
    #4f94fd 100%
  );
  border-radius: var(--double-unit);
  color: var(--c-black);
`;

const MAX_PARTICIPANTS = 3000;

const Remaining = ({
  completedParticipants,
}: {
  completedParticipants: number;
}) => {
  return (
    <Root>
      <LabelS bold>
        <FormattedMessage
          id="AddFundsToFiatWallet.Promo.rewardsLeft"
          defaultMessage="{count} rewards left"
          values={{ count: MAX_PARTICIPANTS - completedParticipants }}
        />
      </LabelS>
    </Root>
  );
};

export default Remaining;
