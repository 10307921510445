import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { ContentWithStickyHeader } from '@sorare/core/src/atoms/layout/ContentWithStickyHeader';
import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineL } from '@sorare/core/src/atoms/typography';
import { SETTINGS } from '@sorare/core/src/constants/__generated__/routes';
import { useIsDesktopAndAbove } from '@sorare/core/src/hooks/device/useIsDesktopAndAbove';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { desktopAndAbove } from '@sorare/core/src/style/mediaQuery';

const Title = styled(HeadlineL)`
  padding: var(--double-unit) 0;
  border-bottom: 1px solid var(--c-nd-100);
`;

const MobileHeader = styled(Horizontal).attrs({ gap: 1 })`
  border-bottom: 1px solid var(--c-nd-100);
  padding: var(--unit);
`;

const Content = styled(Vertical).attrs({ gap: 0 })`
  padding: var(--double-unit);
  @media ${desktopAndAbove} {
    padding: var(--triple-unit) 0;
  }
`;

export const SettingsWrapper = ({
  children,
  pageTitle,
}: {
  children: ReactNode;
  pageTitle: MessageDescriptor;
}) => {
  const { isIosApp } = useIsMobileApp();
  const isDesktopAndAbove = useIsDesktopAndAbove();
  if (isDesktopAndAbove || isIosApp) {
    return (
      <Vertical gap={2}>
        <ContentWithStickyHeader
          header={
            <FullWidthContainer.NoPadding>
              <Title as="div">
                <FullWidthContainer>
                  <FormattedMessage {...pageTitle} />
                </FullWidthContainer>
              </Title>
            </FullWidthContainer.NoPadding>
          }
        >
          <Content>{children}</Content>
        </ContentWithStickyHeader>
      </Vertical>
    );
  }
  return (
    <FullWidthContainer.NoPadding>
      <ContentWithStickyHeader
        header={
          <MobileHeader>
            <IconButton
              color="transparent"
              icon={faChevronLeft}
              to={SETTINGS}
            />
            <Link to=".">
              <HeadlineL as="p">
                <FormattedMessage {...pageTitle} />
              </HeadlineL>
            </Link>
          </MobileHeader>
        }
      >
        <Content>{children}</Content>
      </ContentWithStickyHeader>
    </FullWidthContainer.NoPadding>
  );
};
