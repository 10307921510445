import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { BodyM, Caption, HeadlineM } from 'atoms/typography';

const CheckContainer = styled(Horizontal).attrs({ gap: 0, center: true })`
  align-self: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--c-green-600);
`;

export const DepositAcceptedContent = ({
  hideCaption = false,
}: {
  hideCaption?: boolean;
}) => {
  return (
    <>
      <CheckContainer>
        <FontAwesomeIcon icon={faCheck} size="2x" />
      </CheckContainer>
      <HeadlineM as="h3">
        <FormattedMessage
          id="PaypalDepositAccepted.title"
          defaultMessage="Success!"
        />
      </HeadlineM>
      <BodyM as="p">
        <FormattedMessage
          id="PaypalDepositAccepted.content"
          defaultMessage="Your money is en route and will reflect to your cash wallet shortly."
        />
      </BodyM>
      {!hideCaption && (
        <Caption color="var(--c-nd-600)">
          <FormattedMessage
            id="PaypalDepositAccepted.footer"
            defaultMessage="Your deposit will be listed on your statement as Mangopay.com"
          />
        </Caption>
      )}
    </>
  );
};
