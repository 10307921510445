import styled from 'styled-components';

import { ExclamationIcon } from 'components/collections/DetailedScoreLine/ExclamationIcon';

const Wrapper = styled.div`
  position: relative;
  grid-area: img;
`;

const ExclamationBadge = styled(ExclamationIcon)`
  position: absolute;
  top: -5px;
  right: -5px;
`;

const Img = styled.img`
  aspect-ratio: 1;
`;

type Props = {
  img: string;
  listed?: boolean;
  width?: number;
};

export const Image = ({ img, listed, width = 40 }: Props) => {
  return (
    <Wrapper>
      {listed && <ExclamationBadge />}
      <Img src={img} alt="" width={width} />
    </Wrapper>
  );
};
