import { defineMessages } from 'react-intl';

import {
  BasketballAverageStatType,
  NBAPosition,
  Position,
} from '__generated__/globalTypes';
import { ProgressBarThreshold } from 'components/collections/ProgressBar';

export const calculateScoreWithBonus = (score: number, bonus: number) => {
  return score > 0 ? score * (1 + bonus) : score;
};

export const isInSeasonLeaderboard = (cumulativeLeaderboard: {
  slug: string;
}) => /in-season/.test(cumulativeLeaderboard?.slug || '');

export const positionNames = defineMessages<NBAPosition>({
  [NBAPosition.GUARD]: {
    id: 'NBAPlayerPosition.guard',
    defaultMessage: 'Guard',
  },
  [NBAPosition.CENTER]: {
    id: 'NBAPlayerPosition.center',
    defaultMessage: 'Center',
  },
  [NBAPosition.FORWARD]: {
    id: 'NBAPlayerPosition.forward',
    defaultMessage: 'Forward',
  },
  [NBAPosition.UNKNOWN]: {
    id: 'NBAPlayerPosition.unknown',
    defaultMessage: 'Unknown',
  },
});

type PlayerInjuryStatus = 'GTD' | 'OUT' | 'OFS';

const shortMessages = defineMessages<PlayerInjuryStatus>({
  GTD: {
    id: 'Player.InjuryStatusMessage.Short.gtd',
    defaultMessage: 'GTD',
  },
  OUT: {
    id: 'Player.InjuryStatusMessage.Short.out',
    defaultMessage: 'OUT',
  },
  OFS: {
    id: 'Player.InjuryStatusMessage.Short.ofs',
    defaultMessage: 'OFS',
  },
});

const longMessages = defineMessages({
  GTD: {
    id: 'Player.InjuryStatusMessage.Long.gtd',
    defaultMessage: 'Game Time Decision',
  },
  OUT: {
    id: 'Player.InjuryStatusMessage.Long.out',
    defaultMessage: 'Out',
  },
  OFS: {
    id: 'Player.InjuryStatusMessage.Long.ofs',
    defaultMessage: 'Out For Season',
  },
});

export function getPlayerInjuryMessage(
  status: string | null,
  { long }: { long?: boolean } = {}
) {
  const messages = long ? longMessages : shortMessages;
  switch (status) {
    case 'GTD':
      return messages.GTD;
    case 'OFS':
      return messages.OFS;
    default:
    case 'OUT':
      return messages.OUT;
  }
}

export type PlayerInjuryColor = 'yellow' | 'red';

const PLAYER_INJURY_COLORS_BY_STATUS: Record<
  PlayerInjuryStatus,
  PlayerInjuryColor
> = {
  GTD: 'yellow',
  OUT: 'red',
  OFS: 'red',
};

export function getPlayerInjuryColor(status: string | null): PlayerInjuryColor {
  return PLAYER_INJURY_COLORS_BY_STATUS[status as PlayerInjuryStatus] ?? 'red';
}

export const randomNbaPositions = () => {
  const positions = [
    Position.basketball_center,
    Position.basketball_forward,
    Position.basketball_guard,
  ];
  return positions[Math.floor(Math.random() * positions.length)];
};

export const THRESHOLDS: ProgressBarThreshold[] = [
  { value: 0, bonus: '-' },
  { value: 35, bonus: '+1%' },
  { value: 100, bonus: '+2%' },
  { value: 200, bonus: '+3%' },
  { value: 400, bonus: '+4%' },
  { value: 500, bonus: '+5%' },
];

export const shortNbaStats = {
  [BasketballAverageStatType.POINTS]: 'PTS',
  [BasketballAverageStatType.REBOUNDS]: 'REB',
  [BasketballAverageStatType.MINS_PLAYED]: 'MIN',
  [BasketballAverageStatType.STEALS]: 'STL',
  [BasketballAverageStatType.ASSISTS]: 'AST',
  [BasketballAverageStatType.BLOCKS]: 'BLK',
  [BasketballAverageStatType.TURNOVERS]: 'TO',
  [BasketballAverageStatType.THREE_POINTS_MADE]: '3PT',
  [BasketballAverageStatType.DOUBLE_DOUBLE]: 'DD',
  [BasketballAverageStatType.TRIPLE_DOUBLE]: 'TD',
};
