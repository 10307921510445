import { unitMapping } from 'lib/style';

type Props = {
  title?: string;
  size?: keyof typeof unitMapping;
  red?: boolean;
};
export const SwapBudget = ({ title, size = 2, red = false }: Props) => {
  const pathId = red ? 'paint0_linear_4360_red' : 'paint0_linear_4360_blue';
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={title}
    >
      <path
        d="M17.6209 1.01236C20.0213 -0.337452 22.9787 -0.337452 25.3791 1.01236L37.7772 7.98419C40.1776 9.334 41.6562 11.8285 41.6562 14.5282V28.4718C41.6562 31.1715 40.1776 33.666 37.7772 35.0158L25.3791 41.9876C22.9787 43.3375 20.0213 43.3375 17.6209 41.9876L5.22282 35.0158C2.82244 33.666 1.34375 31.1715 1.34375 28.4718V14.5282C1.34375 11.8285 2.82244 9.334 5.22282 7.98419L17.6209 1.01236Z"
        fill={`url(#${pathId})`}
      />
      <path
        d="M36.0909 22.9104C34.6011 19.0071 28.6564 17.9979 25.4497 17.4533C24.0177 17.2311 22.4787 17.0554 20.7445 16.9146L19.8 16.8376L19.4991 18.8982L20.419 19.1015C23.2497 19.7288 26.2452 20.4824 28.9688 21.7356C31.0227 22.6969 31.9947 23.635 32.0468 24.1388C32.0742 24.4031 31.7329 24.7081 31.5145 24.8765C29.4938 26.2444 24.5789 26.5726 18.2523 25.7768L18.4143 23.2966C19.4875 23.4622 20.6288 23.6088 21.8712 23.7381L22.836 23.8383L23.1426 21.7429L22.2068 21.5497C18.5922 20.8019 14.8547 20.0279 11.7319 18.146C10.8105 17.555 10.2464 16.9364 10.2218 16.4877C10.2016 16.1275 10.5661 15.7485 10.8828 15.4886C13.1407 13.8477 18.7759 13.4077 26.0543 14.2892L25.9892 15.2839L27.4558 16.1842L32.7454 13.0069L32.8408 11.4779L28.004 7.67188L26.439 8.3747L26.3985 9.00346C17.3599 8.74789 11.3095 9.94589 8.40802 12.5699C7.34056 13.5486 6.69691 14.8395 6.64339 16.1116C6.59421 17.2544 7.00066 18.3275 7.82367 19.2234C9.86456 21.4161 13.3533 22.3179 16.0668 22.8712L8.27205 27.5528L8.17659 29.0818L16.1116 35.3288L17.6766 34.626L17.8169 32.4725C27.6497 32.3999 33.6566 30.5557 35.6758 26.9864C36.4077 25.6853 36.5639 24.1621 36.0924 22.9118L36.0909 22.9104Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={pathId}
          x1="37.8372"
          y1="48.375"
          x2="17.4062"
          y2="22.7332"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={red ? 'var(--c-red-800)' : '#00FFF7'} />
          <stop offset="1" stopColor={red ? 'var(--c-red-600)' : '#294BFF'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
