import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { AveragePlayerScore } from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { PlayerScore } from '@sorare/core/src/components/scoring/PlayerScore';

import CardBonus from '../CardBonus';
import ItemEligibility from './ItemEligibility';
import U23Eligible from './U23Eligible';
import { FootballCardProperties_card } from './__generated__/index.graphql';

type Props = {
  card: FootballCardProperties_card;
  withTransferMalus: boolean;
  stats?: ReactNode;
  supensionsAndInjuries?: ReactNode;
};

const Root = styled(Horizontal).attrs({ gap: 0 })`
  color: var(--c-white);
  flex-wrap: wrap;
  gap: var(--half-unit);
`;

const FootballCardProperties = ({
  card,
  withTransferMalus,
  stats,
  supensionsAndInjuries,
}: Props) => {
  return (
    <Root>
      {stats}
      <PlayerScore
        player={card.anyPlayer}
        mode={AveragePlayerScore.LAST_FIFTEEN_SO5_AVERAGE_SCORE}
      />
      <CardBonus card={card} withTransferMalus={withTransferMalus} />
      {card.u23Eligible && <U23Eligible />}
      <ItemEligibility cards={[card]} />
      {supensionsAndInjuries}
    </Root>
  );
};

FootballCardProperties.fragments = {
  card: gql`
    fragment FootballCardProperties_card on Card {
      slug
      u23Eligible
      anyPlayer {
        slug
        ...PlayerScore_anyPlayer
      }
      ...ItemEligibility_card
      ...CardBonus_anyCard
    }
    ${CardBonus.fragments.anyCard}
    ${ItemEligibility.fragments.card}
    ${PlayerScore.fragments.anyPlayer}
  ` as TypedDocumentNode<FootballCardProperties_card>,
};

export default FootballCardProperties;
