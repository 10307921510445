import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { Title5 } from '@sorare/core/src/atoms/typography';
import { useSnackNotificationContext } from '@sorare/core/src/contexts/snackNotification';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { sendSafeError } from '@sorare/core/src/lib/error';
import { payment } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { getCurrenciesFromSettlementCurrencies } from '@sorare/core/src/lib/settlementCurrencies';

import LazyPaymentProvider from 'components/buyActions/LazyPaymentProvider';
import { useBuyConfirmationContext } from 'contexts/buyingConfirmation';
import useAcceptOffer from 'hooks/offers/useAcceptOffer';

import CardOverview from '../PaymentBox/CardOverview';
import {
  BuyPaymentFlowQuery,
  BuyPaymentFlowQueryVariables,
} from './__generated__/index.graphql';

export interface Props {
  onClose: () => void;
  offerId: string;
}

const BUY_PAYMENT_FLOW_QUERY = gql`
  query BuyPaymentFlowQuery($id: String!) {
    tokens {
      offer(id: $id) {
        id
        settlementCurrencies
        endDate
        sender {
          ... on User {
            slug
            nickname
          }
        }
        senderSide {
          id
          anyCards {
            slug
            sport
            ...CardOverview_anyCard
          }
        }
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
    }
  }
  ${monetaryAmountFragment}
  ${CardOverview.fragments.anyCard}
` as TypedDocumentNode<BuyPaymentFlowQuery, BuyPaymentFlowQueryVariables>;

const BuyPaymentFlow = ({ offerId, onClose }: Props) => {
  const { showNotification } = useSnackNotificationContext();
  const { setShowBuyingConfirmation } = useBuyConfirmationContext();
  const acceptOffer = useAcceptOffer();

  const { data, error } = useQuery(BUY_PAYMENT_FLOW_QUERY, {
    variables: {
      id: idFromObject(offerId),
    },
  });

  const liveSingleSaleOffer = data?.tokens?.offer;

  if (error) {
    // This should not happen. Temporary added for monitoring purposes.
    sendSafeError(`Offer not found for id: ${offerId}`);
    showNotification('errors', { errors: [error.message] });
    onClose();
    return null;
  }

  const onPaymentSuccess = useCallback(() => {
    setShowBuyingConfirmation(true);
    onClose();
  }, [setShowBuyingConfirmation, onClose]);

  // Don't show the offer to users if it is not live yet
  if (!liveSingleSaleOffer) return null;

  const { id, receiverSide, settlementCurrencies, sender, senderSide } =
    liveSingleSaleOffer;
  const { amounts } = receiverSide;

  const { anyCards } = senderSide;

  const buyWithEth = async ({
    supportedCurrency,
  }: {
    supportedCurrency: SupportedCurrency;
  }) => {
    const errors = await acceptOffer({
      offerId: id,
      supportedCurrency,
      receiveCards: [],
      attemptReference: null,
    });
    if (!errors || errors.length === 0) {
      return onPaymentSuccess();
    }
    return { err: errors };
  };

  return (
    <LazyPaymentProvider
      paymentProps={{
        objectId: id,
        onSuccess: onPaymentSuccess,
        onSubmit: buyWithEth,
        price: amounts,
        cta: payment.confirmAndPay,
        applyCreditCardFees: true,
        currencies: getCurrenciesFromSettlementCurrencies(settlementCurrencies),
        sport: anyCards[0].sport,
        seller: ('nickname' in sender && sender?.nickname) || undefined,
        useCheckout: true,
        usePaypal: false,
        canUseTopUpWalletWithCC: false,
        confirmationProviderStateProps: {
          tokenOfferId: liveSingleSaleOffer?.id,
        },
      }}
      paymentBoxProps={{
        onClose,
        title: (
          <Title5>
            <FormattedMessage {...payment.paymentBoxTitle} />
          </Title5>
        ),

        tokenPreview: <CardOverview cards={anyCards} />,
      }}
    />
  );
};

export default BuyPaymentFlow;
