import { TypedDocumentNode, gql } from '@apollo/client';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Currency, SupportedCurrency } from '__generated__/globalTypes';
import { IconButton } from 'atoms/buttons/IconButton';
import { Vertical } from 'atoms/layout/flex';
import { Text14, Text16 } from 'atoms/typography';
import { Dots } from 'atoms/ui/Dots';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { useToggleHideBalance } from 'hooks/useToggleHideBalance';
import { ETH_DECIMAL_PLACES, RoundingMode, fromWei, toWei } from 'lib/wei';

import { UserBalances } from './__generated__/index.graphql';

const Container = styled.div<{ $inline: boolean }>`
  display: flex;
  align-items: center;

  ${props =>
    props.$inline ? 'gap: var(--unit);' : `justify-content: space-between`};
`;

const Amount = styled.div`
  text-align: center;
  text-align: left;
`;

const Actions = styled.div`
  display: flex;
  gap: var(--unit);
`;

const Icon = styled(IconButton)<{ $inline: boolean }>`
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  min-width: auto;
  display: flex;
  margin-bottom: var(--double-unit);
  ${props => (props.$inline ? `margin-bottom: 0;` : '')}
`;

const Line = styled.div`
  display: flex;
  align-items: baseline;
  gap: var(--half-unit);
`;

const Column = styled(Vertical).attrs({ gap: 0.5 })``;

const MainXL = styled.span`
  font: var(--t-bold) var(--t-32);
`;

const Exponent = styled(Text14)`
  color: var(--c-nd-600);
`;

const ExponentXL = styled(Text16)`
  color: var(--c-nd-600);
`;

type UserBalanceAmountProps = {
  inline?: boolean;
  hideBalance?: boolean;
  availableBalance: bigint;
  primaryCurrency?: Currency;
};

const UserBalanceAmount = ({
  inline,
  hideBalance,
  availableBalance,
  primaryCurrency,
}: UserBalanceAmountProps) => {
  const roudedWeiValue = toWei(
    fromWei(availableBalance, ETH_DECIMAL_PLACES, RoundingMode.ROUND_DOWN)
  );

  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: {
      wei: roudedWeiValue,
      referenceCurrency: SupportedCurrency.WEI,
    },
    primaryCurrency,
  });

  if (inline)
    return (
      <Line>
        {main && (
          <Text14 color="var(--c-white)">
            {hideBalance ? <Dots size="small" count={7} /> : main}
          </Text14>
        )}
        {exponent && (
          <Exponent>
            {hideBalance ? <Dots size="small" count={7} /> : `≈ ${exponent}`}
          </Exponent>
        )}
      </Line>
    );
  return (
    <Column>
      {main && (
        <MainXL>{hideBalance ? <Dots size="medium" count={7} /> : main}</MainXL>
      )}
      {exponent && (
        <ExponentXL>
          {hideBalance ? <Dots size="small" count={7} /> : `≈ ${exponent}`}
        </ExponentXL>
      )}
    </Column>
  );
};

type Props = {
  inline?: boolean;
  right?: ReactNode;
  disableToggle?: boolean;
  primaryCurrency?: Currency;
};

export const UserBalance = ({
  inline,
  right,
  disableToggle = false,
  primaryCurrency,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { toggleHideBalance, loading } = useToggleHideBalance();

  if (!currentUser) return null;

  const { availableBalance } = currentUser;

  return (
    <Container $inline={!!inline}>
      <Amount>
        <UserBalanceAmount
          inline={!!inline}
          availableBalance={availableBalance}
          primaryCurrency={primaryCurrency}
          hideBalance={
            disableToggle ? false : currentUser?.userSettings?.hideBalance
          }
        />
      </Amount>
      <Actions>
        {!disableToggle && (
          <Icon
            color="quaternary"
            small
            disableDebounce
            icon={currentUser?.userSettings?.hideBalance ? faEye : faEyeSlash}
            onClick={() => toggleHideBalance()}
            $inline={!!inline}
            disabled={loading}
          />
        )}
        {right}
      </Actions>
    </Container>
  );
};

UserBalance.fragments = {
  balances: gql`
    fragment UserBalances on CurrentUser {
      slug
      availableBalance
      myAccounts {
        id
        accountable {
          ... on Node {
            id
          }
          ... on PrivateFiatWalletAccount {
            id
            availableBalance
          }
        }
      }
    }
  ` as TypedDocumentNode<UserBalances>,
};
