import { Position } from '@sorare/core/src/__generated__/globalTypes';

export type IndexedPosition =
  | 'Starting pitcher'
  | 'Relief pitcher'
  | 'First base'
  | 'Third base'
  | 'Designated hitter'
  | 'Catcher'
  | 'Second base'
  | 'Shortstop'
  | 'Outfield';

export const MAPPING = {
  'Starting pitcher': Position.baseball_starting_pitcher,
  'Relief pitcher': Position.baseball_relief_pitcher,
  'First base': Position.baseball_first_base,
  'Third base': Position.baseball_third_base,
  'Designated hitter': Position.baseball_designated_hitter,
  Catcher: Position.baseball_catcher,
  'Second base': Position.baseball_second_base,
  Shortstop: Position.baseball_shortstop,
  Outfield: Position.baseball_outfield,
};

export const REVERSE_MAPPING = {
  [Position.baseball_starting_pitcher]: 'Starting pitcher',
  [Position.baseball_relief_pitcher]: 'Relief pitcher',
  [Position.baseball_first_base]: 'First base',
  [Position.baseball_third_base]: 'Third base',
  [Position.baseball_designated_hitter]: 'Designated hitter',
  [Position.baseball_catcher]: 'Catcher',
  [Position.baseball_second_base]: 'Second base',
  [Position.baseball_shortstop]: 'Shortstop',
  [Position.baseball_outfield]: 'Outfield',
};

export const positions = [
  Position.baseball_starting_pitcher,
  Position.baseball_relief_pitcher,
  Position.baseball_first_base,
  Position.baseball_third_base,
  Position.baseball_designated_hitter,
  Position.baseball_catcher,
  Position.baseball_second_base,
  Position.baseball_shortstop,
  Position.baseball_outfield,
] as const;
