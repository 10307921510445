import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactElement } from 'react';
import styled from 'styled-components';

import { Block } from '@sorare/core/src/atoms/layout/Block';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { ANY_SPORT_PLAYERS_SLUG } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';

import PlayerAvatar from 'components/player/PlayerAvatar';

import { PlayerListItem_player } from './__generated__/index.graphql';

export interface Props {
  player: PlayerListItem_player;
  subtitle?: string;
  children?: ReactElement;
}

const Root = styled(Block)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Left = styled(Horizontal).attrs({ gap: 0 })``;
const Avatar = styled(PlayerAvatar)`
  height: 60px;
  width: 60px;
`;
const Text = styled.div`
  margin-left: 20px;
`;

export const PlayerListItem = ({ player, subtitle, children }: Props) => {
  const { sport } = useSportContext();
  const { slug, displayName } = player;

  return (
    <Root
      to={generateSportPath(ANY_SPORT_PLAYERS_SLUG, {
        sport,
        params: { slug },
      })}
    >
      <Left>
        <Avatar player={player} />
        <Text>
          <Text16 bold>{displayName}</Text16>
          <Text16 color="var(--c-nd-600)">{subtitle}</Text16>
        </Text>
      </Left>
      <div>{children}</div>
    </Root>
  );
};

PlayerListItem.fragments = {
  player: gql`
    fragment PlayerListItem_player on AnyPlayerInterface {
      slug
      displayName
      ...GameplayPlayerAvatar_anyPlayer
    }
    ${PlayerAvatar.fragments.anyPlayer}
  ` as TypedDocumentNode<PlayerListItem_player>,
};

export default PlayerListItem;
