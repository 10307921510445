import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { Whistle } from 'atoms/icons/Whistle';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { glossary } from 'lib/glossary';
import { hideScrollbar } from 'style/utils';

const Wrapper = styled(Horizontal)<{ selectedMinute?: number }>`
  ${hideScrollbar}
  padding: 0 0 var(--quadruple-unit) var(--triple-unit);
  --column-width: 2px;
  --column-height: 22px;
  --gap: var(--unit);
  gap: var(--gap);
  ${({ selectedMinute }) =>
    selectedMinute &&
    css`
      transform: translateX(
        calc(-${selectedMinute} * (var(--column-width) + var(--gap)))
      );
    `}
  transition: transform 0.5s ease-in-out;
`;

const MinuteColumn = styled.div`
  scroll-snap-align: center;
  width: var(--column-width);
  background: var(--c-nd-150);
  border-radius: 2px;
  height: var(--column-height);
  flex-shrink: 0;
  position: relative;
  isolation: isolate;
`;

const ActiveMinuteColumn = styled(MinuteColumn)`
  background: var(--c-white);
`;

const BottomContent = styled(Vertical).attrs({ center: true })`
  position: absolute;
  top: calc(var(--column-height) + var(--half-unit));
  left: 0;
  right: 0;
  margin: auto;
`;

const IconWrapper = styled.div`
  width: var(--unit);
  height: var(--unit);
  & > * {
    display: block;
    width: inherit;
    height: inherit;
  }
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;

const MinuteColumnWithInfo = ({
  label,
  icon,
}: {
  label: ReactNode;
  icon: ReactNode;
}) => {
  return (
    <ActiveMinuteColumn>
      <BottomContent>
        <IconWrapper>{icon}</IconWrapper>
        <LabelS className="text-center">{label}</LabelS>
      </BottomContent>
    </ActiveMinuteColumn>
  );
};

const StartMinuteColumn = () => {
  return (
    <MinuteColumnWithInfo
      label={
        <Uppercase>
          <FormattedMessage {...glossary.start} />
        </Uppercase>
      }
      icon={<Whistle />}
    />
  );
};

type Props = {
  timeline: {
    minute: number;
    icon: ReactNode;
    quarter?: ReactNode;
  }[];
  className?: string;
  selectedMinute?: number;
};

export const ScoreTimeline = ({
  timeline,
  className,
  selectedMinute,
}: Props) => {
  return (
    <Wrapper gap={0.5} className={className} selectedMinute={selectedMinute}>
      {new Array(92).fill(0).map((_, i) => {
        if (i === 0) {
          return <StartMinuteColumn key={0} />;
        }
        if (timeline.some(t => t.minute === i)) {
          const { icon, quarter = '' } = timeline.find(t => t.minute === i)!;
          const label = i > 90 ? `90'+${i - 90}` : `'${i}`;

          return (
            <MinuteColumnWithInfo
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              icon={icon}
              label={quarter || label}
            />
          );
        }

        // eslint-disable-next-line react/no-array-index-key
        return <MinuteColumn key={i} />;
      })}
    </Wrapper>
  );
};
