import { add, getUnixTime } from 'date-fns';
import { SearchResponse } from 'instantsearch.js';
import { useEffect, useState } from 'react';

import { Sport } from '__generated__/globalTypes';
import { AlgoliaCardIndexes, useConfigContext } from 'contexts/config';
import { useSearchClient } from 'hooks/search/useSearchClient';
import { useMemoArray } from 'hooks/useMemoArray';
import {
  joinFiltersWithAnd,
  joinFiltersWithOr,
  sportFilter,
} from 'lib/algolia';

interface Props {
  index?: keyof AlgoliaCardIndexes;
  teamSlug?: string;
  playerSlugs?: string[];
  specialEdition?: string;
  rarities?: string[];
  season?: string | number;
  sport?: Sport;
  primaryMarket?: boolean;
  withBundled?: boolean;
  limit?: number;
  minMinutesBeforeSaleEnd?: number;
  distinct?: boolean;
  skip?: boolean;
  primaryOffer?: boolean;
  activeLeagues?: string[];
  optionalFilters?: string | string[];
}

interface HitOnSale {
  slug: string;
}

export const useCardsOnSale = (props: Props) => {
  const {
    index = 'Lowest Price',
    teamSlug,
    playerSlugs = [],
    specialEdition,
    activeLeagues,
    rarities = [],
    season,
    primaryMarket,
    withBundled,
    limit,
    sport,
    minMinutesBeforeSaleEnd,
    distinct = false,
    skip = false,
    primaryOffer = false,
    optionalFilters = undefined,
  } = props;
  const [cards, setCards] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [nbHits, setNbHits] = useState<number>(0);
  const searchClient = useSearchClient();
  const { algoliaIndexes } = useConfigContext();

  // Necessary to avoid infinite loop
  const raritiesStringified = rarities.join(',');
  const playerSlugsStringified = playerSlugs.join(',');

  useEffect(() => {
    if (skip) return;
    const parsedRarities = raritiesStringified.split(',');
    const parsedPlayerSlugs = playerSlugsStringified.split(',');
    const isPrimaryMarket = primaryMarket !== undefined;
    const isInstantBuy =
      !isPrimaryMarket && primaryOffer && activeLeagues?.length;
    setLoading(true);
    searchClient
      .search<HitOnSale>([
        {
          indexName: algoliaIndexes[index],
          type: 'default',
          params: {
            analytics: false,
            distinct,
            hitsPerPage: limit || 10,
            attributesToRetrieve: ['slug', 'asset_id'],
            optionalFilters,
            facets: [],
            filters: joinFiltersWithAnd(
              [
                sport && sportFilter(sport),
                'on_sale:true',
                specialEdition && `card_edition.display_name:${specialEdition}`,
                minMinutesBeforeSaleEnd &&
                  `sale.end_date > ${getUnixTime(
                    add(new Date(), { minutes: minMinutesBeforeSaleEnd })
                  )}`,
                isPrimaryMarket &&
                  !isInstantBuy &&
                  `sale.primary:${primaryMarket}`,
                `sale.bundled:${!!withBundled}`,
                playerSlugsStringified &&
                  joinFiltersWithOr(
                    parsedPlayerSlugs.map(
                      (playerSlug: string) => `player.slug:${playerSlug}`
                    )
                  ),
                teamSlug && `team.slug:${teamSlug}`,
                raritiesStringified &&
                  joinFiltersWithOr(
                    parsedRarities.map((rarity: string) => `rarity:${rarity}`)
                  ),
                season && `season:${season}`,
                isInstantBuy &&
                  joinFiltersWithAnd([
                    'sale.primary:true',
                    'sale.type:PrimaryOffer',
                  ]),
                isInstantBuy &&
                  joinFiltersWithOr(
                    activeLeagues.map(
                      league => `active_league.display_name:"${league}"`
                    )
                  ),
              ].filter(Boolean)
            ),
          },
        },
      ])
      .then(({ results }) => {
        setCards(
          (results[0] as SearchResponse<HitOnSale>).hits.map(hit => hit.slug)
        );
        setNbHits((results[0] as SearchResponse<HitOnSale>).nbHits);
        setLoading(false);
      });
  }, [
    algoliaIndexes,
    index,
    limit,
    playerSlugsStringified,
    primaryMarket,
    primaryOffer,
    activeLeagues,
    raritiesStringified,
    season,
    searchClient,
    sport,
    teamSlug,
    withBundled,
    distinct,
    skip,
    minMinutesBeforeSaleEnd,
    optionalFilters,
    specialEdition,
  ]);

  return {
    loading,
    slugs: useMemoArray(cards),
    nbHits,
  };
};
