import { useCallback } from 'react';

import { Sport } from '__generated__/globalTypes';
import { useDefaultSportPages } from 'constants/routes';
import { useConnectionContext } from 'contexts/connection';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useEvents } from 'lib/events/useEvents';

export const useSportCTAProps = (sport: Sport) => {
  const track = useEvents();

  const trackClickPlayNow = useCallback(() => {
    track('Click Play Now', {
      sport,
    });
  }, [sport, track]);
  const { currentUser } = useCurrentUserContext();

  const { signUp } = useConnectionContext();
  const defaultSportPages = useDefaultSportPages();

  return currentUser
    ? {
        to: defaultSportPages[sport],
        onClick: () => {
          trackClickPlayNow();
        },
      }
    : {
        onClick: () => {
          trackClickPlayNow();
          return signUp();
        },
      };
};
