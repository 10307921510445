import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { LinkOther } from '@sorare/core/src/atoms/navigation/Box';
import { BodyS } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';

import { TransferType } from '../types';

const WarningWrapper = styled.div`
  border-radius: var(--unit);
  padding: var(--unit) var(--intermediate-unit);
  background: rgba(var(--c-rgb-red-600), 0.1);
  border: 1px solid var(--c-static-red-300);
  color: var(--c-white);
`;

const Row = styled.div`
  display: flex;
  align-items: baseline;
  gap: var(--unit);
`;

const IconWrapper = styled.div`
  font: var(--t-14);
`;

const Error = styled.b`
  color: var(--c-static-red-300);
`;

const ErrorBold = (...chunks: ReactNode[]) => <Error>{chunks}</Error>;

const messages = defineMessages({
  listTitle: {
    id: 'So5TransferValidator.listTitle',
    defaultMessage: 'Listing this card will unregister:',
  },
  sendTradeTitle: {
    id: 'So5TransferValidator.sendTradeTitle',
    defaultMessage: 'Sending a trade including this card will unregister:',
  },
  receiveTradeTitle: {
    id: 'So5TransferValidator.receiveTradeTitle',
    defaultMessage: 'Accepting this trade will unregister:',
  },
  liveLineupWarning: {
    id: 'So5TransferValidator.liveLineupWarning',
    defaultMessage: '<e>Live</e> <b>{competition}</b> lineup',
  },
  upcomingLineupWarning: {
    id: 'So5TransferValidator.upcomingLineupWarning',
    defaultMessage: 'Upcoming <b>{competition}</b> lineup',
  },
});

const TITLE_MAPS = {
  list: messages.listTitle,
  send_trade: messages.sendTradeTitle,
  receive_trade: messages.receiveTradeTitle,
};

type Props = {
  transferType: TransferType;
  openedLineups: string[];
  liveLineup?: string;
  lineupUrl?: string;
};

export const UsedCardWarning = ({
  openedLineups,
  liveLineup,
  lineupUrl,
  transferType,
}: Props) => {
  const lineupLink = (...text: ReactNode[]) => (
    <LinkOther target="_blank" rel="noreferrer" href={lineupUrl!}>
      {Bold(text)}
    </LinkOther>
  );

  if (openedLineups.length === 0 && liveLineup === undefined) {
    return null;
  }

  return (
    <WarningWrapper>
      <Row>
        <IconWrapper>
          <FontAwesomeIcon
            icon={faWarning}
            color="var(--c-static-red-300)"
            size="sm"
          />
        </IconWrapper>
        <BodyS as="div">
          <FormattedMessage
            {...TITLE_MAPS[transferType]}
            values={{
              count: openedLineups.length + (liveLineup ? 1 : 0),
            }}
          />
        </BodyS>
      </Row>
      {liveLineup && (
        <Row>
          <BodyS as="div">
            <FormattedMessage
              {...messages.liveLineupWarning}
              values={{
                competition: liveLineup,
                b: lineupUrl ? lineupLink : Bold,
                e: ErrorBold,
              }}
            />
          </BodyS>
        </Row>
      )}
      {openedLineups.map(lineup => (
        <Row key={lineup}>
          <BodyS as="div">
            <FormattedMessage
              {...messages.upcomingLineupWarning}
              values={{
                competition: lineup,
                b: lineupUrl ? lineupLink : Bold,
              }}
            />
          </BodyS>
        </Row>
      ))}
    </WarningWrapper>
  );
};
