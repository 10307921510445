import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  CardCoverageStatus,
  SeasonEligibility,
  Sport,
} from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  BodyL,
  HeadlineL,
  HeadlineXS,
} from '@sorare/core/src/atoms/typography';
import { isType } from '@sorare/core/src/gql';
import { seasonEligibilityMessages } from '@sorare/core/src/lib/glossary';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { EligibleCompetition } from './EligibleCompetition';
import { CompetitionsEligibility_anyCard } from './__generated__/index.graphql';
import useCompetitionsEligibility from './useCompetitionsEligibility';

const Root = styled(Vertical).attrs({ gap: 2 })``;

const Block = styled(Vertical)`
  @media ${laptopAndAbove} {
    flex-direction: row;
    height: 100%;
  }
`;

const Column = styled(Vertical)`
  width: 100%;
  @media ${laptopAndAbove} {
    width: 50%;
    align-items: stretch;
  }
`;

const InSeasonBodyL = styled(BodyL)`
  background: linear-gradient(
    92deg,
    #fff 0%,
    #eabfe2 28.88%,
    #fff 48.67%,
    #bedecb 64.58%,
    #fff 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const NotEligible = styled(Horizontal).attrs({ gap: 0 })`
  padding: var(--double-unit);
  border-radius: var(--double-unit);
  background: linear-gradient(
      160deg,
      rgba(22, 27, 28, 0) 35.97%,
      rgba(255, 168, 0, 0.12) 53.25%,
      rgba(62, 65, 66, 0) 68.55%
    ),
    #231b1b;
  height: 100%;
  width: 100%;
  justify-content: center;
`;

type Props = { card: CompetitionsEligibility_anyCard };

export const CompetitionsEligibility = ({ card }: Props) => {
  const { inSeasonLeaderboards, classicCompetitions } =
    useCompetitionsEligibility(card);

  if (
    (inSeasonLeaderboards.length === 0 && classicCompetitions.length === 0) ||
    card.sport !== Sport.FOOTBALL ||
    (isType(card, 'Card') &&
      card.coverageStatus === CardCoverageStatus.NOT_COVERED)
  )
    return null;
  return (
    <Root>
      <HeadlineL as="h3">
        <FormattedMessage
          id="CompetitionsEligibility.title"
          defaultMessage="Competitions eligibility"
        />
      </HeadlineL>
      <Block gap={2}>
        <Column gap={1}>
          {card.inSeasonEligible ? (
            <InSeasonBodyL as="h6">
              {card.inSeasonUntil ? (
                <FormattedMessage
                  id="CompetitionsEligibility.until"
                  defaultMessage="In season until {date}"
                  values={{
                    date: (
                      <FormattedDate
                        value={card.inSeasonUntil}
                        month="short"
                        day="2-digit"
                      />
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  {...seasonEligibilityMessages[SeasonEligibility.IN_SEASON]}
                />
              )}
            </InSeasonBodyL>
          ) : (
            <BodyL as="h6">
              <FormattedMessage
                {...seasonEligibilityMessages[SeasonEligibility.IN_SEASON]}
              />
            </BodyL>
          )}
          {inSeasonLeaderboards.length > 0 ? (
            <Vertical gap={2}>
              {inSeasonLeaderboards.map(so5Leaderboard => (
                <EligibleCompetition
                  key={so5Leaderboard.slug}
                  so5Leaderboard={so5Leaderboard}
                  inSeason
                />
              ))}
            </Vertical>
          ) : (
            <NotEligible>
              <HeadlineXS as="p" color="var(--c-nd-700)">
                <FormattedMessage
                  id="CompetitionsEligibility.max1classicPerTeam"
                  defaultMessage="Max 1 Classic Card per team"
                />
              </HeadlineXS>
            </NotEligible>
          )}
        </Column>
        <Column gap={1}>
          <BodyL as="h6">
            <FormattedMessage
              {...seasonEligibilityMessages[SeasonEligibility.CLASSIC]}
            />
          </BodyL>
          <Vertical gap={2}>
            {classicCompetitions.map(so5Leaderboard => (
              <EligibleCompetition
                key={so5Leaderboard.slug}
                so5Leaderboard={so5Leaderboard}
              />
            ))}
          </Vertical>
        </Column>
      </Block>
    </Root>
  );
};

CompetitionsEligibility.fragments = {
  anyCard: gql`
    fragment CompetitionsEligibility_anyCard on AnyCardInterface {
      slug
      sport
      inSeasonEligible
      inSeasonUntil
      eligibleUpcomingLeagueTracks {
        slug
        so5Leaderboards {
          slug
          ...EligibleCompetition_so5Leaderboard
        }
      }
      ... on Card {
        slug
        coverageStatus
      }
      ...useCompetitionsEligibility_anyCard
    }
    ${EligibleCompetition.fragments.so5Leaderboard}
    ${useCompetitionsEligibility.fragments.anyCard}
  ` as TypedDocumentNode<CompetitionsEligibility_anyCard>,
};
