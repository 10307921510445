import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { Marquee } from 'atoms/container/Marquee';
import { Horizontal } from 'atoms/layout/flex';

import christmas_limited from './assets/christmas/limited.jpg';
import christmas_rare from './assets/christmas/rare.jpg';
import christmas_super_rare from './assets/christmas/super_rare.jpg';
import christmas_unique from './assets/christmas/unique.jpg';
import common from './assets/common.jpg';
import custom_series from './assets/custom_series.jpg';
import limited from './assets/limited.jpg';
import rare from './assets/rare.jpg';
import super_rare from './assets/super_rare.jpg';
import unique from './assets/unique.jpg';

const BackgroundContainer = styled(Horizontal)`
  position: absolute;
  inset: 0;
  display: flex;
  z-index: -1;
`;

const Gradient = styled.div`
  width: 450px;
  max-width: 100%;
  background-size: 100%;
  height: 100%;
`;

const christmasAssets = {
  [Rarity.common]: common,
  [Rarity.custom_series]: custom_series,
  [Rarity.limited]: christmas_limited,
  [Rarity.rare]: christmas_rare,
  [Rarity.super_rare]: christmas_super_rare,
  [Rarity.unique]: christmas_unique,
};

const classic_assets = {
  [Rarity.common]: common,
  [Rarity.custom_series]: custom_series,
  [Rarity.limited]: limited,
  [Rarity.rare]: rare,
  [Rarity.super_rare]: super_rare,
  [Rarity.unique]: unique,
};

type Props = {
  rarity: Rarity;
  christmas?: boolean;
};

export const ScarcityBackground = ({ rarity, christmas }: Props) => {
  const assets = christmas ? christmasAssets : classic_assets;
  const duration = christmas ? '50s' : '25s';
  return (
    <BackgroundContainer>
      <Marquee direction="reverse" gap={0} duration={duration} stretch>
        <Gradient
          style={{
            backgroundImage: `url(${assets[rarity]})`,
            ...(christmas && { width: '900px' }),
          }}
        />
      </Marquee>
    </BackgroundContainer>
  );
};
