import { ComponentProps, PropsWithChildren } from 'react';
import { generatePath } from 'react-router-dom';

import { Link } from '@sorare/routing';

import {
  FOOTBALL_CLUBS_SLUG,
  FOOTBALL_COUNTRIES_COUNTRYCODE,
} from 'constants/__generated__/routes';

type Team =
  | {
      slug: string;
      country: {
        slug: string;
      };
    }
  | null
  | undefined;

type Props = { team: Team; isNationalTeam: boolean } & Omit<
  ComponentProps<typeof Link>,
  'to'
>;
export const FootballTeamLink = ({
  team,
  isNationalTeam,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  if (!team) {
    return children;
  }

  const link = generatePath(
    isNationalTeam ? FOOTBALL_COUNTRIES_COUNTRYCODE : FOOTBALL_CLUBS_SLUG,
    { countryCode: team.country.slug, slug: team.slug }
  );

  return (
    <Link to={link} {...rest}>
      {children}
    </Link>
  );
};
