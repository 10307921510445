import styled from 'styled-components';

import { CircularProgress } from 'atoms/loader/CircularProgress';
import { LabelL, LabelS } from 'atoms/typography';

const Wrapper = styled.div`
  display: flex;
  gap: var(--half-unit);
`;

const StyledProgress = styled(CircularProgress)`
  width: var(--intermediate-unit);
  --circular-progress-stroke: var(--c-green-800);
`;

type Props = { ownedCards: number; totalCards: number };

export const CardsNumber = ({ ownedCards, totalCards }: Props) => {
  return (
    <Wrapper>
      <span>
        <LabelL bold as="span">
          {ownedCards}
        </LabelL>
        <LabelS as="span" color="var(--c-nd-700)">
          /{totalCards}
        </LabelS>
      </span>

      <StyledProgress maxProgress={totalCards} progress={ownedCards} />
    </Wrapper>
  );
};
