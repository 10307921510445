import { TypedDocumentNode, gql } from '@apollo/client';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
  useIntl,
} from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { InlineEditionInput } from '@sorare/core/src/atoms/inputs/InlineEditionInput';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tab, TabBar } from '@sorare/core/src/atoms/navigation/TabBar';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { Bold } from '@sorare/core/src/atoms/typography/Bold';
import { Card } from '@sorare/core/src/components/card/Card';
import { CardImg } from '@sorare/core/src/components/card/CardImg';
import Dialog from '@sorare/core/src/components/dialog';
import { ButtonWithConfirmDialog } from '@sorare/core/src/components/form/ButtonWithConfirmDialog';
import Warning from '@sorare/core/src/contexts/intl/Warning';
import useAddTokensToDeck from '@sorare/core/src/hooks/decks/useAddTokensToDeck';
import useCreateDeck from '@sorare/core/src/hooks/decks/useCreateDeck';
import { useDecks } from '@sorare/core/src/hooks/decks/useDecks';
import useDeleteDeck from '@sorare/core/src/hooks/decks/useDeleteDeck';
import useEditDeck from '@sorare/core/src/hooks/decks/useEditDeck';
import useRemoveTokenFromDeck from '@sorare/core/src/hooks/decks/useRemoveTokenFromDeck';
import { range } from '@sorare/core/src/lib/arrays';
import { filters, glossary } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import {
  DeckEditorDialog_anyCard,
  DeckEditorDialog_deck,
} from './__generated__/index.graphql';

const messages = defineMessages({
  enterAListName: {
    id: 'EditDeckCards.enterAListName',
    defaultMessage: 'Enter a list name',
  },
});

const Root = styled(Vertical).attrs({ gap: 0 })`
  --root-padding: var(--double-unit);
  height: 100%;
  padding: 0 var(--root-padding) var(--root-padding);
  color: var(--c-white);
`;
const Sticky = styled(Vertical).attrs({ gap: 2 })`
  z-index: 1;
  position: sticky;
  top: 0;
  background-color: var(--c-nd-50);
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  padding-right: var(--double-unit);
  overflow: hidden;

  @media ${tabletAndAbove} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const Actions = styled(Horizontal).attrs({ gap: 2 })``;
const Filters = styled.div`
  padding: var(--unit) 0;
`;
const Cards = styled.section`
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: var(--unit);
`;
const CardWrapper = styled(ButtonBase)`
  padding: var(--unit);
  display: flex;
  &.selected {
    background-color: rgba(var(--c-rgb-brand-600), 0.5);
    border: 2px solid var(--c-brand-600);
    border-radius: var(--unit);
  }
  &.placeholder {
    opacity: 0.1;
  }
`;
const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--c-nd-100);
  padding: var(--double-unit);
`;

type Props<T extends string> = {
  deck?: DeckEditorDialog_deck | null;
  defaultName?: string;
  sport: Sport;
  cards: DeckEditorDialog_anyCard[];
  tokensLoading: boolean;
  infiniteScrollLoader: ReactNode;
  positions?: T[];
  positionsMessages?: Record<T, MessageDescriptor> | Record<T, string>;
  selectedPosition?: T | undefined;
  onSelectedPositionChange?: (selected: T | undefined) => void;
  onClose: () => void;
  onCreateDeck?: (slug: string) => void;
};

const useSelectedSlugs = (deckSlugs: string[]) => {
  const [selectedSlugs, setSelectedSlugs] = useState<string[]>(deckSlugs);

  useEffect(() => {
    if (deckSlugs !== selectedSlugs) {
      setSelectedSlugs(deckSlugs);
    }
    // do not re-sync when selectedSlugs changes since it's from user interaction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckSlugs]);

  return [selectedSlugs, setSelectedSlugs] as const;
};

export const DeckEditorDialog = <T extends string>({
  deck,
  defaultName,
  sport,
  positions,
  positionsMessages,
  selectedPosition,
  onSelectedPositionChange,
  cards,
  tokensLoading,
  infiniteScrollLoader,
  onClose,
  onCreateDeck,
}: Props<T>) => {
  const { formatMessage } = useIntl();
  const creating = !deck?.slug;
  const { decks, loading } = useDecks({
    sport,
    query: '',
  });

  const uniqueDefaultName = useMemo(() => {
    if (!creating) return defaultName;
    const reservedNames = decks.map(deckItem => {
      return deckItem.name;
    });
    let name = defaultName || '';
    let index = 0;
    while (reservedNames.includes(name)) {
      index += 1;
      name = `${defaultName} ${index}`;
    }
    return name;
  }, [defaultName, creating, decks]);

  const deckSlugs = useMemo(
    () =>
      cards
        .filter(card => card.decks.some(d => d.id === deck?.id))
        .map(({ slug }) => slug),
    [deck?.id, cards]
  );

  const [inputName, setInputName] = useState(
    deck?.name || uniqueDefaultName || ''
  );
  const [selectedSlugs, setSelectedSlugs] = useSelectedSlugs(deckSlugs);
  const [deckHasChanged, setDeckHasChanged] = useState(false);
  const [submittingCards, setSubmittingCards] = useState(false);
  const [visible, setVisible] = useState(deck ? deck.visible : true);

  const createDeck = useCreateDeck();
  const deleteDeck = useDeleteDeck();
  const editDeck = useEditDeck();
  const addTokensToDeck = useAddTokensToDeck();
  const removeTokenFromDeck = useRemoveTokenFromDeck();

  const onSelectedSlugChange = useCallback(
    (slugs: string[]) => {
      setDeckHasChanged(true);
      setSelectedSlugs(slugs);
    },
    [setSelectedSlugs]
  );

  const onSubmitName = useCallback(async () => {
    if (creating) return;
    editDeck(deck, { name: inputName.trim(), visible: deck.visible });
    setDeckHasChanged(true);
  }, [creating, deck, editDeck, inputName]);

  const createDeckCallback = useCallback(async () => {
    const { data } = await createDeck({
      sport,
      name: inputName.trim(),
      visible,
    });
    const newDeck = data?.createDeck?.deck;
    if (newDeck) {
      onCreateDeck?.(newDeck.slug);
    }
    return newDeck;
  }, [createDeck, inputName, onCreateDeck, sport, visible]);

  const onVisibleChange = useCallback(async () => {
    setVisible(!visible);
    if (!deck) {
      return;
    }
    editDeck(deck, { name: deck.name, visible: !deck.visible });
    setDeckHasChanged(true);
  }, [deck, visible, editDeck]);

  const onSubmitCards = useCallback(async () => {
    let actualDeck = deck;
    if (!deck?.slug) {
      actualDeck = await createDeckCallback();
    }
    if (!actualDeck?.slug) return;

    setSubmittingCards(true);
    const toAdd = selectedSlugs?.filter(c => !deckSlugs?.includes(c));
    const toRemove = deckSlugs?.filter(c => !selectedSlugs?.includes(c));
    if (toAdd?.length) {
      await addTokensToDeck(actualDeck.slug)(toAdd);
    }
    if (toRemove?.length) {
      await Promise.all(
        toRemove.map(async assetId => {
          if (actualDeck) {
            removeTokenFromDeck(actualDeck.slug)(assetId);
          }
        })
      );
    }
    setSubmittingCards(false);
    onClose();
  }, [
    createDeckCallback,
    addTokensToDeck,
    deck,
    deckSlugs,
    onClose,
    removeTokenFromDeck,
    selectedSlugs,
  ]);

  const onDestroy = useCallback(() => {
    if (!deck) return;

    deleteDeck(deck);
    onClose();
  }, [deck, deleteDeck, onClose]);

  useEffect(() => {
    if (deck?.name) {
      setInputName(deck.name);
    }
  }, [deck]);

  return (
    <Dialog
      open
      onClose={onClose}
      scroll="paper"
      footer={
        <Footer>
          <LabelM>
            <FormattedMessage
              defaultMessage="<b>{nb}</b> {nb, plural, one {card} other {cards}} selected"
              id="EditDeckCards.playerListed"
              values={{ nb: selectedSlugs.length, b: Bold }}
            />
          </LabelM>
          <div>
            <Button
              color="transparent"
              size="medium"
              onClick={onClose}
              disabled={creating}
            >
              <FormattedMessage {...glossary.cancel} />
            </Button>
            <LoadingButton
              color="primary"
              size="medium"
              onClick={() => {
                onSubmitCards();
              }}
              loading={submittingCards}
              disabled={!deckHasChanged}
            >
              <FormattedMessage {...glossary.confirm} />
            </LoadingButton>
          </div>
        </Footer>
      }
      title={
        <Header>
          {!loading && (
            <InlineEditionInput
              initialEditing={creating}
              value={inputName}
              onChange={event => setInputName(event.target.value)}
              maxLength={80}
              onConfirm={() => {
                onSubmitName();
              }}
              onCancel={() => {
                setInputName(deck?.name || '');
              }}
              placeholder={formatMessage(messages.enterAListName)}
            />
          )}
          <Actions>
            <Button
              color={deck?.visible ? 'secondary' : 'tertiary'}
              size="medium"
              onClick={() => {
                onVisibleChange();
              }}
            >
              <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />
              <span>
                {visible ? (
                  <FormattedMessage
                    id="DeckEditorDialog.public"
                    defaultMessage="Public"
                  />
                ) : (
                  <FormattedMessage
                    id="DeckEditorDialog.private"
                    defaultMessage="Private"
                  />
                )}
              </span>
            </Button>
            {!creating && (
              <ButtonWithConfirmDialog
                size="medium"
                color="red"
                onConfirm={onDestroy}
                message={
                  <span>
                    <FormattedMessage
                      id="DeckEditorDialog.areYouSureDestroy"
                      defaultMessage="Are you sure to delete the list <b>{list}</b> ?"
                      values={{ list: deck?.name, b: Bold }}
                    />
                  </span>
                }
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonWithConfirmDialog>
            )}
          </Actions>
        </Header>
      }
    >
      <Root>
        <Sticky>
          {positions && positionsMessages && onSelectedPositionChange && (
            <Filters>
              <TabBar value={selectedPosition} variant="secondary">
                <Tab
                  key={undefined}
                  value={undefined}
                  onClick={() => onSelectedPositionChange(undefined)}
                >
                  <FormattedMessage {...glossary.all} />
                </Tab>
                {positions.map(pos => {
                  const positionName = positionsMessages[pos] as
                    | MessageDescriptor
                    | string;

                  return (
                    <Tab
                      key={pos}
                      value={pos}
                      onClick={() => onSelectedPositionChange(pos)}
                    >
                      {typeof positionName === 'string' ? (
                        positionName
                      ) : (
                        <FormattedMessage {...positionName} />
                      )}
                    </Tab>
                  );
                })}
              </TabBar>
            </Filters>
          )}
        </Sticky>
        {!cards?.length && !tokensLoading && (
          <Warning variant="yellow" title={filters.noCardsFound} />
        )}
        <Cards>
          {cards?.map(card => {
            const isSelected = selectedSlugs.includes(card.slug);
            return (
              <CardWrapper
                key={card.slug}
                className={classNames({ selected: isSelected })}
                aria-label={card.slug}
                onClick={() => {
                  if (isSelected) {
                    onSelectedSlugChange(
                      selectedSlugs.filter(cardSlug => card.slug !== cardSlug)
                    );
                  } else {
                    onSelectedSlugChange([...selectedSlugs, card.slug]);
                  }
                }}
              >
                <Card card={card} />
              </CardWrapper>
            );
          })}
          {tokensLoading &&
            range(50).map(i => (
              <CardWrapper key={i} className="placeholder">
                <CardImg src="" width={160} alt="" />
              </CardWrapper>
            ))}
          {infiniteScrollLoader}
        </Cards>
      </Root>
    </Dialog>
  );
};

DeckEditorDialog.fragments = {
  deck: gql`
    fragment DeckEditorDialog_deck on Deck {
      id
      slug
      name
      visible
      sport
    }
  ` as TypedDocumentNode<DeckEditorDialog_deck>,
  anyCard: gql`
    fragment DeckEditorDialog_anyCard on AnyCardInterface {
      slug
      pictureUrl
      decks {
        slug
        id
      }
      ...Card_anyCard
    }
    ${Card.fragments.anyCard}
  ` as TypedDocumentNode<DeckEditorDialog_anyCard>,
};
