import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MangopayTransactionStatus } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import Dialog from 'components/dialog';
import { DialogKey } from 'components/navigation/WithDialogs';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import { useCloseDialog } from 'hooks/navigation/useCloseDialog';
import { useDialogParam } from 'hooks/navigation/useDialogParam';
import useFetchTransactionStatus from 'hooks/useFetchTransactionStatus';

import { DepositAcceptedContent } from '../DepositAcceptedContent';

const Root = styled(Vertical).attrs({ gap: 2, center: true })`
  padding: 0 var(--triple-unit) var(--triple-unit);
  text-align: center;
  justify-content: stretch;
`;

export const DepositAcceptedDialog = () => {
  const { currentUser } = useCurrentUserContext();
  const { toggleDrawer } = useWalletDrawerContext();
  const [open, setOpen] = useState(false);
  const [fetchTransactionStatus] = useFetchTransactionStatus();
  const transactionId = useDialogParam(DialogKey.paypalTransactionId);
  const closeDialog = useCloseDialog();
  const skip = !currentUser;

  useEffect(() => {
    async function fetchData() {
      if (!transactionId || open) return;
      const response = await fetchTransactionStatus({
        variables: {
          transactionId,
        },
      });
      if (
        response.data?.mangopay.remoteTransactionStatus?.status &&
        [
          MangopayTransactionStatus.CREATED,
          MangopayTransactionStatus.SUCCEEDED,
        ].includes(response.data.mangopay.remoteTransactionStatus.status)
      ) {
        setOpen(true);
        toggleDrawer();
      }
    }
    if (!skip) {
      fetchData();
    }
  }, [skip, transactionId, open, fetchTransactionStatus, toggleDrawer]);

  if (skip) return null;

  return (
    <Dialog
      open={open}
      onClose={() => {
        closeDialog(DialogKey.paypalTransactionId);
        setOpen(false);
      }}
      maxWidth="xs"
      fullWidth
    >
      <Root>
        <DepositAcceptedContent />
      </Root>
    </Dialog>
  );
};
