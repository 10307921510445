import { styled } from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { ButtonGroup } from '@sorare/core/src/atoms/buttons/ButtonGroup';
import useLocalStorage, {
  STORAGE,
} from '@sorare/core/src/hooks/useLocalStorage';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';

import { InlineLineupIcon } from './assets/InlineLineupIcon';
import { SpreadLineupIcon } from './assets/SpreadLineupIcon';

export type LineupStyle = 'inline' | 'spread';

const Wrapper = styled.div`
  display: inline-block;
  color: var(--c-white);
`;

type Props = {
  sport: Sport;
};

export const usePreferredLineupStyle = () => {
  // we may want to use a different key for each sport
  const key = STORAGE.nbaLineupStyle;
  return useLocalStorage<LineupStyle>(key, 'spread');
};

const LineupStyleSwitch = ({ sport }: Props) => {
  const track = useEvents();

  const [lineupStyle, setLineupStyle] = usePreferredLineupStyle();

  const toggle = (inline: boolean) => {
    const newStyle = inline ? 'inline' : 'spread';
    setLineupStyle(newStyle);
    track('Switch Lineup Style', {
      preferred: newStyle,
      sport,
    });
  };

  const currentValue = lineupStyle === 'inline' ? 1 : 0;

  return (
    <Wrapper>
      <ButtonGroup
        defaultValue={currentValue}
        onChange={index => currentValue !== index && toggle(index === 1)}
      >
        <SpreadLineupIcon />
        <InlineLineupIcon />
      </ButtonGroup>
    </Wrapper>
  );
};

export default LineupStyleSwitch;
