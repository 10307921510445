import classNames from 'classnames';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { switchCss } from 'atoms/icons/SwitchIcon/ui';
import { Color } from 'style/types';

type Props = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'id' | 'readOnly'
> & {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  $backgroundColor?: Color;
  name?: string;
  disabled?: boolean;
  small?: boolean;
};

const StyledInput = styled.input.attrs({ type: 'checkbox' })`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const StyledLabel = styled.label<{
  $backgroundColor?: Color;
}>`
  ${switchCss}
`;

export const Switch = ({
  checked,
  onChange,
  $backgroundColor,
  name,
  small,
  ...rest
}: Props) => {
  return (
    <StyledLabel
      $backgroundColor={$backgroundColor}
      role="checkbox"
      aria-checked={checked}
      className={classNames({ small })}
    >
      <StyledInput
        onChange={onChange}
        name={name}
        checked={checked}
        {...rest}
      />
    </StyledLabel>
  );
};
