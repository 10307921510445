import { ComponentType } from 'react';

import { useCurrentUserContext } from 'contexts/currentUser';
import {
  useRedirectToSignin,
  useRedirectToSignup,
} from 'hooks/auth/useRedirectToLandingWithAction';

export const RequireAuth: ComponentType<
  React.PropsWithChildren<{
    to?: string;
    redirectTo?: 'signin' | 'signup';
  }>
> = ({ children, to, redirectTo }) => {
  const { currentUser } = useCurrentUserContext();
  const redirectToSignin = useRedirectToSignin(to);
  const redirectToSignup = useRedirectToSignup(to);

  if (!currentUser) {
    return redirectTo === 'signup' ? redirectToSignup() : redirectToSignin();
  }

  return children;
};

export const requireAuth = <T extends ComponentType<unknown>>(Component: T) => {
  const RequireAuthWrapper = (props: any) => (
    <RequireAuth>
      <Component {...props} />
    </RequireAuth>
  );
  Object.keys(Component).forEach(key => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    RequireAuthWrapper[key] = Component[key];
  });
  return RequireAuthWrapper;
};
