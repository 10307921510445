import { Navigate } from '@sorare/routing';

import { FOOTBALL_PLAY_RIVALS_ARENA } from '@sorare/core/src/constants/__generated__/routes';

import { Onboarding } from 'components/rivals/Onboarding';
import { useRivalsOnboarding } from 'components/rivals/Onboarding/useRivalsOnboarding';

const OnboardingClubSelectionPage = () => {
  const { onboardingEnabled } = useRivalsOnboarding();

  if (onboardingEnabled) {
    return <Onboarding />;
  }

  return <Navigate to={FOOTBALL_PLAY_RIVALS_ARENA} replace />;
};
export default OnboardingClubSelectionPage;
