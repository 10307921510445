import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { isPast } from 'date-fns';
import { ReactElement, ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  LoadingButton,
  Props as LoadingButtonProps,
} from '@sorare/core/src/atoms/buttons/LoadingButton';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import {
  AndroidAppFeature,
  IOsAppFeature,
  NotMobileAppFeature,
  UnvailableButtonForAndroid,
} from '@sorare/core/src/components/BuyableFeature';
import useLoggedCallback from '@sorare/core/src/hooks/useLoggedCallback';
import { useMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { useTokenOfferBelongsToUser } from '@sorare/core/src/hooks/useTokenOfferBelongsToUser';
import { useHandleWalletStateBeforePayment } from '@sorare/core/src/hooks/wallets/useHandleWalletStateBeforePayment';
import { glossary, mobileApp } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import CancelOffer from 'components/buyActions/CancelOffer';
import { useClickBuyEvent } from 'hooks/events/useClickBuyEvent';
import useCannotBuy from 'hooks/offers/useCannotBuy';
import useCannotSettleInFiat from 'hooks/offers/useCannotSettleInFiat';

import BuyPaymentFlow from '../BuyPaymentFlow';
import { BuyField_anyCard } from './__generated__/index.graphql';

export interface Props {
  color?: LoadingButtonProps['color'];
  cancelColor?: LoadingButtonProps['color'];
  card: BuyField_anyCard;
  fullWidth?: boolean;
  buttonLabel?: ReactNode;
  renderButton?: (props: Partial<LoadingButtonProps>) => ReactElement;
  variant: 'small' | 'medium';
}

const Buy = styled(LoadingButton)`
  flex-shrink: 0;
`;

const BuyTooltip = styled(Tooltip)`
  &.fullWidth {
    width: 100%;
  }
`;

const BuyField = ({
  card,
  variant,
  color = 'primary',
  fullWidth = false,
  buttonLabel = <FormattedMessage {...glossary.buy} />,
  renderButton,
}: Props) => {
  const handleWalletStateBeforePayment = useHandleWalletStateBeforePayment();
  const belongsToUser = useTokenOfferBelongsToUser();
  const cannotBuy = useCannotBuy();
  const cannotSettleInFiat = useCannotSettleInFiat();

  const { formatMessage } = useIntl();
  const { toMonetaryAmount } = useMonetaryAmount();
  const [paymentStarted, setPaymentStarted] = useState<boolean>(false);
  const loggedTogglePaymentStarted = useLoggedCallback<boolean>(b =>
    handleWalletStateBeforePayment(() => {
      setPaymentStarted(b);
    })
  );

  const trackClickBuy = useClickBuyEvent();

  const { liveSingleSaleOffer, myMintedSingleSaleOffer } = card;

  const cannotBuyToken = cannotBuy(card);
  const cannotBuyTokenWithFiat = cannotSettleInFiat(card);
  const disableBuyButtonMessage =
    cannotBuyToken || cannotBuyTokenWithFiat || false;

  // Give the possibility for owner of the card to cancel his offer before it is live
  if (myMintedSingleSaleOffer && belongsToUser(myMintedSingleSaleOffer))
    return (
      <NotMobileAppFeature>
        <CancelOffer
          color="red"
          variant={variant}
          card={card}
          fullWidth={fullWidth}
        />
      </NotMobileAppFeature>
    );

  // Don't show the offer to users if it is not live yet
  if (!liveSingleSaleOffer) return null;

  const { id, endDate, receiverSide } = liveSingleSaleOffer;
  const { amounts } = receiverSide;

  // Don't show the button if the offer expired
  if (isPast(endDate)) return null;

  const monetaryAmount = toMonetaryAmount(amounts);

  const onClick = () => {
    trackClickBuy(
      id,
      monetaryAmount.wei,
      monetaryAmount.eur / 100,
      [card],
      card.sport
    );
    return loggedTogglePaymentStarted(true);
  };

  return (
    <>
      <BuyTooltip
        className={classNames({ fullWidth })}
        title={
          disableBuyButtonMessage ? formatMessage(disableBuyButtonMessage) : ''
        }
      >
        <NotMobileAppFeature>
          {renderButton ? (
            renderButton({
              onClick,
              loading: paymentStarted,
              disabled: Boolean(disableBuyButtonMessage),
            })
          ) : (
            <Buy
              color={color}
              onClick={onClick}
              size={variant}
              loading={paymentStarted}
              disabled={Boolean(disableBuyButtonMessage)}
              fullWidth={fullWidth}
            >
              {buttonLabel}
            </Buy>
          )}
        </NotMobileAppFeature>
        <AndroidAppFeature>
          <UnvailableButtonForAndroid
            color={color}
            size={variant}
            fullWidth={fullWidth}
          />
        </AndroidAppFeature>
        <IOsAppFeature>
          <Buy
            color={color}
            onClick={() => {}}
            size={variant}
            fullWidth={fullWidth}
            loading={false}
            disabled
          >
            <FormattedMessage {...mobileApp.unavailable} />
          </Buy>
        </IOsAppFeature>
      </BuyTooltip>
      {paymentStarted && (
        <BuyPaymentFlow
          onClose={() => setPaymentStarted(false)}
          offerId={liveSingleSaleOffer.id}
        />
      )}
    </>
  );
};

BuyField.fragments = {
  anyCard: gql`
    fragment BuyField_anyCard on AnyCardInterface {
      slug
      sport
      liveSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        endDate
        receiverSide {
          id
          amounts {
            ...MonetaryAmountFragment_monetaryAmount
          }
        }
      }
      myMintedSingleSaleOffer @skip(if: $onlyPrimary) {
        id
        ...useTokenOfferBelongsToUser_offer
      }
      ...CancelOffer_anyCard
      ...useCannotBuy_anyCard
      ...useCannotSettleInFiat_anyCard
      ...useClickBuyEvent_anyCard
    }
    ${monetaryAmountFragment}
    ${useTokenOfferBelongsToUser.fragments.offer}
    ${CancelOffer.fragments.anyCard}
    ${useCannotBuy.fragments.anyCard}
    ${useCannotSettleInFiat.fragments.anyCard}
    ${useClickBuyEvent.fragments.anyCard}
  ` as TypedDocumentNode<BuyField_anyCard>,
};

export default BuyField;
