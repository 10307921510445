import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { MessageDescriptor } from 'react-intl';

import {
  BenchSortType,
  RangeInput,
  Rarity,
  SortingOption,
} from '@sorare/core/src/__generated__/globalTypes';
import { TotalAveragePlayerScore } from '@sorare/core/src/lib/averagePlayerScore';
import { Scarcity } from '@sorare/core/src/lib/cards';

import { EditableLineupItem } from 'lib/so5';

import {
  ContextProvider_anyCard,
  ContextProvider_composeTeamBenchObjectInterface,
  ContextProvider_so5Leaderboard,
  ContextProvider_so5Lineup,
} from '../ContextProvider/__generated__/index.graphql';

type ContextProvider_so5Leaderboard_displayedRules = NonNullable<
  ContextProvider_so5Leaderboard['displayedRules']
>;

export type So5Lineup = ContextProvider_so5Lineup;

export type AppearanceRule = NonNullable<
  NonNullable<ContextProvider_so5Leaderboard['rules']>['appearances']
>[number];

export type ContextEditableLineupItem = EditableLineupItem<
  ContextProvider_composeTeamBenchObjectInterface,
  ContextProvider_anyCard
>;

export type EditableLineupList = ContextEditableLineupItem[];

export type Errors = { path: string[] | null; message: string }[] | null;

export type BenchFilters = {
  includeNoGameCards: boolean;
  includeUsedCards: boolean;
  inSeasonEligible: boolean;
  averageScoreRange?: RangeInput;
  grades?: RangeInput;
};

export enum BenchSortPreference {
  AVERAGE_SCORE = 'AVERAGE_SCORE',
  POPULAR = 'popular',
}

const composeTeamContext = createContext<{
  appearanceRules: AppearanceRule[];
  lineup: EditableLineupList;
  lineupRarities: {
    [x: string]: number;
    [x: number]: number;
  };
  lineupComplete: boolean;
  addLineupItem: (
    lineupItem: ContextEditableLineupItem,
    args?: {
      isUserInteraction?: boolean;
    }
  ) => void;
  removeLineupItem: (index: number) => void;
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
  activeAppearanceRule: AppearanceRule;
  so5Lineup: ContextProvider_so5Lineup;
  so5Leaderboard: ContextProvider_so5Leaderboard;
  toggleCaptain: (index: number) => void;
  isBoostAvailable: boolean;
  boostInfos?: {
    boostApplied?: {
      rarity: Rarity | undefined;
      boostId: string;
      useExistingBoost: boolean;
    };
    canBoost: boolean;
    userBoostsCountByRarity: Record<Rarity, number>;
    boostListingByRarity: Record<
      Rarity,
      {
        __typename: 'AppearanceBoostShopItem';
        rarity: Rarity;
        id: string;
        price: number;
      }
    >;
  };
  toggleBoost: (index: number) => void;
  statsView: boolean;
  toggleStatsView: () => void;
  nextGamesView: boolean;
  toggleNextGamesView: () => void;
  onClose?: () => void;
  submitting: boolean;
  submit: () => Promise<void>;
  captain: ContextEditableLineupItem | null;
  needCaptain: boolean;
  captainRarities: Scarcity[];
  allowMvp: boolean;
  mvpBenchObjectIds: Nullable<Nullable<string>[]>;
  search: string;
  setSearch: (value: string) => void;
  rules: ContextProvider_so5Leaderboard_displayedRules | null;
  onboarding?: boolean;
  showFilters: boolean;
  filters: BenchFilters;
  setFilters: Dispatch<SetStateAction<BenchFilters>>;
  favoriteAverageScore: TotalAveragePlayerScore;
  setFavoriteAverageScore: (scoreMode: TotalAveragePlayerScore) => void;
  benchOpen: boolean;
  setBenchOpen: (value: boolean) => void;
  defaultAverageScore: TotalAveragePlayerScore | null;
  setDefaultAverageScore: Dispatch<
    SetStateAction<TotalAveragePlayerScore | null>
  >;
  displayedAverageScore: TotalAveragePlayerScore;
  leaderboardRarities: readonly Scarcity[];
  cardsScarcities: readonly Scarcity[];
  setCardsScarcities: Dispatch<SetStateAction<readonly Scarcity[] | undefined>>;
  errors?: Errors | null;
  isCappedMode?: boolean;
  playerDetails: Omit<ContextEditableLineupItem, 'captain'> | null;
  setPlayerDetails: Dispatch<
    SetStateAction<Omit<ContextEditableLineupItem, 'captain'> | null>
  >;
  isDrawerOpen: boolean;
  isCreateMode: boolean;
  isLiveEditMode: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  showAffordableOnly: boolean;
  setShowAffordableOnly: Dispatch<SetStateAction<boolean>>;
  showProjectedScore: boolean;
  averageScoreOptions: {
    value: TotalAveragePlayerScore;
    label: string | MessageDescriptor;
  }[];
  toggleAvgScore: () => void;
  customListFilter?: string;
  setCustomListFilter: (list: string | undefined) => void;
  showThresholds: string;
  submitThresholds: () => void;
  setShowThresholds: (id: string) => void;
  disableExtraInfoOnMobile: boolean;
  ftue: boolean;
  benchSortPreference: BenchSortPreference;
  setBenchSortPreference: Dispatch<SetStateAction<BenchSortPreference>>;
  sortType?: {
    direction: SortingOption;
    type: BenchSortType;
  };
  composeSessionId: string;
} | null>(null);

export const useComposeTeamContext = () => useContext(composeTeamContext)!;

export default composeTeamContext;
