import { useRef } from 'react';

import { FootballManagerTaskSlug } from '@sorare/core/src/__generated__/globalTypes';
import { WithOptionalCommonProperties } from '@sorare/core/src/lib/events/EventsType';
import { generateUseEvents } from '@sorare/core/src/lib/events/generateUseEvents';
import {
  Sport,
  sportToJSON,
} from '@sorare/core/src/protos/events/shared/events';

export type UnlockButtonAction =
  | 'Open Starter Bundle Dialog'
  | 'Redirect to Market';

export type LeaderboardInfos = {
  leaderboardSlug?: string;
  leaderboardName?: string;
  leaderboardRarity?: string;
};

export type FootballEventTypes = {
  'Click Beginners Guide': {
    campaignSlug?: string;
    isLogged: boolean;
    isOnboarding: boolean;
  };
  'Click Content Unit': {
    title: string;
  };
  'Click Manager Assistant Task': {
    task: FootballManagerTaskSlug;
  };
  'Click Submit Draft': {
    campaignSlug: string;
    success: boolean;
    errorMessage?: string;
    isLogged: boolean;
    isOnboarding: boolean;
  };
  'Click Starter Bundle Buy Button': {
    primaryOfferId: string;
    eurAmount: number;
    domesticLeagueName?: string;
    playerSlugs: string[];
    tournamentType: string;
    rarity: string;
    remainingSeconds: number;
  };
  'Click Team Placeholder': LeaderboardInfos;
  'Click Unlock Button': {
    action: UnlockButtonAction;
  } & LeaderboardInfos;
  'Click View More In Homepage': {
    context: 'Live' | 'Upcoming' | 'Private Leagues' | 'Discover' | 'Past';
  };
  'Dismiss Content Unit': {
    title: string;
  };
  'Expand Manager Assistant': {
    source: 'button' | 'title';
  };
  'Open Private League': {
    privateLeagueSlug: string;
  };
  'Pick League': {
    campaignSlug: string;
    automaticPick: boolean;
    isLogged: boolean;
    isOnboarding: boolean;
  };
  'Redirect To Marketplace': void;
  'Start Draft': {
    campaignSlug: string;
    sourcePage?: string;
    isLogged: boolean;
    isOnboarding: boolean;
  };
  'View Shared Lineup': {
    lineup: string;
    competition: string;
  };
  'Open Match View': {
    gameId: string;
    gameStatus: string;
    deviceType: string;
  };
  'Open Match View Player Details': {
    playerSlug: string;
  };
  'Click Competition': {
    leaderboardSlug: string;
    leaderboardName: string;
  };
  'Click Delete All Confirmed Lineups': void;
  'Click Delete All Unconfirmed Lineups': void;
  'Click Delete All Lineups': void;
  'Click Confirm All Lineups': void;
  'Click Add A Team': void;
  'Click Join Private League': {
    privateLeagueSlug: string;
  };
  'Click Play Rivals Onboarding': void;
  'Display Draft Onboarding': void;
  'Display Tactics Onboarding': void;
  'Click DivRepRank Onboarding': void;
  'Enter General Tutorial': void;
  'Click Next In General Tutorial': {
    step: 'Team of 5' | 'Score Points' | 'Compare Score';
  };
  'Click How To Play': void;
  'Search Player In Compose Team': void;
  'View Limited Card Tutorial': {
    variant: 'Early Access Card' | 'Card';
  };
  'Click Next in Limited Card Tutorial': {
    step: 'Limited Card' | 'Offers' | 'Easy Selling';
  };
  'View Compose Team Prize Pool & Details': void;
  'Select Player In First Compose Team': {
    position?: string;
  };
  'Select Captain In First Compose Team': void;

  'Select Player In First Rivals Draft': {
    position?: string;
  };
  'Confirm Captain In First Rivals Draft': void;
  'Skip Simulation': void;
  'Viewed PickFavoriteTeamScreen': void;
  'Viewed RivalsWelcomeScreen': void;
  'Viewed RivalsStartReplayScreen': void;
  'Viewed SimulationDecisiveScreen': void;
  'Viewed SimulationAllAroundScreen': void;
  'Viewed SimulationTacticsScreen': void;
  'Simulation ended': {
    teamSlugs: string[];
    result: string;
  };
  'Viewed First Mission': void;
  'Click First Game Pick': { teamSlugs: string[]; label: string };
  'Enter Add Team From Lobby': {
    source: 'Competition Unlocked By Card' | 'Quick Add' | 'Your Next Step';
  };
};

export type NBAEventTypes = {
  'View Onboarding Welcome': void;
  'View Onboarding Score Points': void;
  'View Onboarding Chase Glory': void;
  'View Onboarding Build your club': void;
  'View Onboarding Select star player': void;
  'Click Onboarding Select star player': {
    player: string;
  };
  'View Onboarding Star signing': void;
  'Click Onboarding Star signing': {
    team: string;
  };
  'View Onboarding Pick favorite team': void;
  'Click Onboarding Pick favorite team': {
    team: string;
  };
  'View Onboarding Starter pack': void;
  'View Onboarding Starter pack content': void;
  'Click close Onboarding': void;
  'View Skip The Grind': void;
  'Click Close Skip The Grind': void;
  'Click Skip The Grind Market Redirection': void;
  'View Gallery Onboarding': void;
  'View Common Market': void;
  'Click Your Lineups on Play': {
    leaderboardSlug: string;
  };
};

export const useNBAEvents = generateUseEvents<NBAEventTypes>({
  defaultProperties: {
    sport: sportToJSON(Sport.NBA),
  },
});

export const useFootballEvents = generateUseEvents<FootballEventTypes>({
  defaultProperties: {
    sport: sportToJSON(Sport.FOOTBALL),
  },
});

export const useFootballEventOnce = () => {
  const ref = useRef<Record<string, boolean>>({});
  const track = useFootballEvents();

  return <K extends keyof FootballEventTypes>(
    event: K,
    ...rest: FootballEventTypes[K] extends void
      ? []
      : [WithOptionalCommonProperties<FootballEventTypes[K]>]
  ) => {
    if (!ref.current[event]) {
      ref.current[event] = true;
      track(event, ...rest);
    }
  };
};
