import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { isFuture } from 'date-fns';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import {
  ItemImgContainer,
  ItemInfosContainer,
} from 'components/ItemPreview/ui';

import { TokenDetails } from './TokenDetails';
import { TokenImg } from './TokenImg';
import { TokenProperties } from './TokenProperties';
import { TokenContent_anyCard } from './__generated__/index.graphql';

type Props = {
  card: TokenContent_anyCard;
  hideOwner?: boolean;
  disableSportSpecific?: boolean;
  displayMarketplaceOnboardingTooltip?: boolean;
  galleryOwnerSlug?: string;
  hideSorareUser?: boolean;
  stackedTokensCount?: number;
  onInfoClick?: () => void;
  hideDetails: boolean;
  action?: ReactNode;
};

const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: var(--intermediate-unit);
  &.isDesktopLayout {
    display: flex;
    flex-direction: column;
  }
`;

const ActionWrapper = styled.div`
  position: absolute;
  right: var(--unit);
  top: var(--unit);
  display: none;
  pointer-events: none;

  @media ${tabletAndAbove} {
    ${Root}:hover & {
      display: block;
      pointer-events: auto;
    }
  }
`;

export const TokenContent = ({
  card,
  stackedTokensCount,
  disableSportSpecific,
  displayMarketplaceOnboardingTooltip,
  hideOwner,
  hideSorareUser,
  galleryOwnerSlug,
  onInfoClick,
  hideDetails,
  action,
}: Props) => {
  const isDesktopLayout = true;

  const isBundledAuction = !!(
    card.latestEnglishAuction &&
    isFuture(card.latestEnglishAuction.endDate) &&
    card.latestEnglishAuction?.cards?.length > 1
  );

  return (
    <Root className={classNames({ isDesktopLayout })}>
      <ItemImgContainer className={classNames({ isDesktopLayout })}>
        <TokenImg
          card={card}
          stackedTokensCount={stackedTokensCount}
          isBundledAuction={isBundledAuction}
          isDesktopLayout={isDesktopLayout}
        />
        {action && <ActionWrapper>{action}</ActionWrapper>}
      </ItemImgContainer>
      {!hideDetails && (
        <ItemInfosContainer className={classNames({ isDesktopLayout })}>
          <TokenProperties
            card={card}
            isBundledAuction={isBundledAuction}
            disableSportSpecific={disableSportSpecific}
            onInfoClick={onInfoClick}
          />
          <TokenDetails
            card={card}
            stackedTokensCount={stackedTokensCount}
            isDesktopLayout={isDesktopLayout}
            hideSorareUser={hideSorareUser}
            hideOwner={hideOwner}
            galleryOwnerSlug={galleryOwnerSlug}
            disableSportSpecific={disableSportSpecific}
            displayMarketplaceOnboardingTooltip={
              displayMarketplaceOnboardingTooltip
            }
          />
        </ItemInfosContainer>
      )}
    </Root>
  );
};

TokenContent.fragments = {
  anyCard: gql`
    fragment TokenContent_anyCard on AnyCardInterface {
      slug
      latestEnglishAuction {
        id
        cards: anyCards {
          slug
        }
      }
      ...TokenImg_anyCard
      ...TokenDetails_anyCard
      ...TokenProperties_anyCard
    }
    ${TokenImg.fragments.anyCard}
    ${TokenDetails.fragments.anyCard}
    ${TokenProperties.fragments.anyCard}
  ` as TypedDocumentNode<TokenContent_anyCard>,
};
