import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { Tab, TabBar } from '@sorare/core/src/atoms/navigation/TabBar';
import {
  FOOTBALL_PLAY_RIVALS_MY_LINEUPS,
  FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA,
  FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_LINEUPS,
  FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_MY_SQUAD,
} from '@sorare/core/src/constants/__generated__/routes';
import { DEFAULT_SEASON_SLUG } from '@sorare/core/src/constants/routes';
import { randomizedPollInterval } from '@sorare/core/src/gql';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { navLabels, rivals } from '@sorare/core/src/lib/glossary';

import { PlayHeader } from 'components/rivals/PlayHeader';
import useLiveGamePollInterval from 'hooks/useLiveGamePollInterval';

import {
  PlayLayoutGameQuery,
  PlayLayoutGameQueryVariables,
} from './__generated__/index.graphql';

const FullHeightVertical = styled(Vertical).attrs({ gap: 0 })`
  height: 100%;
`;
const LabelWrapper = styled.div`
  position: relative;
`;

const PLAY_LAYOUT_GAME_QUERY = gql`
  query PlayLayoutGameQuery($slug: String!) {
    football {
      id
      rivals {
        id
        game(slug: $slug) {
          slug
          game {
            id
            ...useLiveGamePollInterval_game
            ...PlayHeader_game
          }
          season {
            slug
          }
        }
      }
    }
  }
  ${useLiveGamePollInterval.fragments.game}
  ${PlayHeader.fragments.game}
` as TypedDocumentNode<PlayLayoutGameQuery, PlayLayoutGameQueryVariables>;

type TabsProps = {
  slug: string;
  seasonSlug: string;
};
const Tabs = ({ slug, seasonSlug }: TabsProps) => {
  return (
    <>
      <Tab
        to={generatePath(
          FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_ARENA,
          {
            seasonSlug,
            gameSlug: slug,
          }
        )}
      >
        <LabelWrapper>
          <FormattedMessage {...rivals.arena} />
        </LabelWrapper>
      </Tab>
      <Tab
        to={generatePath(
          FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_MY_SQUAD,
          {
            seasonSlug,
            gameSlug: slug,
          }
        )}
      >
        <FormattedMessage {...navLabels.mySquad} />
      </Tab>
      <Tab
        to={generatePath(
          FOOTBALL_PLAY_RIVALS_MY_LINEUPS_SEASONSLUG_GAMESLUG_LINEUPS,
          {
            seasonSlug,
            gameSlug: slug,
          }
        )}
      >
        <FormattedMessage {...navLabels.lineups} />
      </Tab>
    </>
  );
};

const POLL_INTERVAL = randomizedPollInterval(0.5);

type Props = {
  slug: string;
  children: ReactNode;
};
export const PlayLayout = ({ slug, children }: Props) => {
  const [pollInterval, setPollInterval] = useState(0);
  const { data } = useQuery(PLAY_LAYOUT_GAME_QUERY, {
    pollInterval,
    variables: { slug },
  });
  const rivalsGame = data?.football.rivals.game;
  const { game } = rivalsGame || {};

  useLiveGamePollInterval(
    POLL_INTERVAL,
    [game].filter(Boolean),
    newPollInterval => setPollInterval(newPollInterval)
  );

  if (!game) {
    return <LoadingIndicator />;
  }

  return (
    <FullHeightVertical>
      <FullHeightVertical>
        {game && (
          <PlayHeader
            game={game}
            backUrl={generatePath(FOOTBALL_PLAY_RIVALS_MY_LINEUPS)}
          />
        )}
        <FullWidthContainer.NoPadding>
          <TabBar variant="flat" fullWidth>
            <Tabs
              slug={slug}
              seasonSlug={rivalsGame?.season?.slug || DEFAULT_SEASON_SLUG}
            />
          </TabBar>
        </FullWidthContainer.NoPadding>
        {children}
      </FullHeightVertical>
    </FullHeightVertical>
  );
};
