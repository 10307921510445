import { TypedDocumentNode, gql } from '@apollo/client';

import {
  GameStatus,
  PlayerFieldStatus,
  ScoreStatus,
} from '__generated__/globalTypes';
import { Bench } from 'atoms/icons/Bench';
import { isType } from 'gql';

import { Score } from '../Score';
import { PlayerGameScoreScore_playerGameScoreInterface } from './__generated__/index.graphql';

type Props = {
  playerGameScore?: PlayerGameScoreScore_playerGameScoreInterface;
  shape?: 'TEXT' | 'HEXAGON';
};

export const PlayerGameScoreScore = ({
  playerGameScore,
  shape = 'HEXAGON',
}: Props) => {
  const { score, scoreStatus, anyPlayerGameStats, anyGame } =
    playerGameScore || {};

  const isBenched =
    isType(anyPlayerGameStats, 'PlayerGameStats') &&
    anyGame?.status === GameStatus.playing &&
    anyPlayerGameStats.fieldStatus === PlayerFieldStatus.ON_BENCH;

  if (shape === 'TEXT' && isBenched) {
    return (
      <div className="text-center">
        <Bench color="var(--c-nd-600)" />
      </div>
    );
  }

  return (
    <Score
      hideReviewing
      shape={shape}
      score={score || 0}
      scoreStatus={
        typeof score === 'number' && scoreStatus
          ? scoreStatus
          : ScoreStatus.DID_NOT_PLAY
      }
      scoreType="PLAYER_SCORE"
      animated={false}
    />
  );
};

PlayerGameScoreScore.fragments = {
  playerGameScoreInterface: gql`
    fragment PlayerGameScoreScore_playerGameScoreInterface on PlayerGameScoreInterface {
      id
      score
      scoreStatus
      anyGame {
        id
        status: statusTyped
      }
      anyPlayerGameStats {
        id
        ... on PlayerGameStats {
          id
          fieldStatus
        }
      }
    }
  ` as TypedDocumentNode<PlayerGameScoreScore_playerGameScoreInterface>,
};
