import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CloseButton } from '@sorare/core/src/atoms/buttons/CloseButton';
import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { Container } from '@sorare/core/src/atoms/layout/Container';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { CardsPreviewContainer } from '@sorare/core/src/components/bundled/CardsPreviewContainer';
import { ANY_SPORT_MARKET } from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { useSafePreviousNavigate } from '@sorare/core/src/hooks/useSafePreviousNavigate';
import { glossary } from '@sorare/core/src/lib/glossary';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: var(--unit) var(--double-unit);
  @media ${laptopAndAbove} {
    flex-direction: row;
    padding: var(--double-unit) var(--quadruple-unit);
  }
`;
const Page = styled.div`
  color: var(--c-white);
  background: linear-gradient(180deg, var(--c-nl-900) 0%, var(--c-black) 375px),
    var(--c-white);
`;

const HeaderLayout = styled.div`
  padding: var(--triple-unit) 0;
`;
const DetailsLayout = styled(Vertical).attrs({ gap: 4 })`
  padding: var(--quadruple-unit) 0;
  width: 100%;
`;
const PreviewContainer = styled(Vertical).attrs({ gap: 2 })`
  max-width: 526px;
  margin: 0 auto;
`;
export const CardsContainer = styled(Container)`
  border-top-left-radius: var(--double-unit);
  border-top-right-radius: var(--double-unit);
`;

type Props = {
  cardsPreview: ReactNode;
  detailsContent?: ReactNode;
  customPreview?: ReactNode;
};

export const MultiCardPageContent = ({
  cardsPreview,
  detailsContent,
  customPreview,
}: Props) => {
  const isLaptopAndAbove = useIsLaptop();
  const { sport, generateSportContextPath } = useSportContext();

  const goBack = useSafePreviousNavigate(
    generateSportContextPath(ANY_SPORT_MARKET, {
      sport,
    })
  );
  return (
    <Page>
      <Header>
        {isLaptopAndAbove ? (
          <Horizontal>
            <IconButton
              icon={faChevronLeft}
              color="tertiary"
              onClick={goBack}
            />
            <Text16 color="var(--c-white)">
              <FormattedMessage {...glossary.back} />
            </Text16>
          </Horizontal>
        ) : (
          <CloseButton onClose={goBack} />
        )}
      </Header>
      {customPreview || (
        <Container>
          <HeaderLayout>
            <PreviewContainer>
              <CardsPreviewContainer>{cardsPreview}</CardsPreviewContainer>
            </PreviewContainer>
          </HeaderLayout>
        </Container>
      )}
      <CardsContainer>
        <DetailsLayout>{detailsContent}</DetailsLayout>
      </CardsContainer>
    </Page>
  );
};

export default MultiCardPageContent;
