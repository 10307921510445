import classNames from 'classnames';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';

import crest from 'assets/user/crest.png';
import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';

import { FileWithDataURL, useUploadFile } from './useUploadFile';

const Root = styled(Vertical).attrs({ gap: 0 })``;
const Error = styled.div`
  color: var(--c-red-600);
`;
const Container = styled(Vertical).attrs({ gap: 2, center: true })`
  position: relative;
  &.row {
    flex-direction: row;
  }
  justify-content: stretch;
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  inset: 0;
  cursor: pointer;
`;
const Image = styled.img`
  &.sm {
    --image-size: calc(6 * var(--unit));
  }
  &.md {
    --image-size: calc(8 * var(--unit));
  }
  &.xl {
    --image-size: calc(12 * var(--unit));
  }

  width: var(--image-size);
  height: var(--image-size);
  object-fit: cover;
  border-radius: 50%;
`;

const onUploadClick = (e: any) => {
  e.target.value = null;
};

type Props = {
  onChange: (file: FileWithDataURL) => void;
  currentFileUrl: string | null;
  name: string;
  validExtensions?: string[];
  buttonLabel?: ReactNode;
  children?: ReactNode;
  type: string;
  maxFileSizeMb?: number;
  row?: boolean;
  size?: 'sm' | 'md' | 'xl';
};
export const UploadFile = ({
  onChange,
  currentFileUrl,
  name,
  type,
  validExtensions,
  buttonLabel,
  children,
  maxFileSizeMb,
  row,
  size = 'xl',
}: Props) => {
  const { onDropFile, fileTypeError, fileSizeError, fileUrl, displayableFile } =
    useUploadFile(onChange, validExtensions, maxFileSizeMb);

  const input = useRef<HTMLInputElement>(null);

  const triggerFileUpload = () => {
    input.current!.click();
  };

  return (
    <Root>
      {fileTypeError && <Error>{fileTypeError}</Error>}
      {fileSizeError && <Error>{fileSizeError}</Error>}
      <Container className={classNames({ row })} data-sentry-block>
        <Input
          type="file"
          ref={input}
          name={name}
          onChange={e => {
            onDropFile(e);
          }}
          onClick={onUploadClick}
          accept={type}
        />
        {!children && displayableFile && (
          <Image
            src={fileUrl || currentFileUrl || crest}
            alt={name}
            className={classNames(size)}
          />
        )}
        {children}
        {buttonLabel && (
          <Button color="quaternary" size="small" onClick={triggerFileUpload}>
            {buttonLabel}
          </Button>
        )}
      </Container>
    </Root>
  );
};
