import { TypedDocumentNode, gql } from '@apollo/client';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import TimeLeft from '@sorare/core/src/contexts/ticker/TimeLeft';
import { glossary } from '@sorare/core/src/lib/glossary';

import { MissingCardsMessage } from 'components/unlockCompetition/MissingCardsMessage';

import {
  LeaderboardComposable_so5Leaderboard,
  LeaderboardComposable_so5LeagueTrack,
} from './__generated__/index.graphql';

type Props = {
  so5Leaderboard: LeaderboardComposable_so5Leaderboard;
  so5LeagueTrack?: LeaderboardComposable_so5LeagueTrack;
  labelOnly?: boolean;
};

export const LeaderboardComposable = ({
  so5Leaderboard,
  so5LeagueTrack,
  labelOnly,
}: Props) => {
  const { value: canCompose, upcomingAt: composableAt } =
    so5Leaderboard.canCompose;

  const managerTeamBased = so5LeagueTrack
    ? so5LeagueTrack.maxManagerTeamsCount > 0
    : false;
  const maxTeamsCount =
    (managerTeamBased
      ? so5LeagueTrack?.maxManagerTeamsCount
      : so5LeagueTrack?.entrySo5Leaderboard.teamsCap) || 0;
  const teamsCreatedCount =
    (managerTeamBased
      ? so5LeagueTrack?.myManagerTeams.length
      : so5LeagueTrack?.entrySo5Leaderboard.mySo5Lineups.length) || 0;
  const cannotCreateMoreTeams =
    so5LeagueTrack && teamsCreatedCount < maxTeamsCount;

  let content;
  if (composableAt) {
    content = (
      <TimeLeft time={composableAt}>
        {({ timeLeft }) => (
          <FormattedMessage {...glossary.opensIn} values={{ time: timeLeft }} />
        )}
      </TimeLeft>
    );
  } else if (!canCompose) {
    content = <MissingCardsMessage so5Leaderboard={so5Leaderboard} />;
  } else if (cannotCreateMoreTeams) {
    content = (
      <FormattedMessage
        id="LeagueTrcks.LeagueTrck.teamLimitReached"
        defaultMessage="Team limit reached"
      />
    );
  }

  if (!content) {
    return null;
  }

  if (labelOnly) {
    return content;
  }

  return (
    <Horizontal center as="span">
      <FontAwesomeIcon icon={faLock} size="sm" />
      <span>{content}</span>
    </Horizontal>
  );
};

LeaderboardComposable.fragments = {
  so5Leaderboard: gql`
    fragment LeaderboardComposable_so5Leaderboard on So5Leaderboard {
      slug
      mySo5Lineups {
        id
      }
      canCompose {
        value
        upcomingAt
      }
      ...MissingCardsMessage_so5Leaderboard
    }
    ${MissingCardsMessage.fragments.so5Leaderboard}
  ` as TypedDocumentNode<LeaderboardComposable_so5Leaderboard>,
  so5LeagueTrack: gql`
    fragment LeaderboardComposable_so5LeagueTrack on So5LeagueTrack {
      slug
      maxManagerTeamsCount
      myManagerTeams {
        id
      }
      entrySo5Leaderboard {
        slug
        teamsCap
        mySo5Lineups {
          id
        }
      }
    }
  ` as TypedDocumentNode<LeaderboardComposable_so5LeagueTrack>,
};
