import { FormattedMessage } from 'react-intl';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { ScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { SwapBudget } from '@sorare/core/src/atoms/icons/SwapBudget';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';

export const CommonMarketStatus = () => {
  const { currentUser } = useCurrentUserContext();
  const pointsCount = currentUser?.commonCardPoints || 0;
  const cardsCount = currentUser?.cardCounts?.common || 0;
  if (!currentUser) return null;
  return (
    <Horizontal gap={1}>
      <SwapBudget />
      <LabelM as="span" color="var(--c-white)">
        <FormattedMessage
          id="SwapHeader.pointsCount"
          defaultMessage="{pointsCount} points"
          values={{ pointsCount }}
        />
      </LabelM>
      <LabelM as="span" color="var(--c-nd-400)">
        •
      </LabelM>
      <ScarcityIcon scarcity={Rarity.common} />
      <LabelM
        as="span"
        color={cardsCount < 9 ? 'var(--c-yellow-600)' : 'var(--c-white)'}
      >
        {cardsCount}
      </LabelM>
    </Horizontal>
  );
};
