import { TypedDocumentNode, gql } from '@apollo/client';
import isFuture from 'date-fns/isFuture';

import { GameStatus } from '@sorare/core/src/__generated__/globalTypes';
import { isType } from '@sorare/core/src/gql';
import { FixtureState } from '@sorare/core/src/lib/so5';

import { calculateScoreWithBonus } from '@sorare/us-sports/src/lib/sports';

import {
  PlayerCardScore_so5Appearance,
  PlayerCardScore_so5Fixture,
} from './__generated__/index.graphql';
import { FinalPlayerCardScore } from './variants/FinalPlayerCardScore';
import { LivePlayerCardScore } from './variants/LivePlayerCardScore';
import { UpcomingPlayerCardScore } from './variants/UpcomingPlayerCardScore';

type Props = {
  fixture: PlayerCardScore_so5Fixture;
  so5Appearance: PlayerCardScore_so5Appearance;
  coloredWithDelta?: boolean;
};
export const PlayerCardScore = ({
  fixture,
  so5Appearance,
  coloredWithDelta,
}: Props) => {
  const {
    score: cardInLineupScoreWithBonus,
    anyPlayer,
    bonus,
    upcomingGame,
    eligiblePlayerGameScores,
  } = so5Appearance;
  const { lastTenPlayedSo5AverageScore } = anyPlayer;
  const gamesIdsInLeaderboards = fixture?.anyGames.map(g => g.id);
  const hasTBDGames = !!upcomingGame;
  const liveGameScore = eligiblePlayerGameScores.find(
    gS =>
      gS.anyGame.statusTyped === GameStatus.playing &&
      (gamesIdsInLeaderboards
        ? gamesIdsInLeaderboards.includes(gS.anyGame.id)
        : true)
  );

  const inGame =
    liveGameScore &&
    isType(liveGameScore, 'BasketballPlayerGameScore') &&
    liveGameScore.basketballPlayerGameStats.onGameSheet &&
    (liveGameScore.basketballPlayerGameStats.minsPlayed || 0) > 0;

  const isLive = liveGameScore && inGame;

  const pastGames = eligiblePlayerGameScores
    .filter(gS =>
      gamesIdsInLeaderboards
        ? gamesIdsInLeaderboards.includes(gS.anyGame.id)
        : true
    )
    .filter(
      gS =>
        gS.anyGame.statusTyped === GameStatus.played ||
        gS.anyGame.statusTyped === GameStatus.playing
    );
  const upcomingGames = eligiblePlayerGameScores
    .filter(({ anyGame }) =>
      gamesIdsInLeaderboards
        ? gamesIdsInLeaderboards.includes(anyGame.id)
        : true
    )
    .filter(
      gS =>
        isFuture(gS.anyGame.date) ||
        gS.anyGame.statusTyped === GameStatus.playing
    )
    .map(gS => gS.anyGame);
  const nbUpcomingGames = upcomingGames.length;
  const isScoreFinal = !isLive && nbUpcomingGames === 0;

  const liveGamePlayerScoreWithBonus = isLive
    ? calculateScoreWithBonus(liveGameScore.score, bonus || 1).score
    : 0;

  if (fixture.aasmState === FixtureState.opened) {
    return (
      <UpcomingPlayerCardScore
        player={anyPlayer}
        upcomingGames={upcomingGames}
        hasTBDGames={hasTBDGames}
      />
    );
  }

  if (isScoreFinal) {
    return (
      <FinalPlayerCardScore
        cardInLineupScoreWithBonus={cardInLineupScoreWithBonus}
        tenGameAverage={lastTenPlayedSo5AverageScore || 0}
        coloredWithDelta={coloredWithDelta}
        displayFinal={fixture.aasmState === FixtureState.started}
        playedInGame={Boolean(
          cardInLineupScoreWithBonus ||
            eligiblePlayerGameScores.some(
              gS =>
                isType(gS, 'BasketballPlayerGameScore') &&
                (gS.basketballPlayerGameStats.minsPlayed || 0) > 0
            )
        )}
      />
    );
  }

  return (
    <LivePlayerCardScore
      player={anyPlayer}
      upcomingGames={upcomingGames}
      nbPastGames={pastGames.length}
      isLive={!!isLive}
      liveCardScoreWithBonus={{
        score: liveGamePlayerScoreWithBonus,
        playedInGame: Boolean(liveGamePlayerScoreWithBonus || inGame),
      }}
      cardInLineupScoreWithBonus={{
        score: cardInLineupScoreWithBonus,
        playedInGame: Boolean(cardInLineupScoreWithBonus || inGame),
      }}
      hasTBDGames={hasTBDGames}
    />
  );
};

PlayerCardScore.fragments = {
  so5Fixture: gql`
    fragment PlayerCardScore_so5Fixture on So5Fixture {
      slug
      aasmState
      anyGames {
        id
      }
    }
  ` as TypedDocumentNode<PlayerCardScore_so5Fixture>,
  so5Appearance: gql`
    fragment PlayerCardScore_so5Appearance on So5Appearance {
      id
      score
      bonus
      anyCard {
        slug
        anyPlayer {
          slug
        }
      }
      upcomingGame {
        id
      }
      scoreStatus
      eligiblePlayerGameScores {
        id
        score
        anyGame {
          id
          statusTyped
          date
          ...LivePlayerCardScore_anyGame
          ...UpcomingPlayerCardScore_anyGame
        }
        ... on BasketballPlayerGameScore {
          id
          basketballPlayerGameStats {
            id
            minsPlayed
            onGameSheet
          }
        }
      }
      anyPlayer {
        slug
        lastTenPlayedSo5AverageScore: averageScore(
          type: LAST_TEN_PLAYED_SO5_AVERAGE_SCORE
        )

        ...LivePlayerCardScore_anyPlayer
        ...UpcomingPlayerCardScore_anyPlayer
      }
    }
    ${LivePlayerCardScore.fragments.anyPlayer}
    ${LivePlayerCardScore.fragments.anyGame}
    ${UpcomingPlayerCardScore.fragments.anyPlayer}
    ${UpcomingPlayerCardScore.fragments.anyGame}
  ` as TypedDocumentNode<PlayerCardScore_so5Appearance>,
};
