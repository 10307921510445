import { TypedDocumentNode, gql } from '@apollo/client';
import { generatePath } from 'react-router-dom';

import {
  ANY_SPORT_CARDS_SLUG,
  FOOTBALL_BUNDLED_AUCTIONS_ID,
} from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';
import { idFromObject } from 'gql/idFromObject';

import { AuctionLink_auction } from './__generated__/useAuctionLink.graphql';

export const useAuctionLink = () => {
  const { generateSportContextPath } = useSportContext();

  return (tokenAuction: AuctionLink_auction) => {
    if (tokenAuction.cards.length === 1) {
      return generateSportContextPath(ANY_SPORT_CARDS_SLUG, {
        params: {
          slug: tokenAuction.cards[0].slug,
        },
        sport: tokenAuction.cards[0].sport,
      });
    }
    return generatePath(FOOTBALL_BUNDLED_AUCTIONS_ID, {
      id: idFromObject(tokenAuction.id),
    });
  };
};

useAuctionLink.fragments = {
  auctionLink: gql`
    fragment AuctionLink_auction on TokenAuction {
      id
      cards: anyCards {
        slug
        sport
      }
    }
  ` as TypedDocumentNode<AuctionLink_auction>,
};
