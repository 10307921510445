import styled, { css } from 'styled-components';

import {
  Horizontal,
  SBHorizontal,
  Vertical,
} from '@sorare/core/src/atoms/layout/flex';
import { Text14, text14 } from '@sorare/core/src/atoms/typography';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';
import { inferRuleSetProps } from '@sorare/core/src/style/utils';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding-right: 0;
  padding-left: 0;
  gap: var(--unit);
  &:not(:last-child) + &:not(.noBorder) {
    border-bottom: 1px solid var(--c-nd-100);
  }
  &:not(.noBorder) + &:not(.noBorder) {
    padding-top: var(--double-unit);
    border-bottom: none;
  }
`;

export const ItemDesktopPreviewContainer = styled(Vertical).attrs({ gap: 0 })`
  & > * {
    flex: 1;
  }
`;

export const CardContainer = styled(Horizontal).attrs({ gap: 0 })`
  position: relative;
  width: var(--card-mobile-width);
`;

export const CardCount = styled.div`
  position: absolute;
  left: 5px;
  bottom: 5px;
  height: 24px;
  width: 26px;
  background-color: var(--c-black);
  display: flex;
  justify-content: center;
  border-radius: var(--unit);
`;
export const Content = styled(Vertical).attrs({ gap: 0 })`
  flex: 1;
  min-width: 0;
  align-items: flex-start;
`;
export const Properties = styled(SBHorizontal)`
  width: 100%;
  color: var(--c-nd-600);
  flex-direction: row-reverse;
`;

export const Info = styled(Vertical).attrs({ gap: 0 })`
  width: 100%;
  justify-content: space-between;
`;
export const PriceInfoButtonContainer = styled(Vertical)`
  justify-content: space-between;
`;
export const PriceInfo = styled(Vertical).attrs({ gap: 0 })`
  min-width: 0;
`;
export const SaleInfoContainer = styled(Horizontal)`
  min-width: 0;
  height: var(--triple-unit);
  color: var(--c-nd-600);
`;
export const SaleInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Bid = styled.div`
  display: flex;
`;

export const ItemForSaleContainer = styled(Text14).attrs({ as: 'div' })`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ItemForSaleSecondRow = styled(SBHorizontal)`
  width: 100%;
  margin-bottom: var(--unit);
  height: var(--triple-unit);
  font: var(--t-14);
`;

export const ItemImgContainer = styled.div`
  --image-width: var(--card-mobile-width);
  flex-basis: var(--image-width);
  min-width: var(--image-width);
  aspect-ratio: var(--card-aspect-ratio);
  &.isDesktopLayout {
    --image-width: auto;
  }
`;

export const ItemInfosContainer = styled(Vertical).attrs({ gap: 0.5 })`
  overflow: hidden;
  flex: 1;
  &.isDesktopLayout {
    gap: var(--unit);
  }
`;

export const ItemPropertiesContainer = styled(Horizontal).attrs({ gap: 0 })`
  min-height: var(--quadruple-unit);
  color: var(--c-nd-600);
`;

export const TokenDetailsRoot = styled(Vertical).attrs({ as: 'div' })<
  inferRuleSetProps<typeof text14>
>`
  ${text14}
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--unit);
  &.allowColumnLayout {
    @media ${tabletAndAbove} {
      flex-direction: row;
    }
  }
`;

export const TokenDetailsRow = styled(SBHorizontal)`
  width: 100%;
  min-height: var(--triple-unit);
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div<{ $isDesktopLayout?: boolean }>`
  ${({ $isDesktopLayout }) =>
    $isDesktopLayout !== undefined
      ? css`
          display: ${$isDesktopLayout ? 'block' : 'flex'};
        `
      : css`
          display: flex;
          @media ${tabletAndAbove} {
            display: block;
          }
        `}
`;
