import { unitMapping } from 'lib/style';

import { IconColor, useIconColor } from './useIconColor';

type Props = {
  active?: boolean;
  size?: keyof typeof unitMapping;
  color?: IconColor;
};

export const MyCards = ({ active, size = 3, color }: Props) => {
  const { fill, svgNode } = useIconColor(color);
  const activeFill = active ? fill : 'none';
  const inactiveFill = active ? 'none' : fill;
  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8 4.79999H7.35V20.4H16.8V4.79999ZM2.4 5.99999V19.35H4.8V5.99999H2.4ZM21.6 19.35V5.99999H19.2V19.35H21.6Z"
        fill={activeFill}
      />
      <path
        d="M7.35 4.79999V4.34999L6.9 4.79999H7.35ZM16.8 4.79999H17.25L16.8 4.34999V4.79999ZM7.35 20.4H6.9L7.35 20.85V20.4ZM16.8 20.4V20.85L17.25 20.4H16.8ZM2.4 19.35H1.95L2.4 19.8V19.35ZM2.4 5.99999V5.54999L1.95 5.99999H2.4ZM4.8 19.35V19.8L5.25 19.35H4.8ZM4.8 5.99999H5.25L4.8 5.54999V5.99999ZM21.6 5.99999H22.05L21.6 5.54999V5.99999ZM21.6 19.35V19.8L22.05 19.35H21.6ZM19.2 5.99999V5.54999L18.75 5.99999H19.2ZM19.2 19.35H18.75L19.2 19.8V19.35ZM7.35 5.24999H16.8V4.34999H7.35V5.24999ZM7.8 20.4V4.79999H6.9V20.4H7.8ZM16.8 19.95H7.35V20.85H16.8V19.95ZM16.35 4.79999V20.4H17.25V4.79999H16.35ZM2.85 19.35V5.99999H1.95V19.35H2.85ZM4.8 18.9H2.4V19.8H4.8V18.9ZM4.35 5.99999V19.35H5.25V5.99999H4.35ZM2.4 6.44999H4.8V5.54999H2.4V6.44999ZM21.15 5.99999V19.35H22.05V5.99999H21.15ZM19.2 6.44999H21.6V5.54999H19.2V6.44999ZM19.65 19.35V5.99999H18.75V19.35H19.65ZM21.6 18.9H19.2V19.8H21.6V18.9Z"
        fill={activeFill}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45 4.79999C6.45 4.30294 6.85294 3.89999 7.35 3.89999H16.8C17.2971 3.89999 17.7 4.30294 17.7 4.79999V20.4C17.7 20.8971 17.2971 21.3 16.8 21.3H7.35C6.85294 21.3 6.45 20.8971 6.45 20.4V4.79999ZM8.25 5.69999V19.5H15.9V5.69999H8.25ZM1.5 5.99999C1.5 5.50294 1.90294 5.09999 2.4 5.09999H4.8V6.89999H3.3V18.45H4.8V20.25H2.4C1.90294 20.25 1.5 19.8471 1.5 19.35V5.99999ZM19.2 5.09999H21.6C22.0971 5.09999 22.5 5.50294 22.5 5.99999V19.35C22.5 19.8471 22.0971 20.25 21.6 20.25H19.2V18.45H20.7V6.89999H19.2V5.09999Z"
        fill={inactiveFill}
      />
      {svgNode}
    </svg>
  );
};
