import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { BodyS } from 'atoms/typography';
import { glossary } from 'lib/glossary';

type Props = {
  status: 'owned' | 'equipped';
};

export const ClubShopItemStatus = ({ status }: Props) => (
  <BodyS
    bold
    color={status === 'equipped' ? 'var(--c-green-600)' : 'var(--c-nd-800)'}
    as="div"
  >
    <Horizontal gap={0.5}>
      <FontAwesomeIcon icon={faCircleCheck} />
      <FormattedMessage
        {...(status === 'equipped' ? glossary.equipped : glossary.owned)}
      />
    </Horizontal>
  </BodyS>
);
