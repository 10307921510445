import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, MessageDescriptor, defineMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Vertical } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { DumbNotification } from 'components/activity/DumbNotification';
import {
  FOOTBALL_CLUB_SHOP,
  FOOTBALL_CLUB_SHOP_TAB,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';

import { commonNotificationInterfaceFragment } from '../fragments';
import { CommonNotificationProps } from '../types';
import { ShopItemNotification_shopItemNotification } from './__generated__/index.graphql';

type ShopItemNotificationNames =
  ShopItemNotification_shopItemNotification['name'];
const props: {
  [key in ShopItemNotificationNames]?: {
    link: string;
    message: MessageDescriptor;
  };
} = {
  raffle_shop_item_won: {
    link: FOOTBALL_CLUB_SHOP,
    message: defineMessage({
      id: 'ShopItemNotification.raffle_shop_item_won_2',
      defaultMessage: 'Congratulations! You’ve won our latest raffle!',
    }),
  },
  raffle_shop_item_lost: {
    link: generatePath(FOOTBALL_CLUB_SHOP_TAB, {
      tab: 'inventory',
    }),
    message: defineMessage({
      id: 'ShopItemNotification.raffle_shop_item_lost_2',
      defaultMessage:
        'Unfortunately, your raffle entry wasn’t successful this time.',
    }),
  },
};

type Props = CommonNotificationProps & {
  notification: ShopItemNotification_shopItemNotification;
};
export const ShopItemNotification = ({ notification, ...rest }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { createdAt, name, read, sport } = notification;

  if (!currentUser) {
    return null;
  }

  const { link, message } = props[name] || {};
  return (
    <DumbNotification
      name={name}
      title={
        <Vertical gap={0}>
          <LabelM as="span" bold>
            <FormattedMessage
              id="ShopItemNotification.title"
              defaultMessage="Raffle Results"
            />
          </LabelM>
          <LabelM as="span" color="var(--c-nd-600)">
            <FormattedMessage {...message} />
          </LabelM>
        </Vertical>
      }
      link={link}
      createdAt={createdAt}
      read={read}
      sport={sport}
      {...rest}
    />
  );
};

ShopItemNotification.fragments = {
  shopItemNotification: gql`
    fragment ShopItemNotification_shopItemNotification on ShopItemNotification {
      id
      createdAt
      name
      read
      sport
      shopItem {
        id
      }
      user {
        slug
        id
      }
      ...Notification_notificationInterface
    }
    ${commonNotificationInterfaceFragment}
  ` as TypedDocumentNode<ShopItemNotification_shopItemNotification>,
};
