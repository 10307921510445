import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

export const Cell = styled.div`
  display: table-cell;
  padding: var(--unit);
  background-color: var(--c-nd-100-opaque);

  .section & {
    padding: 0;
  }
  .header & {
    padding: var(--double-unit);
  }

  .stat &,
  .matches & {
    padding: 0 var(--double-unit);
  }

  .stat:first-child & {
    padding-top: var(--double-unit);
  }

  .stat:last-child & {
    padding-bottom: var(--double-unit);
  }
`;

export const Row = styled.div`
  width: 100%;
  display: table-row;

  & > *:first-child {
    width: 150px;
    position: sticky;
    left: 0;
  }

  & > *:not(:first-child) {
    width: 140px;
    text-align: right;

    ${Horizontal} {
      justify-content: flex-end;
    }
  }
`;

export const RowGroup = styled.div`
  display: table-row-group;

  &:not(.expanded) {
    & > .header > * {
      transition: opacity 0.2s ease-in-out;
    }
    &:hover > .header > * {
      background: var(--c-nd-150-opaque);
    }

    & > *:not(:first-child) {
      display: none;
    }
  }

  &:not(:first-of-type) > .header > * {
    border-top: 1px solid var(--c-nd-200);
  }
`;

export const Table = styled.div`
  width: 100%;
  display: table;
  table-layout: fixed;

  &.categories {
    border-radius: var(--double-unit);

    ${RowGroup}:first-child {
      ${Row}:first-child {
        ${Cell}:first-child {
          border-top-left-radius: var(--double-unit);
        }
        ${Cell}:last-child {
          border-top-right-radius: var(--double-unit);
        }
      }
    }
    ${RowGroup}:last-child {
      ${Row}:last-child, &:not(.expanded) ${Row}:first-child {
        ${Cell}:first-child {
          border-bottom-left-radius: var(--double-unit);
        }
        ${Cell}:last-child {
          border-bottom-right-radius: var(--double-unit);
        }
      }
    }
  }
`;
