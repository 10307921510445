import { defineMessages } from 'react-intl';

import { BaseballPosition, Position, Rarity } from '__generated__/globalTypes';

export type Scarcity = 'common' | 'limited' | 'rare' | 'super_rare' | 'unique';

export const getScarcityName = (scarcity: Scarcity | Rarity): string =>
  scarcity.replace('_', ' ');

export const battingThrowingHandMessage = defineMessages({
  header: {
    id: 'MLB.playerStats.battingAndThrowingHands.header',
    defaultMessage: 'Batting / Throwing',
  },
});

export const fixturePositionsNames = defineMessages({
  [Position.baseball_starting_pitcher]: {
    id: 'PositionFilter.startingPitcher',
    defaultMessage: 'Starting Pitcher',
  },
  [Position.baseball_relief_pitcher]: {
    id: 'PositionFilter.reliefPitcher',
    defaultMessage: 'Relief Pitcher',
  },
  [Position.baseball_catcher]: {
    id: 'PositionFilter.baseball_catcher',
    defaultMessage: 'Catcher',
  },
  [Position.baseball_designated_hitter]: {
    id: 'PositionFilter.baseball_designated_hitter',
    defaultMessage: 'Designated hitter',
  },
  [Position.baseball_first_base]: {
    id: 'PositionFilter.baseball_first_base',
    defaultMessage: 'First base',
  },
  [Position.baseball_outfield]: {
    id: 'PositionFilter.baseball_outfield',
    defaultMessage: 'Outfield',
  },
  [Position.baseball_second_base]: {
    id: 'PositionFilter.baseball_second_base',
    defaultMessage: 'Second base',
  },
  [Position.baseball_shortstop]: {
    id: 'PositionFilter.baseball_shortstop',
    defaultMessage: 'Shortstop',
  },
  [Position.baseball_third_base]: {
    id: 'PositionFilter.baseball_third_base',
    defaultMessage: 'Third base',
  },
});

export const positionNames = defineMessages<BaseballPosition>({
  [BaseballPosition.STARTING_PITCHER]: {
    id: 'PositionFilter.startingPitcher',
    defaultMessage: 'Starting Pitcher',
  },
  [BaseballPosition.RELIEF_PITCHER]: {
    id: 'PositionFilter.reliefPitcher',
    defaultMessage: 'Relief Pitcher',
  },
  [BaseballPosition.FIRST_BASE]: {
    defaultMessage: 'First base',
    id: 'PositionFilter.firstBase',
  },
  [BaseballPosition.THIRD_BASE]: {
    defaultMessage: 'Third base',
    id: 'PositionFilter.thirdBase',
  },
  [BaseballPosition.DESIGNATED_HITTER]: {
    defaultMessage: 'Designated hitter',
    id: 'PositionFilter.DesignatedHitter',
  },
  [BaseballPosition.CATCHER]: {
    defaultMessage: 'Catcher',
    id: 'PositionFilter.Catcher',
  },
  [BaseballPosition.SECOND_BASE]: {
    defaultMessage: 'Second base',
    id: 'PositionFilter.SecondBase',
  },
  [BaseballPosition.SHORTSTOP]: {
    defaultMessage: 'Shortstop',
    id: 'PositionFilter.ShortStop',
  },
  [BaseballPosition.OUTFIELD]: {
    defaultMessage: 'Outfield',
    id: 'PositionFilter.Outfield',
  },
  [BaseballPosition.UNKNOWN]: {
    defaultMessage: 'Unknown',
    id: 'PositionFilter.Unknown',
  },
});

type PlayerInjuryStatus =
  | 'DayToDay'
  | '60DayIL'
  | '15DayIL'
  | '10DayIL'
  | '7DayIL'
  | 'Out'
  | 'Suspension';

const shortMessages = defineMessages<PlayerInjuryStatus>({
  DayToDay: {
    id: 'Player.InjuryStatusMessage.Short.DayToDay',
    defaultMessage: 'DTD',
  },
  '60DayIL': {
    id: 'Player.InjuryStatusMessage.Short.60DayIL',
    defaultMessage: 'IL60',
  },
  '15DayIL': {
    id: 'Player.InjuryStatusMessage.Short.15DayIL',
    defaultMessage: 'IL15',
  },
  '10DayIL': {
    id: 'Player.InjuryStatusMessage.Short.10DayIL',
    defaultMessage: 'IL10',
  },
  '7DayIL': {
    id: 'Player.InjuryStatusMessage.Short.7DayIL',
    defaultMessage: 'IL7',
  },
  Out: {
    id: 'Player.InjuryStatusMessage.Short.Out',
    defaultMessage: 'OUT',
  },
  Suspension: {
    id: 'Player.InjuryStatusMessage.Short.Suspension',
    defaultMessage: 'SUSP',
  },
});

const longMessages = defineMessages<PlayerInjuryStatus>({
  DayToDay: {
    id: 'Player.InjuryStatusMessage.Long.DayToDay',
    defaultMessage: 'Day-To-Day',
  },
  '60DayIL': {
    id: 'Player.InjuryStatusMessage.Long.60DayIL',
    defaultMessage: '60-Day IL',
  },
  '15DayIL': {
    id: 'Player.InjuryStatusMessage.Long.15DayIL',
    defaultMessage: '15-Day IL',
  },
  '10DayIL': {
    id: 'Player.InjuryStatusMessage.Long.10DayIL',
    defaultMessage: '10-Day IL',
  },
  '7DayIL': {
    id: 'Player.InjuryStatusMessage.Long.7DayIL',
    defaultMessage: '7-Day IL',
  },
  Out: {
    id: 'Player.InjuryStatusMessage.Long.Out',
    defaultMessage: 'Out',
  },
  Suspension: {
    id: 'Player.InjuryStatusMessage.Long.Suspension',
    defaultMessage: 'Suspension',
  },
});

export function getPlayerInjuryMessage(
  status: string | null,
  { long }: { long?: boolean } = {}
) {
  const messages = long ? longMessages : shortMessages;

  switch (status) {
    case 'Day-To-Day':
      return messages.DayToDay;
    case '60-Day IL':
      return messages['60DayIL'];
    case '15-Day IL':
      return messages['15DayIL'];
    case '10-Day IL':
      return messages['10DayIL'];
    case '7-Day IL':
      return messages['7DayIL'];
    case 'Suspension':
      return messages.Suspension;
    default:
    case 'Out':
      return messages.Out;
  }
}

export type PlayerInjuryColor = 'yellow' | 'red';

export function getPlayerInjuryColor(status: string | null): PlayerInjuryColor {
  switch (status) {
    case 'Day-To-Day':
      return 'yellow';
    default:
      return 'red';
  }
}

export const randomMlbPositions = () => {
  const positions = [
    Position.baseball_catcher,
    Position.baseball_starting_pitcher,
    Position.baseball_relief_pitcher,
    Position.baseball_first_base,
    Position.baseball_second_base,
    Position.baseball_third_base,
    Position.baseball_shortstop,
    Position.baseball_designated_hitter,
  ];
  return positions[Math.floor(Math.random() * positions.length)];
};
