import { css } from 'styled-components';

import { Color } from 'style/types';

export const switchCss = css<{
  $backgroundColor?: Color;
}>`
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 24px;
  background: var(--c-nd-200);
  display: block;
  border-radius: 24px;
  position: relative;
  flex-shrink: 0;
  ${({ $backgroundColor }) =>
    $backgroundColor
      ? css`
          background: ${$backgroundColor};
        `
      : ''}

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    transform: translateX(0px);
    width: 20px;
    height: 20px;
    background: var(--c-white);
    border-radius: 20px;
    transition: transform 0.1s ease-in-out;
  }

  &[aria-checked='true'] {
    background: var(--c-brand-600);
  }

  &[aria-checked='true']:after {
    transform: translateX(calc(100% - 4px));
  }

  &.small {
    width: 24px;
    height: 16px;
    border-radius: 12px;
    &::after {
      width: 12px;
      height: 12px;
      border-radius: 10px;
    }
    &[aria-checked='true']:after {
      transform: translateX(calc(100% - 4px));
    }
  }
`;
