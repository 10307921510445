import { ReactNode } from 'react';
import styled from 'styled-components';

import { Snow } from '../Snow';
import backgroundUrl from '../assets/background.jpg';

const Wrapper = styled.div`
  background-image: url(${backgroundUrl});
  background-size: cover;
  background-position: 20% top;
  position: relative;
  isolation: isolate;
  height: 100%;
`;

type Props = { children?: ReactNode } & React.JSX.IntrinsicElements['div'];

export const NeonChristmasBackground = ({ children, ...props }: Props) => {
  return (
    <Wrapper {...props}>
      <Snow />
      {children}
    </Wrapper>
  );
};
