import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from 'style/types';

const Root = styled.span`
  position: relative;
  isolation: isolate;
  display: inline-flex;
`;
const Stroked = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--stroke-color);
  -webkit-text-stroke: var(--stroke-width) var(--stroke-color);
  z-index: -1;
`;

type Props = {
  children: ReactNode;
  strokeColor?: Color;
  strokeWidth?: number;
  xOffset?: number;
  yOffset?: number;
};
export const StrokedText = ({
  children,
  strokeColor = 'var(--c-black)',
  strokeWidth = 1,
  xOffset = 0,
  yOffset = 0,
}: Props) => {
  return (
    <Root>
      <Stroked
        style={
          {
            '--stroke-color': strokeColor,
            '--stroke-width': `${strokeWidth}px`,
            left: `${xOffset}px`,
            top: `${yOffset}px`,
          } as CSSProperties
        }
      >
        {children}
      </Stroked>
      {children}
    </Root>
  );
};
