import {
  Position as AnyPosition,
  BaseballPosition,
  NBAPosition,
  Sport,
} from '__generated__/globalTypes';

import { allPositions } from './players';

const positions: Record<
  AnyPosition | BaseballPosition | NBAPosition,
  { readonly name: string; readonly initials: string }
> = {
  [AnyPosition.Unknown]: { name: 'Unknown', initials: '--' },

  // FOOTBALL
  [AnyPosition.Coach]: { name: 'Coach', initials: 'C' },
  [AnyPosition.Goalkeeper]: { name: 'Goalkeeper', initials: 'GK' },
  [AnyPosition.Defender]: { name: 'Defender', initials: 'DF' },
  [AnyPosition.Midfielder]: { name: 'Midfielder', initials: 'MD' },
  [AnyPosition.Forward]: { name: 'Forward', initials: 'FW' },

  // Baseball
  [BaseballPosition.STARTING_PITCHER]: {
    name: 'Starting pitcher',
    initials: 'SP',
  },
  [BaseballPosition.RELIEF_PITCHER]: {
    name: 'Relief pitcher',
    initials: 'RP',
  },
  [BaseballPosition.FIRST_BASE]: { name: 'First base', initials: '1B' },
  [BaseballPosition.THIRD_BASE]: { name: 'Third base', initials: '3B' },
  [BaseballPosition.DESIGNATED_HITTER]: {
    name: 'Designated hitter',
    initials: 'DH',
  },
  [BaseballPosition.CATCHER]: { name: 'Catcher', initials: 'C' },
  [BaseballPosition.SECOND_BASE]: { name: 'Second base', initials: '2B' },
  [BaseballPosition.SHORTSTOP]: { name: 'Shortstop', initials: 'SS' },
  [BaseballPosition.OUTFIELD]: { name: 'Outfield', initials: 'OF' },

  [AnyPosition.baseball_starting_pitcher]: {
    name: 'Starting pitcher',
    initials: 'SP',
  },
  [AnyPosition.baseball_relief_pitcher]: {
    name: 'Relief pitcher',
    initials: 'RP',
  },
  [AnyPosition.baseball_first_base]: { name: 'First base', initials: '1B' },
  [AnyPosition.baseball_third_base]: { name: 'Third base', initials: '3B' },
  [AnyPosition.baseball_designated_hitter]: {
    name: 'Designated hitter',
    initials: 'DH',
  },
  [AnyPosition.baseball_catcher]: { name: 'Catcher', initials: 'C' },
  [AnyPosition.baseball_second_base]: { name: 'Second base', initials: '2B' },
  [AnyPosition.baseball_shortstop]: { name: 'Shortstop', initials: 'SS' },
  [AnyPosition.baseball_outfield]: { name: 'Outfield', initials: 'OF' },

  // NBA
  [NBAPosition.GUARD]: {
    name: 'Guard',
    initials: 'G',
  },
  [NBAPosition.CENTER]: {
    name: 'Center',
    initials: 'C',
  },
  [NBAPosition.FORWARD]: {
    name: 'Forward',
    initials: 'F',
  },
  [NBAPosition.UNKNOWN]: {
    name: 'Unknown',
    initials: 'U',
  },
  [AnyPosition.basketball_center]: {
    name: 'Center',
    initials: 'C',
  },
  [AnyPosition.basketball_forward]: {
    name: 'Forward',
    initials: 'F',
  },
  [AnyPosition.basketball_guard]: {
    name: 'Guard',
    initials: 'G',
  },
} as const;

export type Position = keyof typeof positions;

export const Positions = Object.keys(positions) as Position[];

export const getPositionInitials = (p: Position): string => {
  return positions[p].initials;
};

export const getPositionName = (p: Position): string => {
  return positions[p].name;
};

export const positionsBySport = {
  [Sport.FOOTBALL]: [...allPositions],
  [Sport.BASEBALL]: [
    AnyPosition.baseball_catcher,
    AnyPosition.baseball_designated_hitter,
    AnyPosition.baseball_first_base,
    AnyPosition.baseball_outfield,
    AnyPosition.baseball_relief_pitcher,
    AnyPosition.baseball_second_base,
    AnyPosition.baseball_shortstop,
    AnyPosition.baseball_starting_pitcher,
    AnyPosition.baseball_third_base,
  ],
  [Sport.NBA]: [
    AnyPosition.basketball_guard,
    AnyPosition.basketball_center,
    AnyPosition.basketball_forward,
  ],
};
