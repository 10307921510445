import { SVGProps } from 'react';

import { unitMapping } from 'lib/style';

import { IconColor, useIconColor } from './useIconColor';

interface SVGRProps {
  title?: string;
  titleId?: string;
  color?: IconColor;
  size?: keyof typeof unitMapping;
}

export const StarEmpty = ({
  title,
  titleId,
  color,
  size = 2,
  ...props
}: Omit<SVGProps<SVGSVGElement>, 'color'> & SVGRProps) => {
  const { fill, svgNode } = useIconColor(color);
  return (
    <svg
      viewBox="0 0 36 36"
      style={{
        width: unitMapping[size],
        height: unitMapping[size],
      }}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M17.9999 3.26251L19.7118 2.70622H16.288L17.9999 3.26251ZM21.5868 14.3005L19.8749 14.8568L20.2791 16.1005L21.5868 16.1005L21.5868 14.3005ZM33.193 14.301L34.2511 15.7572L33.1931 12.501L33.193 14.301ZM23.8037 21.1233L22.7456 19.6671L21.6877 20.4358L22.0917 21.6794L23.8037 21.1233ZM27.3898 32.1616L26.3318 33.6178L29.1017 31.6054L27.3898 32.1616ZM17.9999 25.34L19.0578 23.8837L17.9999 23.1151L16.9419 23.8837L17.9999 25.34ZM8.61002 32.1616L6.8981 31.6054L9.66798 33.6178L8.61002 32.1616ZM12.1961 21.1233L13.908 21.6794L14.3121 20.4358L13.2542 19.6671L12.1961 21.1233ZM2.80676 14.301L2.80669 12.501L1.74869 15.7572L2.80676 14.301ZM14.413 14.3005L14.413 16.1005L15.7207 16.1005L16.1248 14.8568L14.413 14.3005ZM16.288 3.81881L19.8749 14.8568L23.2987 13.7442L19.7118 2.70622L16.288 3.81881ZM21.5868 16.1005L33.1929 16.101L33.1931 12.501L21.5869 12.5005L21.5868 16.1005ZM32.1349 12.8448L22.7456 19.6671L24.8617 22.5795L34.2511 15.7572L32.1349 12.8448ZM22.0917 21.6794L25.6778 32.7177L29.1017 31.6054L25.5156 20.5671L22.0917 21.6794ZM28.4477 30.7053L19.0578 23.8837L16.9419 26.7962L26.3318 33.6178L28.4477 30.7053ZM16.9419 23.8837L7.55206 30.7053L9.66798 33.6178L19.0578 26.7962L16.9419 23.8837ZM10.3219 32.7177L13.908 21.6794L10.4842 20.5671L6.8981 31.6054L10.3219 32.7177ZM13.2542 19.6671L3.86483 12.8448L1.74869 15.7572L11.138 22.5795L13.2542 19.6671ZM2.80683 16.101L14.413 16.1005L14.4129 12.5005L2.80669 12.501L2.80683 16.101ZM16.1248 14.8568L19.7118 3.81881L16.288 2.70622L12.7011 13.7442L16.1248 14.8568Z"
        fill={fill}
      />
      {svgNode}
    </svg>
  );
};
