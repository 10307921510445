import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled, { css } from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Caption } from '@sorare/core/src/atoms/typography';
import { ManagerTaskTooltip } from '@sorare/core/src/components/onboarding/managerTask/ManagerTaskTooltip';
import { MarketplaceOnboardingTask } from '@sorare/core/src/components/onboarding/managerTask/MarketplaceOnboardingTask';
import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from '@sorare/core/src/contexts/managerTask';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { glossary } from '@sorare/core/src/lib/glossary';

import {
  ButtonContainer,
  TokenDetailsRoot,
  TokenDetailsRow,
} from 'components/ItemPreview/ui';
import TokenListingsCount from 'components/TokenPreview/TokenListingsCount';
import TokenOwner from 'components/TokenPreview/TokenOwner';
import AuctionTimeLeft from 'components/auction/AuctionTimeLeft';
import BuyField from 'components/buyActions/BuyField';

import { SalePrice } from '../SalePrice';
import {
  LiveSaleDetails_anyCard,
  LiveSaleDetails_offer,
} from './__generated__/index.graphql';

const messages = defineMessages({
  button: {
    id: 'TokenOfferDetails.tooltip.buttonLabel',
    defaultMessage: 'Thanks for the training!',
  },
});

const SaleInfoContainer = styled(Horizontal)`
  min-width: 0;
  min-height: var(--triple-unit);
  color: var(--c-nd-600);
`;

const StyledCaption = styled(Caption)<{ $isDesktopLayout: boolean }>`
  ${({ $isDesktopLayout }) =>
    !$isDesktopLayout
      ? css`
          display: flex;
          gap: var(--half-unit);
        `
      : css``}
  overflow: hidden;
`;

const DivElement: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div>{children}</div>
);

type Props = {
  sale: LiveSaleDetails_offer;
  card: LiveSaleDetails_anyCard;
  isDesktopLayout?: boolean;
  stackedTokensCount?: number;
  displayMarketplaceOnboardingTooltip?: boolean;
  allowColumnLayout?: boolean;
  showFees?: boolean;
};

export const LiveSaleDetails = ({
  sale,
  card,
  isDesktopLayout,
  stackedTokensCount,
  displayMarketplaceOnboardingTooltip,
  allowColumnLayout,
  showFees,
}: Props) => {
  const { setStep, onSuccessCallback, setTask, task } = useManagerTaskContext();

  const isStackedOffer = stackedTokensCount && stackedTokensCount > 1;
  const Wrapper = isDesktopLayout ? DivElement : Fragment;
  const { up: isTabletOrDesktop } = useScreenSize('tablet');

  return (
    <TokenDetailsRoot className={classNames({ allowColumnLayout })}>
      {isStackedOffer ? (
        <>
          <div>
            {isDesktopLayout && (
              <SaleInfoContainer>
                <Caption color="var(--c-nd-600)">
                  <FormattedMessage {...glossary.startingAt} />
                </Caption>
              </SaleInfoContainer>
            )}
            <SalePrice sale={sale} showFees={showFees} />
          </div>
          <TokenDetailsRow>
            <Caption color="var(--c-nd-600)">
              <TokenListingsCount count={stackedTokensCount} />
            </Caption>
          </TokenDetailsRow>
        </>
      ) : (
        <>
          <Wrapper>
            <SalePrice sale={sale} showFees={showFees} />
            <TokenDetailsRow>
              <StyledCaption
                color="var(--c-nd-600)"
                as="div"
                $isDesktopLayout={!!isDesktopLayout}
              >
                {isDesktopLayout ? (
                  <FormattedMessage
                    id="TokenDesktopPreviewContent.directSale"
                    defaultMessage="Manager sale"
                  />
                ) : (
                  <TokenOwner card={card} withAvatar />
                )}
                {' • '}
                <AuctionTimeLeft
                  endDate={sale.endDate}
                  withExplicitTime={isTabletOrDesktop && isDesktopLayout}
                />
              </StyledCaption>
            </TokenDetailsRow>
          </Wrapper>
          {isDesktopLayout && (
            <TokenDetailsRow>
              <TokenOwner card={card} withAvatar />
            </TokenDetailsRow>
          )}
        </>
      )}

      <ButtonContainer $isDesktopLayout={isDesktopLayout}>
        <ManagerTaskTooltip
          name={MarketplaceOnboardingStep.buy}
          TaskComponent={MarketplaceOnboardingTask}
          onClick={() => {
            onSuccessCallback?.();
            setTask();
            setStep();
          }}
          taskProps={{
            buttonLabel: messages.button,
          }}
          noBackdrop
          placement={isDesktopLayout ? 'right-end' : 'bottom-start'}
          fullWidth={isDesktopLayout}
          disable={!task || !displayMarketplaceOnboardingTooltip}
        >
          <BuyField
            color="quaternary"
            card={card}
            variant="small"
            fullWidth={isDesktopLayout}
            buttonLabel={
              isStackedOffer ? (
                <FormattedMessage {...glossary.buyLowest} />
              ) : undefined
            }
          />
        </ManagerTaskTooltip>
      </ButtonContainer>
    </TokenDetailsRoot>
  );
};

LiveSaleDetails.fragments = {
  anyCard: gql`
    fragment LiveSaleDetails_anyCard on AnyCardInterface {
      slug
      sport
      ...TokenOwner_anyCard
      ...BuyField_anyCard
    }
    ${TokenOwner.fragments.anyCard}
    ${BuyField.fragments.anyCard}
  ` as TypedDocumentNode<LiveSaleDetails_anyCard>,
  offer: gql`
    fragment LiveSaleDetails_offer on TokenOffer {
      id
      endDate
      ...SalePrice_offer
    }
    ${SalePrice.fragments.offer}
  ` as TypedDocumentNode<LiveSaleDetails_offer>,
};
