import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faCheck,
  faMemo,
  faUpRightFromSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Lightning } from '@sorare/core/src/atoms/icons/Lightning';
import { StarBall } from '@sorare/core/src/atoms/icons/StarBall';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  BodyS,
  HeadlineM,
  LabelM,
  LabelS,
} from '@sorare/core/src/atoms/typography';
import { Card3DWithFallback } from '@sorare/core/src/components/3d/Card3DWithFallback';
import { cardsPreviewContainerStyle } from '@sorare/core/src/components/bundled/CardsPreviewContainer';
import { Card } from '@sorare/core/src/components/card/Card';
import { ScarcityBackground } from '@sorare/core/src/components/card/ScarcityBackground';
import { TokenMetas } from '@sorare/core/src/components/token/TokenMetas';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import useFeatureFlags from '@sorare/core/src/hooks/useFeatureFlags';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import CardBonus from 'components/card/CardBonus';
import PriceHistoryTooltip from 'components/price/PriceHistoryTooltip';

import EligibilityTooltip from './EligibilityTooltip';
import { CardOverview_anyCard } from './__generated__/index.graphql';

const Leftcolumn = styled(Vertical)`
  align-self: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  @media ${laptopAndAbove} {
    align-items: center;
    justify-content: center;
  }
`;

const Item = styled(Horizontal)`
  padding: var(--unit) var(--double-unit);
  gap: var(--unit);
  width: 100%;
  @media ${laptopAndAbove} {
    gap: 0;
    flex-direction: column;
  }
`;

const RecentSales = styled(ButtonBase)`
  display: flex;
  align-self: center;
  padding: var(--unit);
  gap: var(--double-unit);
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.11);

  @media ${laptopAndAbove} {
    width: auto;
    margin: 0 auto;
    border-top: none;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  max-width: 80px;
  aspect-ratio: var(--card-aspect-ratio);
  @media ${laptopAndAbove} {
    max-width: 300px;
    min-height: 0;
  }
`;

const Metas = styled(Vertical)`
  align-items: flex-start;
  @media ${laptopAndAbove} {
    align-items: center;
  }
`;

const StyledSorareLogo = styled(Horizontal)`
  font-weight: var(--t-bold);
`;

const StyledStarBall = styled(StarBall)`
  width: 12px;
  height: 12px;
`;

const Attributes = styled(Horizontal)`
  flex-wrap: wrap;
`;

const AttributeWrapper = styled(Horizontal)`
  border-radius: var(--double-unit);
  background-color: rgba(var(--c-rgb-neutral-1000), 0.1);
`;

const Row = styled(AttributeWrapper)`
  padding-left: var(--unit);
`;

const Value = styled(AttributeWrapper)`
  padding: var(--half-unit) var(--unit);
`;

const BundlePreview = styled.div`
  display: flex;
  gap: var(--unit);

  @media ${laptopAndAbove} {
    max-width: 500px;
    margin: var(--triple-unit) 0;
    ${cardsPreviewContainerStyle}
  }
`;

const Attribute = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => {
  const isLaptopAndAbove = useIsLaptop();
  const TextTag = isLaptopAndAbove ? LabelM : LabelS;
  return (
    <Row gap={1}>
      <TextTag>{label}</TextTag>
      <TextTag bold>{value}</TextTag>
    </Row>
  );
};

const StyledLink = styled.a`
  opacity: 0.5;
  display: flex;
  padding: var(--double-unit);
  gap: var(--unit);
  align-self: center;
`;

const CenteredContent = styled(Vertical).attrs({ gap: 0 })`
  width: 100%;
  margin: auto 0;
`;

const CardOverview = ({
  cards,
  soldOnPrimary,
  showRecentSales = true,
}: {
  cards: CardOverview_anyCard[];
  soldOnPrimary?: boolean;
  showRecentSales?: boolean;
}) => {
  const {
    flags: { useEarlyAccessDetailsLink = '/blog', useEnableEarlyAccessFlows },
  } = useFeatureFlags();
  const isLaptopAndAbove = useIsLaptop();
  return (
    <Leftcolumn gap={0}>
      <ScarcityBackground
        rarity={cards[0].rarityTyped}
        christmas={!!cards[0]?.cardEditionName?.startsWith('neon_christmas')}
      />
      <CenteredContent center={isLaptopAndAbove}>
        <Item>
          {cards.length > 1 ? (
            <BundlePreview>
              {cards.map(card => (
                <Card key={card.slug} width={320} card={card} />
              ))}
            </BundlePreview>
          ) : (
            <CardWrapper>
              <Card3DWithFallback
                card={cards[0]}
                forceDisable3d={!isLaptopAndAbove}
              />
            </CardWrapper>
          )}
          <Metas>
            {cards.length === 1 && cards[0].sport === Sport.FOOTBALL && (
              <>
                {!isLaptopAndAbove && (
                  <div>
                    <HeadlineM>{cards[0].anyPlayer.displayName}</HeadlineM>
                    <BodyS>
                      <TokenMetas card={cards[0]} separator={' \u2013 '} />
                    </BodyS>
                  </div>
                )}
                <Attribute
                  label={
                    <>
                      <FontAwesomeIcon icon={faCheck} />{' '}
                      <FormattedMessage
                        id="CardOverview.playable"
                        defaultMessage="Playable"
                      />
                    </>
                  }
                  value={
                    cards.length === 1 && (
                      <Value>
                        <EligibilityTooltip card={cards[0]} />
                      </Value>
                    )
                  }
                />
              </>
            )}
            {soldOnPrimary && (cards.length === 1 || isLaptopAndAbove) && (
              <Attributes>
                <Attribute
                  label={
                    <Horizontal gap={1}>
                      <FormattedMessage
                        id="CardOverview.soldBy"
                        defaultMessage="Sold by"
                      />
                      <StyledSorareLogo gap={0.5}>
                        <StyledStarBall color="var(--c-black)" />
                        Sorare
                      </StyledSorareLogo>
                    </Horizontal>
                  }
                  value={<CardBonus card={cards[0]} />}
                />
                <Attribute
                  label={
                    <Horizontal>
                      <FormattedMessage
                        id="CardOverview.newCard"
                        defaultMessage="New Card"
                      />
                    </Horizontal>
                  }
                  value={
                    <Value>
                      20 <Lightning />
                    </Value>
                  }
                />
              </Attributes>
            )}
          </Metas>
        </Item>
        {cards.length === 1 && showRecentSales && (
          <PriceHistoryTooltip
            card={cards[0]}
            displayTransactionType
            context="Payment Box"
            Label={props => (
              <RecentSales {...props}>
                <FontAwesomeIcon icon={faMemo} />
                <LabelM>
                  <FormattedMessage
                    id="CardOverview.recentSales"
                    defaultMessage="View recent sales"
                  />
                </LabelM>
              </RecentSales>
            )}
          />
        )}
      </CenteredContent>
      {cards[0].blueprint && useEnableEarlyAccessFlows && isLaptopAndAbove && (
        <StyledLink href={useEarlyAccessDetailsLink} target="_blank">
          <FontAwesomeIcon icon={faUpRightFromSquare} size="xs" />
          <LabelS>
            <FormattedMessage
              id="CardOverview.cardCount"
              defaultMessage="Learn more information on Early Access cards"
            />
          </LabelS>
        </StyledLink>
      )}
    </Leftcolumn>
  );
};

CardOverview.fragments = {
  anyCard: gql`
    fragment CardOverview_anyCard on AnyCardInterface {
      slug
      cardEditionName
      rarityTyped
      seasonYear
      singleCivilYear
      anyPlayer {
        slug
        displayName
      }
      sport
      blueprint {
        id
      }
      ...Card_anyCard
      ...EligibilityTooltip_anyCard
      ...TokenMetas_anyCard
      ...PriceHistoryTooltip_anyCard
      ...CardBonus_anyCard
      ...Card3DWithFallback_anyCardInterface
    }
    ${Card.fragments.anyCard}
    ${EligibilityTooltip.fragments.anyCard}
    ${TokenMetas.fragments.anyCard}
    ${PriceHistoryTooltip.fragments.anyCard}
    ${CardBonus.fragments.anyCard}
    ${Card3DWithFallback.fragments.anyCardInterface}
  ` as TypedDocumentNode<CardOverview_anyCard>,
};
export default CardOverview;
