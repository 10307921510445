import { TypedDocumentNode, gql } from '@apollo/client';
import { isFuture } from 'date-fns';

import { EndedSaleDetails } from './EndedSaleDetails';
import { LiveSaleDetails } from './LiveSaleDetails';
import {
  SaleDetails_anyCard,
  SaleDetails_offer,
} from './__generated__/index.graphql';

type Props = {
  sale: SaleDetails_offer;
  card: SaleDetails_anyCard;
  isDesktopLayout?: boolean;
  stackedTokensCount?: number;
  displayMarketplaceOnboardingTooltip?: boolean;
  allowColumnLayout?: boolean;
  showFees?: boolean;
  onSuccess?: () => void;
};

export const SaleDetails = ({
  sale,
  card,
  allowColumnLayout,
  ...rest
}: Props) => {
  const isLive = sale?.endDate ? isFuture(sale.endDate) : true;

  if (isLive) {
    return (
      <LiveSaleDetails
        sale={sale}
        card={card}
        allowColumnLayout={allowColumnLayout}
        {...rest}
      />
    );
  }

  return (
    <EndedSaleDetails
      sale={sale}
      card={card}
      allowColumnLayout={allowColumnLayout}
    />
  );
};

SaleDetails.fragments = {
  anyCard: gql`
    fragment SaleDetails_anyCard on AnyCardInterface {
      slug
      ...EndedSaleDetails_anyCard
      ...LiveSaleDetails_anyCard
    }
    ${LiveSaleDetails.fragments.anyCard}
    ${EndedSaleDetails.fragments.anyCard}
  ` as TypedDocumentNode<SaleDetails_anyCard>,
  offer: gql`
    fragment SaleDetails_offer on TokenOffer {
      id
      endDate
      ...LiveSaleDetails_offer
      ...EndedSaleDetails_offer
    }
    ${LiveSaleDetails.fragments.offer}
    ${EndedSaleDetails.fragments.offer}
  ` as TypedDocumentNode<SaleDetails_offer>,
};
