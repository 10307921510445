import * as RadixPopover from '@radix-ui/react-popover';
import styled from 'styled-components';

import { theme } from 'style/theme';

export const PopoverPortal = RadixPopover.Portal;
export const PopoverTrigger = RadixPopover.Trigger;
export const PopoverContent = styled(RadixPopover.Content)`
  z-index: ${theme.zIndex.modal};
`;
export const PopoverRoot = RadixPopover.Root;
export const PopoverClose = RadixPopover.Close;
export const PopoverArrow = RadixPopover.Arrow;
