import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Card3DWithFallback } from '@sorare/core/src/components/3d/Card3DWithFallback';
import { HexColorOnly } from '@sorare/core/src/style/types';

import { cellBackground } from '../../styles';
import { Step_anyCard } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({
  gap: 2,
  center: true,
})`
  height: 100%;
  padding: calc(6 * var(--unit)) 0 calc(9 * var(--unit));
  --color: #f1ff000f;
  justify-content: space-between;
  overflow: hidden;
  ${cellBackground}
`;

const CardWrapper = styled.div`
  max-height: 40vh;
  flex: 1;
`;

const Badge = styled.div`
  border-radius: var(--double-unit);
  height: var(--triple-unit);
  padding: 2px var(--unit);
  border: 1px solid var(--color);
  background: linear-gradient(-25deg, var(--color) 50%, transparent 50%)
    padding-box;
`;
const Foreground = styled.div`
  position: relative;
  z-index: 1;
  padding: var(--double-unit);
  width: 100%;
  max-width: 100%;
`;

const ContentWrapper = styled(Vertical)`
  flex: 1;
`;

type Props = {
  card: Nullable<Step_anyCard>;
  badge: ReactNode;
  color: HexColorOnly;
  description: ReactNode;
};
export const Step = ({ card, badge, color, description }: Props) => {
  return (
    <Root style={{ '--color': `${color}50` } as CSSProperties}>
      <ContentWrapper center gap={2}>
        <CardWrapper>{card && <Card3DWithFallback card={card} />}</CardWrapper>
        <Badge>{badge}</Badge>
      </ContentWrapper>
      <Foreground>{description}</Foreground>
    </Root>
  );
};

Step.fragments = {
  anyCard: gql`
    fragment Step_anyCard on AnyCardInterface {
      slug
      ...Card3DWithFallback_anyCardInterface
    }
    ${Card3DWithFallback.fragments.anyCardInterface}
  ` as TypedDocumentNode<Step_anyCard>,
};
