import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, LabelXS } from '@sorare/core/src/atoms/typography';
import { Ellipsis } from '@sorare/core/src/atoms/typography/Ellipsis';
import { Ordinal } from '@sorare/core/src/atoms/typography/Ordinal';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { glossary } from '@sorare/core/src/lib/glossary';

import { GridDiv } from '@sorare/gameplay/src/components/rivals/GridBackground/BackgroundAligned';

import { PodiumAvatar_user } from './__generated__/index.graphql';
import first from './assets/1st.png';
import second from './assets/2nd.png';
import third from './assets/3rd.png';

const RelativeWrapper = styled.div`
  isolation: isolate;
  position: relative;
`;
const PodiumImg = styled.img`
  position: absolute;
  bottom: -11px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;

  width: 80px;
  .first & {
    width: 120%;
  }
`;
const DropShadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
  filter: drop-shadow(0 0 30px var(--drop-shadow-color));
  z-index: 1;
`;
const AvatarWrapper = styled(Horizontal).attrs({ center: true })`
  position: absolute;
  inset: 0;
  margin: 0 auto;
  top: calc(50% - 15 * var(--half-unit));
  z-index: 0;

  .third & {
    top: calc(50% - 16 * var(--half-unit));
  }
  .second & {
    top: calc(50% - 12 * var(--half-unit));
  }
  .first & {
    top: calc(50% - 13 * var(--half-unit));
  }
`;
const Rank = styled.div`
  position: absolute;
  transform: translate(-55%);
  left: 50%;
  top: 56px;
  z-index: 3;

  .third & {
    top: 54px;
  }
  .second & {
    top: 56px;
  }
  .first & {
    top: 56px;
  }
`;

type Props = {
  user: PodiumAvatar_user;
  rank: number;
  width: number;
  nickname?: string;
};
export const PodiumAvatar = ({ user, rank, width, nickname }: Props) => {
  let src = first;
  let dropShadowColor = '#FEA657';

  if (rank === 2) {
    src = second;
    dropShadowColor = 'var(--c-rivals-friendlies)';
  }
  if (rank === 3) {
    src = third;
    dropShadowColor = '#FF00F5';
  }

  return (
    <Vertical center gap={3}>
      <RelativeWrapper
        className={classNames({
          first: rank === 1,
          second: rank === 2,
          third: rank === 3,
        })}
      >
        <DropShadow
          style={
            {
              '--drop-shadow-color': dropShadowColor,
            } as CSSProperties
          }
        >
          <PodiumImg src={src} style={{ width }} />
        </DropShadow>

        <GridDiv>
          <AvatarWrapper>
            <Avatar
              user={user}
              variant="responsive"
              rounded
              size={rank === 1 ? 5.5 : 5}
            />
          </AvatarWrapper>
        </GridDiv>

        <Rank>
          <LabelXS as="span" bold>
            <Ordinal i18n={glossary.ordinal} ordinal={rank} />
          </LabelXS>
        </Rank>
      </RelativeWrapper>
      <LabelM as="span" bold>
        <Ellipsis>{nickname || user.nickname}</Ellipsis>
      </LabelM>
    </Vertical>
  );
};

PodiumAvatar.fragments = {
  user: gql`
    fragment PodiumAvatar_user on PublicUserInfoInterface {
      slug
      nickname
      ...Avatar_publicUserInfoInterface
    }
    ${Avatar.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<PodiumAvatar_user>,
};
