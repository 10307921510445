import { isPast, parseISO } from 'date-fns';

import {
  ConversionCreditCampaign,
  ConversionCreditCampaignStatus,
  Sport,
  SupportedCurrency,
} from '__generated__/globalTypes';
import { useSportContext } from 'contexts/sport';
import { useMonetaryAmount } from 'hooks/useMonetaryAmount';

import { SESSION_STORAGE, useSessionStorage } from './useSessionStorage';

export const useSignupConversionCreditCampaign = ():
  | (ConversionCreditCampaign & { sport?: Sport | null })
  | undefined => {
  const { sport } = useSportContext();
  const [campaign] = useSessionStorage(SESSION_STORAGE.signupPromo);
  const { toMonetaryAmount } = useMonetaryAmount();

  if (
    !campaign?.sport ||
    campaign.sport !== sport ||
    campaign.status !== ConversionCreditCampaignStatus.ACTIVE ||
    isPast(parseISO(campaign.endDate))
  ) {
    return undefined;
  }

  const { maxDiscount } = campaign;
  const amounts = toMonetaryAmount(maxDiscount);

  return {
    ...campaign,
    endDate: parseISO(campaign.endDate),
    maxDiscount: {
      __typename: 'MonetaryAmount',
      referenceCurrency: SupportedCurrency.WEI,
      eur: amounts.eur,
      usd: amounts.usd,
      gbp: amounts.gbp,
      wei: amounts.wei,
    },
  };
};
