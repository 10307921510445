import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { SBHorizontal, Vertical } from 'atoms/layout/flex';
import { Text16, Title4 } from 'atoms/typography';
import { Bold } from 'atoms/typography/Bold';

const Container = styled(Vertical)`
  align-items: stretch;
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

type Description = MessageDescriptor | MessageDescriptor[];

export type Props = {
  title?: MessageDescriptor;
  description?: Description;
  toggleButton?: ReactNode;
};

const ToggleButton = styled.div``;

const SingleDescription = ({
  description,
}: {
  description: MessageDescriptor;
}) => (
  <Text16 color="var(--c-nd-600)">
    <FormattedMessage {...description} values={{ bold: Bold }} />
  </Text16>
);

const DescriptionDisplayer = ({
  description,
}: {
  description: Description;
}) => {
  if (Array.isArray(description)) {
    return (
      <>
        {description.map(d => {
          return <SingleDescription key={d.id} description={d} />;
        })}
      </>
    );
  }
  return <SingleDescription description={description} />;
};

export const Header = ({ description, title, toggleButton }: Props) => (
  <>
    {(description || title) && (
      <SBHorizontal>
        <Container>
          {title && (
            <Title>
              <Title4>
                <FormattedMessage {...title} />
              </Title4>
            </Title>
          )}
          {description && <DescriptionDisplayer description={description} />}
        </Container>
        {toggleButton && <ToggleButton>{toggleButton}</ToggleButton>}
      </SBHorizontal>
    )}
  </>
);
