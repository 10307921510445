import { ReactNode, useMemo, useState } from 'react';
import { Params, useLocation } from 'react-router-dom';

import { Sport } from '__generated__/globalTypes';
import {
  generateSportPath,
  matchAnySportPath,
} from 'lib/routing/generateSportPath';
import { sportFromPathAndLocalStorage } from 'lib/routing/sportFromPathAndLocalStorage';

import SportContextProvider from '.';

interface Props {
  children: ReactNode;
}

const SportProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const [sport, setSport] = useState<Sport>(
    sportFromPathAndLocalStorage(pathname)
  );

  const value = useMemo(
    () => ({
      sport,
      setSport,
      // wrap the `generateSportPath` to handle the default sport
      generateSportContextPath: (
        path: string,
        { params, sport: s = sport }: { params?: Params; sport?: Sport } = {}
      ) => generateSportPath(path, { params, sport: s }),
      // eslint-disable-next-line @typescript-eslint/no-shadow
      matchAnySportPath: (pattern: string, pathname: string, s?: Sport) =>
        matchAnySportPath(pattern, pathname, s || sport),
    }),
    [sport]
  );

  return <SportContextProvider value={value}>{children}</SportContextProvider>;
};

export default SportProvider;
