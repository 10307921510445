import { differenceInDays } from 'date-fns';

import { VerifyPhoneNumber } from 'components/user/VerifyPhoneNumber';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useRestrictedAccessContext } from 'contexts/restrictedAccess';
import EmailVerificationDialog from 'contexts/restrictedAccess/EmailVerificationDialog';
import { useSkipOneDayEmailVerificationLatency } from 'hooks/useSkipOneDayEmailVerificationLatency';

export const RestrictedAccessModals = () => {
  const { currentUser } = useCurrentUserContext();

  const skipOneDayEmailVerificationLatency =
    useSkipOneDayEmailVerificationLatency();
  const { showRestrictedAccess, setShowRestrictedAccess } =
    useRestrictedAccessContext();

  const displayVerifyEmail =
    currentUser &&
    (skipOneDayEmailVerificationLatency ||
      differenceInDays(new Date(), currentUser.createdAt) > 1) &&
    !currentUser.confirmed;

  if (displayVerifyEmail || showRestrictedAccess === 'email')
    return (
      <EmailVerificationDialog
        closable={!displayVerifyEmail}
        onClose={() => setShowRestrictedAccess(undefined)}
      />
    );
  if (showRestrictedAccess === 'phone')
    return (
      <VerifyPhoneNumber onCancel={() => setShowRestrictedAccess(undefined)} />
    );

  return null;
};
