import { LabelS } from '@sorare/core/src/atoms/typography';

type Props = { displayName: string };

export const CompetitionName = ({ displayName }: Props) => {
  return (
    <LabelS as="span" color="var(--c-nd-600)">
      {displayName}
    </LabelS>
  );
};
