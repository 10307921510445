// https://github.com/w3c/csswg-drafts/issues/3744
// Allow for a callback after the scroll has finished
export const scrollIntoViewAndWait = async (
  element: HTMLElement,
  scrollOption?: ScrollIntoViewOptions
) => {
  return new Promise(resolve => {
    if ('onscrollend' in window) {
      window.addEventListener('scrollend', resolve, { once: true });
      element.scrollIntoView(
        scrollOption || {
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        }
      );
    } else {
      element.scrollIntoView(
        scrollOption || { block: 'center', inline: 'center' }
      );
      resolve(undefined);
    }
  });
};
