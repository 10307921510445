import { TypedDocumentNode, gql } from '@apollo/client';
import { isAfter } from 'date-fns';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';

import { TokenOwnerPrice } from '../TokenOwnerPrice';
import TokenTransferTypeIcon from '../TokenTransferTypeIcon';
import { TokenOwnerTransfer_owner } from './__generated__/index.graphql';

interface Props {
  tokenOwner: TokenOwnerTransfer_owner | null;
}

const Root = styled(Horizontal)`
  color: var(--c-white);
`;

const oneYearAgo = new Date();
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

export const TokenOwnerTransfer = ({ tokenOwner }: Props) => {
  if (!tokenOwner) return null;

  const boughtLessThanAYearAgo = isAfter(new Date(tokenOwner.from), oneYearAgo);

  return (
    <Root>
      <TokenTransferTypeIcon transferType={tokenOwner.transferType} />
      {boughtLessThanAYearAgo && <TokenOwnerPrice tokenOwner={tokenOwner} />}
    </Root>
  );
};

TokenOwnerTransfer.fragments = {
  tokenOwner: gql`
    fragment TokenOwnerTransfer_owner on TokenOwner {
      id
      transferType
      from
      ...TokenOwnerPrice_tokenOwner
    }
    ${TokenOwnerPrice.fragments.tokenOwner}
  ` as TypedDocumentNode<TokenOwnerTransfer_owner>,
};

export default TokenOwnerTransfer;
