import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { NoIndex } from '@sorare/core/src/contexts/seo';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

const Root = styled.div`
  min-height: inherit;
  position: relative;
  &:not(.cards):not(.home) {
    padding: var(--double-unit);
    @media ${laptopAndAbove} {
      padding: var(--triple-unit);
    }
  }
  &.cards {
    padding: 0 var(--double-unit);
    @media ${laptopAndAbove} {
      padding: 0 var(--triple-unit);
    }
  }
`;
const BlurredBackground = styled.div`
  --gradient: linear-gradient(
    to bottom,
    rgba(var(--c-rgb-black), 0.6),
    var(--c-black) 220px
  );
  min-height: 350px;
  background-size:
    100% 50%,
    auto 50%;
  background-position: top center;
  background-repeat: no-repeat;
  filter: blur(2px);
  position: absolute;
  inset: 0;
`;
const Content = styled.div`
  min-height: inherit;
  position: relative;
`;

type Props = {
  home?: boolean;
  bannerUrl?: string | null;
  children: ReactNode;
  cards?: boolean;
};

export const ClubLayout: React.FC<Props> = ({
  bannerUrl,
  home,
  children,
  cards,
}: Props) => {
  return (
    <Root className={classNames({ home, cards })}>
      {!home && <NoIndex />}
      {home && (
        <BlurredBackground
          style={{
            backgroundImage: `var(--gradient), url(${bannerUrl})`,
          }}
        />
      )}
      <Content>{children}</Content>
    </Root>
  );
};
