import { TypedDocumentNode, gql } from '@apollo/client';
import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import {
  FOOTBALL_PLAY_RIVALS_MY_SQUAD_CREATE,
  FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG,
} from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';

import { hasJoinedSquad } from 'lib/rivals';

import {
  SquadsRedirectQuery,
  SquadsRedirectQueryVariables,
} from './__generated__/index.graphql';

const SQUADS_REDIRECT_QUERY = gql`
  query SquadsRedirectQuery {
    football {
      rivals {
        id
        currentSeason {
          slug
        }
        currentManager {
          id
          ...hasJoinedSquad_footballRivalsCurrentManager
        }
      }
    }
  }
  ${hasJoinedSquad.fragments.footballRivalsCurrentManager}
` as TypedDocumentNode<SquadsRedirectQuery, SquadsRedirectQueryVariables>;

export const SquadsRedirect = () => {
  const { currentUser } = useCurrentUserContext();
  const { data } = useQuery(SQUADS_REDIRECT_QUERY);

  if (!data) return <LoadingIndicator fullHeight />;

  const { currentManager, currentSeason } = data.football.rivals;

  const isInSquad = hasJoinedSquad(currentManager);

  if (isInSquad && currentUser && currentSeason) {
    return (
      <Navigate
        to={generatePath(
          FOOTBALL_PLAY_RIVALS_MY_SQUAD_LEADERBOARD_SEASONSLUG_USERSLUG,
          {
            seasonSlug: currentSeason.slug,
            userSlug: currentUser.slug,
          }
        )}
        replace
      />
    );
  }

  return <Navigate to={FOOTBALL_PLAY_RIVALS_MY_SQUAD_CREATE} replace />;
};
