import classNames from 'classnames';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Shine } from '@sorare/core/src/atoms/ui/Shine';
import {
  CARD_ASPECT_RATIO_SMALL,
  getCardBorderRadiusWithOffsets,
} from '@sorare/core/src/lib/cards';
import { glossary } from '@sorare/core/src/lib/glossary';

const Wrapper = styled.div`
  --border-radius: ${getCardBorderRadiusWithOffsets(24, 8, 8)};
  --title-font-size: 8px;
  --title-height: calc(var(--unit) * 2 + var(--title-font-size));
  --padding: var(--half-unit);
  position: relative;
`;

const NewWrapper = styled(Wrapper)`
  --title-font-size: 8px;
  --title-height: calc(var(--unit) + var(--title-font-size));
  --padding: 2px;
`;

const Background = styled(Shine)`
  background: linear-gradient(to bottom right, rgb(89 116 245), rgb(41 67 204));
  border-radius: var(--border-radius);
  position: absolute;
  z-index: -1;
  top: calc(-1 * var(--title-height));
  left: calc(-1 * var(--padding));
  right: calc(-1 * var(--padding));
  bottom: calc(-1 * var(--padding));
`;

const NewBackground = styled(Shine)`
  background: linear-gradient(
    92deg,
    #fff 0%,
    #eabfe2 28.88%,
    #fff 48.67%,
    #bedecb 64.58%,
    #fff 100%
  );
  border-radius: var(--border-radius);
  position: absolute;
  z-index: -1;
  top: calc(-1 * var(--title-height));
  left: calc(-1 * var(--padding));
  right: calc(-1 * var(--padding));
  bottom: calc(-1 * var(--padding));
`;

const WrapperSmall = styled(Wrapper)`
  --border-radius: ${getCardBorderRadiusWithOffsets(
    48,
    8,
    8,
    CARD_ASPECT_RATIO_SMALL
  )};
  --title-font-size: 6px;
  --padding: calc(0.25 * var(--unit));
  --title-height: calc(var(--half-unit) * 2 + var(--title-font-size));
`;

const Title = styled(Horizontal).attrs({ gap: 0, center: true })`
  font-family: Druk Wide;
  text-align: center;
  color: var(--c-white-100);
  text-transform: uppercase;
  font-size: var(--title-font-size);
  line-height: 1;
  height: var(--title-height);
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 var(--half-unit);

  .newBackground & {
    color: var(--c-black);
  }
`;

const CardWrapper = styled.div`
  position: relative;
`;

type Props = {
  children: ReactNode;
  className?: string;
};

const MVPWrapperDumb = ({
  children,
  title,
  className,
  wrapper: WrapperComponent,
  newBackground,
}: Props & {
  title: string;
  newBackground?: boolean;
  wrapper: typeof Wrapper;
}) => {
  const ActualBackground = newBackground ? NewBackground : Background;

  return (
    <WrapperComponent
      className={classNames(className, { newBackground })}
      title={title}
    >
      <ActualBackground>
        <Title>{title}</Title>
      </ActualBackground>
      <CardWrapper>{children}</CardWrapper>
    </WrapperComponent>
  );
};

export const MVPWrapper = ({ children, className }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <MVPWrapperDumb
      wrapper={Wrapper}
      title={formatMessage({
        id: 'nba.mvp.title',
        defaultMessage: 'Most Valuable Player',
      })}
      className={className}
    >
      {children}
    </MVPWrapperDumb>
  );
};

export const NewMVPWrapper = ({ children, className }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <MVPWrapperDumb
      newBackground
      wrapper={NewWrapper}
      title={formatMessage(glossary.mvp)}
      className={className}
    >
      {children}
    </MVPWrapperDumb>
  );
};

export const MVPWrapperSmall = ({ children, className }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <MVPWrapperDumb
      wrapper={WrapperSmall}
      title={formatMessage({
        id: 'nba.mvp.titleShort',
        defaultMessage: 'MVP',
      })}
      className={className}
    >
      {children}
    </MVPWrapperDumb>
  );
};
