import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
  className?: string;
  gradient?: 'blue';
};

export const Card = ({ size = 2, className, gradient }: Props) => {
  let fill = 'currentColor';
  if (gradient) {
    fill = `url(#card-gradient-${gradient})`;
  }

  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50305 1.875C4.50305 0.839466 5.34252 0 6.37805 0L25.1281 0C26.1636 0 27.0031 0.839466 27.0031 1.875V28.125C27.0031 29.1605 26.1636 30 25.1281 30H6.37805C5.34252 30 4.50305 29.1605 4.50305 28.125L4.50305 1.875ZM8.25305 3.75V26.25H23.2531V3.75L8.25305 3.75ZM15.8702 11.25C15.3525 11.25 14.9327 11.6697 14.9327 12.1875C14.9327 12.7053 15.3525 13.125 15.8702 13.125C16.388 13.125 16.8077 12.7053 16.8077 12.1875C16.8077 11.6697 16.388 11.25 15.8702 11.25ZM11.1827 12.1875C11.1827 9.59866 13.2814 7.5 15.8702 7.5C18.4591 7.5 20.5577 9.59866 20.5577 12.1875C20.5577 14.7763 18.4591 16.875 15.8702 16.875C13.2814 16.875 11.1827 14.7763 11.1827 12.1875ZM15.7531 20.625C14.598 20.625 13.7609 21.5184 13.7609 22.5H10.0109C10.0109 19.3394 12.6365 16.875 15.7531 16.875C18.8696 16.875 21.4952 19.3394 21.4952 22.5H17.7452C17.7452 21.5184 16.9081 20.625 15.7531 20.625Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="card-gradient-blue"
          x1="18.6676"
          y1="36.6476"
          x2="8.67626"
          y2="19.2614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFF7" />
          <stop offset="1" stopColor="#294BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
