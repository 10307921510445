import { addDays } from 'date-fns/esm';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Drawer } from '@sorare/core/src/atoms/layout/Drawer';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Text16, Title2, Title3 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import useScreenSize from '@sorare/core/src/hooks/device/useScreenSize';
import { fiatWallet, glossary } from '@sorare/core/src/lib/glossary';

import { useNextTimeUserShouldSeeActivateFiatWalletOnListing } from 'hooks/offers/useNextTimeUserShouldSeeActivateFiatWalletOnListing';

import cashBackground from './assets/cashBackground.png';

const Wrapper = styled(Vertical).attrs({ gap: 0 })``;
const DrawerRoot = styled(Vertical).attrs({ gap: 0 })`
  background-color: var(--c-dialog-background);
  border-top-left-radius: var(--double-unit);
  border-top-right-radius: var(--double-unit);
  overflow: hidden;
`;

const Center = styled.div`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`;

const Text = styled(Vertical).attrs({ gap: 2, center: true })`
  width: 100%;
  padding: var(--double-unit);
  justify-content: stretch;
`;

const Actions = styled(Vertical)`
  padding: var(--double-unit);
  border-top: 1px solid var(--c-nd-200);
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

type Props = {
  onDismiss: () => void;
  onActivate: () => void;
};

export const ActivateYourFiatWalletInterstitial = ({
  onDismiss: doOnDismiss,
  onActivate,
}: Props) => {
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const { update } = useNextTimeUserShouldSeeActivateFiatWalletOnListing();
  const onDismiss = () => {
    update(addDays(new Date(), 7).toISOString());
    doOnDismiss();
  };

  const renderTitle = () => {
    if (!isTabletOrDesktop) {
      return (
        <Title2 color="var(--c-white)">
          <FormattedMessage
            id="ActivateYourFiatWalletInterstitial.title"
            defaultMessage="Sell your cards for cash and reach more managers"
          />
        </Title2>
      );
    }
    return (
      <Title3 color="var(--c-white)">
        <FormattedMessage
          id="ActivateYourFiatWalletInterstitial.title"
          defaultMessage="Sell your cards for cash and reach more managers"
        />
      </Title3>
    );
  };
  const renderContent = () => (
    <Wrapper>
      <Image src={cashBackground} />
      <Text>
        {renderTitle()}
        <Text16 color="var(--c-nd-600)">
          <FormattedMessage
            id="ActivateYourFiatWalletInterstitial.description"
            defaultMessage="Activate your Cash Wallet and start accepting cash as payment."
          />
        </Text16>
      </Text>
    </Wrapper>
  );

  const renderActions = () => (
    <Actions>
      <Button color="primary" size="medium" onClick={onActivate}>
        <FormattedMessage {...fiatWallet.activateCashWallet} />
      </Button>
      <Button color="tertiary" size="medium" onClick={onDismiss}>
        <FormattedMessage {...glossary.noThanks} />
      </Button>
    </Actions>
  );
  return (
    <>
      <Dialog
        maxWidth="xs"
        open={isTabletOrDesktop}
        footer={<Center>{renderActions()}</Center>}
        onClose={onDismiss}
        hideHeader
      >
        <Center>{renderContent()}</Center>
      </Dialog>
      <Drawer
        open={!isTabletOrDesktop}
        side="bottom"
        onBackdropClick={onDismiss}
        blur
      >
        <DrawerRoot>
          {renderContent()}
          {renderActions()}
        </DrawerRoot>
      </Drawer>
    </>
  );
};
