import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { HeadlineXL, LabelS } from 'atoms/typography';
import { DumbClubAvatar } from 'components/club/DumbClubAvatar';
import { glossary } from 'lib/glossary';
import { FootballTeamLink } from 'routing/FootballTeamLink';

const Root = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content minmax(0, 1fr);
  gap: var(--double-unit);
`;
const ClubAvatar = styled(DumbClubAvatar)`
  --size: calc(6 * var(--unit));
`;
const TeamName = styled(LabelS)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-align: center;
`;
const StyledVertical = styled(Vertical)`
  width: 100%;
`;

type Team = {
  slug: string;
  country: {
    slug: string;
  };
  name: string;
  avatarUrl?: string | null;
  currentRanking?: number | null;
  isNationalTeam: boolean;
};

type TeamAvatarAndNameProps = {
  team: Team;
  isHigherRank: boolean;
  noLinks?: boolean;
};

const TeamAvatarAndName = ({
  team,
  isHigherRank,
  noLinks,
}: TeamAvatarAndNameProps) => {
  const avatar = (
    <ClubAvatar club={{ name: team.name, avatarUrl: team.avatarUrl }} />
  );
  const name = <TeamName as="span">{team.name}</TeamName>;

  return (
    <Vertical gap={0.5} center>
      {noLinks ? (
        avatar
      ) : (
        <FootballTeamLink team={team} isNationalTeam={team.isNationalTeam}>
          {avatar}
        </FootballTeamLink>
      )}
      <StyledVertical center gap={0}>
        {noLinks ? (
          name
        ) : (
          <FootballTeamLink team={team} isNationalTeam={team.isNationalTeam}>
            {name}
          </FootballTeamLink>
        )}
        {team.currentRanking ? (
          <LabelS
            as="span"
            color={isHigherRank ? 'var(--c-score-high)' : 'var(--c-red-600)'}
          >
            <FormattedMessage
              {...glossary.ordinal}
              values={{ ordinal: team.currentRanking }}
            />
          </LabelS>
        ) : null}
      </StyledVertical>
    </Vertical>
  );
};

type Props = {
  homeTeam: Team;
  awayTeam: Team;
  title: ReactNode;
  subtitle: ReactNode;
  noLinks?: boolean;
};

export const HeaderTeamsMatchup = ({
  homeTeam,
  awayTeam,
  title,
  subtitle,
  noLinks,
}: Props) => {
  return (
    <Root>
      <TeamAvatarAndName
        team={homeTeam}
        isHigherRank={
          (homeTeam.currentRanking || Infinity) <
          (awayTeam.currentRanking || Infinity)
        }
        noLinks={noLinks}
      />
      <Vertical center gap={0.5}>
        <HeadlineXL as="span">{title}</HeadlineXL>
        {subtitle}
      </Vertical>
      <TeamAvatarAndName
        team={awayTeam}
        isHigherRank={
          (awayTeam.currentRanking || Infinity) <
          (homeTeam.currentRanking || Infinity)
        }
        noLinks={noLinks}
      />
    </Root>
  );
};
