import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { ComponentProps, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { Checkbox } from '@sorare/core/src/atoms/inputs/Checkbox';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Caption, Text16 } from '@sorare/core/src/atoms/typography';
import Dialog from '@sorare/core/src/components/dialog';
import {
  GraphQLResult,
  GraphqlForm,
} from '@sorare/core/src/components/form/Form';
import MonetaryInputField from '@sorare/core/src/components/form/Form/MonetaryInputField';
import { useConfigContext } from '@sorare/core/src/contexts/config';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { AcceptedCurrenciesValue } from '@sorare/core/src/hooks/useAcceptedCurrencies';
import { useCurrentUserAcceptedCurrencies } from '@sorare/core/src/hooks/useCurrentUserAcceptedCurrencies';
import {
  MonetaryAmountOutput,
  useMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import {
  MonetaryAmountCurrency,
  monetaryAmountFragment,
} from '@sorare/core/src/lib/monetaryAmount';

import {
  CreateOrUpdateSingleBuyOfferMinPriceMutation,
  CreateOrUpdateSingleBuyOfferMinPriceMutationVariables,
  SetupMinimumPriceDialog_anyCard,
} from './__generated__/index.graphql';

const messages = defineMessages({
  amountPrivate: {
    id: 'SetupMinPriceDialog.amountPrivate',
    defaultMessage: 'Keep the amount secret',
  },
  disclaimer: {
    id: 'SetupMinPriceDialog.disclaimer',
    defaultMessage: 'You will not receive the offers under your minimum price',
  },
  disclaimerLegend: {
    id: 'SetupMinPriceDialog.disclaimerLegend',
    defaultMessage: '* Minimum price you receive after fees',
  },
  submit: {
    id: 'SetupMinPriceDialog.submit',
    defaultMessage: 'Save',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
  card: SetupMinimumPriceDialog_anyCard;
}

const SetupMinimumPriceDialogFragments = {
  anyCard: gql`
    fragment SetupMinimumPriceDialog_anyCard on AnyCardInterface {
      slug
      assetId
      privateMinPrices {
        ...MonetaryAmountFragment_monetaryAmount
      }
      publicMinPrices {
        ...MonetaryAmountFragment_monetaryAmount
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<SetupMinimumPriceDialog_anyCard>,
};

const CREATE_OR_UPDATE_SINGLE_BUY_OFFER_MIN_PRICE_MUTATION = gql`
  mutation CreateOrUpdateSingleBuyOfferMinPriceMutation(
    $input: createOrUpdateSingleBuyOfferMinPriceInput!
  ) {
    createOrUpdateSingleBuyOfferMinPrice(input: $input) {
      card {
        slug
        ...SetupMinimumPriceDialog_anyCard
      }
      errors {
        code
        message
        path
      }
    }
  }
  ${SetupMinimumPriceDialogFragments.anyCard}
` as TypedDocumentNode<
  CreateOrUpdateSingleBuyOfferMinPriceMutation,
  CreateOrUpdateSingleBuyOfferMinPriceMutationVariables
>;

const Content = styled(Vertical).attrs({ gap: 3 })`
  padding: var(--quadruple-unit) var(--double-unit);
  border-top: solid 1px var(--c-nd-100);
`;
const StyledGraphqlForm = styled(GraphqlForm)`
  margin-bottom: 0;
  position: absolute;
`;
const SubmitButtonWrapper = styled.div`
  padding: 0 var(--double-unit) var(--double-unit);
`;
const CheckboxCtn = styled(Horizontal)`
  padding-top: var(--unit);
  border-top: solid 1px var(--c-nd-100);
`;

const SetupMinimumPriceDialog = ({ onClose, open, card }: Props) => {
  const { formatMessage } = useIntl();
  const { privateMinPrices, publicMinPrices } = card;
  const { marketFeeRateBasisPoints } = useConfigContext();
  const { acceptedCurrencies } = useCurrentUserAcceptedCurrencies();
  const { toMonetaryAmount } = useMonetaryAmount();
  const [updateMinPrice] = useMutation(
    CREATE_OR_UPDATE_SINGLE_BUY_OFFER_MIN_PRICE_MUTATION
  );
  const { fiatCurrency } = useCurrentUserContext();

  const minPriceMonetaryAmount = privateMinPrices || publicMinPrices || null;

  const [keepPrivate, setKeepPrivate] = useState(!!privateMinPrices);

  const handleChecked: ComponentProps<typeof Checkbox>['onChange'] = event =>
    setKeepPrivate(event.target.checked);

  const currency =
    privateMinPrices?.referenceCurrency ||
    publicMinPrices?.referenceCurrency ||
    acceptedCurrencies === AcceptedCurrenciesValue.ETH
      ? SupportedCurrency.WEI
      : SupportedCurrency[fiatCurrency.code];

  const [monetaryAmount, setMonetaryAmount] = useState<MonetaryAmountOutput>(
    minPriceMonetaryAmount
      ? toMonetaryAmount(minPriceMonetaryAmount)
      : toMonetaryAmount({
          [currency.toLowerCase()]: '0',
          referenceCurrency: currency,
        })
  );

  const submit = async (
    attributes: {
      price: string;
    },
    onResult: (result: GraphQLResult) => void,
    onCancel: () => void
  ) => {
    await updateMinPrice({
      variables: {
        input: {
          minPrice: {
            amount:
              monetaryAmount[
                currency.toLowerCase() as MonetaryAmountCurrency
              ].toString(),
            currency,
          },
          isPrivate: keepPrivate,
          assetId: card.assetId,
        },
      },
    }).then(
      ({ data: mutationData }) => {
        if (mutationData) {
          onResult(mutationData.createOrUpdateSingleBuyOfferMinPrice!);
        }
      },
      () => {
        setTimeout(() => onCancel(), 500);
      }
    );
  };

  return (
    <StyledGraphqlForm
      onSubmit={(attributes, onResult, onCancel) => {
        submit(attributes, onResult, onCancel);
      }}
      onSuccess={onClose}
      render={(Error, SubmitButton) => (
        <Dialog
          disablePortal
          maxWidth="xs"
          open={open}
          onClose={onClose}
          title={
            <Text16 className="text-center">
              <FormattedMessage
                id="MinPrice.setupMinPrice"
                defaultMessage="Setup a minimum price"
              />
            </Text16>
          }
          footer={
            <SubmitButtonWrapper>
              <SubmitButton size="medium" fullWidth>
                {formatMessage(messages.submit)}
              </SubmitButton>
            </SubmitButtonWrapper>
          }
        >
          <Content>
            <div>
              <Text16 bold className="text-center">
                <FormattedMessage
                  id="SetupMinPriceDialog.yourMinimumPrice"
                  defaultMessage="Your minimum price"
                />
              </Text16>
              <Error />
              <MonetaryInputField
                name="price"
                defaultValue={monetaryAmount}
                defaultCurrency={
                  currency === SupportedCurrency.WEI
                    ? Currency.ETH
                    : Currency.FIAT
                }
                onChange={amount => {
                  setMonetaryAmount(amount);
                }}
              />
            </div>
            <Vertical gap={2}>
              <Text16>
                <FormattedMessage {...messages.disclaimer} />
                {marketFeeRateBasisPoints > 0 && <>*</>}
              </Text16>
              {marketFeeRateBasisPoints > 0 && (
                <Caption color="var(--c-nd-600)">
                  <FormattedMessage {...messages.disclaimerLegend} />
                </Caption>
              )}
            </Vertical>
            <CheckboxCtn>
              <Checkbox
                checked={keepPrivate}
                onChange={handleChecked}
                label={
                  <Text16 color="var(--c-white)">
                    {formatMessage(messages.amountPrivate)}
                  </Text16>
                }
              />
            </CheckboxCtn>
          </Content>
        </Dialog>
      )}
    />
  );
};

SetupMinimumPriceDialog.fragments = SetupMinimumPriceDialogFragments;

export default SetupMinimumPriceDialog;
