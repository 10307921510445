import { defineMessages } from 'react-intl';

import { Position } from '@sorare/core/src/__generated__/globalTypes';

export type BattingStatKey =
  | 'runs'
  | 'rbi'
  | 'singles'
  | 'doubles'
  | 'triples'
  | 'homeRuns'
  | 'walks'
  | 'strikeouts'
  | 'stolenBases'
  | 'hitByPitch'
  | 'stealsCaught';
export type PitchingStatKey =
  | 'outs'
  | 'strikeouts'
  | 'hitsAllowed'
  | 'earnedRuns'
  | 'walks'
  | 'hitBatsmen'
  | 'win'
  | 'save'
  | 'hold'
  | 'reliefAppearance';

export const PITCHING_STAT_KEYS: readonly PitchingStatKey[] = [
  'outs',
  'strikeouts',
  'hitsAllowed',
  'earnedRuns',
  'walks',
  'hitBatsmen',
  'win',
  'save',
  'hold',
  'reliefAppearance',
] as const;

export const BATTING_STAT_KEYS: readonly BattingStatKey[] = [
  'runs',
  'rbi',
  'singles',
  'doubles',
  'triples',
  'homeRuns',
  'walks',
  'strikeouts',
  'stolenBases',
  'hitByPitch',
  'stealsCaught',
] as const;

export const shortPitchingStatMessages: Record<PitchingStatKey, string> = {
  outs: 'IP',
  strikeouts: 'K',
  hitsAllowed: 'H',
  earnedRuns: 'ER',
  walks: 'BB',
  hitBatsmen: 'HB',
  win: 'W',
  save: 'SV',
  hold: 'HLD',
  reliefAppearance: 'RA',
};

export const longPitchingStatMessages: Record<PitchingStatKey, string> = {
  outs: 'Innings Pitched',
  strikeouts: 'Strikeouts',
  hitsAllowed: 'Hits Allowed',
  earnedRuns: 'Earned Runs',
  walks: 'Walks',
  hitBatsmen: 'Hit Batsmen',
  win: 'Win',
  save: 'Save',
  hold: 'Hold',
  reliefAppearance: 'Relief Appearance',
};

export const shortBattingStatMessages: Record<BattingStatKey, string> = {
  runs: 'R',
  rbi: 'RBI',
  singles: '1B',
  doubles: '2B',
  triples: '3B',
  homeRuns: 'HR',
  walks: 'BB',
  strikeouts: 'K',
  stolenBases: 'SB',
  hitByPitch: 'HBP',
  stealsCaught: 'CS',
};

export const longBattingStatMessages: Record<BattingStatKey, string> = {
  runs: 'Runs',
  rbi: 'RBI',
  singles: 'Singles',
  doubles: 'Doubles',
  triples: 'Triples',
  homeRuns: 'Home Runs',
  walks: 'Walks',
  strikeouts: 'Strikeouts',
  stolenBases: 'Stolen Bases',
  hitByPitch: 'Hit By Pitch',
  stealsCaught: 'Caught Stealing',
};

export const mlbGlossary = defineMessages({
  pitchers: {
    id: 'MLB.Pitchers',
    defaultMessage: 'Pitchers',
  },
  reliefPitchers: {
    id: 'MLB.ReliefPitchers',
    defaultMessage: 'Relief Pitchers',
  },
  probablePitcher: {
    id: 'MLB.ProbablePitcher',
    defaultMessage: 'Probable Pitcher',
  },
  batters: {
    id: 'MLB.Batters',
    defaultMessage: 'Batters',
  },
});

const anyHitterPositions = [
  Position.baseball_first_base,
  Position.baseball_third_base,
  Position.baseball_designated_hitter,
  Position.baseball_catcher,
  Position.baseball_catcher,
  Position.baseball_shortstop,
  Position.baseball_outfield,
];

const flexPositions = [
  Position.baseball_starting_pitcher,
  Position.baseball_relief_pitcher,
  Position.baseball_first_base,
  Position.baseball_third_base,
  Position.baseball_designated_hitter,
  Position.baseball_catcher,
  Position.baseball_catcher,
  Position.baseball_shortstop,
  Position.baseball_outfield,
];

export const isAnyHitter = (positions: Position[]) =>
  anyHitterPositions.every(p => positions.includes(p));

export const isFlex = (positions: Position[]) =>
  flexPositions.every(p => positions.includes(p));

export const messages = defineMessages({
  startingPitcher: {
    id: 'slot.startingPitcher.name',
    defaultMessage: 'Starting Pitcher',
  },
  reliefPitcher: {
    id: 'slot.reliefPitcher.name',
    defaultMessage: 'Relief Pitcher',
  },
  cornerInfielder: {
    id: 'slot.cornerInfielder.name',
    defaultMessage: 'Corner Infielder',
  },
  middleInfielder: {
    id: 'slot.middleInfielder.name',
    defaultMessage: 'Middle Infielder',
  },
  outfielder: { id: 'slot.outfielder.name', defaultMessage: 'Outfielder' },
  hitter: { id: 'slot.hitter.name', defaultMessage: 'Hitter' },
  flex: { id: 'slot.flex.name', defaultMessage: 'Flex' },
  hintHitter: { id: 'slot.extra.hint', defaultMessage: 'Any hitter' },
  hintFlex: { id: 'slot.extra.flex', defaultMessage: 'Any player' },
});
