import { TypedDocumentNode, gql } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { SupportedCurrency } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { MonetaryInput } from '@sorare/core/src/atoms/inputs/MonetaryInput';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { Text16 } from '@sorare/core/src/atoms/typography';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';

import { usePaymentContext } from 'components/buyActions/Context';
import { PaymentProvider_auction } from 'components/buyActions/PaymentProvider/__generated__/fragments.graphql';
import { BuyConfirmationProviderStateProps } from 'contexts/buyingConfirmation';

import { PaymentBoxBlock } from '../ui';
import AuctionMetas from './AuctionMetas';
import { BidContent_auction } from './__generated__/index.graphql';

const messages = defineMessages({
  bidInputTitle: {
    id: 'NewPaymentBox.bidInputTitle',
    defaultMessage: 'Your bid',
  },
  autoBidInputTitle: {
    id: 'NewPaymentBox.autoBidInputTitle',
    defaultMessage: 'Your max bid',
  },
  outBidInputTitle: {
    id: 'NewPaymentBox.outBidInputTitle',
    defaultMessage: 'New max bid',
  },
});

interface Props {
  displayEth: boolean;
  setDisplayEth: (value: boolean) => void;
  auction: PaymentProvider_auction;
  loadingPolling?: boolean;
  outBidByAutoBid?: boolean;
  isLoading?: boolean;
  setOutBidCallback?: (callback: (amount: string) => void) => void;
  confirmationProviderStateProps?: BuyConfirmationProviderStateProps;
  referenceCurrency: SupportedCurrency;
  topUpWalletWithCC?: boolean;
}

const InputTitle = styled(Text16)`
  text-align: center;
  display: flex;
  align-items: center;
  gap: var(--unit);
  justify-content: center;
`;

const WarningContainer = styled.div`
  color: var(--c-red-600);
  border-radius: var(--unit);
  text-align: center;
`;

const BidInput = styled.div`
  margin-bottom: var(--double-unit);
`;

const OutBidByAutoBidWarning = () => {
  return (
    <WarningContainer>
      <FormattedMessage
        id="NewPaymentBox.outBidByAutoBid"
        defaultMessage="Another bidder is winning. Increase your bid."
      />
    </WarningContainer>
  );
};

const BidContent = (props: Props) => {
  const {
    currency,
    fiatCurrency: { code },
    walletPreferences: { onlyShowFiatCurrency },
  } = useCurrentUserContext();
  const { formatWei, formatNumber } = useIntlContext();

  const {
    displayEth,
    setDisplayEth,
    auction,
    outBidByAutoBid = false,
    isLoading,
    setOutBidCallback,
  } = props;

  const monetaryAmountIndex = getMonetaryAmountIndex(code) as
    | 'eur'
    | 'usd'
    | 'gbp';

  const {
    defaultMonetaryAmount,
    monetaryAmount,
    updateMonetaryAmount,
    fiatCurrency,
    amountTooLow,
    isFiat,
    setReferenceCurrency,
  } = usePaymentContext();

  const ethMinimumAmount = formatWei(defaultMonetaryAmount.wei, undefined, {
    maximumFractionDigits: 4,
  });
  const fiatMinimumAmount = useMemo(
    () =>
      formatNumber(defaultMonetaryAmount[monetaryAmountIndex] / 100, {
        style: 'currency',
        currency: fiatCurrency,
      }),
    [formatNumber, defaultMonetaryAmount, monetaryAmountIndex, fiatCurrency]
  );

  const autoBidTitle = outBidByAutoBid
    ? messages.outBidInputTitle
    : messages.autoBidInputTitle;

  const inputTitle = auction?.autoBid ? autoBidTitle : messages.bidInputTitle;

  const onChange = useCallback(
    (
      newMonetaryAmount: MonetaryAmountOutput,
      _: HTMLInputElement,
      referenceCurrency: SupportedCurrency
    ) => {
      updateMonetaryAmount(newMonetaryAmount, referenceCurrency);
    },
    [updateMonetaryAmount]
  );

  return (
    <>
      <AuctionMetas auction={auction} />
      <PaymentBoxBlock>
        {outBidByAutoBid && !isLoading && <OutBidByAutoBidWarning />}
        <div>
          <BidInput>
            <InputTitle bold>
              <FormattedMessage {...inputTitle} />
              {auction?.autoBid && (
                <Tooltip
                  placement="top"
                  title={
                    <FormattedMessage
                      id="NewPaymentBox.autoBidTooltip"
                      defaultMessage="Sorare will continue to bid up on your behalf until your max bid is reached."
                    />
                  }
                >
                  <FontAwesomeIcon
                    color="var(--c-nd-600)"
                    icon={faInfoCircle}
                  />
                </Tooltip>
              )}
            </InputTitle>
            <MonetaryInput
              defaultValue={monetaryAmount}
              onlyShowFiatCurrency={onlyShowFiatCurrency}
              fiatCurrency={code}
              onChange={onChange}
              defaultCurrency={currency}
              onToggleEthDisplay={(b: boolean) => {
                setDisplayEth(b);
                setReferenceCurrency(
                  b ? SupportedCurrency.WEI : (code as SupportedCurrency)
                );
              }}
              useSecondaryInput
              controlledEthDisplay={!isFiat}
              setOutBidCallback={setOutBidCallback}
              error={
                amountTooLow &&
                !isLoading && (
                  <WarningContainer>
                    <FormattedMessage
                      id="BidInput.amountTooLow"
                      defaultMessage="Please bid {value} or higher"
                      values={{
                        value: displayEth
                          ? ethMinimumAmount
                          : fiatMinimumAmount,
                      }}
                    />
                  </WarningContainer>
                )
              }
              autoFocus={false}
            />
          </BidInput>
        </div>
      </PaymentBoxBlock>
    </>
  );
};

BidContent.fragments = {
  auction: gql`
    fragment BidContent_auction on TokenAuction {
      id
      autoBid
      ...AuctionMetas_auction
    }
    ${AuctionMetas.fragments.auction}
  ` as TypedDocumentNode<BidContent_auction>,
};

export default BidContent;
