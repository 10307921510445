import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { Text14 } from '@sorare/core/src/atoms/typography';

import {
  ItemImgContainer,
  ItemInfosContainer,
  ItemPropertiesContainer,
} from 'components/ItemPreview/ui';
import CardDescription from 'components/token/CardDescription';

import Bundle from '../Bundle';
import { AuctionDetails } from './AuctionDetails';
import { AuctionImg } from './AuctionImg';
import { Auction_auction } from './__generated__/index.graphql';

type Props = {
  auction: Auction_auction;
};

const Root = styled(Horizontal).attrs({ gap: 1.5 })``;

export const Auction = ({ auction }: Props) => {
  return (
    <Root>
      <ItemImgContainer>
        <AuctionImg auction={auction} />
      </ItemImgContainer>
      <ItemInfosContainer>
        {auction.cards.length > 1 && (
          <ItemPropertiesContainer>
            <Bundle />
          </ItemPropertiesContainer>
        )}
        {auction.cards.length === 1 && (
          <CardDescription
            card={auction.cards[0]}
            Details={Text14}
            detailsColor="var(--c-nd-600)"
          />
        )}
        <AuctionDetails
          auction={auction}
          isDesktopLayout={false}
          allowColumnLayout
        />
      </ItemInfosContainer>
    </Root>
  );
};

Auction.fragments = {
  auction: gql`
    fragment Auction_auction on TokenAuction {
      id
      cards: anyCards {
        slug
        ...CardDescription_anyCard
      }
      ...AuctionImg_auction
      ...AuctionDetails_auction
    }
    ${AuctionImg.fragments.auction}
    ${AuctionDetails.fragments.auction}
    ${CardDescription.fragments.anyCard}
  ` as TypedDocumentNode<Auction_auction>,
};
