import { TypedDocumentNode, gql } from '@apollo/client';
import BigNumber from 'bignumber.js';

import {
  FiatCurrency,
  Rates,
  SupportedCurrency,
} from '__generated__/globalTypes';
import { BigToBigInt } from 'bigint';

import { MonetaryAmountFragment_monetaryAmount } from './__generated__/monetaryAmount.graphql';
import { CurrencyCode } from './fiat';

const ETH_IN_WEI = new BigNumber('1000000000000000000');
const ETH_PRECISION = 4;

const currencies = ['eur', 'usd', 'gbp', 'wei'] as const;
export type MonetaryAmountCurrency = (typeof currencies)[number];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fiatCurrencies = ['eur', 'usd', 'gbp'] as const;
export type MonetaryAmountFiatCurrency = (typeof fiatCurrencies)[number];

export interface MonetaryAmountParams {
  referenceCurrency: SupportedCurrency;
  eur?: bigint | number | string | null;
  usd?: bigint | number | string | null;
  gbp?: bigint | number | string | null;
  wei?: bigint | string | null;
}

export const getMonetaryAmountIndex = (
  currency: SupportedCurrency | FiatCurrency | CurrencyCode
) => {
  return currency.toLowerCase() as MonetaryAmountCurrency;
};

export const getFiatMonetaryAmountIndex = (
  currency: FiatCurrency | CurrencyCode
) => {
  return currency.toLowerCase() as MonetaryAmountFiatCurrency;
};

export const roundWeiHalfUp = (BN: BigNumber) => {
  return BN.dividedBy(ETH_IN_WEI)
    .decimalPlaces(ETH_PRECISION, BigNumber.ROUND_HALF_UP)
    .multipliedBy(ETH_IN_WEI);
};

const getMinorRate = (rates: Rates, currency: MonetaryAmountFiatCurrency) => {
  const key: keyof Rates = `${currency}Minor`;
  return new BigNumber(rates[key]);
};

const rate = (
  referenceCurrency: MonetaryAmountCurrency,
  currency: MonetaryAmountCurrency,
  rates: Rates
) => {
  if (referenceCurrency === 'wei') {
    return getMinorRate(rates, currency as MonetaryAmountFiatCurrency);
  }
  if (currency === 'wei') {
    return new BigNumber(1).dividedBy(getMinorRate(rates, referenceCurrency));
  }

  return getMinorRate(rates, currency).dividedBy(
    getMinorRate(rates, referenceCurrency)
  );
};

class MonetaryAmount {
  public static convert(
    referenceCurrency: MonetaryAmountCurrency,
    referenceAmount: BigNumber,
    currency: MonetaryAmountCurrency,
    rates: Rates
  ): BigNumber {
    if (referenceCurrency === currency) return referenceAmount;

    if (referenceCurrency === 'wei') {
      return referenceAmount
        .dividedBy(ETH_IN_WEI)
        .multipliedBy(rate(referenceCurrency, currency, rates))
        .decimalPlaces(0, BigNumber.ROUND_HALF_UP);
    }
    if (currency === 'wei') {
      return referenceAmount
        .multipliedBy(rate(referenceCurrency, currency, rates))
        .decimalPlaces(ETH_PRECISION, BigNumber.ROUND_HALF_UP)
        .multipliedBy(ETH_IN_WEI);
    }

    return referenceAmount
      .multipliedBy(rate(referenceCurrency, currency, rates))
      .decimalPlaces(0, BigNumber.ROUND_HALF_UP);
  }

  private referenceCurrency: MonetaryAmountCurrency;

  private eur: BigNumber | null;

  private usd: BigNumber | null;

  private gbp: BigNumber | null;

  private wei: bigint | null;

  constructor(params: MonetaryAmountParams) {
    this.referenceCurrency =
      params.referenceCurrency.toLowerCase() as MonetaryAmountCurrency;
    this.eur = params.eur != null ? new BigNumber(params.eur.toString()) : null;
    this.usd = params.usd != null ? new BigNumber(params.usd.toString()) : null;
    this.gbp = params.gbp != null ? new BigNumber(params.gbp.toString()) : null;
    this.wei = params.wei != null ? BigInt(params.wei) : null;

    if (this.referenceValue === null) {
      throw new Error('Reference currency is required');
    }

    currencies.forEach(currency => {
      const val = this[currency];
      if (typeof val !== 'bigint' && val?.isNaN()) {
        throw new Error(
          `Invalid ${currency} value (received: ${params[currency]})`
        );
      }
    });
  }

  public inCurrencies(rates: Rates) {
    return {
      eur: this.inCurrency('eur', rates) as number,
      usd: this.inCurrency('usd', rates) as number,
      gbp: this.inCurrency('gbp', rates) as number,
      wei: BigInt(this.inCurrency('wei', rates)),
    };
  }

  public inCurrency(
    currency: MonetaryAmountCurrency,
    rates: Rates
  ): bigint | number {
    if (this[currency]) {
      if (currency === 'wei') return this[currency]!;

      return this[currency]!.toNumber();
    }

    const value = this.bigValueAbove(currency, rates);

    return currency === 'wei' ? BigToBigInt(value) : value.toNumber();
  }

  private bigValueAbove(
    currency: MonetaryAmountCurrency,
    rates: Rates
  ): BigNumber {
    let bigValue = this.convert(currency, rates);
    const step =
      currency === 'wei'
        ? new BigNumber(ETH_IN_WEI).dividedBy(10 ** ETH_PRECISION)
        : 1;
    let bigValueInReferenceCurrency: BigNumber;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      bigValueInReferenceCurrency = MonetaryAmount.convert(
        currency,
        bigValue,
        this.referenceCurrency,
        rates
      );

      if (
        bigValueInReferenceCurrency.isNaN() ||
        bigValueInReferenceCurrency.isZero()
      ) {
        return new BigNumber(0);
      }
      if (
        bigValueInReferenceCurrency.isGreaterThanOrEqualTo(
          this.referenceValue.toString()
        )
      ) {
        return bigValue;
      }

      bigValue = bigValue.plus(step);
    }
  }

  private convert(currency: MonetaryAmountCurrency, rates: Rates) {
    return MonetaryAmount.convert(
      this.referenceCurrency,
      new BigNumber(this.referenceValue.toString()),
      currency,
      rates
    );
  }

  private get referenceValue() {
    return this[this.referenceCurrency]!;
  }
}

export const monetaryAmountFragment = gql`
  fragment MonetaryAmountFragment_monetaryAmount on MonetaryAmount {
    eur
    usd
    gbp
    wei
    referenceCurrency
  }
` as TypedDocumentNode<MonetaryAmountFragment_monetaryAmount>;

export default MonetaryAmount;
